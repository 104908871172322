import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  Spacing,
  Typography,
  colorTextDefault,
  gridUnit,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { SquareButton } from '../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { NavigateAction } from '../../constants/NavigateAction.ts';
import { CalendarArrangementSwitcher } from '../../containers/CalendarArrangementSwitcher.tsx';
import { CalendarEmptyState } from '../../containers/CalendarEmptyState.tsx';
import { CalendarView } from '../../models/CalendarView.ts';
import { Legend } from './Legend.tsx';

interface ICalendarToolbarProps {
  readonly calendarView: CalendarView;
  readonly isCurrentMonthLoaded: boolean;
  readonly label: string;
  readonly onNavigate: (navigate: NavigateAction) => void;
  readonly onNext: () => void;
  readonly onPrev: () => void;
}

export const CalendarToolbar: React.FC<ICalendarToolbarProps> = ({
  calendarView,
  isCurrentMonthLoaded,
  label,
  onNavigate,
  onNext,
  onPrev,
}) => {
  const onNextClicked = (): void => {
    onNext();
    onNavigate(NavigateAction.next);
  };

  const onPrevClicked = (): void => {
    onPrev();
    onNavigate(NavigateAction.prev);
  };

  const onTodayClicked = (): void => {
    onNavigate(NavigateAction.today);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        gap={Spacing.L}
        marginRight={Spacing.XL}
        flexWrap="wrap"
      >
        <Inline spacing={Spacing.S}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            width={gridUnit * 30}
          >
            <SquareButton
              iconName={IconName.ChevronLeft}
              onClick={onPrevClicked}
              style={ButtonStyle.Tertiary}
              tooltipPlacement="bottom-start"
              tooltipText="Previous month"
              {...getDataUiActionAttribute(DataUiAction.GoToPreviousMonth)}
            />
            <Box
              color={colorTextDefault}
              typography={Typography.HeadlineMedium}
              {...getDataUiElementAttribute(DataUiElement.CalendarMonthName)}
            >
              {label}
            </Box>
            <SquareButton
              iconName={IconName.ChevronRight}
              onClick={onNextClicked}
              style={ButtonStyle.Tertiary}
              tooltipPlacement="bottom-start"
              tooltipText="Next month"
              {...getDataUiActionAttribute(DataUiAction.GoToNextMonth)}
            />
          </Box>
          <Inline spacing={Spacing.S}>
            <Button
              customButtonClassName="calendar__today"
              onClick={onTodayClicked}
              style={ButtonStyle.Tertiary}
            >
              Today
            </Button>
            <CalendarArrangementSwitcher />
          </Inline>
        </Inline>
        <Legend />
      </Box>
      {!isCurrentMonthLoaded && (
        <div className="calendar__overlay-wrapper">
          <div className="calendar__overlay">
            <Loader />
          </div>
        </div>
      )}
      {calendarView !== CalendarView.Items && (
        <div className="calendar__overlay-wrapper">
          <div className="calendar__overlay">
            <CalendarEmptyState type={calendarView} />
          </div>
        </div>
      )}
    </>
  );
};

CalendarToolbar.displayName = 'CalendarToolbar';
