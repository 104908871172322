export enum AiActionName {
  CategorizeItem = 'CategorizeItem',
  ChangeTone = 'ChangeTone',
  MatchWritingStyleOfItem = 'MatchWritingStyleOfItem',
  CreateTitle = 'CreateTitle',
  DescribeImage = 'DescribeImage',
  ImproveContentByBrief = 'ImproveContentByBrief',
  MakeShorter = 'MakeShorter',
  PlainTextInlineInstruction = 'PlainTextInlineOpenPrompt',
  ReviewContent = 'ReviewContent',
  RichTextInlineInstruction = 'RichTextInlineOpenPrompt',
  Summarize = 'Summarize',
  TranslateContent = 'TranslateContent',
}
