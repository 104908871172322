import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { FloatingEditorPosition } from '../../../../types/floatingEditorPosition.ts';

interface ITogglePositionActionProps {
  readonly onTogglePosition: () => void;
  readonly position: FloatingEditorPosition;
}

const propTypes: PropTypesShape<ITogglePositionActionProps> = {
  onTogglePosition: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
};

export const TogglePositionAction: React.FC<ITogglePositionActionProps> = ({
  onTogglePosition,
  position,
}) => {
  const switchEditorIcon =
    position === FloatingEditorPosition.Left ? IconName.ArrowRight : IconName.ArrowLeft;
  const switchEditorTooltip = position === FloatingEditorPosition.Left ? 'Move right' : 'Move left';

  return (
    <SquareButton
      iconName={switchEditorIcon}
      onClick={onTogglePosition}
      style={ButtonStyle.Quinary}
      size={SquareButtonSize.Quinary}
      tooltipText={switchEditorTooltip}
      ariaLabel={switchEditorTooltip}
    />
  );
};

TogglePositionAction.displayName = 'TogglePositionAction';
TogglePositionAction.propTypes = propTypes;
