import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import {
  FullScreenModalDialog,
  IFullScreenModalDialogProps,
} from '../../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { ShortcutSymbols } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AssetLibrary } from '../../../containers/AssetListing/AssetLibrary.tsx';
import { AssetsOrderBy } from '../../../types/orderBy.type.ts';

export interface IModalAssetSelectorOwnProps
  extends Pick<IFullScreenModalDialogProps, 'onClose' | 'onReturnFocus'> {
  readonly editedEntityName: string;
  readonly isLinkSelector?: boolean;
  readonly showImagesOnly?: boolean;
  readonly onSelect: (assetId: Uuid) => void;
}

interface IModalAssetSelectorProps extends IModalAssetSelectorOwnProps {
  readonly onInit: (orderBy: AssetsOrderBy) => void;
}

export const ModalAssetSelector: React.FC<IModalAssetSelectorProps> = ({
  editedEntityName,
  isLinkSelector,
  onSelect,
  onClose,
  onInit,
  onReturnFocus,
  showImagesOnly,
}) => {
  const onCancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClose?.();
  };

  const title = isLinkSelector
    ? `Select an asset to link to ${editedEntityName}`
    : `Insert an asset to ${editedEntityName}`;

  return (
    <FullScreenModalDialog
      bodyPadding="none"
      cancelAction={{
        onClick: onCancelClick,
        tooltipText: 'Cancel',
        tooltipShortcuts: ShortcutSymbols.Escape,
        ...getDataUiActionAttribute(DataUiAction.Cancel),
      }}
      headline={title}
      isDismissable
      isOpen
      onClose={onClose}
      onReturnFocus={onReturnFocus}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <Box
        className="canvas__workspace canvas__workspace--in-modal"
        height="100%"
        paddingTop={Spacing.XL}
      >
        <AssetLibrary
          allowSelect={false}
          clearAssetSelection={noOperation}
          deselectAssets={noOperation}
          onAssetClick={onSelect}
          onAssetDoubleClick={onSelect}
          onInit={onInit}
          selectAssets={noOperation}
          selectedAssets={noSelectedAssets}
          showImagesOnly={showImagesOnly}
          toggleAssetSelection={noOperation}
        />
      </Box>
    </FullScreenModalDialog>
  );
};

ModalAssetSelector.displayName = 'ModalAssetSelector';

const noSelectedAssets = new Set<Uuid>();
