import { Key, Node } from '@react-types/shared';
import { ReactElement } from 'react';
import { VerticalMenuProps } from '../VerticalMenu.tsx';
import { VerticalMenuItem } from '../types.ts';
import { isItemNode } from '../utils/utils.tsx';

export interface IItemProps<TItem extends VerticalMenuItem<TItem>>
  extends Pick<VerticalMenuProps<TItem>, 'pinnedItemId' | 'renderItem'> {
  readonly expandedKeys?: Set<Key>;
  readonly item: Node<TItem>;
  readonly level?: number;
}

export const Item = <TItem extends VerticalMenuItem<TItem>>(
  props: IItemProps<TItem>,
): ReactElement | null => {
  const { expandedKeys, item, level = 0, pinnedItemId, renderItem, ...otherProps } = props;

  if (item.key === pinnedItemId) {
    return null;
  }

  const isExpanded = item.hasChildNodes && expandedKeys?.has(item.key);

  return (
    <>
      {renderItem({
        ...otherProps,
        item,
        level,
      })}
      {isExpanded &&
        Array.from(item.childNodes).map(
          (childNode) =>
            isItemNode(childNode) && (
              <Item
                key={childNode.key}
                level={level + 1}
                {...{
                  expandedKeys,
                  item: childNode,
                  pinnedItemId,
                  renderItem,
                }}
              />
            ),
        )}
    </>
  );
};

Item.displayName = 'Item';
