import { Item, Section } from '@react-stately/collections';
import { Node } from '@react-types/shared';
import {
  ObjectWithDataAttribute,
  getDataUiObjectNameAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { VerticalMenuItem, VerticalMenuSection } from '../types.ts';
import { VerticalMenuState } from '../useNonAccessibleVerticalMenu.ts';

export const isSection = <TItem extends VerticalMenuItem<TItem>>(
  item: VerticalMenuSection<TItem> | TItem,
): item is VerticalMenuSection<TItem> => (item as VerticalMenuSection<TItem>).type === 'section';

export const isSectionNode = <TItem extends VerticalMenuItem<TItem>>(
  node: Node<VerticalMenuSection<TItem> | TItem>,
): node is Node<VerticalMenuSection<TItem>> =>
  (node as Node<VerticalMenuSection<TItem>>).type === 'section';

export const isItem = <TItem extends VerticalMenuItem<TItem>>(
  item: VerticalMenuSection<TItem> | TItem,
): item is TItem => (item as TItem).type === 'item' || (item as TItem).type === undefined;

export const isItemNode = <TItem extends VerticalMenuItem<TItem>>(
  node: Node<VerticalMenuSection<TItem> | TItem>,
): node is Node<TItem> => (node as Node<TItem>).type === 'item' && !!node.value;

const itemize = <TItem extends VerticalMenuItem<TItem>>(item: VerticalMenuItem<TItem>) => {
  const { id, items, ...otherProps } = item;
  return (
    <Item key={item.id} childItems={item.items?.length ? item.items : undefined} {...otherProps}>
      {item.label}
    </Item>
  );
};

// https://react-spectrum.adobe.com/react-stately/collections.html
export const mapItemsToCollectionComponents = <TItem extends VerticalMenuItem<TItem>>(
  item: VerticalMenuSection<TItem> | TItem,
) =>
  isSection(item) ? (
    <Section key={item.id} title={item.label} items={item.items}>
      {itemize}
    </Section>
  ) : (
    itemize(item)
  );

export const getDataUiAttributeWithSectionName = <TItem extends VerticalMenuItem<TItem>>(
  item: Node<TItem>,
  state: VerticalMenuState<TItem>,
): ObjectWithDataAttribute | Record<string, never> => {
  if (item.parentKey) {
    const sectionName = state.collection.getItem(item.parentKey)?.value?.label;

    if (sectionName) {
      return getDataUiObjectNameAttribute(sectionName);
    }
  }

  return {};
};
