import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { DataState } from '../../../constants/DataState.ts';
import { HorizontalStackedBarChart } from '../../../contentStatus/components/HorizontalStackedBarChart/HorizontalStackedBarChart.tsx';
import { getContentProgressChartData } from '../../../contentStatus/selectors/getContentProgressChartData.ts';
import { MissionControlRepository } from '../../../repositories/MissionControlRepository.type.ts';
import { IHorizontalStackedBarData } from '../../../types/IHorizontalStackedBarData.type.ts';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetErrorState } from '../../shared/components/WidgetErrorState.tsx';

type ContentProgressWidgetProps = Readonly<{
  contentProgressFetcher: MissionControlRepository['getContentProgress'];
}>;

export const ContentProgressWidget: React.FC<ContentProgressWidgetProps> = ({
  contentProgressFetcher,
}) => {
  const [state, setState] = useState<DataState>(DataState.Loading);
  const [chartData, setChartData] = useState<ReadonlyArray<IHorizontalStackedBarData>>([]);

  useEffect(() => {
    setState(DataState.Loading);

    const fetcher = makeCancellablePromise(contentProgressFetcher)
      .then((value) => {
        const newData = getContentProgressChartData(value);
        const newState = newData.every((item) => !item.value) ? DataState.Empty : DataState.Loaded;

        setChartData(newData);
        setState(newState);
      })
      .catch(swallowCancelledPromiseError)
      .catch((error) => {
        logError(`${__filename}: Failed to fetch.`, error);
        setChartData([]);
        setState(DataState.Error);
      });

    return fetcher.cancel;
  }, [contentProgressFetcher]);

  const hasFailed = state === DataState.Error;
  const isLoading = state === DataState.Loading;
  const noData = state === DataState.Empty;

  return (
    <Widget>
      <Widget.Title
        text="Content progress"
        explanatoryLabel={
          noData && !isLoading
            ? 'Shows only items edited within the past 90 days. Start editing your content and check back later.'
            : 'Shows only items edited within the past 90 days.'
        }
      />
      <Widget.Subtitle>
        {hasFailed ? (
          <WidgetErrorState isInline />
        ) : (
          <HorizontalStackedBarChart
            chartLabel="A horizontal stacked bar chart showing how many items fell in which category in the last 90 days."
            itemTypeName="content item"
            data={chartData}
            loading={isLoading}
          />
        )}
      </Widget.Subtitle>
    </Widget>
  );
};

ContentProgressWidget.displayName = 'ContentProgressWidget';
