import { Action } from '../../../../@types/Action.type.ts';
import { ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set } from '../constants/changeWorkflowStepActionTypes.ts';

export function isPublishingRescheduled(state = false, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.isPublishingRescheduled;

    default:
      return state;
  }
}
