import { Box } from '@kontent-ai/component-library/Box';
import {
  Spacing,
  Typography,
  colorTextDisabled,
  colorTextLowEmphasis,
} from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FallbacksStatus } from '../../../../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatus.tsx';
import { FallbacksStatusWithBadges } from '../../../../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatusWithBadges.tsx';
import { IconName } from '../../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { NotTranslatedOrFallbacksStatus } from '../../../../../../../../_shared/containers/FallbacksForLinkedContent/NotTranslatedOrFallbacksStatus.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { getLanguageName } from '../../../../../../../../_shared/selectors/getLanguageName.ts';
import { getSelectedLanguageId } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { SquareButtonShell } from '../../../../../../../../_shared/uiComponents/Button/SquareButtonShell.tsx';
import { ButtonStyle } from '../../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { Icon } from '../../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { ScrollTableCell } from '../../../../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCellWrappedByLink } from '../../../../../../../../_shared/uiComponents/ScrollTable/ScrollTableCellWrappedByLink.tsx';
import {
  VariantCompletionStatus,
  getContentVariantState,
} from '../../../../../../../../_shared/utils/contentItemVariantUtils.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../../../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { RedactedItemName } from '../../../../../constants/uiConstants.ts';
import { ContentItemSearchPhraseHighlighter } from './ContentItemSearchPhraseHighlighter.tsx';
import { NotTranslatedVariantCellContent } from './NotTranslatedVariantCellContent.tsx';
import { UnfinishedStatusIcon } from './UnfinishedStatusIcon.tsx';

interface IItemLanguageNameProps {
  readonly isDisabled: boolean;
  readonly isPlaceholder?: boolean;
  readonly variantId?: Uuid;
}

const ItemLanguageName = ({ isDisabled, isPlaceholder, variantId }: IItemLanguageNameProps) => {
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const itemLanguageName = useSelector((s) => getLanguageName(s, variantId));
  const shouldDisplayLanguageName = itemLanguageName && selectedLanguageId !== variantId;
  const placeholderStyles = isPlaceholder ? 'font-style: italic;' : undefined;

  if (!shouldDisplayLanguageName) {
    return null;
  }

  return (
    <Box
      typography={Typography.LabelLarge}
      paddingLeft={Spacing.XS}
      color={isDisabled ? colorTextDisabled : colorTextLowEmphasis}
      css={placeholderStyles}
    >
      ({itemLanguageName})
    </Box>
  );
};

export const getTextForTableCell = (text: string | undefined | null): string => text || '—';

type BaseProps = {
  readonly disabled?: boolean;
  readonly hideLanguageName?: boolean;
  readonly hideVariantSpecificInfo?: boolean;
  readonly item: IListingContentItem;
  readonly variantId?: string;
  readonly children?: ReactNode;
};

const ItemNameCellContentBase = ({
  children,
  disabled = false,
  hideLanguageName,
  hideVariantSpecificInfo,
  item,
  variantId,
}: BaseProps) => {
  const itemVariantId = item.variant?.id.variantId ?? variantId;
  const isVariantUnfinished =
    getContentVariantState(item.variant) === VariantCompletionStatus.unfinished;

  return (
    <div className="scroll-table__item-name">
      {isVariantUnfinished && !hideVariantSpecificInfo && <UnfinishedStatusIcon />}
      {children}
      {!hideLanguageName && <ItemLanguageName isDisabled={disabled} variantId={itemVariantId} />}
    </div>
  );
};

export const ItemNameCellContent = (props: BaseProps) => {
  const { item } = props;

  return (
    <ItemNameCellContentBase {...props}>
      <span className="scroll-table__item-name-text">{item.item.name}</span>
    </ItemNameCellContentBase>
  );
};

type WithHighlightProps = BaseProps & {
  readonly filterSearchPhrase: string;
};

export const ItemNameCellContentWithHighlight = ({
  filterSearchPhrase,
  ...baseProps
}: WithHighlightProps) => {
  const { item } = baseProps;

  return (
    <ItemNameCellContentBase {...baseProps}>
      <ContentItemSearchPhraseHighlighter
        highlightedName={item.highlightedName}
        itemName={item.item.name}
        searchPhrase={filterSearchPhrase}
      />
    </ItemNameCellContentBase>
  );
};

interface IRedactedItemNameCellContentProps {
  readonly variantId?: Uuid;
}

export const RedactedItemNameCellContent = ({ variantId }: IRedactedItemNameCellContentProps) => (
  <div className="scroll-table__item-name">
    <div className="scroll-table__value scroll-table__item-name-text scroll-table__item-name-text--placeholder">
      {RedactedItemName}
    </div>
    <ItemLanguageName variantId={variantId} isDisabled isPlaceholder />
  </div>
);

export const DeletedItemNameCellContent = ({
  item,
}: {
  readonly item: IListingContentItem;
}) => (
  <div className="scroll-table__item-name">
    {item.item.name}
    <div className="scroll-table__deleted-label">(Deleted)</div>
  </div>
);

export const WorkflowStatusWithFallbacksCellContent = (props: {
  readonly variant: IListingVariantData | null;
  readonly itemId: Uuid;
}) =>
  props.variant && !props.variant.isArchived && props.variant.assignment.workflowStatus ? (
    <WorkflowStatusTagForVariant
      workflowStatus={props.variant.actualWorkflowStatus || props.variant.assignment.workflowStatus}
      publishingState={props.variant.publishingState}
      scheduledToPublishAt={props.variant.assignment.scheduledToPublishAt}
      scheduledToUnpublishAt={props.variant.assignment.scheduledToUnpublishAt}
    />
  ) : (
    <NotTranslatedOrFallbacksStatus
      itemId={props.itemId}
      renderFallbacksStatus={(itemId) => <FallbacksStatus itemId={itemId} />}
      renderNotTranslatedStatus={() => <NotTranslatedVariantCellContent />}
    />
  );

type ClickableProps = {
  readonly isClickable?: boolean;
  readonly disabledTooltip?: string;
};

const WorkflowStatusCell = ({
  children,
  onToggleExpand,
  ...clickableProps
}: {
  readonly children?: React.ReactNode;
  readonly onToggleExpand: () => void;
} & ClickableProps) => (
  <ScrollTableCell size={4} isGrowing onClick={onToggleExpand} {...clickableProps}>
    {children}
  </ScrollTableCell>
);

export const WorkflowStatusWithFallbackBadgesCell = (
  props: {
    readonly variant: IListingVariantData | null;
    readonly itemId: Uuid;
    readonly onToggleExpand: () => void;
  } & ClickableProps,
) =>
  props.variant && !props.variant.isArchived && props.variant.assignment.workflowStatus ? (
    <WorkflowStatusCell
      disabledTooltip={props.disabledTooltip}
      isClickable={props.isClickable}
      onToggleExpand={props.onToggleExpand}
    >
      <WorkflowStatusTagForVariant
        workflowStatus={
          props.variant.actualWorkflowStatus || props.variant.assignment.workflowStatus
        }
        publishingState={props.variant.publishingState}
        scheduledToPublishAt={props.variant.assignment.scheduledToPublishAt}
        scheduledToUnpublishAt={props.variant.assignment.scheduledToUnpublishAt}
      />
    </WorkflowStatusCell>
  ) : (
    <NotTranslatedOrFallbacksStatus
      itemId={props.itemId}
      renderFallbacksStatus={(itemId) => (
        <WorkflowStatusCell onToggleExpand={props.onToggleExpand}>
          <FallbacksStatusWithBadges itemId={itemId} />
        </WorkflowStatusCell>
      )}
      renderNotTranslatedStatus={() => (
        <WorkflowStatusCell
          disabledTooltip={props.disabledTooltip}
          isClickable={props.isClickable}
          onToggleExpand={props.onToggleExpand}
        >
          <NotTranslatedVariantCellContent />
        </WorkflowStatusCell>
      )}
    />
  );

export const WorkflowStatusCellContent = ({
  variant,
}: {
  readonly variant: IListingVariantData | null;
}) =>
  variant && !variant.isArchived && variant.assignment.workflowStatus ? (
    <WorkflowStatusTagForVariant
      workflowStatus={variant.actualWorkflowStatus || variant.assignment.workflowStatus}
      publishingState={variant.publishingState}
      scheduledToPublishAt={variant.assignment.scheduledToPublishAt}
      scheduledToUnpublishAt={variant.assignment.scheduledToUnpublishAt}
    />
  ) : (
    <NotTranslatedVariantCellContent />
  );

export const ItemTypeCellContent = ({ itemType }: { readonly itemType: IContentType | null }) => (
  <div className="scroll-table__value">{getTextForTableCell(itemType?.name)}</div>
);

export const ItemCollectionCellContent = ({
  collectionName,
}: {
  readonly collectionName: string | undefined;
}) => <div className="scroll-table__value">{getTextForTableCell(collectionName)}</div>;

export const ItemSpaceCellContent = ({
  spaceNames,
}: { readonly spaceNames: ReadonlyArray<string> }) => (
  <div className="scroll-table__value">{getTextForTableCell(spaceNames?.join(', '))}</div>
);

const OpenInNewTabIcon = ({ isDisabled }: { readonly isDisabled?: boolean }) => {
  const screenReaderText = 'Open in new tab';

  const renderIcon = () => (
    <Icon
      iconName={IconName.ArrowRightTopSquare}
      className={classNames({
        'scroll-table__icon--is-disabled': isDisabled,
      })}
      screenReaderText={screenReaderText}
    />
  );

  return (
    <span {...getDataUiActionAttribute(DataUiAction.OpenContentItem)}>
      <SquareButtonShell
        disabled={isDisabled}
        ariaLabel={screenReaderText}
        size={SquareButtonSize.Quinary}
        style={ButtonStyle.Quinary}
      >
        {renderIcon()}
      </SquareButtonShell>
    </span>
  );
};

type Props = {
  readonly disabledMessage?: string;
  readonly path: string;
};

export const OpenInNewTabCell = ({ disabledMessage, path }: Props) => {
  return disabledMessage ? (
    <ScrollTableCell size={1} disabledTooltip={disabledMessage}>
      <OpenInNewTabIcon isDisabled />
    </ScrollTableCell>
  ) : (
    <ScrollTableCellWrappedByLink
      allowNavigationWithOnClick
      hasBalloon
      linkPath={path}
      openInNewWindow
      size={1}
      tooltipText="Open in new tab"
    >
      <OpenInNewTabIcon />
    </ScrollTableCellWrappedByLink>
  );
};

export const EmptyCellContent = () => <div>{' '}</div>;
