import { useAttachRef } from '@kontent-ai/hooks';
import { useDialog } from '@react-aria/dialog';
import { useOverlay } from '@react-aria/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import type { DisabledFocusLockProp } from '../../types/utils.d.ts';
import { Overlay } from '../Scrims/Overlay.tsx';
import { OverlayPortalContainer } from '../utils/OverlayPortalContainer.tsx';
import { InversePopover, StepCounterProps } from './components/InversePopover.tsx';
import { StyledWrapper } from './components/StyledWrapper.tsx';

export type OnboardingBubbleProps = DisabledFocusLockProp &
  Readonly<{
    headlineText: string;
    onDismiss: () => void;
    onNext?: () => void;
    showPrimaryButton?: boolean;
    includeFog?: boolean;
    isCentered?: boolean;
    isVisible: boolean;
  }>;

type OverlayTypeProps = Readonly<{
  includeFog: boolean;
}>;

const OverlayType: React.FC<React.PropsWithChildren<OverlayTypeProps>> = ({
  children,
  includeFog,
}) =>
  includeFog ? (
    <Overlay>{children}</Overlay>
  ) : (
    <OverlayPortalContainer>{children}</OverlayPortalContainer>
  );

OverlayType.displayName = 'OverlayType';

type AllOnboardingBubbleProps = React.PropsWithChildren<
  OnboardingBubbleProps & AllOrNoneProps<StepCounterProps>
>;

const propTypes: PropTypeMap<AllOnboardingBubbleProps> = {
  children: PropTypes.node,
  currentStep: PropTypes.number as any,
  totalSteps: PropTypes.number as any,
  headlineText: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  includeFog: PropTypes.bool,
  isCentered: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  showPrimaryButton: PropTypes.bool,
  __disabledFocusLock: PropTypes.bool,
};

export const OnboardingBubble = React.forwardRef<HTMLDivElement, AllOnboardingBubbleProps>(
  (
    {
      children,
      currentStep,
      totalSteps,
      headlineText,
      onDismiss,
      onNext,
      includeFog = false,
      isCentered = false,
      isVisible,
      showPrimaryButton,
      __disabledFocusLock,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { refToForward, refObject } = useAttachRef<HTMLDivElement>(forwardedRef);

    const { overlayProps } = useOverlay(
      {
        isDismissable: !includeFog,
        isOpen: isVisible,
        onClose: onDismiss,
      },
      refObject,
    );

    // Get props for the dialog and its title
    const { titleProps } = useDialog(otherProps, refObject);

    if (!isVisible) {
      return null;
    }

    const stepsProps =
      currentStep && totalSteps
        ? {
            currentStep,
            totalSteps,
          }
        : {};

    const primaryButtonProps = showPrimaryButton
      ? {
          showPrimaryButton,
          onPrimaryButtonClick: currentStep !== totalSteps && onNext ? onNext : onDismiss,
        }
      : ({
          showPrimaryButton: false,
        } as const);

    return (
      <OverlayType includeFog={includeFog}>
        <ReactFocusLock disabled={!includeFog || __disabledFocusLock}>
          <StyledWrapper isCentered={isCentered} {...overlayProps} ref={refToForward}>
            <InversePopover
              dialogTitleProps={titleProps}
              headlineText={headlineText}
              onCloseActionClick={onDismiss}
              {...primaryButtonProps}
              {...stepsProps}
            >
              {children}
            </InversePopover>
          </StyledWrapper>
        </ReactFocusLock>
      </OverlayType>
    );
  },
);

OnboardingBubble.displayName = 'OnboardingBubble';
OnboardingBubble.propTypes = propTypes;
