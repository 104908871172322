import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { DropDownMenuControlled } from '../../../../../../../../component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import { AiError } from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiTranslateStatus } from './AiTranslateStatus.tsx';

type AiTranslateButtonProps = {
  readonly aiOperationState: AiOperationState;
  readonly availableLanguages: ReadonlyArray<IBaseSelectItem>;
  readonly disabled: boolean;
  readonly error: AiError | null;
  readonly perform: (sourceLanguageId: string) => void;
};

export const AiTranslateButton: React.FC<AiTranslateButtonProps> = ({
  aiOperationState,
  availableLanguages,
  disabled,
  error,
  perform,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } = useVerticalMenu([
    {
      id: 'availableLanguages',
      items: availableLanguages,
      label: 'Translate from',
      type: 'section',
    },
  ]);

  return (
    <Box maxWidth={55 * gridUnit}>
      <Row alignY="center" noWrap spacingX={Spacing.M}>
        <Column>
          <AiTranslateStatus error={error} aiOperationState={aiOperationState} />
        </Column>
        <Column>
          <DropDownMenuControlled
            onDropDownVisibilityChange={setIsMenuVisible}
            isDropDownVisible={isMenuVisible}
            tippyOptions={{
              placement: 'bottom-end',
            }}
            renderDropDown={() => (
              <VerticalMenu
                {...verticalMenuProps}
                aria-label="Translate from"
                renderItem={({ item }) => (
                  <MenuItemWithMouseHover
                    menuItemState="default"
                    text={item.textValue}
                    onPress={() => {
                      if (item.value) {
                        perform(item.value.id);
                      }
                      setIsMenuVisible(false);
                    }}
                  />
                )}
                state={verticalMenuState}
                contain={false}
              />
            )}
            renderTrigger={(triggerProps) => (
              <QuinaryButton
                tooltipText={isMenuVisible ? '' : getTooltipText(disabled, aiOperationState)}
                tooltipPlacement="bottom"
                disabled={disabled}
                activated={isMenuVisible}
                onClick={() => setIsMenuVisible(true)}
                ref={triggerProps.ref}
                {...getDataUiElementAttribute(DataUiElement.AiTranslateButton)}
              >
                <QuinaryButton.Icon icon={Icons.Translate} />
              </QuinaryButton>
            )}
          />
        </Column>
      </Row>
    </Box>
  );
};

AiTranslateButton.displayName = 'AiTranslateButton';

const getTooltipText = (isDisabled: boolean, aiOperationState: AiOperationState): string => {
  if (isDisabled) {
    return aiOperationState === AiOperationState.Pending
      ? 'Translation in progress'
      : 'Nothing to translate. Provide content in other language variants first.';
  }
  return 'Translate with AI';
};
