import React, { RefObject } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  ContentTypesRoute,
  EnvironmentRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import {
  getContentItemPath,
  matchPath,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadContentTypeAsComponentUsage } from '../../../../data/actions/thunkDataActions.ts';
import { getContentTypeUsedAsComponentItemListForScrollTable } from '../../../../data/reducers/listingContentItems/selectors/getItemListForScrollTable.ts';
import { ContentTypeUsageScrollTableEmptyState } from '../../../contentInventory/content/features/ContentItemInventory/containers/ContentTypeUsageScrollTableEmptyState.tsx';
import { ContentTypeUsageScrollTableTitle } from '../../../contentInventory/content/features/ContentItemInventory/containers/ContentTypeUsageScrollTableTitle.tsx';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { getNumberOfItemsInViewport } from '../../../contentInventory/content/reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import { ContentItemSelectorScrollTableHead } from '../../../features/ModalContentItemSelector/containers/ContentItemSelectorScrollTable/ContentItemSelectorScrollTableHead.tsx';
import { ContentItemSelectorScrollTableRow } from '../../../features/ModalContentItemSelector/containers/ContentItemSelectorScrollTable/ContentItemSelectorScrollTableRow.tsx';
import { getContentTypeIdInContentTypeAsComponentUsageDialog } from '../selectors/getContentTypeId.ts';

const getItemListSelector = (state: IStore) =>
  getContentTypeUsedAsComponentItemListForScrollTable(
    state.data.contentTypeComponentsUsage,
    getNumberOfItemsInViewport(state.contentApp.listingUi.contentItemListingScrollTableState),
  );

interface IContentTypeUsedAsComponentScrollTable {
  readonly closeModal: () => void;
  readonly isInitRetrieving: boolean;
  readonly parentContainerRef: RefObject<HTMLDivElement>;
}

export const ContentTypeUsedAsComponentScrollTable: React.FC<
  IContentTypeUsedAsComponentScrollTable
> = ({ closeModal, isInitRetrieving, parentContainerRef }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const items = useSelector(getItemListSelector);
  const contentTypeId = useSelector(getContentTypeIdInContentTypeAsComponentUsageDialog);

  const onItemClick = (contentItemId: Uuid, variantId?: Uuid) => {
    closeModal();
    const matchParams = matchPath<EnvironmentRouteParams>(location.pathname, ContentTypesRoute);
    if (!matchParams) {
      logError('Current route does not belong to ContentItemsRoute routes');
    }

    const contentItemPath = getContentItemPath(
      location.pathname,
      contentItemId,
      variantId ?? DefaultVariantId,
    );
    history.push(contentItemPath);
  };

  return isInitRetrieving ? (
    <Loader />
  ) : (
    <ContentItemScrollTable
      items={items}
      onItemClick={onItemClick}
      onItemDoubleClick={onItemClick}
      onLoadContentItems={loadContentTypeAsComponentUsage({
        contentTypeId,
        scrollPositionChanged: true,
      })}
      parentContainerRef={parentContainerRef}
      renderEmptyState={() => <ContentTypeUsageScrollTableEmptyState />}
      renderRowItem={(params) => (
        <ContentItemSelectorScrollTableRow
          key={params.item.item.id + params.index}
          index={params.index}
          item={params.item}
          onItemClick={params.onItemClick}
          onItemDoubleClick={params.onItemDoubleClick}
        />
      )}
      renderTableHead={() => (
        <ContentItemSelectorScrollTableHead
          onItemOrderChanged={() => {
            dispatch(
              loadContentTypeAsComponentUsage({
                contentTypeId,
                scrollPositionChanged: false,
              }),
            );
          }}
        />
      )}
      renderTableTitle={() => <ContentTypeUsageScrollTableTitle contentTypeId={contentTypeId} />}
      withColumnSettings
      ariaLabel="Content type used"
    />
  );
};
