import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import {
  BorderRadius,
  IconSize,
  Spacing,
  px,
  shadowFocusStyles,
} from '@kontent-ai/component-library/tokens';
import { useLink } from '@react-aria/link';
import { mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import { useRef } from 'react';
import styled from 'styled-components';
import { AssetTilePreview } from '../../../../../_shared/components/AssetTile/AssetTilePreview.tsx';
import { AssetTileProgressBar } from '../../../../../_shared/components/AssetTile/AssetTileProgressBar.tsx';
import { AssetTileSummary } from '../../../../../_shared/components/AssetTile/AssetTileSummary.tsx';
import { Option } from '../../../../../_shared/components/Options/Option.tsx';
import { TrackUserEventWithData } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { OptionMode } from '../../../../../_shared/models/optionMode.ts';
import { getAssetDescriptionBySelectedLanguage } from '../../../../../_shared/selectors/AssetTile/getAssetDescription.ts';
import { getDataUiObjectNameAttribute } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetValidationResult } from '../../../../itemEditor/features/ContentItemEditing/components/elements/asset/AssetTile.tsx';
import { useAssetTileSelection } from '../../context/AssetListing/AssetTileSelectionContext.tsx';
import { AssetTileActions } from './AssetTileActions.tsx';
import { FlexingTile } from './FlexingTile.tsx';
import { StyledAssetTileWrapper } from './StyledAssetTileWrapper.tsx';

export const AssetThumbnailBottomClassname = 'asset-thumbnail__bottom';

const StyledOption = styled(Option)`
    align-items: baseline;

    ::before {
      position: relative;
      top: ${px(Spacing.XS)};
      z-index: 2;
    }

    ::after {
      position: absolute;
			z-index: 1;
			top: ${px(Spacing.L)};
			right: calc(100% - (${px(Spacing.XL)} + ${px(Spacing.L)} + ${px(Spacing.XS)}));
			width: calc(${px(IconSize.S)} + ${px(Spacing.S)});
			min-width: calc(${px(IconSize.S)} + ${px(Spacing.S)});
			height: calc(${px(IconSize.S)} + ${px(Spacing.S)});
			min-height: calc(${px(IconSize.S)} + ${px(Spacing.S)});
			box-sizing: border-box;
			border-radius: ${px(BorderRadius.S)};
			content: "";
    }

    .toggle__input--hidden:focus + & {
      box-shadow: none;
    }

    .toggle__input--hidden:focus + &::after{
      ${shadowFocusStyles}
    }

    .toggle__input--hidden:focus:not(:focus-visible) + &::after{
      box-shadow: none;
    }
  `;

export interface IAssetTileStateProps {
  readonly collectionName: string | null;
  readonly disabled?: boolean;
  readonly folderName?: string;
  readonly folderPathTooltip?: string;
  readonly isSelectable: boolean;
  readonly isUncategorized: boolean | null;
  readonly isValidType: boolean;
  readonly showHeight: boolean;
  readonly showWidth: boolean;
  readonly selectedLanguageId: Uuid;
  readonly validationResult: IAssetValidationResult;
}

export interface IAssetTileDispatchProps {
  readonly trackUserEvent?: TrackUserEventWithData;
}

export interface IAssetTileOwnProps {
  readonly item: IAsset;
  readonly onClick?: (assetId: Uuid) => void;
  readonly searchPhrase?: string;
}

type Props = IAssetTileStateProps & IAssetTileDispatchProps & IAssetTileOwnProps;

export const AssetTile: React.FC<Props> = ({
  collectionName,
  disabled,
  folderName,
  folderPathTooltip,
  isSelectable,
  isUncategorized,
  isValidType,
  item,
  onClick,
  searchPhrase,
  selectedLanguageId,
  showHeight,
  showWidth,
  trackUserEvent,
  validationResult,
}) => {
  const { selectedAssets, toggleAssetSelection: onSelectAsset } = useAssetTileSelection();
  const isSelected = selectedAssets.has(item.id);

  const onAssetSelected = (_isAssetSelected: boolean, isShiftPressed: boolean): void => {
    onSelectAsset(item.id, isShiftPressed);
  };

  const getSummary = (): JSX.Element => {
    return (
      <AssetTileSummary
        asset={item}
        collectionName={collectionName}
        folderName={folderName}
        folderPathTooltip={folderPathTooltip}
        searchPhrase={searchPhrase || ''}
        isHeightLimitationSet={showHeight}
        isWidthLimitationSet={showWidth}
        validationResult={validationResult}
      />
    );
  };

  const getAssetTileOption = (): JSX.Element => (
    <div className="asset-thumbnail__asset-selection">
      <StyledOption
        showFullSize
        label={getSummary()}
        isSelected={isSelected}
        accessibleLabel={`Select asset — ${item.title || item.filename}`}
        mode={OptionMode.InvertedMultiple}
        onOptionSelected={onAssetSelected}
      />
    </div>
  );

  const isDisabled = (): boolean => disabled || !!item._uploading;

  const isClickable = !isDisabled() && isValidType && !item._failed;

  const handleLinkAction = (): void => {
    if (isClickable && onClick) {
      onClick(item.id);
    }
  };

  const showCheckbox = isSelectable && isClickable && !!onSelectAsset;

  const altText = getAssetDescriptionBySelectedLanguage(item.descriptions, selectedLanguageId);

  const { isFocusVisible, focusProps } = useOurFocusRing();

  const linkRef = useRef<HTMLDivElement>(null);

  const { linkProps } = useLink(
    {
      isDisabled: !isClickable,
      onPress: handleLinkAction,
      elementType: 'div',
    },
    linkRef,
  );

  return (
    <FlexingTile>
      <Tooltip
        tooltipText={isValidType ? undefined : 'This asset is not an image.'}
        placement="right"
      >
        <StyledAssetTileWrapper isFocusVisible={isFocusVisible}>
          <div
            {...getDataUiObjectNameAttribute(item.title || item.filename)}
            className={classNames('asset-thumbnail', 'asset-thumbnail--is-in-asset-library', {
              'asset-thumbnail--is-disabled': isDisabled(),
              'asset-thumbnail--has-focus': isSelected,
              'asset-thumbnail--is-not-clickable': !isClickable,
              'asset-thumbnail--is-not-selectable': !isClickable,
            })}
          >
            <div
              className="asset-thumbnail__preview-pane"
              aria-label={`Navigate to asset detail — ${item.title || item.filename}`}
              {...mergeProps(linkProps, focusProps)}
            >
              <AssetTileProgressBar asset={item} />
              <AssetTilePreview asset={item} altText={altText} />
            </div>
            {item && !item.archived && trackUserEvent && (
              <AssetTileActions
                asset={item}
                isUncategorized={isUncategorized}
                trackUserEvent={trackUserEvent}
              />
            )}
            <div
              className={classNames(AssetThumbnailBottomClassname, {
                [`${AssetThumbnailBottomClassname}--with-padding`]: !showCheckbox,
              })}
              onClick={showCheckbox ? undefined : handleLinkAction}
            >
              {showCheckbox ? getAssetTileOption() : getSummary()}
            </div>
          </div>
        </StyledAssetTileWrapper>
      </Tooltip>
    </FlexingTile>
  );
};

AssetTile.displayName = 'AssetTile';
