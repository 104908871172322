import { Icons } from '@kontent-ai/component-library/Icons';
import { ControlAltShortcutTemplate } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  RTEToolbarIconButton,
  ToggleButtonProps,
} from '../../../toolbars/components/buttons/RTEToolbarButton.tsx';

export const UnorderedListButton = (props: ToggleButtonProps) => (
  <RTEToolbarIconButton
    {...props}
    icon={Icons.ListBullets}
    shortcut={ControlAltShortcutTemplate('U')}
    tooltipPlacement={props.tooltipPlacement}
    tooltipText={props.tooltipText ?? 'Unordered list'}
    {...getDataUiActionAttribute(DataUiRteAction.UnorderedList)}
  />
);
