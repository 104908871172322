import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { useWaitForAnimationDelay } from '../../hooks/useWaitForAnimationDelay.ts';
import { SquareButton } from '../../uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../uiComponents/Button/squareButtonSize.ts';
import { OptionalTooltip } from '../../uiComponents/Tooltip/OptionalTooltip.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { EnterHotkeyDisabler } from '../Hotkeys/EnterHotkeyDisabler.tsx';
import { Modal } from '../Modal/Modal.tsx';
import { ModalDialogAnimationFinishedContext } from './ModalDialogAnimationFinishedContext.tsx';

export interface IModalDialogProps {
  readonly bodyContent: React.ReactNode;
  readonly customClassName?: string;
  readonly dataUiElement?: DataUiElement;
  readonly disableCloseOnOverlayClick?: boolean;
  readonly footerContentLeft?: React.ReactNode;
  readonly footerContentRight?: React.ReactNode;
  readonly headerContent: React.ReactNode;
  readonly headerTooltip?: string;
  readonly isFullHeight?: boolean;
  readonly onClose: () => void;
  readonly overAllOtherElements?: boolean;
  readonly shouldHaveFixedTop?: boolean;
  readonly withDividers?: boolean;
  readonly withFullWidthBodyContent?: boolean;
}

const propTypes: PropTypeMap<IModalDialogProps> = {
  bodyContent: PropTypes.node.isRequired,
  customClassName: PropTypes.string,
  dataUiElement: PropTypes.oneOf(Object.values(DataUiElement)),
  disableCloseOnOverlayClick: PropTypes.bool,
  footerContentLeft: PropTypes.node,
  footerContentRight: PropTypes.node,
  headerContent: PropTypes.node.isRequired,
  headerTooltip: PropTypes.string,
  isFullHeight: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  overAllOtherElements: PropTypes.bool,
  shouldHaveFixedTop: PropTypes.bool,
  withDividers: PropTypes.bool,
  withFullWidthBodyContent: PropTypes.bool,
};

const ModalDialogEnterAnimationLength = 250;

export const ModalDialog: React.FC<IModalDialogProps> = ({
  bodyContent,
  customClassName,
  dataUiElement = DataUiElement.Dialog,
  disableCloseOnOverlayClick,
  footerContentLeft,
  footerContentRight,
  headerContent,
  headerTooltip,
  isFullHeight,
  onClose,
  overAllOtherElements,
  shouldHaveFixedTop,
  withDividers,
  withFullWidthBodyContent,
}) => {
  const dialogPaneRef = useRef<HTMLDivElement>(null);
  const [clickStartedInsideDialog, setClickStartedInsideDialog] = useState<boolean>();

  const mouseDown = (event: SyntheticEvent<HTMLDivElement>): void => {
    const target = event.target as Node;
    const dialogPane = dialogPaneRef.current;

    setClickStartedInsideDialog(!!dialogPane && dialogPane.contains(target));
  };

  const close = (): void => {
    if (disableCloseOnOverlayClick && !clickStartedInsideDialog) {
      onClose();
    }
  };

  const hasEnterAnimationFinished = useWaitForAnimationDelay(ModalDialogEnterAnimationLength, true);

  return (
    <EnterHotkeyDisabler>
      <Modal
        onClose={onClose}
        underlayClass={classNames('mask-modal', {
          'mask-modal--over-all-other-elements': overAllOtherElements,
        })}
        dialogClass="dialog"
        underlayDataAttribute={DataUiElement.MaskModal}
      >
        <div
          className={classNames('modal-dialog', customClassName, {
            'modal-dialog--with-dividers': withDividers,
            'modal-dialog--full-height': isFullHeight,
            'modal-dialog--with-fixed-top': shouldHaveFixedTop,
          })}
          onClick={close}
          onMouseDown={mouseDown}
          {...getDataUiElementAttribute(dataUiElement)}
        >
          <div
            className="modal-dialog__pane"
            ref={dialogPaneRef}
            onClick={(event) => event.stopPropagation()}
          >
            <ModalDialogAnimationFinishedContext.Provider value={hasEnterAnimationFinished}>
              <div className="modal-dialog__header">
                <OptionalTooltip
                  appendToParent={overAllOtherElements}
                  placement="bottom"
                  text={
                    headerTooltip ?? (typeof headerContent === 'string' ? headerContent : undefined)
                  }
                  customCondition={(isOverflowing) => !!headerTooltip || isOverflowing}
                  className="modal-dialog__header-title"
                >
                  {headerContent}
                </OptionalTooltip>
                <SquareButton
                  appendTooltipToParent={overAllOtherElements}
                  className="modal-dialog__header-close"
                  iconName={IconName.ModalClose}
                  onClick={onClose}
                  size={SquareButtonSize.Quinary}
                  style={ButtonStyle.Quinary}
                  tooltipPlacement="left-start"
                  tooltipShortcuts="Esc"
                  tooltipText="Close"
                  {...getDataUiActionAttribute(DataUiAction.Cancel)}
                />
              </div>
              <div
                className={classNames('modal-dialog__content', {
                  'modal-dialog__content--with-dividers': withDividers,
                  'modal-dialog__content--with-full-width': withFullWidthBodyContent,
                })}
              >
                {bodyContent}
              </div>
              {(footerContentLeft || footerContentRight) && (
                <div className="modal-dialog__footer">
                  <div className="modal-dialog__footer-content">{footerContentLeft}</div>
                  <div className="modal-dialog__footer-content">{footerContentRight}</div>
                </div>
              )}
            </ModalDialogAnimationFinishedContext.Provider>
          </div>
        </div>
      </Modal>
    </EnterHotkeyDisabler>
  );
};

ModalDialog.displayName = 'ModalDialog';
ModalDialog.propTypes = propTypes;
