import { WidgetDescriptor, WidgetRequirement } from '../../types/WidgetDescriptor.type.ts';
import { ContentProgressWidgetDemoContainer } from '../../widgets/contentProgress/containers/ContentProgressWidgetDemoContainer.tsx';
import { ContentProgressWidgetLiveContainer } from '../../widgets/contentProgress/containers/ContentProgressWidgetLiveContainer.tsx';
import { ItemsPublishedOnTimeWidgetDemoContainer } from '../../widgets/itemsPublishedOnTime/containers/ItemsPublishedOnTimeWidgetDemoContainer.tsx';
import { ItemsPublishedOnTimeWidgetLiveContainer } from '../../widgets/itemsPublishedOnTime/containers/ItemsPublishedOnTimeWidgetLiveContainer.tsx';
import { MostOverdueTasksWidgetDemoContainer } from '../../widgets/mostOverdueTasks/containers/MostOverdueTasksWidgetDemoContainer.tsx';
import { MostOverdueTasksWidgetLiveContainer } from '../../widgets/mostOverdueTasks/containers/MostOverdueTasksWidgetLiveContainer.tsx';
import { TasksDoneOnTimeWidgetDemoContainer } from '../../widgets/tasksDoneOnTime/containers/TasksDoneOnTimeWidgetDemoContainer.tsx';
import { TasksDoneOnTimeWidgetLiveContainer } from '../../widgets/tasksDoneOnTime/containers/TasksDoneOnTimeWidgetLiveContainer.tsx';
import { UnchangedPublishedItemsWidgetDemoContainer } from '../../widgets/unchangedPublishedContent/containers/UnchangedPublishedItemsWidgetDemoContainer.tsx';
import { UnchangedPublishedItemsWidgetLiveContainer } from '../../widgets/unchangedPublishedContent/containers/UnchangedPublishedItemsWidgetLiveContainer.tsx';
import { UnchangedUnpublishedWidgetDemoContainer } from '../../widgets/unchangedUnpublishedContent/containers/UnchangedUnpublishedWidgetDemoContainer.tsx';
import { UnchangedUnpublishedWidgetLiveContainer } from '../../widgets/unchangedUnpublishedContent/containers/UnchangedUnpublishedWidgetLiveContainer.tsx';

export const widgets: ReadonlyArray<WidgetDescriptor> = [
  {
    uniqueKey: 'contentProgressWidget',
    liveComponent: ContentProgressWidgetLiveContainer,
    demoComponent: ContentProgressWidgetDemoContainer,
    requirements: [],
  },
  {
    uniqueKey: 'tasksDoneOnTimeWidget',
    liveComponent: TasksDoneOnTimeWidgetLiveContainer,
    demoComponent: TasksDoneOnTimeWidgetDemoContainer,
    requirements: [],
  },
  {
    uniqueKey: 'itemsPublishedOnTimeWidget',
    liveComponent: ItemsPublishedOnTimeWidgetLiveContainer,
    demoComponent: ItemsPublishedOnTimeWidgetDemoContainer,
    requirements: [],
  },
  {
    uniqueKey: 'mostOverdueTasksWidget',
    liveComponent: MostOverdueTasksWidgetLiveContainer,
    demoComponent: MostOverdueTasksWidgetDemoContainer,
    requirements: [WidgetRequirement.userIsProjectManager],
  },
  {
    uniqueKey: 'unchangedPublishedContentWidget',
    liveComponent: UnchangedPublishedItemsWidgetLiveContainer,
    demoComponent: UnchangedPublishedItemsWidgetDemoContainer,
    requirements: [WidgetRequirement.userIsProjectManager],
  },
  {
    uniqueKey: 'unchangedUnpublishedContentWidget',
    liveComponent: UnchangedUnpublishedWidgetLiveContainer,
    demoComponent: UnchangedUnpublishedWidgetDemoContainer,
    requirements: [WidgetRequirement.userIsProjectManager],
  },
];
