import { OnboardingPopover } from '@kontent-ai/component-library/OnboardingBubble';
import { isBefore } from 'date-fns';
import React, { ComponentProps } from 'react';
import { useRouteMatch } from 'react-router';
import { upsertUserProperty } from '../../../_shared/actions/thunkSharedActions.ts';
import { MissionControlRootRoute } from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { DismissedMissionControlOnboardingServerKey } from '../../../_shared/models/UserPropertiesServerKeys.ts';

type Props = Readonly<{
  children: ComponentProps<typeof OnboardingPopover>['renderTarget'];
}>;

export const MissionControlOnboarding: React.FC<Props> = ({ children }) => {
  const shouldShowOnboarding = useSelector(
    (s) =>
      !s.sharedApp.userProperties.dismissedMissionControlOnboarding &&
      isBefore(s.data.user.info.createdAt, new Date('2024-09-30')),
  );
  const isInMissionControl = !!useRouteMatch(MissionControlRootRoute);

  const dispatch = useDispatch();
  const onDismiss = () => {
    dispatch(upsertUserProperty(DismissedMissionControlOnboardingServerKey, 'true'));
  };

  return (
    <OnboardingPopover
      headlineText="Welcome to Mission Control – your new home to all things content!"
      isVisible={shouldShowOnboarding && isInMissionControl}
      onDismiss={onDismiss}
      renderTarget={children}
      showPrimaryButton
    >
      <p>With Mission Control, you can:</p>
      <ul>
        <li>Plan, track, and deliver your content.</li>
        <li>Stay on top of comments and tasks.</li>
        <li>Establish accountability.</li>
      </ul>
      <p>
        Mission Control makes it easier than ever to stay organized and focused on your content and
        goals.
      </p>
    </OnboardingPopover>
  );
};
