import { AnimatedProgressIcon } from '@kontent-ai/component-library/Icons';
import { Input, InputState, InputType } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { ChangeEvent, useEffect, useState } from 'react';
import { DialogState } from '../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { NotificationBarAlert } from '../../../../../component-library/components/NotificationBar/NotificationBar.tsx';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  isEmptyOrWhitespace,
  trimAndRemoveMultipleSpaces,
} from '../../../../_shared/utils/stringUtils.ts';
import { MinDialogWidth } from '../constants/uiConstants.ts';

type Props = {
  readonly deletedEnvironmentId: Uuid;
  readonly deletingEnvironment: boolean;
  readonly deletingEnvironmentFailed: boolean;
  readonly environmentName: string;
  readonly onClose: () => void;
  readonly onDelete: (environmentId: Uuid) => void;
  readonly showDialog: boolean;
};

export const DeleteEnvironmentDialog = ({
  deletedEnvironmentId,
  deletingEnvironment,
  deletingEnvironmentFailed,
  environmentName,
  onClose,
  onDelete,
  showDialog,
}: Props) => {
  const [confirmationName, setConfirmationName] = useState('');

  const trimmedConfirmationName = trimAndRemoveMultipleSpaces(confirmationName);
  const isConfirmationValid =
    !isEmptyOrWhitespace(trimmedConfirmationName) && trimmedConfirmationName === environmentName;

  useEffect(() => {
    if (showDialog) {
      setConfirmationName('');
    }
  }, [showDialog]);

  const onConfirmationInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setConfirmationName(event.target.value);
  };

  const deleteEnvironment = (): void => {
    if (isConfirmationValid) {
      onDelete(deletedEnvironmentId);
    }
  };

  const deletedEnvironmentName = isEmptyOrWhitespace(environmentName)
    ? confirmationName
    : environmentName;

  return (
    <ModalDialog
      isDismissable
      isOpen={showDialog}
      minWidth={px(MinDialogWidth).toString()}
      headline={`Delete environment ${deletedEnvironmentName}?`}
      state={deletingEnvironment ? DialogState.InProgress : DialogState.Default}
      primaryAction={{
        text: deletingEnvironment ? 'Deleting environment' : 'Delete environment',
        tooltipText: isConfirmationValid
          ? undefined
          : `Environment name doesn’t match “${environmentName}”.`,
        tooltipPlacement: 'bottom-end',
        disabled: !isConfirmationValid,
        iconBefore: deletingEnvironment ? AnimatedProgressIcon : undefined,
        destructive: true,
        onClick: deleteEnvironment,
        ...getDataUiActionAttribute(DataUiAction.Delete),
      }}
      onClose={onClose}
      renderNotificationBar={() =>
        deletingEnvironmentFailed && (
          <NotificationBarAlert
            onDismiss={
              undefined /* Makes the dismiss button disappear*/ as unknown as () => void /* Cast makes the code compile */
            }
          >
            Something went wrong. Please try again. If this keeps happening, let us know.
          </NotificationBarAlert>
        )
      }
      {...getDataUiElementAttribute(DataUiElement.DeleteEnvironmentDialog)}
    >
      <Stack spacing={Spacing.XL}>
        <p>
          <strong>Deleted environment cannot be restored.</strong>
        </p>
        <Input
          autoFocus
          type={InputType.Text}
          value={confirmationName}
          onChange={onConfirmationInputChanged}
          placeholder="Environment name"
          label="To confirm, type the name of the environment:"
          inputState={
            !isConfirmationValid && confirmationName !== '' ? InputState.Alert : InputState.Default
          }
          {...getDataUiInputAttribute(DataUiInput.EnvironmentName)}
        />
      </Stack>
    </ModalDialog>
  );
};
