import {
  DarkGradient,
  DarkGradients,
  GradientFunction,
} from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import { assert, Collection, alphabetically } from '@kontent-ai/utils';
import {
  defaultUserFirstName,
  defaultUserLastName,
} from '../../applications/environmentSettings/users/constants/newUserName.ts';
import { ICurrentUserInfo, UserState } from '../../data/models/user/CurrentUserInfo.ts';
import { IProjectContributor } from '../../data/models/users/ProjectContributor.ts';
import { IUserIdentifier } from '../models/UserIdentifier.ts';
import { IUserInfo, createUnknownUserInfo, unknownUserInfo } from '../models/UserInfo.ts';

interface IUserWithId {
  readonly userId: UserId;
}

interface IUserWithLastName extends IUserWithId {
  readonly lastName: string;
}

interface IUserWithFirstName extends IUserWithId {
  readonly firstName: string;
}

export type CommonUser = IUserWithLastName & {
  readonly firstName: string;
  readonly email?: string;
  readonly isVirtual?: boolean;
};

export const getUserId = (user?: CommonUser | null): UserId => {
  assert(!!user, () => 'Attempting to access nonsensical `user` parameter!');

  return user.userId;
};

export const getUserById = memoize.weak(
  (allUsers: ReadonlyMap<UserId, IProjectContributor>, userId: UserId): IUserInfo =>
    allUsers.get(userId) ?? createUnknownUserInfo(userId),
);

export const getUsersByIds = memoize.weak(
  (
    allUsers: ReadonlyMap<UserId, IProjectContributor>,
    ids: ReadonlyArray<UserId>,
  ): ReadonlyArray<IUserInfo> => ids.map((userId: UserId) => getUserById(allUsers, userId)),
);

export const isAdmin = (admins: ReadonlyArray<UserId>, userId: UserId): boolean =>
  admins.includes(userId);

const isMe = (currentUserId: UserId, user: CommonUser | null) => currentUserId === getUserId(user);

export const isInvited = (userInfo: ICurrentUserInfo): boolean =>
  userInfo.state === UserState.InvitationPending ||
  (userInfo.isSsoUser && userInfo.state === UserState.RegistrationPending);

export const isFromMarketplace = (userInfo: ICurrentUserInfo): boolean =>
  userInfo.state === UserState.MarketplaceRegistrationPending;

export const isManuallyCreated = (userInfo: ICurrentUserInfo): boolean =>
  userInfo.state === UserState.ManualRegistrationPending;

const hasUnsetName = (user: Pick<CommonUser, 'lastName' | 'firstName'>): boolean =>
  user.firstName === '' && user.lastName === '';

const hasDefaultName = (user: Pick<CommonUser, 'lastName' | 'firstName'>): boolean =>
  user.firstName === defaultUserFirstName && user.lastName === defaultUserLastName;

export const isNewUserWithoutName = (user: Pick<CommonUser, 'lastName' | 'firstName'>): boolean =>
  hasUnsetName(user) || hasDefaultName(user);

const getUserName = (
  user: Pick<CommonUser, 'lastName' | 'firstName' | 'isVirtual'>,
): string | null => {
  if (user.isVirtual) {
    return user.firstName?.trim() ?? null;
  }

  const firstName = user.firstName?.trim();
  const lastName = user.lastName?.trim();
  return firstName && lastName ? `${firstName} ${lastName}` : null;
};

const getFirstName = (user: Pick<CommonUser, 'firstName'>): string | null =>
  user.firstName?.trim() ?? null;

const getLastName = (user: Pick<CommonUser, 'lastName'>): string | null =>
  user.lastName?.trim() ?? null;

export const formatUserName = (user: CommonUser | null | undefined): string => {
  if (!user) {
    return '[username redacted]';
  }

  if (!isNewUserWithoutName(user)) {
    const fullName = getUserName(user);
    if (fullName) {
      return fullName;
    }
  }

  return user.email?.trim() || '';
};

export const formatUserFirstNameForUsersListing = (user: CommonUser | null | undefined): string => {
  if (!user) {
    return 'Unknown';
  }

  if (isNewUserWithoutName(user)) {
    return '—';
  }

  return getFirstName(user) ?? (user.email?.trim() || '');
};

export const formatUserLastNameForUsersListing = (user: CommonUser | null | undefined): string => {
  if (!user) {
    return 'Unknown';
  }

  if (isNewUserWithoutName(user)) {
    return '—';
  }

  return getLastName(user) ?? (user.email?.trim() || '');
};

export const formatUserNameForUsersListing = (user: CommonUser | null | undefined): string => {
  if (!user) {
    return '[username redacted]';
  }

  if (isNewUserWithoutName(user)) {
    return '—';
  }

  return formatUserName(user);
};

export const formatCurrentUserName =
  (currentUserId: UserId, shouldRenderFullName = true) =>
  (user: CommonUser | null): string => {
    if (!user) {
      return '[username redacted]';
    }

    const userName = formatUserName(user);
    if (isMe(currentUserId, user)) {
      return shouldRenderFullName ? `You (${userName})` : 'You';
    }

    return userName;
  };

export const userLastNameComparer = (userA: IUserWithLastName, userB: IUserWithLastName): number =>
  alphabetically(userA.lastName ?? '—', userB.lastName ?? '—');

export const userFirstNameComparer = (
  userA: IUserWithFirstName,
  userB: IUserWithFirstName,
): number => alphabetically(userA.firstName, userB.firstName);

const getFirstChar = (str?: string): string | undefined => str && Array.from(str)[0];

const getFirstInitial = (firstName?: string, email?: string): string =>
  getFirstChar(firstName) || getFirstChar(email) || '';

const getLastInitial = (lastName?: string, email?: string): string =>
  getFirstChar(lastName) || (email && getFirstChar(email.slice(email.indexOf('@') + 1))) || '';

export const getUserInitials = (userInfo: Partial<CommonUser> | null): string => {
  if (!userInfo || isUserRedacted(userInfo)) {
    return '?';
  }

  const { firstName, lastName, email } = userInfo;

  return `${getFirstInitial(firstName, email)}${getLastInitial(lastName, email)}`.toUpperCase();
};

export const makeCurrentUserFirst = memoize.allForever(
  <TUser extends IUserWithId>(
    people: ReadonlyArray<TUser>,
    currentUserId: UserId,
  ): ReadonlyArray<TUser> => {
    const me = people.find((user) => user.userId === currentUserId);
    if (!me) {
      return people;
    }

    return [me, ...people.filter((user) => user.userId !== currentUserId)];
  },
);

/**
 * Method places the current user on the first position and creates new objects representing each user depending on the passed functions.
 * @param people Users identifiable by the userId property.
 * @param currentUserId UserId of the current user.
 * @param createCurrentUser Function that creates an object representing a current user.
 * @param createUser Function that creates an object representing a user.
 */
export const getCustomizedPeopleList = <TOriginalUser extends IUserWithLastName, TUser>(
  people: ReadonlyArray<TOriginalUser>,
  currentUserId: UserId,
  createCurrentUser: (originalUser: TOriginalUser) => TUser,
  createUser: (originalUser: TOriginalUser) => TUser,
): ReadonlyArray<TUser> => {
  const peopleListWithCurrentAsFirst = makeCurrentUserFirst(
    [...people].sort(userLastNameComparer),
    currentUserId,
  );

  return peopleListWithCurrentAsFirst.map((originalUser) =>
    currentUserId === originalUser.userId
      ? createCurrentUser(originalUser)
      : createUser(originalUser),
  );
};

const CountOfNewColors = Object.keys(DarkGradients).length;

export const getExistingUserNewColorGradient = memoize.weak(
  (userInfo: Partial<CommonUser>): GradientFunction => {
    const sumOfChars = (userInfo.userId || '')
      .split('')
      .reduce((total, char) => total + char.charCodeAt(0), 0);
    const colorIndex = sumOfChars % CountOfNewColors;
    const darkGradientColorKey = Object.keys(DarkGradients)[colorIndex] ?? DarkGradients.GrayDark;
    return DarkGradient[darkGradientColorKey];
  },
);

export const getUserNewColorGradient = (userInfo: IProjectContributor | null): GradientFunction =>
  !userInfo || userInfo.isGdprForgotten
    ? DarkGradient[DarkGradients.GrayDark]
    : getExistingUserNewColorGradient(userInfo);

const isUserRedacted = (userInfo: Partial<IUserInfo>): boolean =>
  userInfo.firstName === unknownUserInfo.firstName &&
  userInfo.lastName === unknownUserInfo.lastName &&
  !userInfo.email;

export const getUsersInfo = (
  userIdentifiers: ReadonlySet<IUserIdentifier>,
  usersById: ReadonlyMap<UserId, IUserInfo>,
): ReadonlySet<IUserInfo> => {
  if (Collection.isEmpty(userIdentifiers)) {
    return new Set<IUserInfo>();
  }

  return new Set(
    Collection.getValues(userIdentifiers).map(
      (identifier) => usersById.get(identifier.userId) ?? createUnknownUserInfo(identifier.userId),
    ),
  );
};
