import { SpacesInDeliver, UnifiedMissionControl } from './ProjectFeatureFlagsServerKeys.ts';

export type ProjectFeatureFlagValue = boolean;

export type ProjectFeatureFlagsModel = {
  SpacesInDeliver: ProjectFeatureFlagValue;
  UnifiedMissionControl: ProjectFeatureFlagValue;
};

export const ProjectFeatureFlagsStateKeysByServerKeys: Dictionary<keyof ProjectFeatureFlagsModel> =
  {
    [SpacesInDeliver]: 'SpacesInDeliver',
    [UnifiedMissionControl]: 'UnifiedMissionControl',
  };
