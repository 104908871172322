import {
  BorderRadius,
  offsetFocus,
  px,
  shadowFocusStyles,
} from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

interface StyledAssetTileWrapper {
  isFocusVisible: boolean;
}

export const StyledAssetTileWrapper = styled.div<StyledAssetTileWrapper>`
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: ${px(-1 * offsetFocus)};
      border-radius: ${px(BorderRadius.M)};
      pointer-events: none;
      ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  }
`;
