import { mergeProps } from '@react-aria/utils';
import React, { PropsWithChildren, RefObject } from 'react';
import { ObjectWithDataAttribute } from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { QuinaryButton } from '../../../Button/QuinaryButton/QuinaryButton.tsx';
import { Icon } from '../../../Icons/types.ts';
import { Menu } from '../../../Menu/Menu.tsx';

type Props = {
  readonly onMenuOpenChange: () => void;
  readonly trigger: {
    readonly icon: Icon;
    readonly name: string;
  };
  readonly menuDataAttributes?: ObjectWithDataAttribute;
  readonly showBadge?: boolean;
};

export const NavigationBarQuinaryMenuButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  menuDataAttributes,
  onMenuOpenChange,
  trigger: { name, icon, ...otherTriggerProps },
  showBadge,
}) => {
  return (
    <Menu onOpenChange={onMenuOpenChange}>
      <Menu.Trigger>
        {(ref, triggerProps, isOpen) => (
          <QuinaryButton
            activated={isOpen}
            ref={ref as RefObject<HTMLButtonElement>}
            tooltipPlacement="right"
            tooltipText={isOpen ? '' : name}
            onClick={triggerProps.onClick}
            {...mergeProps(triggerProps, otherTriggerProps)}
          >
            <QuinaryButton.Icon icon={icon} screenReaderText={name} />
            {showBadge && <QuinaryButton.Badge />}
          </QuinaryButton>
        )}
      </Menu.Trigger>
      <Menu.List placement="right bottom" {...menuDataAttributes}>
        {children}
      </Menu.List>
    </Menu>
  );
};

NavigationBarQuinaryMenuButton.displayName = 'NavigationBarQuinaryMenuButton';
