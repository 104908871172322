import { paperBorderRadius } from '@kontent-ai/component-library/Paper';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Placement } from '@kontent-ai/component-library/types';
import { noOperation } from '@kontent-ai/utils';
import { DraftInlineStyle } from 'draft-js';
import { toolbarArrowSize } from '../../../../../../component-library/components/Dialogs/Popover/tokens.ts';
import {
  GetPopperOffset,
  createGetPopperOffset,
} from '../../../../../../component-library/components/Dialogs/Popover/utils/placementUtils.ts';
import { EditorChangeReason } from '../../../editorCore/types/EditorChangeReason.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { IAggregatedMetadata } from '../../../utils/metadata/editorMetadataUtils.ts';
import { EditorFeatureLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import { FeatureNameMap } from '../../apiLimitations/api/editorLimitationUtils.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import {
  EditorCommandStatus,
  canCommandExecute,
  getCommandStatus,
  getFeature,
  isFeatureActive,
  isInsertObjectCommand,
} from '../../keyboardShortcuts/api/editorCommandUtils.ts';

function getTooltipTextForStatus(
  command: RichTextInputCommand,
  status: EditorCommandStatus,
): string | undefined {
  const feature = getFeature(command);
  if (!feature) {
    return undefined;
  }

  const featureName = FeatureNameMap[feature];

  switch (status) {
    case EditorCommandStatus.ActiveNotAllowed:
      return `Remove ${featureName.toLowerCase()} from selection`;

    case EditorCommandStatus.InactiveNotAllowed:
      return `${featureName} ${isInsertObjectCommand(command) ? 'are' : 'is'} not allowed here`;

    default:
      return featureName;
  }
}

export const getToolbarButtonTooltipText = (
  command: RichTextInputCommand,
  disabled?: boolean,
): string | undefined =>
  getTooltipTextForStatus(
    command,
    disabled ? EditorCommandStatus.InactiveNotAllowed : EditorCommandStatus.InactiveAllowed,
  );

export type ToolbarButtonInputProps = {
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly currentVisualStyle: DraftInlineStyle | null;
  readonly hidesDisallowedFeatures: boolean;
  readonly limitations: EditorFeatureLimitations;
  readonly metadataAtSelection: IAggregatedMetadata | null;
  readonly onCommand: (command: RichTextInputCommand) => void;
  readonly selectionContainsText: boolean | null;
  readonly tooltipPlacement?: Placement;
};

export type ToolbarButtonProps = {
  readonly disabled: boolean;
  readonly isActive: boolean;
  readonly isViolated: boolean;
  readonly onClick: () => void;
  readonly tooltipText: string | undefined;
  readonly tooltipPlacement?: Placement;
};

export const getToolbarButtonProps = (
  command: RichTextInputCommand,
  toolbarProps: ToolbarButtonInputProps,
): ToolbarButtonProps | null => {
  const {
    fullBlockTypesAtSelection,
    currentVisualStyle,
    hidesDisallowedFeatures,
    limitations,
    metadataAtSelection,
    onCommand,
    selectionContainsText,
    tooltipPlacement,
  } = toolbarProps;

  const status = getCommandStatus(
    command,
    fullBlockTypesAtSelection,
    currentVisualStyle,
    metadataAtSelection,
    selectionContainsText,
    limitations,
  );
  const isNotAllowed = status === EditorCommandStatus.InactiveNotAllowed;

  if (hidesDisallowedFeatures && isNotAllowed) {
    return null;
  }

  const disabled = !canCommandExecute(status);
  const isActive = isFeatureActive(status);
  const isViolated = status === EditorCommandStatus.ActiveNotAllowed;

  const onClick = !disabled && onCommand ? () => onCommand(command) : noOperation;
  const tooltipText = getTooltipTextForStatus(command, status);

  return {
    isActive,
    isViolated,
    disabled,
    onClick,
    tooltipText,
    tooltipPlacement,
  };
};

export function shouldResetBlockToolbar(changeReason: EditorChangeReason): boolean {
  switch (changeReason) {
    // Changes that influence the caret position or state upon which the block toolbar displays its options
    case EditorChangeReason.Native:
    case EditorChangeReason.Regular:
    case EditorChangeReason.Undo:
    case EditorChangeReason.Redo:
      return true;

    default:
      return false;
  }
}

export const getToolbarPopperOffset: GetPopperOffset = createGetPopperOffset(
  toolbarArrowSize,
  Spacing.S,
  paperBorderRadius,
);
