import { convertFileSizeToBytes } from '../../../../../_shared/utils/fileSizeUtils.ts';
import { RichTextTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  allTableBlockCategoryFeatures,
  allTextBlockTypeFeatures,
  allTextFormattingFeatures,
  allTopLevelBlockCategoryFeatures,
} from '../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import {
  IRichTextTypeElementData,
  getRichTextTypeElementDataFromServerModel,
} from '../../models/elements/RichTextTypeElementData.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';
import {
  getFloatFromString,
  getIntegerFromString,
  getLimitationsServerModel,
} from './utils/typeElementDataConvertersUtils.ts';

function convertTypeElementRecordToServerModel(
  typeElement: IRichTextTypeElementData,
): RichTextTypeElementServerModel {
  const maxChars = typeElement.maxChars && getIntegerFromString(typeElement.maxChars, -1);
  const maxWords = typeElement.maxWords && getIntegerFromString(typeElement.maxWords, -1);
  const minWidth = typeElement.minWidth && getIntegerFromString(typeElement.minWidth);
  const maxWidth = typeElement.maxWidth && getIntegerFromString(typeElement.maxWidth);
  const minHeight = typeElement.minHeight && getIntegerFromString(typeElement.minHeight);
  const maxHeight = typeElement.maxHeight && getIntegerFromString(typeElement.maxHeight);
  const fileSize = typeElement.fileSize && getFloatFromString(typeElement.fileSize);
  const fileSizeUnit = typeElement._fileSizeUnitOption;

  const allowedBlocks = getLimitationsServerModel(
    typeElement.allowedBlocks,
    allTopLevelBlockCategoryFeatures,
  );
  const allowedTextBlocks = getLimitationsServerModel(
    typeElement.allowedTextBlocks,
    allTextBlockTypeFeatures,
  );
  const allowedTextFormatting = getLimitationsServerModel(
    typeElement.allowedTextFormatting,
    allTextFormattingFeatures,
  );
  const allowedTableBlocks = getLimitationsServerModel(
    typeElement.allowedTableBlocks,
    allTableBlockCategoryFeatures,
  );
  const allowedTableTextBlocks = getLimitationsServerModel(
    typeElement.allowedTableTextBlocks,
    allTextBlockTypeFeatures,
  );
  const allowedTableTextFormatting = getLimitationsServerModel(
    typeElement.allowedTableTextFormatting,
    allTextFormattingFeatures,
  );

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    aiGuidelinesIds: typeElement.aiGuidelinesIds,
    allowedBlocks,
    allowedItemLinkTypes: typeElement.allowedItemLinkTypes,
    allowedTextBlocks,
    allowedTextFormatting,
    allowedTableBlocks,
    allowedTableTextBlocks,
    allowedTableTextFormatting,
    allowedTypes: typeElement.allowedTypes,
    fileSize:
      fileSize && fileSize > 0 && fileSizeUnit
        ? convertFileSizeToBytes(fileSize, fileSizeUnit)
        : null,
    maxChars: maxChars && maxChars >= 0 ? maxChars : null,
    maxHeight: maxHeight && maxHeight > 0 ? maxHeight : null,
    maxWidth: maxWidth && maxWidth > 0 ? maxWidth : null,
    maxWords: maxWords && maxWords >= 0 ? maxWords : null,
    minHeight: minHeight && minHeight > 0 ? minHeight : null,
    minWidth: minWidth && minWidth > 0 ? minWidth : null,
    richStringImageType: typeElement.fileType,
    type: ElementType.RichText,
  };
}

export function createRichTextTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: RichTextTypeElementServerModel) =>
      getRichTextTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: IRichTextTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
