import { Icons } from '@kontent-ai/component-library/Icons';
import { ChangeWorkflowStep } from '../../../../../../../_shared/constants/itemActions.ts';
import { openUpdateStepModal } from '../../../../../../../_shared/features/ChangeWorkflowStepModal/actions/thunkChangeWorkflowStepModalActions.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DataUiWorkflowAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStepActionButton } from '../../../components/details/WorkflowStepActionButton.tsx';

export const ChangeWorkflowStepButton = () => {
  const dispatch = useDispatch();
  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.OpenWorkflowStepModal}
      icon={Icons.ArrowDoubleRight}
      label={ChangeWorkflowStep}
      onClick={() => {
        dispatch(openUpdateStepModal(ContentItemEditingEventOrigins.ItemDetails));
      }}
    />
  );
};
