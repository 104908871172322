import React from 'react';
import { Paper, PaperLevel, PaperProps } from '../../containers/Paper/Paper.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { colorTextDefault } from '../../tokens/decision/colors.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { ScrollableContainer } from './components/ScrollableContainer.tsx';
import {
  verticalMenuMaxHeight,
  verticalMenuMaxWidth,
  verticalMenuMinWidth,
} from './decisionTokens.ts';

export type VerticalMenuSizingProps = Pick<PaperProps, 'minWidth' | 'maxWidth' | 'width'> & {
  readonly maxHeight?: string;
};

export interface VerticalMenuFrameProps extends VerticalMenuSizingProps {
  readonly children: React.ReactNode;
  readonly renderPinnedItem: () => React.ReactNode;
}

export const VerticalMenuFrame = React.forwardRef<HTMLDivElement, VerticalMenuFrameProps>(
  (
    {
      maxHeight = verticalMenuMaxHeight,
      maxWidth = verticalMenuMaxWidth,
      minWidth = verticalMenuMinWidth,
      width,
      children,
      renderPinnedItem,
      ...otherProps
    },
    forwardedRef,
  ) => (
    <Paper
      ref={forwardedRef}
      display="flex"
      flexDirection="column"
      color={colorTextDefault}
      level={PaperLevel.Elevated}
      overflow="hidden"
      maxHeight={maxHeight}
      minWidth={minWidth}
      maxWidth={maxWidth}
      width={width}
      {...otherProps}
      {...getDataUiComponentAttribute(VerticalMenuFrame)}
    >
      <ScrollableContainer>
        <Stack>{children}</Stack>
      </ScrollableContainer>
      {renderPinnedItem()}
    </Paper>
  ),
);

VerticalMenuFrame.displayName = 'VerticalMenuFrameComponent';
