import { createGuid } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { PublishErrorMessage } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { TrackUserEventWithDataAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import { OnboardingNotificationTriggeredAction } from '../../../../../../../../data/actions/thunks/onboardingNotifications/onboardingNotificationTriggered.ts';
import { OnboardingNotification } from '../../../../../../../../data/models/user/OnboardingNotification.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_PublishItems_Failed,
  ContentListing_PublishItems_Finished,
  ContentListing_PublishItems_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFailed,
  createBulkActionFinished,
  createBulkActionStarted,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IPublishSelectedContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly loadListingContentItems: () => ThunkPromise;
  readonly onboardingNotificationTriggered: OnboardingNotificationTriggeredAction;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_PublishItems_Started>(
  ContentListing_PublishItems_Started,
);
const actionFinished = createBulkActionFinished<typeof ContentListing_PublishItems_Finished>(
  ContentListing_PublishItems_Finished,
);
const actionFailed = createBulkActionFailed<typeof ContentListing_PublishItems_Failed>(
  ContentListing_PublishItems_Failed,
);

export type PublishSelectedContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished | typeof actionFailed
>;

export const publishSelectedContentItemsActionCreator =
  (deps: IPublishSelectedContentItemsActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<any> => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());

    const query = getItemListingOperationQueryFromFilter(getState());
    const { scheduledToUnpublishAt, scheduledUnpublishDisplayTimeZone } =
      getState().contentApp.changeWorkflowStepModalData;

    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.publishVariants(
        selectedLanguageId,
        query,
        scheduledToUnpublishAt,
        scheduledUnpublishDisplayTimeZone,
      );

      dispatch(actionFinished(createBulkActionResult(operationResult, selectedLanguageId)));

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentEntryBulkPublished, {
          count: operationResult.modifiedItemIds.length,
        }),
      );
      dispatch(
        deps.onboardingNotificationTriggered(OnboardingNotification.ContentPublishedNotification, {
          publishedItemIds: Immutable.List(operationResult.modifiedItemIds),
        }),
      );
    } catch (error) {
      logError('Error while bulk publish', error);
      dispatch(actionFailed(PublishErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
