import React, { useCallback } from 'react';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ListingRequestOptionsModel } from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import {
  useTrackWidgetOverviewItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../../../unifiedMissionControl/widgets/shared/hooks/tracking.ts';
import { UnchangedUnpublishedWidget as UnchangedUnpublishedContentWidgetComponent } from '../components/UnchangedUnpublishedWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const UnchangedUnpublishedWidgetLiveContainer: React.FC = () => {
  const itemsFetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal: AbortSignal) =>
      missionControlRepository.getUnchangedUnpublishedItems(requestOptions, abortSignal),
    [],
  );

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.UnchangedUnpublishedItems,
  );
  const trackWidgetShowMoreButtonClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.UnchangedUnpublishedItems,
  );

  return (
    <UnchangedUnpublishedContentWidgetComponent
      itemsFetcher={itemsFetcher}
      onOverviewItemClick={trackWidgetOverviewItemClick}
      onShowMoreClick={trackWidgetShowMoreButtonClick}
      useRowLinks
    />
  );
};

UnchangedUnpublishedWidgetLiveContainer.displayName = 'UnchangedUnpublishedWidgetLiveContainer';
