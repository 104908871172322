import { alphabetically } from '@kontent-ai/utils';
import React, { useMemo, useRef } from 'react';
import { HideOutsideViewport } from '../../../../_shared/components/HideOutsideViewport.tsx';
import { ScrollTableCell } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCellWrappedByLink } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCellWrappedByLink.tsx';
import { ScrollTableRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { SubscriptionUserStatusCellContent } from '../containers/SubscriptionUserStatusCellContent.tsx';
import { DecoratedUser } from '../utils/subscriptionUserUsageFilterUtils.ts';
import {
  getLastActivityFieldText,
  getSwitchUserStatusTooltip,
  getUserAssignmentCellText,
  getUserAssignmentCellTooltip,
} from '../utils/subscriptionUsersScrollTableRowUtils.ts';
import { SubscriptionUsersScrollTableRowSkeleton } from './SubscriptionUsersScrollTableRowSkeleton.tsx';

export interface ISubscriptionUserViewModel extends DecoratedUser {}

type SubscriptionUsersScrollTableRowProps = {
  readonly onCreateUserDetailLink: (userId: Uuid) => string;
  readonly subscriptionUserViewModel: ISubscriptionUserViewModel;
};

export const SubscriptionUsersScrollTableRow: React.FC<SubscriptionUsersScrollTableRowProps> = ({
  onCreateUserDetailLink,
  subscriptionUserViewModel,
}) => {
  const userDetailLink = onCreateUserDetailLink(subscriptionUserViewModel.userId);

  const clickableCellProps = {
    allowNavigationWithOnClick: true,
    linkPath: userDetailLink,
  };

  const ref = useRef<HTMLObjectElement | null>(null);

  const {
    fullName,
    isInvitationPending,
    latestLastLoginDateTimeString,
    userProjectNames,
    userEnvironmentNames,
    userRoleNames,
  } = subscriptionUserViewModel;

  const assignedProjectsTooltip = useMemo(
    () => getUserAssignmentCellTooltip([...userProjectNames].sort(alphabetically)),
    [userProjectNames],
  );
  const assignedEnvironmentsTooltip = useMemo(
    () => getUserAssignmentCellTooltip([...new Set(userEnvironmentNames)].sort(alphabetically)),
    [userEnvironmentNames],
  );
  const userRoleUniqueNames = useMemo(() => [...new Set(userRoleNames)], [userRoleNames]);
  const assignedRolesTooltip = useMemo(
    () => getUserAssignmentCellTooltip(userRoleUniqueNames.sort(alphabetically)),
    [userRoleUniqueNames],
  );

  return (
    <div ref={ref}>
      <HideOutsideViewport
        targetRef={ref}
        renderVisible={() => (
          <ScrollTableRow
            objectName={fullName}
            rowFocusLinkPath={userDetailLink}
            rowFocusLinkAriaLabel={`visit content item — ${fullName}`}
          >
            <ScrollTableCellWrappedByLink
              size={10}
              isGrowing
              tooltipText={fullName}
              {...clickableCellProps}
            >
              <span className="scroll-table__value">{fullName}</span>
            </ScrollTableCellWrappedByLink>

            <ScrollTableCellWrappedByLink
              size={10}
              isGrowing
              tooltipText={assignedProjectsTooltip}
              {...clickableCellProps}
            >
              <span className="scroll-table__value">
                {getUserAssignmentCellText(userProjectNames, 'project')}
              </span>
            </ScrollTableCellWrappedByLink>

            <ScrollTableCellWrappedByLink
              size={10}
              isGrowing
              tooltipText={assignedEnvironmentsTooltip}
              {...clickableCellProps}
            >
              <span className="scroll-table__value">
                {getUserAssignmentCellText(userEnvironmentNames, 'environment')}
              </span>
            </ScrollTableCellWrappedByLink>

            <ScrollTableCellWrappedByLink
              size={10}
              isGrowing
              tooltipText={assignedRolesTooltip}
              {...clickableCellProps}
            >
              <span className="scroll-table__value">
                {getUserAssignmentCellText(userRoleUniqueNames, 'role')}
              </span>
            </ScrollTableCellWrappedByLink>

            <ScrollTableCellWrappedByLink
              size={5}
              tooltipText={`Last activity: ${getLastActivityFieldText(
                latestLastLoginDateTimeString,
                isInvitationPending,
                true,
              )}`}
              {...clickableCellProps}
            >
              <span className="scroll-table__value">
                {getLastActivityFieldText(latestLastLoginDateTimeString, isInvitationPending)}
              </span>
            </ScrollTableCellWrappedByLink>
            <ScrollTableCell
              size={5}
              tooltip={getSwitchUserStatusTooltip(subscriptionUserViewModel)}
              {...clickableCellProps}
            >
              <SubscriptionUserStatusCellContent
                subscriptionUserViewModel={subscriptionUserViewModel}
              />
            </ScrollTableCell>
          </ScrollTableRow>
        )}
        renderHidden={() => <SubscriptionUsersScrollTableRowSkeleton />}
        rootMargin="600px 0px 600px 0px"
      />
    </div>
  );
};

SubscriptionUsersScrollTableRow.displayName = 'SubscriptionUsersScrollTableRow';
