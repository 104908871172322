import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  BorderRadius,
  offsetFocus,
  px,
  shadowFocusStyles,
} from '@kontent-ai/component-library/tokens';
import { usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { useRef } from 'react';
import {
  DataUiAction,
  DataUiWorkflowAction,
  getDataAttributeProps,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface ILinkLikeProps {
  readonly className?: string;
  readonly onClick?: () => void;
  readonly dataUiAction?: DataUiAction | DataUiWorkflowAction;
  readonly screenReaderText?: string;
}

export const LinkLike: React.FC<React.PropsWithChildren<ILinkLikeProps>> = (props) => {
  const { isFocusVisible, focusProps } = useOurFocusRing();
  const ref = useRef(null);
  const { pressProps } = usePress({
    ref,
    onPress: () => {
      if (props.onClick) {
        props.onClick();
      }
    },
  });
  return (
    <a
      tabIndex={0}
      className={props.className}
      css={`
        position: relative;
        display: inline-block;

        &::before {
          content: '';
          position: absolute;
          inset: 0 ${px(-1 * offsetFocus)} -2px;
          border-radius: ${px(BorderRadius.S)};
          pointer-events: none;
          ${isFocusVisible ? shadowFocusStyles : ''};
        }
      `}
      {...getDataAttributeProps(props)}
      {...mergeProps(pressProps, focusProps)}
    >
      {props.screenReaderText && <SrOnly>{props.screenReaderText}</SrOnly>}
      {props.children}
    </a>
  );
};

LinkLike.displayName = 'LinkLike';
