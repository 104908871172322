import { Spacing } from '@kontent-ai/component-library/tokens';
import { Divider } from '../../../../../../../component-library/components/Dividers/Divider.tsx';
import { DividerDirection } from '../../../../../../../component-library/components/Dividers/components/StyledDivider.tsx';

export const EditorToolbarDivider = () => (
  <Divider
    direction={DividerDirection.Vertical}
    offsetBefore={Spacing.None}
    offsetAfter={Spacing.None}
  />
);
