import { useAttachRef } from '@kontent-ai/hooks';
import { FocusScope } from '@react-aria/focus';
import { AriaMenuProps, useMenu } from '@react-aria/menu';
import { mergeProps } from '@react-aria/utils';
import React, { ComponentProps, ReactNode } from 'react';
import { RefForwardingComponent } from '../../@types/RefForwardingComponent.type.ts';
import {
  VirtualizedVerticalMenuFrame,
  VirtualizedVerticalMenuFrameProps,
} from './VirtualizedVerticalMenuFrame.tsx';
import { BaseMenuItem, VerticalMenuItem, VerticalMenuSection } from './types.ts';

export interface VirtualizedVerticalMenuProps<TItem extends VerticalMenuItem<TItem>>
  extends VirtualizedVerticalMenuFrameProps<TItem>,
    Partial<Pick<ComponentProps<typeof FocusScope>, 'contain' | 'restoreFocus'>>,
    Partial<Pick<AriaMenuProps<TItem>, 'autoFocus'>> {
  readonly items: ReadonlyArray<VerticalMenuSection<TItem> | TItem> | undefined;
}

interface VirtualizedVerticalMenuForwardingRef
  extends RefForwardingComponent<VirtualizedVerticalMenuProps<BaseMenuItem>, HTMLDivElement> {
  <TItem extends VerticalMenuItem<TItem>>(props: VirtualizedVerticalMenuProps<TItem>): ReactNode;
}

export const VirtualizedVerticalMenu: VirtualizedVerticalMenuForwardingRef = React.forwardRef(
  (
    { autoFocus, contain = true, items, restoreFocus = true, state, ...otherProps },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const { menuProps } = useMenu(
      {
        autoFocus,
        items,
        shouldFocusWrap: false,
        ...otherProps,
      },
      state,
      refObject,
    );

    const verticalMenuProps = mergeProps(
      otherProps,
      {
        ref: refToForward,
        state,
      },
      menuProps,
    );

    return (
      <FocusScope contain={contain} restoreFocus={restoreFocus}>
        <VirtualizedVerticalMenuFrame {...verticalMenuProps} />
      </FocusScope>
    );
  },
);

VirtualizedVerticalMenu.displayName = 'VirtualizedVerticalMenu';
