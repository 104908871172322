import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';

interface IDropDownOptionListProps extends IForwardedRefProps<HTMLDivElement> {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly longer?: boolean;
  readonly hasTrailingElements?: boolean;
  readonly optionListDataUiAttributes: ObjectWithDataAttribute;
  readonly style?: CSSProperties;
  readonly role?: string;
}

const DropDownOptionList: React.FC<IDropDownOptionListProps> = (props) => (
  <div
    className={classNames(props.className, 'dropdown-options')}
    data-hj-suppress=""
    ref={props.forwardedRef}
    style={props.style}
    aria-label="option list"
  >
    <div
      className={classNames('dropdown-options__pane', {
        'dropdown-options__pane--longer': props.longer,
        'dropdown-options__pane--has-trailing-elements': props.hasTrailingElements,
      })}
      role={props.role}
      {...props.optionListDataUiAttributes}
    >
      {props.children}
    </div>
  </div>
);

const DropDownOptionListFRC = forwardRef(DropDownOptionList);
export { DropDownOptionListFRC as DropDownOptionList };
