import PropTypes, { ValidationMap } from 'prop-types';
import { getFileSizeObject } from '../../../../../_shared/utils/fileSizeUtils.ts';
import { RichTextTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { defaultRichTextFileTypeOption } from '../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../../../contentInventory/content/models/fileSizeOptions.ts';
import { QuantityOption } from '../../../../contentInventory/content/models/quantityOptions.ts';
import { UnitOption } from '../../../../contentInventory/content/models/textLengthOptions.ts';
import {
  allTableBlockCategoryFeatures,
  allTextBlockTypeFeatures,
  allTextFormattingFeatures,
  allTopLevelBlockCategoryFeatures,
} from '../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { IBaseTextTypeElementData } from '../../types/IBaseTextTypeElementData.type.ts';
import {
  getLimitationsDomainModel,
  getQuantityUnitOption,
} from '../../utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import {
  BaseTypeElementDataValidationMap,
  EmptyBaseTypeElementData,
  ITypeElementDataWithAllowedContentTypesLimitation,
  ITypeElementDataWithAllowedItemLinkTypesLimitation,
  ITypeElementDataWithAssetDimensions,
  ITypeElementDataWithAssetTypeLimitation,
  ITypeElementDataWithFileSizeLimit,
  ITypeElementDataWithLimitationCategories,
  TypeElementDataWithAiGuidelinesIdsValidationMap,
  TypeElementDataWithAssetDimensionsValidationMap,
  TypeElementDataWithAssetTypeLimitationValidationMap,
  TypeElementDataWithFileSizeLimitValidationMap,
  TypeElementDataWithLimitationCategoriesValidationMap,
  TypeElementDataWithTextLimitValidationMap,
  TypeElementWithAllowedContentTypesLimitationValidationMap,
  TypeElementWithAllowedItemLinkTypesLimitationValidationMap,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface IRichTextTypeElementData
  extends IBaseTextTypeElementData,
    ITypeElementDataWithAssetDimensions,
    ITypeElementDataWithFileSizeLimit,
    ITypeElementDataWithAssetTypeLimitation,
    ITypeElementDataWithAllowedContentTypesLimitation,
    ITypeElementDataWithAllowedItemLinkTypesLimitation,
    ITypeElementDataWithLimitationCategories {}

export const RichTextTypeElementDataPropTypesShape = PropTypes.exact<
  ValidationMap<IRichTextTypeElementData>
>({
  ...BaseTypeElementDataValidationMap,
  ...TypeElementDataWithAiGuidelinesIdsValidationMap,
  ...TypeElementDataWithTextLimitValidationMap,
  ...TypeElementDataWithAssetDimensionsValidationMap,
  ...TypeElementDataWithFileSizeLimitValidationMap,
  ...TypeElementDataWithAssetTypeLimitationValidationMap,
  ...TypeElementWithAllowedContentTypesLimitationValidationMap,
  ...TypeElementWithAllowedItemLinkTypesLimitationValidationMap,
  ...TypeElementDataWithLimitationCategoriesValidationMap,
});

export const EmptyRichTextTypeElementData: IRichTextTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Recommended,
  _fileSizeUnitOption: FileSizeUnit.Kilobyte,
  _heightOption: QuantityOption.Exactly,
  _lengthUnitOption: UnitOption.Words,
  _widthOption: QuantityOption.Exactly,
  aiGuidelinesIds: [],
  allowedBlocks: allTopLevelBlockCategoryFeatures,
  allowedItemLinkTypes: [],
  allowedTableBlocks: allTableBlockCategoryFeatures,
  allowedTableTextBlocks: allTextBlockTypeFeatures,
  allowedTableTextFormatting: allTextFormattingFeatures,
  allowedTextBlocks: allTextBlockTypeFeatures,
  allowedTextFormatting: allTextFormattingFeatures,
  allowedTypes: [],
  fileSize: null,
  fileType: defaultRichTextFileTypeOption,
  maxChars: null,
  maxHeight: null,
  maxWidth: null,
  maxWords: null,
  minHeight: null,
  minWidth: null,
  type: ElementType.RichText,
};

export function getRichTextTypeElementDataFromServerModel(
  serverModel: RichTextTypeElementServerModel,
): IRichTextTypeElementData {
  const widthOption = getQuantityUnitOption(serverModel.minWidth, serverModel.maxWidth);
  const heightOption = getQuantityUnitOption(serverModel.minHeight, serverModel.maxHeight);
  const fileSizeObject = serverModel.fileSize ? getFileSizeObject(serverModel.fileSize) : null;
  const allowedBlocks = getLimitationsDomainModel(
    serverModel.allowedBlocks,
    allTopLevelBlockCategoryFeatures,
  );
  const allowedTextBlocks = getLimitationsDomainModel(
    serverModel.allowedTextBlocks,
    allTextBlockTypeFeatures,
  );
  const allowedTextFormatting = getLimitationsDomainModel(
    serverModel.allowedTextFormatting,
    allTextFormattingFeatures,
  );
  const allowedTableBlocks = getLimitationsDomainModel(
    serverModel.allowedTableBlocks,
    allTableBlockCategoryFeatures,
  );
  const allowedTableTextBlocks = getLimitationsDomainModel(
    serverModel.allowedTableTextBlocks,
    allTextBlockTypeFeatures,
  );
  const allowedTableTextFormatting = getLimitationsDomainModel(
    serverModel.allowedTableTextFormatting,
    allTextFormattingFeatures,
  );

  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyRichTextTypeElementData._configurability,
    _fileSizeUnitOption: fileSizeObject
      ? fileSizeObject.unit
      : EmptyRichTextTypeElementData._fileSizeUnitOption,
    _heightOption: heightOption,
    _lengthUnitOption: serverModel.maxChars !== null ? UnitOption.Characters : UnitOption.Words,
    _widthOption: widthOption,
    aiGuidelinesIds: serverModel.aiGuidelinesIds ?? [],
    allowedBlocks,
    allowedItemLinkTypes: serverModel.allowedItemLinkTypes,
    allowedTableBlocks,
    allowedTableTextBlocks,
    allowedTableTextFormatting,
    allowedTextBlocks,
    allowedTextFormatting,
    allowedTypes: serverModel.allowedTypes,
    fileSize: fileSizeObject?.fileSize ?? null,
    fileType: serverModel.richStringImageType || EmptyRichTextTypeElementData.fileType,
    maxChars:
      serverModel.maxChars !== null
        ? serverModel.maxChars.toString()
        : EmptyRichTextTypeElementData.maxChars,
    maxHeight: serverModel.maxHeight
      ? serverModel.maxHeight.toString()
      : EmptyRichTextTypeElementData.maxHeight,
    maxWidth: serverModel.maxWidth
      ? serverModel.maxWidth.toString()
      : EmptyRichTextTypeElementData.maxWidth,
    maxWords:
      serverModel.maxWords !== null
        ? serverModel.maxWords.toString()
        : EmptyRichTextTypeElementData.maxWords,
    minHeight: serverModel.minHeight
      ? serverModel.minHeight.toString()
      : EmptyRichTextTypeElementData.minHeight,
    minWidth: serverModel.minWidth
      ? serverModel.minWidth.toString()
      : EmptyRichTextTypeElementData.minWidth,
    type: EmptyRichTextTypeElementData.type,
  };
}
