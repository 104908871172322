export enum AiFeatureSet {
  Beta = 'Beta',
  ImageDescription = 'ImageDescription',
  Inline = 'Inline',
  ImageTagging = 'ImageTagging',
  Taxonomies = 'Taxonomies',
  Translations = 'Translations',
  ItemVariantTranslations = 'ItemVariantTranslations',
  LinkedItems = 'LinkedItems',
  LinkedItemsScore = 'LinkedItemsScore',
  SemanticSearch = 'SemanticSearch',
  Review = 'Review',
  ReviewPrototype = 'ReviewPrototype',
}
