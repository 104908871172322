import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ContentState, EditorState } from 'draft-js';
import { forwardRef, useMemo, useState } from 'react';
import { ActionMenuPositioner } from '../../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import {
  AiActionMenu,
  createCopyToClipboardAiAction,
  createDiscardAiActionFromResult,
  createEditInputsAiAction,
  createRejectSuggestionAiSection,
  createTryAgainAiAction,
  createUseSuggestionAiSection,
} from '../../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { AiResult } from '../../../../../../_shared/features/AI/components/AiResult.tsx';
import { ActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import {
  SpecificFeedbackReason,
  createFeedbackReasons,
} from '../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { AiSuggestionError } from '../../components/AiSuggestionError.tsx';
import { RichAiSuggestionViewer } from '../../containers/viewers/RichAiSuggestionViewer.tsx';
import { ReviewContentDialog } from './ReviewContentDialog.tsx';

const feedbackReasons = createFeedbackReasons([
  SpecificFeedbackReason.ResultNotByInstructions,
  SpecificFeedbackReason.ResultSlow,
]);

type ReviewContentActionProps = {
  readonly initialGuidelines: string;
  readonly onClosePropsDialog: () => void;
  readonly onCopyToClipboard?: () => void;
  readonly onDiscard: () => void;
  readonly onInputsEdited: () => void;
  readonly onSubmit: (guidelines: string) => void;
  readonly onTryAgain?: () => void;
  readonly preferMenuOnTop: boolean;
  readonly result: ActionResult<ContentState>;
  readonly resultWidth: number;
};

export const ReviewContentAction = forwardRef<HTMLElement, ReviewContentActionProps>(
  (
    {
      initialGuidelines,
      onClosePropsDialog,
      onCopyToClipboard,
      onDiscard,
      onInputsEdited,
      onSubmit,
      onTryAgain,
      preferMenuOnTop,
      result,
      resultWidth,
    },
    ref,
  ) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const editAiGuidelinesAction = useMemo(
      () =>
        result.isFinished
          ? () => {
              setIsDialogOpen(true);
              onInputsEdited();
            }
          : undefined,
      [result.isFinished, onInputsEdited],
    );

    const reviewContentMenuOptions = [
      createUseSuggestionAiSection([createCopyToClipboardAiAction(onCopyToClipboard)], result),
      createRejectSuggestionAiSection([
        createEditInputsAiAction('Edit AI guidelines', editAiGuidelinesAction),
        createTryAgainAiAction(result, onTryAgain),
        createDiscardAiActionFromResult(onDiscard, result),
      ]),
    ];

    const resultEditorState = useMemo(
      () => (result.content ? EditorState.createWithContent(result.content) : null),
      [result.content],
    );
    const hasResult = !!result.error || !!resultEditorState;

    const handleSubmit = (guidelines: string): void => {
      setIsDialogOpen(false);
      onSubmit(guidelines);
    };

    const handleClose = (): void => {
      setIsDialogOpen(false);
      onClosePropsDialog();
    };

    return (
      <>
        <ReviewContentDialog
          initialGuidelines={initialGuidelines}
          isDialogOpen={isDialogOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
        {!isDialogOpen && (
          <ActionMenuPositioner
            preferMenuOnTop={preferMenuOnTop}
            ref={ref}
            renderMenu={() => <AiActionMenu options={reviewContentMenuOptions} />}
            renderResult={(resultRef) => (
              <AiResult
                ref={resultRef}
                actionName={AiActionName.ReviewContent}
                actionTitle="Review content"
                feedbackReasons={feedbackReasons}
                isLoading={!result.isFinished}
                renderResult={() =>
                  hasResult && (
                    <Stack spacing={Spacing.L}>
                      {result.error && <AiSuggestionError error={result.error} />}
                      {resultEditorState && (
                        <RichAiSuggestionViewer editorState={resultEditorState} />
                      )}
                    </Stack>
                  )
                }
                width={resultWidth}
              />
            )}
          />
        )}
      </>
    );
  },
);

ReviewContentAction.displayName = 'ReviewContentAction';
