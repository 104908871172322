import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { DataUiRteAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { CommandToolbarMenuItem } from '../../toolbars/components/menu/EditorCommandMenu.tsx';

export const headingMenuItems: ReadonlyArray<CommandToolbarMenuItem> = [
  {
    label: 'Normal text',
    command: RichTextInputCommand.Unstyled,
    shortcuts: ControlAltShortcutTemplate(0),
    uiAction: DataUiRteAction.Unstyled,
  },
  {
    label: 'Heading 1',
    command: RichTextInputCommand.HeadingOne,
    shortcuts: ControlAltShortcutTemplate(1),
    uiAction: DataUiRteAction.HeaderOne,
  },
  {
    label: 'Heading 2',
    command: RichTextInputCommand.HeadingTwo,
    shortcuts: ControlAltShortcutTemplate(2),
    uiAction: DataUiRteAction.HeaderTwo,
  },
  {
    label: 'Heading 3',
    command: RichTextInputCommand.HeadingThree,
    shortcuts: ControlAltShortcutTemplate(3),
    uiAction: DataUiRteAction.HeaderThree,
  },
  {
    label: 'Heading 4',
    command: RichTextInputCommand.HeadingFour,
    shortcuts: ControlAltShortcutTemplate(4),
    uiAction: DataUiRteAction.HeaderFour,
  },
  {
    label: 'Heading 5',
    command: RichTextInputCommand.HeadingFive,
    shortcuts: ControlAltShortcutTemplate(5),
    uiAction: DataUiRteAction.HeaderFive,
  },
  {
    label: 'Heading 6',
    command: RichTextInputCommand.HeadingSix,
    shortcuts: ControlAltShortcutTemplate(6),
    uiAction: DataUiRteAction.HeaderSix,
  },
];

export const listMenuItems: ReadonlyArray<CommandToolbarMenuItem> = [
  {
    label: 'Ordered list',
    command: RichTextInputCommand.OrderedList,
    shortcuts: ControlAltShortcutTemplate('O'),
    iconName: IconName.ListNumbers,
    uiAction: DataUiRteAction.OrderedList,
  },
  {
    label: 'Unordered list',
    command: RichTextInputCommand.UnorderedList,
    shortcuts: ControlAltShortcutTemplate('U'),
    iconName: IconName.ListBullets,
    uiAction: DataUiRteAction.UnorderedList,
  },
];
