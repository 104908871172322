import { Placement } from '@popperjs/core';
import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  DataUiCollection,
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Resettable } from '../block/BlockToolbar.tsx';
import { ToolbarButtonMenuPositioner } from '../block/ToolbarButtonMenuPositioner.tsx';
import { RTEToolbarIconButton, RTEToolbarIconButtonProps } from './RTEToolbarButton.tsx';

type Props = Omit<RTEToolbarIconButtonProps, 'onClick'> & {
  readonly children: React.ReactNode;
  readonly toolbarPlacement: 'top-start' | 'bottom-start' | 'right';
  readonly fallbackToolbarPlacements?: ReadonlyArray<Placement>;
  readonly uiAction: DataUiRteAction;
  readonly menuUiCollection: DataUiCollection;
};

export const RTEToolbarButtonWithMenu = forwardRef<Resettable, Props>(
  (
    {
      children,
      fallbackToolbarPlacements,
      isViolated,
      toolbarPlacement,
      tooltipPlacement,
      tooltipText,
      uiAction,
      menuUiCollection,
      ...buttonProps
    },
    forwardedRef,
  ) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    useImperativeHandle(forwardedRef, () => ({
      reset: closeMenu,
    }));

    const closeMenu = (): void => {
      setIsMenuVisible(false);
    };

    const openMenu = (): void => {
      setIsMenuVisible(true);
    };

    return (
      <ToolbarButtonMenuPositioner
        isDropDownVisible={isMenuVisible}
        fallbackToolbarPlacements={fallbackToolbarPlacements}
        menuUiCollection={menuUiCollection}
        onClose={closeMenu}
        renderDropDown={() => children}
        renderTrigger={(triggerProps) => (
          <RTEToolbarIconButton
            {...buttonProps}
            isActive={isMenuVisible}
            isViolated={isViolated}
            onClick={buttonProps.disabled || isMenuVisible ? closeMenu : openMenu}
            tooltipPlacement={tooltipPlacement}
            tooltipText={isMenuVisible ? undefined : tooltipText}
            {...triggerProps}
            {...getDataUiActionAttribute(uiAction)}
          />
        )}
        toolbarPlacement={toolbarPlacement}
      />
    );
  },
);

RTEToolbarButtonWithMenu.displayName = 'BlockToolbarButton';
