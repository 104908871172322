import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import {
  PopoverMessage,
  enhanceWithStickyPopover,
} from '../../../../_shared/uiComponents/Popover/Popover.tsx';
import {
  IForwardedRefProps,
  forwardedRefProps,
} from '../../../../_shared/utils/forwardedRefProps.tsx';

type DismissiblePopoverProps = IForwardedRefProps<HTMLDivElement> & {
  readonly headline?: string;
  readonly onClick?: (e: React.MouseEvent) => void;
  readonly onDismiss: (event: React.MouseEvent) => void;
};

const propTypes: PropTypesShape<DismissiblePopoverProps> = {
  ...forwardedRefProps,
  headline: PropTypes.string,
  onClick: PropTypes.func,
  onDismiss: PropTypes.func.isRequired,
};

const DismissiblePopover: React.FC<React.PropsWithChildren<DismissiblePopoverProps>> = ({
  children,
  forwardedRef,
  onClick,
  onDismiss,
  headline,
}) => (
  <div className="dismissible-popover flex" onClick={onClick} ref={forwardedRef}>
    <PopoverMessage>
      {headline && <h4 className="dismissible-popover__title">{headline}</h4>}
      {children}
    </PopoverMessage>
    <SquareButton
      className="dismissible-popover__button"
      iconName={IconName.Times}
      onClick={onDismiss}
      ariaLabel="Dismiss message"
      size={SquareButtonSize.Quinary}
      style={ButtonStyle.Quinary}
    />
  </div>
);

DismissiblePopover.displayName = 'DismissiblePopover';
DismissiblePopover.propTypes = propTypes;

const EnhancedDismissiblePopover =
  enhanceWithStickyPopover<DismissiblePopoverProps>(DismissiblePopover);

export { EnhancedDismissiblePopover as DismissiblePopover };
