import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { OnboardingCardsSection } from '../../containers/GettingStartedQuickstart/OnboardingCardsSection.tsx';
import { GettingStartedVideoSection } from '../shared/GettingStartedVideoSection.tsx';
import { QuickstartTitle } from '../shared/QuickstartTitle.tsx';
import { GettingStartedSdkSection } from './GettingStartedSdkSection.tsx';
import { QuickstartQuickTip } from './QuickstartQuickTip.tsx';

interface IGettingStartedQuickstartProps {
  readonly userName: string;
  readonly showSdkSection: boolean;
}

export const GettingStartedQuickstart: React.FC<IGettingStartedQuickstartProps> = (props) => (
  <div className="canvas__workspace">
    <div className="canvas__content">
      <div className="canvas__content-pane">
        <div className="flex flex--center">
          <div className="quickstart">
            <QuickstartTitle>
              Welcome, {props.userName}. This project will help you get started.
            </QuickstartTitle>
            <OnboardingCardsSection />
            <GettingStartedVideoSection />
            <Stack spacing={Spacing.XL}>
              {props.showSdkSection && <GettingStartedSdkSection />}
              <QuickstartQuickTip />
            </Stack>
          </div>
        </div>
      </div>
    </div>
  </div>
);

GettingStartedQuickstart.displayName = 'GettingStartedQuickstart';
