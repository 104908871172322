import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import React from 'react';
import { ITriggerProps } from '../../components/PopoverDialog/types/PopoverDialogContract.type.ts';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';
import { SquareButton } from '../Button/SquareButton.tsx';
import { ButtonStyle } from '../Button/buttonStyle.ts';
import { SquareButtonSize } from '../Button/squareButtonSize.ts';

interface IConfigurationButtonProps extends ITriggerProps, IForwardedRefProps<HTMLDivElement> {
  readonly dataUiAction: DataUiAction;
  readonly iconName: IconName;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText: string;
}

const ConfigurationButton: React.FC<IConfigurationButtonProps> = (props) => (
  <div ref={props.forwardedRef}>
    <Tooltip
      tooltipText={props.isActive ? undefined : props.tooltipText}
      placement={props.tooltipPlacement ?? 'bottom-end'}
    >
      <SquareButton
        iconName={props.iconName}
        isActive={props.isActive}
        onClick={props.onToggle}
        ariaLabel={props.tooltipText}
        style={ButtonStyle.Quinary}
        size={SquareButtonSize.Quinary}
        {...getDataUiActionAttribute(props.dataUiAction)}
      />
    </Tooltip>
    {props.popover}
  </div>
);

ConfigurationButton.displayName = 'ConfigurationButton';

const ConfigurationButtonRFC = forwardRef(ConfigurationButton);
export { ConfigurationButtonRFC as ConfigurationButton };
