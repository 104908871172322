import { CLPropTypes } from '@kontent-ai/component-library/validators';
import { UnreachableCaseException } from '@kontent-ai/errors';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { OrderByDirection } from '../../models/OrderBy.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icon } from '../Icon/Icon.tsx';
import {
  ScrollTableColumnSize,
  translateColumnSizeToClassSuffix,
} from './types/scrollTableTypes.ts';

const getOrderingIcon = (orderByDirection: OrderByDirection) => {
  switch (orderByDirection) {
    case OrderByDirection.Ascending:
      return <Icon className="scroll-table__ordering-mark-icon" iconName={IconName.CaretUp} />;
    case OrderByDirection.Descending:
      return <Icon className="scroll-table__ordering-mark-icon" iconName={IconName.CaretDown} />;
    case OrderByDirection.None:
      return null;
    default:
      throw UnreachableCaseException(orderByDirection);
  }
};

interface IScrollTableHeadColumnProps {
  readonly dataUiElement?: DataUiElement;
  readonly isGrowing?: boolean;
  readonly orderByDirection?: OrderByDirection;
  readonly name: string;
  readonly size: ScrollTableColumnSize;
}

export const ScrollTableHeadColumn: React.FC<IScrollTableHeadColumnProps> = ({
  dataUiElement,
  isGrowing,
  orderByDirection = OrderByDirection.None,
  name,
  size,
}) => {
  const classSizeSuffix = translateColumnSizeToClassSuffix(size);

  return (
    <div
      className={classNames('scroll-table__column-head', {
        [`scroll-table__column--growing-${classSizeSuffix}`]: isGrowing,
        [`scroll-table__column--${classSizeSuffix}`]: !isGrowing,
        'scroll-table__column-head--is-selected': orderByDirection !== OrderByDirection.None,
      })}
    >
      <div
        className="scroll-table__column-name"
        {...(dataUiElement && getDataUiElementAttribute(dataUiElement))}
        role="columnheader"
      >
        <span>{name}</span>
        <div className="scroll-table__ordering-mark">{getOrderingIcon(orderByDirection)}</div>
      </div>
    </div>
  );
};

ScrollTableHeadColumn.displayName = 'ScrollTableHeadColumn';
const propTypes: PropTypesShape<IScrollTableHeadColumnProps> = {
  dataUiElement: PropTypes.string,
  isGrowing: PropTypes.bool,
  orderByDirection: CLPropTypes.enum(OrderByDirection),
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};
ScrollTableHeadColumn.propTypes = propTypes;
