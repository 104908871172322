import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { AnimatedProgressIcon } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React, { useEffect, useState } from 'react';
import { DialogState } from '../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { NotificationBarAlert } from '../../../../../component-library/components/NotificationBar/NotificationBar.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CustomAssetDomainSwapWarningText, MinDialogWidth } from '../constants/uiConstants.ts';

type SwapEnvironmentDialogProps = {
  readonly showDialog: boolean;
  readonly environmentName: string;
  readonly environmentId: Uuid;
  readonly isCustomAssetDomainSet: boolean;
  readonly isObsolete: boolean;
  readonly oldProductionId: Uuid;
  readonly onClose: () => void;
  readonly onSwap: (environmentId: Uuid, oldProductionId: Uuid, enableWebhooks: boolean) => void;
  readonly swappingEnvironment: boolean;
  readonly swappingFailed: boolean;
};

export const SwapEnvironmentDialog: React.FC<SwapEnvironmentDialogProps> = ({
  showDialog,
  environmentId,
  environmentName,
  isCustomAssetDomainSet,
  isObsolete,
  oldProductionId,
  onClose,
  onSwap,
  swappingEnvironment,
  swappingFailed,
}) => {
  const [enableWebhooks, setEnableWebhooks] = useState(true);

  useEffect(() => {
    if (!showDialog) {
      setEnableWebhooks(true);
    }
  }, [showDialog]);

  return (
    <ModalDialog
      isDismissable
      isOpen={showDialog}
      minWidth={px(MinDialogWidth).toString()}
      headline={`Mark ${environmentName} as production`}
      state={swappingEnvironment ? DialogState.InProgress : DialogState.Default}
      primaryAction={{
        onClick: () => onSwap(environmentId, oldProductionId, enableWebhooks),
        text: swappingEnvironment ? 'Marking as production' : 'Mark as production',
        tooltipText: 'Mark environment as production',
        tooltipPlacement: 'bottom-end',
        iconBefore: swappingEnvironment ? AnimatedProgressIcon : undefined,
        ...getDataUiActionAttribute(DataUiAction.SwapEnvironments),
      }}
      cancelAction={{
        onClick: onClose,
        ...getDataUiActionAttribute(DataUiAction.CloseDialog),
      }}
      onClose={onClose}
      renderNotificationBar={() =>
        swappingFailed && (
          <NotificationBarAlert onDismiss={() => undefined}>
            Something went wrong. Please try again. If this keeps happening, let us know.
          </NotificationBarAlert>
        )
      }
      {...getDataUiElementAttribute(DataUiElement.SwapEnvironmentDialog)}
    >
      <Stack spacing={Spacing.XL}>
        {isObsolete && (
          <Callout calloutType="warning" headline="This environment is obsolete">
            <p>
              This environment was cloned without revisions, webhooks, preview URLs, and tasks. We
              recommend you <strong>make a new clone of production</strong> and recreate your
              changes there. You can then safely mark the new clone as production.
            </p>
          </Callout>
        )}
        <Callout calloutType="friendlyWarning" hideSubheadline>
          <Stack spacing={Spacing.XL}>
            <div>
              <p>After the {environmentName} environment becomes production:</p>
              <ul>
                {isCustomAssetDomainSet && <li>{CustomAssetDomainSwapWarningText}</li>}
                <li>
                  Users that are active in the current production but inactive in {environmentName}{' '}
                  will be activated.
                </li>
                <li>
                  Users in the current production that aren’t in {environmentName} will NOT appear
                  in {environmentName}.
                </li>
                <li>Update the project ID and API keys in your code.</li>
                <li>Rename {environmentName} for more clarity.</li>
              </ul>
            </div>
            <p>
              Learn more about{' '}
              <OutwardLink href={documentationLinks.environmentsSwap}>
                swapping environments
              </OutwardLink>
              .
            </p>
          </Stack>
        </Callout>
        <Checkbox
          checkboxState={swappingEnvironment ? 'disabled' : 'default'}
          onToggle={(value) => setEnableWebhooks(value)}
          checked={enableWebhooks}
        >
          Enable webhooks after the swap is done
        </Checkbox>
      </Stack>
    </ModalDialog>
  );
};

SwapEnvironmentDialog.displayName = 'SwapEnvironmentDialog';
