import { Node } from '@react-types/shared';
import React, { ForwardedRef } from 'react';
import { VariableSizeList as VirtualizedList } from 'react-window';
import { px } from '../../../tokens/utils/utils.ts';
import { ObjectWithDataAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { NonAccessibleVerticalMenu, VerticalMenuProps } from '../../VerticalMenu/VerticalMenu.tsx';
import { VirtualizedVerticalMenuFrame } from '../../VerticalMenu/VirtualizedVerticalMenuFrame.tsx';
import { VerticalMenuState } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { ISelectItem } from '../types.ts';
import { EmptyVerticalMenu } from './EmptyVerticalMenu.tsx';
import { IOptionProps } from './Option.tsx';

export type RenderSelectMenuOptionProps<TItem extends ISelectItem<TItem>> = Pick<
  IOptionProps<TItem>,
  'highlightPattern' | 'isReadOnly' | 'isVirtualized' | 'item' | 'level' | 'onPress' | 'state'
>;

export type SelectMenuProps<TItem extends ISelectItem<TItem>> = Readonly<{
  isVirtualized: boolean;
  optionHeight: number | ((item: Node<TItem>) => number);
  renderMenuOption: (optionProps: RenderSelectMenuOptionProps<TItem>) => React.ReactNode;
  state: VerticalMenuState<TItem>;
  triggerWidth: number | undefined;
  verticalMenuDataAttributes?: ObjectWithDataAttribute;
  virtualizedListRef?: React.Ref<VirtualizedList<HTMLDivElement>>;
}> &
  Omit<VerticalMenuProps<TItem>, 'renderItem' | 'state' | 'items'>;

export const SelectMenu = React.forwardRef(
  <TItem extends ISelectItem<TItem>>(
    {
      isVirtualized,
      optionHeight,
      renderMenuOption,
      state,
      triggerWidth,
      verticalMenuDataAttributes,
      virtualizedListRef,
      ...otherProps
    }: SelectMenuProps<TItem>,
    forwardedRef: ForwardedRef<HTMLDivElement>,
  ) => {
    const hasResults = !!state.collection.size;

    if (!hasResults) {
      return (
        <EmptyVerticalMenu
          ref={forwardedRef}
          width={(triggerWidth && px(triggerWidth)) || undefined}
          {...verticalMenuDataAttributes}
          {...otherProps}
        />
      );
    }

    if (isVirtualized) {
      return (
        <VirtualizedVerticalMenuFrame
          menuItemHeight={optionHeight}
          minWidth={(triggerWidth && px(triggerWidth)) || '300px'}
          ref={forwardedRef}
          renderItem={renderMenuOption}
          state={state}
          virtualizedListRef={virtualizedListRef}
          {...otherProps}
        />
      );
    }

    return (
      <NonAccessibleVerticalMenu
        minWidth={(triggerWidth && px(triggerWidth)) || undefined}
        ref={forwardedRef}
        renderItem={renderMenuOption}
        state={state}
        {...otherProps}
      />
    );
  },
);

SelectMenu.displayName = 'SelectMenu';
