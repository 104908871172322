import { DraftEditorCommand } from 'draft-js';

export const TextInputCommand = {
  AddComment: 'kentico-add-comment',
  AddSuggestion: 'kentico-add-suggestion',
  Backspace: 'backspace',
  Delete: 'delete',
  InsertNonBreakingSpace: 'kentico-insert-non-breaking-space',
  Redo: 'kentico-redo',
  Undo: 'kentico-undo',
} as const;
export type TextInputCommand =
  | DraftEditorCommand
  | (typeof TextInputCommand)[keyof typeof TextInputCommand];

export const TextStyleCommand = {
  Bold: 'bold',
  Code: 'kentico-code',
  Italic: 'italic',
  Subscript: 'kentico-subscript',
  Superscript: 'kentico-superscript',
} as const;
export type TextStyleCommand = (typeof TextStyleCommand)[keyof typeof TextStyleCommand];

export const TextFormattingCommand = {
  ...TextStyleCommand,
  InsertLink: 'kentico-draft-link',
} as const;
export type TextFormattingCommand =
  (typeof TextFormattingCommand)[keyof typeof TextFormattingCommand];

export const BlockTypeCommand = {
  HeadingOne: 'kentico-heading-one',
  HeadingTwo: 'kentico-heading-two',
  HeadingThree: 'kentico-heading-three',
  HeadingFour: 'kentico-heading-four',
  HeadingFive: 'kentico-heading-five',
  HeadingSix: 'kentico-heading-six',
  OrderedList: 'kentico-ordered-list',
  UnorderedList: 'kentico-unordered-list',
  Unstyled: 'kentico-unstyled',
} as const;
export type BlockTypeCommand = (typeof BlockTypeCommand)[keyof typeof BlockTypeCommand];

export const CaretSelectionCommand = {
  AdjustSelectionToEndOfLine: 'kentico-adjust-selection-end-line',
  AdjustSelectionToNextBlock: 'kentico-adjust-selection-to-next-block',
  AdjustSelectionToPreviousBlock: 'kentico-adjust-selection-to-previous-block',
  AdjustSelectionToStartOfLine: 'kentico-adjust-selection-start-line',
  MoveCaretToEndOfLine: 'kentico-move-caret-end-line',
  MoveCaretToNextBlock: 'kentico-move-caret-to-next-block',
  MoveCaretToPreviousBlock: 'kentico-move-caret-to-previous-block',
  MoveCaretToStartOfLine: 'kentico-move-caret-start-line',
} as const;
export type CaretSelectionCommand =
  (typeof CaretSelectionCommand)[keyof typeof CaretSelectionCommand];

export const InsertObjectCommand = {
  InsertAsset: 'kentico-insert-asset',
  InsertComponent: 'kentico-insert-new-component',
  InsertItem: 'kentico-insert-existing-item',
  InsertTable: 'kentico-insert-table',
} as const;
export type InsertObjectCommand = (typeof InsertObjectCommand)[keyof typeof InsertObjectCommand];

export const RichTextInputCommand = {
  ...TextInputCommand,
  ...TextFormattingCommand,
  ...BlockTypeCommand,
  ...CaretSelectionCommand,
  ...InsertObjectCommand,
  BlockDepthDecrease: 'kentico-decrease-block-depth',
  BlockDepthIncrease: 'kentico-increase-block-depth',
  CycleHeading: 'kentico-cycle-heading-level',
  ClearFormatting: 'kentico-clear-formatting',
} as const;

export type RichTextInputCommand =
  | DraftEditorCommand
  | (typeof RichTextInputCommand)[keyof typeof RichTextInputCommand];

export const RichTextViewerCommand = {
  Noop: 'no-operation',
} as const;

export type RichTextViewerCommand =
  | DraftEditorCommand
  | (typeof RichTextViewerCommand)[keyof typeof RichTextViewerCommand];
