import React from 'react';
import { WorkflowStepColor } from '../../../data/constants/WorkflowStepColor.ts';
import { Assignment, AssignmentWorkflowStatus } from '../../uiComponents/Assignment/Assignment.tsx';
import { ItemDetailSection } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../utils/dateTime/timeUtils.ts';

type ScheduledToUnpublishAssignmentProps = {
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
};

const scheduledToPublishVariantAssignment: AssignmentWorkflowStatus = {
  color: WorkflowStepColor.Gray,
  name: 'To be unpublished',
};

export const ScheduledUnpublishVariantAssignment: React.FC<ScheduledToUnpublishAssignmentProps> = ({
  scheduledToUnpublishAt,
}) => {
  if (!scheduledToUnpublishAt) {
    return null;
  }

  return (
    <Assignment
      workflowStatusColor={scheduledToPublishVariantAssignment.color}
      dataUiObjectName={ItemDetailSection.UnpublishDateSection}
    >
      <Assignment.Details suffix={<>On {renderDatetimeString(scheduledToUnpublishAt)}</>}>
        {scheduledToPublishVariantAssignment.name}
      </Assignment.Details>
    </Assignment>
  );
};
