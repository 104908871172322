import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { addDays, isAfter } from 'date-fns';
import React, { useRef } from 'react';
import {
  formatDatetimeToReadable,
  parseDatetime,
} from '../../../../../../component-library/components/DatetimePicker/datetimeUtils.ts';
import { DatetimePickerPlaceholder } from '../../../../../applications/projects/constants/UIConstants.ts';
import { Caption } from '../../../../components/DateTimeWithTimeZonePicker/Caption.tsx';
import {
  DatetimePicker,
  DatetimePickerRefType,
} from '../../../../components/DatetimePicker/DatetimePicker.tsx';
import { TimeZoneSelect } from '../../../../components/TimeZoneSelect/TimeZoneSelect.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { getTodayReadableDate, toLocalTime } from '../../../../utils/dateTime/timeUtils.ts';
import { transferBetweenTimeZonesKeepingLocalTime } from '../../../../utils/dateTime/timeZoneUtils.ts';

export type ScheduleEditorProps = {
  readonly scheduledAt: DateTimeStamp | null;
  readonly scheduledTimeZone: string;
  readonly onScheduleChange: (scheduledAt: DateTimeStamp | null, scheduledTimeZone: string) => void;
  readonly error: string | undefined;
  readonly minValue?: DateTimeStamp | undefined;
  readonly maxValue?: DateTimeStamp | undefined;
  readonly renderCheckBox?: (
    datetimePickerRef: React.RefObject<DatetimePickerRefType>,
  ) => JSX.Element | null;
  readonly dataUiElementName?: DataUiElement;
};

export const ScheduleEditor = ({
  scheduledAt,
  scheduledTimeZone,
  onScheduleChange,
  error,
  minValue,
  maxValue,
  renderCheckBox,
  dataUiElementName,
}: ScheduleEditorProps) => {
  const datetimePickerRef = useRef<DatetimePickerRefType>(null);

  const scheduledToMaxValueLocalDate = toLocalTime(maxValue);
  const scheduledToMinValueLocalDate = toLocalTime(minValue);
  const defaultMinValue = new Date();

  const scheduledChanged = (newUtcDate: string | null, newTimeZoneId: string): void => {
    const utcDateTimeInNewTimeZone =
      newUtcDate &&
      newTimeZoneId &&
      transferBetweenTimeZonesKeepingLocalTime(newUtcDate, scheduledTimeZone, newTimeZoneId);

    onScheduleChange(utcDateTimeInNewTimeZone ?? newUtcDate, newTimeZoneId);
  };

  return (
    <Stack spacing={Spacing.XS}>
      {renderCheckBox?.(datetimePickerRef)}
      <Box paddingLeft={Spacing.XXL}>
        <Row>
          <Stack spacing={Spacing.S}>
            <Inline
              spacingX={Spacing.S}
              {...(dataUiElementName && getDataUiElementAttribute(dataUiElementName))}
            >
              <DatetimePicker
                onChange={(dateTime) =>
                  dateTime.isValid && scheduledChanged(dateTime.value, scheduledTimeZone)
                }
                defaultDate={getDefaultDate(minValue)}
                errorMessage={error}
                hasError={!!error}
                minValue={scheduledToMinValueLocalDate ?? defaultMinValue}
                maxValue={scheduledToMaxValueLocalDate ?? undefined}
                placeholder={DatetimePickerPlaceholder}
                value={scheduledAt}
                timeZoneId={scheduledTimeZone ?? undefined}
                hideUtcLabel
                ref={datetimePickerRef}
              />
              <TimeZoneSelect
                selectedTimeZoneId={scheduledTimeZone}
                onChange={(timeZoneId) => scheduledChanged(scheduledAt, timeZoneId)}
              />
            </Inline>
            <Caption
              date={scheduledAt}
              timeZone={scheduledTimeZone}
              renderStyledText={(text) => <Label size={LabelSize.M}>{text}</Label>}
            />
          </Stack>
        </Row>
      </Box>
    </Stack>
  );
};

const getDefaultDate = (scheduledToMinValue: DateTimeStamp | null | undefined): string => {
  const now = new Date();

  const scheduledToMinValueDate = scheduledToMinValue
    ? addDays(parseDatetime(scheduledToMinValue, true), 1)
    : null;

  const scheduledToMinAsDefault =
    scheduledToMinValueDate &&
    isAfter(scheduledToMinValueDate, now) &&
    formatDatetimeToReadable(scheduledToMinValueDate, false);

  return scheduledToMinAsDefault || getTodayReadableDate();
};
