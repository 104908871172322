import { ScheduleMethod } from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/scheduleMethods.ts';
import { IChangeWorkflowStepModalData } from '../../../../../../_shared/features/ChangeWorkflowStepModal/reducers/IChangeWorkflowStepModalData.ts';
import { isTimeInPast } from '../../../../../../_shared/utils/dateTime/timeUtils.ts';

export enum UnpublishingValidationResult {
  Ok = 'Ok',
  UnpublishDateIsEmptyOrInvalid = 'UnpublishDateIsEmptyOrInvalid',
  UnpublishDateIsInPast = 'UnpublishDateIsInPast',
  UnpublishDateIsSameOrBeforePublishDate = 'UnpublishDateIsSameOrBeforePublishDate',
}

export const validateUnpublishingConfiguration = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): UnpublishingValidationResult => {
  if (changeWorkflowStepModalData.scheduleMethod === ScheduleMethod.Now) {
    return UnpublishingValidationResult.Ok;
  }

  const scheduledToPublishAt = changeWorkflowStepModalData.scheduledToPublishAt;
  const scheduledToUnpublishAt = changeWorkflowStepModalData.scheduledToUnpublishAt;

  if (
    !!scheduledToPublishAt &&
    !!scheduledToUnpublishAt &&
    new Date(scheduledToPublishAt) >= new Date(scheduledToUnpublishAt)
  ) {
    return UnpublishingValidationResult.UnpublishDateIsSameOrBeforePublishDate;
  }

  return validateUnpublishingDate(scheduledToUnpublishAt);
};

export const validateUnpublishingDate = (scheduledToUnpublishAt: DateTimeStamp | null) => {
  if (!scheduledToUnpublishAt) {
    return UnpublishingValidationResult.UnpublishDateIsEmptyOrInvalid;
  }

  if (isTimeInPast(new Date(), scheduledToUnpublishAt)) {
    return UnpublishingValidationResult.UnpublishDateIsInPast;
  }

  return UnpublishingValidationResult.Ok;
};
