import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { DefaultCollectionId } from '../../../../../_shared/constants/variantIdValues.ts';
import { ImageLimitsConfig } from '../../../../../_shared/utils/assets/assetValidationUtils.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AssetLibraryFolders } from '../../containers/AssetFolders/AssetLibraryFolders.tsx';
import { AssetFilter } from '../../containers/AssetListing/AssetFilter.tsx';
import { AssetListingActionPane } from '../../containers/AssetListing/AssetListingActionPane.tsx';
import { AssetQueryObserver } from '../../containers/AssetListing/AssetQueryObserver.tsx';
import { AssetScrollStateObserver } from '../../containers/AssetListing/AssetScrollStateObserver.tsx';
import { AssetSearchPhrase } from '../../containers/AssetListing/AssetSearchPhrase.tsx';
import { AssetsUploadToCollectionDialog } from '../../containers/AssetListing/AssetsUploadToCollectionDialog.tsx';
import { EmptyStateHandler } from '../../containers/EmptyState/EmptyStateHandler.tsx';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { IAssetLibraryQuery } from '../../models/IAssetLibraryQuery.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { AssetFolderNameDialog, AssetFolderState } from '../AssetFolders/AssetFolderNameDialog.tsx';
import { Dropzone } from '../UploadDropzone.tsx';
import { AssetLibraryLayout } from './AssetLibraryLayout.tsx';
import { AssetLibraryTiles } from './AssetLibraryTiles.tsx';
import { UnsupportedAssetFileTypeModal } from './UnsupportedAssetFileTypeModal.tsx';

export interface IAssetLibraryStateProps {
  readonly areCollectionsVisible: boolean;
  readonly canCreateAssets: boolean;
  readonly isAssetLibraryLoading: boolean;
}

export interface IAssetLibraryDispatchProps {
  readonly createFolder: ((name: string) => void) | null;
  readonly onUpload: (files: ReadonlyArray<FileWithThumbnail>, collectionId: string | null) => void;
}

export interface IAssetLibraryOwnProps {
  readonly allowSelect: boolean;
  readonly clearAssetSelection: () => void;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly imageLimits?: ImageLimitsConfig;
  readonly isWithAnchor?: boolean;
  readonly onAssetClick?: (assetId: Uuid) => void;
  readonly onAssetDoubleClick?: (assetId: Uuid) => void;
  readonly onOrderingChange: (orderingData: AssetsOrderBy) => void;
  readonly onQueryChange?: (filter: IAssetLibraryQuery) => void;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly showImagesOnly?: boolean;
  readonly showUnsupportedAssetFileTypeModal: boolean;
  readonly toggleAssetSelection: (assetId: Uuid, shiftPressed: boolean) => void;
}

interface IAssetLibraryProps
  extends IAssetLibraryStateProps,
    IAssetLibraryDispatchProps,
    IAssetLibraryOwnProps {}

const propTypes: PropTypesShape<IAssetLibraryProps> = {
  //  State props
  areCollectionsVisible: PropTypes.bool.isRequired,
  canCreateAssets: PropTypes.bool.isRequired,
  isAssetLibraryLoading: PropTypes.bool.isRequired,

  //  Dispatch props
  createFolder: PropTypes.func,
  onUpload: PropTypes.func.isRequired,

  //  Own props
  allowSelect: PropTypes.bool.isRequired,
  clearAssetSelection: PropTypes.func.isRequired,
  deselectAssets: PropTypes.func.isRequired,
  imageLimits: PropTypes.object,
  isWithAnchor: PropTypes.bool,
  showUnsupportedAssetFileTypeModal: PropTypes.bool.isRequired,
  onAssetClick: PropTypes.func,
  onAssetDoubleClick: PropTypes.func,
  onQueryChange: PropTypes.func,
  showImagesOnly: PropTypes.bool,
  onOrderingChange: PropTypes.func.isRequired,
  orderBy: PropTypes.object,
  selectedAssets: PropTypes.object,
  toggleAssetSelection: PropTypes.func.isRequired,
};

const AssetLibrary: React.FC<IAssetLibraryProps> = ({
  allowSelect,
  areCollectionsVisible,
  canCreateAssets,
  clearAssetSelection,
  createFolder,
  deselectAssets,
  imageLimits,
  isAssetLibraryLoading,
  isWithAnchor,
  onAssetClick,
  onAssetDoubleClick,
  onOrderingChange,
  onQueryChange,
  onUpload,
  orderBy,
  selectedAssets,
  showImagesOnly,
  showUnsupportedAssetFileTypeModal,
  toggleAssetSelection,
}) => {
  const [assetsToUpload, setAssetsToUpload] = useState<ReadonlyArray<FileWithThumbnail>>([]);
  const beforeUpload = (files: ReadonlyArray<FileWithThumbnail>) => {
    if (areCollectionsVisible) {
      setAssetsToUpload(files);
      return;
    }
    onUpload(files, DefaultCollectionId);
  };

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const openCreateFolderModal = () => {
    setShowCreateFolderModal(true);
  };

  const closeCreateFolderModal = () => {
    setShowCreateFolderModal(false);
  };

  const onCreateFolder = (name: string): void => {
    if (createFolder) {
      createFolder(name);
    }
    closeCreateFolderModal();
  };

  return (
    <>
      <Dropzone
        onDrop={beforeUpload}
        disabled={!canCreateAssets}
        tagName="div"
        className="dropzone canvas__content"
      >
        <AssetQueryObserver onQueryChange={onQueryChange} orderBy={orderBy} />
        <AssetScrollStateObserver orderBy={orderBy} />
        <div className="asset-listing">
          <AssetLibraryLayout
            renderFilter={() => <AssetFilter />}
            renderContent={() => (
              <div className="asset-library">
                <AssetSearchPhrase />
                {isAssetLibraryLoading ? (
                  <Loader />
                ) : (
                  <div
                    className="asset-library__asset-content"
                    {...getDataUiCollectionAttribute(DataUiCollection.Assets)}
                  >
                    <AssetListingActionPane
                      clearAssetSelection={clearAssetSelection}
                      deselectAssets={deselectAssets}
                      orderBy={orderBy}
                      selectedAssets={selectedAssets}
                    />
                    <div className="asset-library__sections-pane asset-library__sections-pane--no-top-padding">
                      <EmptyStateHandler
                        onUpload={canCreateAssets ? beforeUpload : null}
                        onCreateFolder={createFolder ? openCreateFolderModal : null}
                      >
                        <AssetLibraryFolders
                          clearAssetSelection={clearAssetSelection}
                          orderBy={orderBy}
                        />
                        <AssetLibraryTiles
                          allowSelect={allowSelect}
                          imageLimits={imageLimits}
                          isWithAnchor={isWithAnchor}
                          onAssetClick={onAssetClick}
                          onAssetDoubleClick={onAssetDoubleClick}
                          onOrderingChange={onOrderingChange}
                          onUpload={canCreateAssets ? beforeUpload : null}
                          selectedAssets={selectedAssets}
                          showImagesOnly={showImagesOnly}
                          orderBy={orderBy}
                          toggleAssetSelection={toggleAssetSelection}
                        />
                      </EmptyStateHandler>
                    </div>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </Dropzone>
      {assetsToUpload.length > 0 && (
        <AssetsUploadToCollectionDialog
          handleClose={() => setAssetsToUpload([])}
          handleSelect={(collectionId: Uuid | null) => onUpload(assetsToUpload, collectionId)}
        />
      )}
      {showCreateFolderModal && (
        <AssetFolderNameDialog
          onCancel={closeCreateFolderModal}
          onConfirm={onCreateFolder}
          state={AssetFolderState.New}
        />
      )}
      {showUnsupportedAssetFileTypeModal && <UnsupportedAssetFileTypeModal />}
    </>
  );
};

AssetLibrary.displayName = 'AssetLibrary';
AssetLibrary.propTypes = propTypes;

const AssetLibraryMemo = memo(AssetLibrary);
export { AssetLibraryMemo as AssetLibrary };
