import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useLocation } from 'react-router';
import { VariantAssignment } from '../../../../../_shared/components/VariantAssignments/VariantAssignment.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  toFuzzyDateDifference,
  toLocalTime,
} from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import { AgendaItemStatus } from '../../constants/AgendaItemStatus.ts';
import { IAgendaItem } from '../../models/CalendarModels.type.ts';
import { AgendaItemStatusView } from '../AgendaItemStatusView.tsx';
import { CalendarPopup } from './CalendarPopup.tsx';

interface StatusViewProps {
  readonly itemState: AgendaItemStatus;
}

const statusViewTooltipText: ReadonlyMap<AgendaItemStatus, string> = new Map([
  [AgendaItemStatus.OnTrack, 'This item has a due date in the future'],
  [AgendaItemStatus.Delayed, 'This item has a due date in the past'],
  [AgendaItemStatus.Published, 'This item is published'],
  [AgendaItemStatus.Scheduled, 'This item is scheduled to be published'],
]);

const StatusView: React.FC<StatusViewProps> = ({ itemState }) => (
  <AgendaItemStatusView
    itemState={itemState}
    tooltipText={statusViewTooltipText.get(itemState) || ''}
    tooltipPlacement="right"
  />
);
StatusView.displayName = 'StatusView';

export interface IItemDetailsStateProps {
  readonly item: IAgendaItem;
  readonly assignees: ReadonlySet<IUserInfo>;
  readonly isNavigationBackEnabled: boolean;
}

export interface IItemDetailsDispatchProps {
  readonly onItemSelected: () => void;
  readonly onBack: () => void;
}

export interface IItemDetailsOwnProps {
  readonly onDismiss: () => void;
}

export type IItemDetailsProps = IItemDetailsStateProps &
  IItemDetailsDispatchProps &
  IItemDetailsOwnProps &
  IForwardedRefProps<HTMLDivElement>;

const toFuzzyTime = (dateAndTime: DateTimeStamp | null): string => {
  const localTime = toLocalTime(dateAndTime);
  return localTime ? toFuzzyDateDifference(new Date(), localTime, true) : '';
};

const isPublishedOrScheduled = (status: AgendaItemStatus): boolean =>
  status === AgendaItemStatus.Published || status === AgendaItemStatus.Scheduled;

const ItemDetails: React.FC<IItemDetailsProps> = (props) => {
  const location = useLocation();
  const itemUrl = getContentItemPath(
    location.pathname,
    props.item.id.itemId,
    props.item.id.variantId,
  );

  return (
    <CalendarPopup
      className="item-details"
      headerContent={<div className="item-details__header">{props.item.name}</div>}
      ref={props.forwardedRef}
      onClose={props.onDismiss}
      backButton={
        props.isNavigationBackEnabled ? (
          <SquareButton
            iconName={IconName.ChevronLeft}
            onClick={props.onBack}
            style={ButtonStyle.Quinary}
            tooltipPlacement="bottom-start"
            tooltipText="Back"
            size={SquareButtonSize.Quinary}
          />
        ) : undefined
      }
      bodyContent={
        <div className="item-details__body">
          <div className="item-details__category">
            <StatusView itemState={props.item.status} />
          </div>
          <div className="item-details__category">
            <div className="item-details__title">Workflow step</div>
            <Stack spacing={Spacing.L}>
              <VariantAssignment variant={props.item} showDueDate canDiscard={false} />
            </Stack>
          </div>
          {props.item.isWorkflowNameShown && props.item.workflowName && (
            <div className="item-details__category">
              <div className="item-details__title">Workflow</div>
              {props.item.workflowName}
            </div>
          )}
          {!isPublishedOrScheduled(props.item.status) && (
            <div className="item-details__category">
              <div className="item-details__title">Contributors</div>
              {Collection.getValues(props.assignees).map(formatUserName).join(', ')}
            </div>
          )}
          {props.item.lastModified && (
            <div className="item-details__category">
              <div className="item-details__title">Last content change</div>
              {toFuzzyTime(props.item.lastModified)}
            </div>
          )}
          <div className="item-details__category item-details__open-item-button">
            <RouterLinkButton
              buttonStyle="primary"
              onPress={props.onItemSelected}
              to={itemUrl}
              {...getDataUiActionAttribute(DataUiAction.OpenContentItem)}
            >
              Open content item
            </RouterLinkButton>
          </div>
        </div>
      }
    />
  );
};
ItemDetails.displayName = 'ItemDetails';

const ItemDetailsFRC = forwardRef(ItemDetails);
export { ItemDetailsFRC as ItemDetails };
