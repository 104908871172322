import React from 'react';
import { AssignmentSection } from '../containers/details/AssignmentSection.tsx';
import { CollectionSection } from '../containers/details/Collection/CollectionSection.tsx';
import { ContributorsSection } from '../containers/details/Contributors/ContributorsSection.tsx';
import { DueDateSection } from '../containers/details/DueDate/DueDateSection.tsx';
import { IncompleteItemElementsNotification } from '../containers/details/IncompleteItemElementsNotification.tsx';
import { LastUpdatedSection } from '../containers/details/LastUpdatedSection.tsx';
import { NoteSection } from '../containers/details/Note/NoteSection.tsx';
import { SitemapSection } from '../containers/details/SitemapSection.tsx';
import { SpacesSection } from '../containers/details/Spaces/SpacesSection.tsx';
import { TypeSection } from '../containers/details/TypeSection.tsx';
import { UsagesSection } from '../containers/details/UsagesSection.tsx';
import { WorkflowSection } from '../containers/details/Workflow/WorkflowSection.tsx';
import { TasksSection } from './details/Tasks/TasksSection.tsx';
import { IncompleteItemElementsSidebarNotification } from './incompleteItemElementsNotifications/IncompleteItemElementsSidebarNotification.tsx';

export const ContentItemDetails: React.FC = () => {
  return (
    <>
      <AssignmentSection />
      <IncompleteItemElementsNotification
        render={(onClick, incompleteElementsCount) => (
          <IncompleteItemElementsSidebarNotification
            onClick={onClick}
            incompleteElementsCount={incompleteElementsCount}
          />
        )}
      />
      <WorkflowSection />
      <TasksSection />
      <DueDateSection />
      <ContributorsSection />
      <NoteSection />
      <LastUpdatedSection />
      <CollectionSection />
      <SpacesSection />
      <TypeSection />
      <UsagesSection />
      <SitemapSection />
    </>
  );
};
ContentItemDetails.displayName = 'ContentItemDetails';
