import { Icons } from '@kontent-ai/component-library/Icons';
import { forwardRef, useCallback } from 'react';
import { RTECommandSource } from '../../../../../../_shared/models/events/RTECommandEventData.type.ts';
import {
  DataUiCollection,
  DataUiRteAction,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PluginState } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { getFullBlockTypesAtSelection } from '../../../../utils/editorSelectionUtils.ts';
import { EditorFeatureLimitations } from '../../../apiLimitations/api/EditorFeatureLimitations.ts';
import { RichTextInputCommand } from '../../../keyboardShortcuts/api/EditorCommand.ts';
import { BlockToolbarPlugin } from '../../BlockToolbarPlugin.tsx';
import { RTEToolbarButtonWithMenu } from '../buttons/RTEToolbarButtonWithMenu.tsx';
import { CommandToolbarMenuItem, EditorCommandMenu } from '../menu/EditorCommandMenu.tsx';
import { Resettable } from './BlockToolbar.tsx';

type InsertBlockButtonProps = Pick<
  PluginState<BlockToolbarPlugin>,
  'editorState' | 'handleCommand'
> & {
  readonly hidesDisallowedFeatures: boolean;
  readonly isToolbarVertical: boolean;
  readonly items: ReadonlyArray<CommandToolbarMenuItem>;
  readonly limitations: EditorFeatureLimitations;
};

export const InsertBlockButton = forwardRef<Resettable, InsertBlockButtonProps>(
  (
    { editorState, handleCommand, hidesDisallowedFeatures, isToolbarVertical, items, limitations },
    ref,
  ) => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);

    const onCommand = useCallback(
      (command: RichTextInputCommand) => handleCommand(command, RTECommandSource.BlockToolbar),
      [handleCommand],
    );

    return (
      <RTEToolbarButtonWithMenu
        fallbackToolbarPlacements={isToolbarVertical ? ['left'] : ['bottom-start']}
        icon={Icons.PlusCircle}
        menuUiCollection={DataUiCollection.RteInsertBlockMenuOptions}
        ref={ref}
        toolbarPlacement={isToolbarVertical ? 'right' : 'top-start'}
        tooltipPlacement={isToolbarVertical ? 'right' : 'top'}
        tooltipText="Insert..."
        uiAction={DataUiRteAction.AddBlock}
      >
        <EditorCommandMenu
          fullBlockTypesAtSelection={fullBlockTypesAtSelection}
          hidesDisallowedFeatures={hidesDisallowedFeatures}
          limitations={limitations}
          onCommand={onCommand}
          items={items}
        />
      </RTEToolbarButtonWithMenu>
    );
  },
);

InsertBlockButton.displayName = 'InsertBlockButton';
