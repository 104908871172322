import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { VerticalMenuItem, useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { EditorState } from 'draft-js';
import React, { useCallback, useMemo, useState } from 'react';
import { DropDownMenuControlled } from '../../../../../component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { AiSignalRFailMessage } from '../../../../_shared/constants/uiConstants.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiCollection,
  DataUiRteAction,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { EditorToolbarDivider } from '../toolbars/components/buttons/EditorToolbarDivider.tsx';

export type AiMenuPlugin = EditorPlugin<AiMenuPluginState, None, None, [InlineToolbarPlugin]>;

type AiMenuPluginState = {
  readonly getInlineToolbarAiMenuItems: DecorableFunction<GetAiMenuItems>;
};

export type GetAiMenuItems = (editorState: EditorState) => ReadonlyArray<AiMenuItem>;

const getEmptyAiMenuItems: GetAiMenuItems = () => [];

export const useAiMenu: PluginCreator<AiMenuPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('AiMenuPlugin', {
        ComposedEditor: (props) => {
          const renderInlineToolbarButtons: Decorator<Render<AiMenuPlugin>> = useCallback(
            (baseRender) => (state) => {
              const buttonsAfter = baseRender(state);

              if (!state.canUpdateContent()) {
                return buttonsAfter;
              }

              return (
                <>
                  <AiMenu items={state.getInlineToolbarAiMenuItems(state.editorState)} />
                  {buttonsAfter && <EditorToolbarDivider />}
                  {buttonsAfter}
                </>
              );
            },
            [],
          );

          const apply: Apply<AiMenuPlugin> = useCallback(
            (state) => {
              state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);

              return {
                getInlineToolbarAiMenuItems: decorable(getEmptyAiMenuItems),
              };
            },
            [renderInlineToolbarButtons],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );

export enum AiMenuSection {
  EditOrReviewSelection = 'EditOrReviewSelection',
  GenerateFromSelection = 'GenerateFromSelection',
}

const menuSections: ReadonlyArray<{ readonly id: AiMenuSection; readonly label: string }> = [
  {
    id: AiMenuSection.EditOrReviewSelection,
    label: 'Edit or Review selection',
  },
  {
    id: AiMenuSection.GenerateFromSelection,
    label: 'Generate from selection',
  },
];

export type AiMenuItem = VerticalMenuItem<AiMenuItem> & {
  readonly renderIntoMenu: (item: AiMenuItem, onActionStarted: () => void) => React.ReactNode;
  readonly section: AiMenuSection;
};

type AiMenuProps = {
  readonly items: ReadonlyArray<AiMenuItem>;
};

const AiMenu = ({ items }: AiMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { VerticalMenu, verticalMenuProps, verticalMenuState } = useVerticalMenu<AiMenuItem>(
    menuSections
      .map(
        (section) =>
          ({
            id: section.id,
            label: section.label,
            type: 'section',
            items: items.filter((item) => item.section === section.id),
          }) as const,
      )
      .filter((section) => section.items.length > 0),
  );

  const disabledTooltip = useSelector((state) => getDisabledTooltip(state, items));

  const onActionStarted = () => {
    setIsOpen(false);
  };

  return (
    <DropDownMenuControlled
      onDropDownVisibilityChange={setIsOpen}
      isDropDownVisible={isOpen}
      renderDropDown={(triggerWidth) => (
        <VerticalMenu
          {...verticalMenuProps}
          aria-label="Ask AI menu"
          minWidth={triggerWidth}
          state={verticalMenuState}
          renderItem={({ item }) => item.value?.renderIntoMenu(item.value, onActionStarted)}
          {...getDataUiCollectionAttribute(DataUiCollection.AiActions)}
        />
      )}
      renderTrigger={(triggerProps) => {
        return (
          <QuinaryButton
            activated={isOpen}
            disabled={!!disabledTooltip}
            onClick={() => {
              setIsOpen(true);
            }}
            tooltipText={isOpen ? '' : disabledTooltip ?? 'Ask AI'}
            ref={triggerProps.ref}
            {...getDataUiActionAttribute(DataUiRteAction.AskAi)}
          >
            <QuinaryButton.Icon icon={Icons.KopilotAi} /> Ask AI
          </QuinaryButton>
        );
      }}
    />
  );
};

const getDisabledTooltip = (state: IStore, items: ReadonlyArray<AiMenuItem>): string | null => {
  if (items.length === 0) {
    return 'No actions available';
  }

  const signalRFail = state.sharedApp.signalRConnection.lastConnectionFailReason;

  if (signalRFail) {
    return AiSignalRFailMessage;
  }

  return null;
};
