import { BaseColor } from '@kontent-ai/component-library/tokens';
import { mergeProps } from '@react-aria/utils';
import Tippy, { TippyProps } from '@tippyjs/react';
import React, { PropsWithChildren, RefObject, useCallback, useEffect, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { useUpdateTippyWithScroll } from '../../../../../app/_shared/hooks/useUpdateTippyWithScroll.ts';
import { ArrowSize } from '../ArrowSizeEnum.ts';
import { AnimatedPopoverDialog, IAnimatedPopoverDialogProps } from './AnimatedPopoverDialog.tsx';

export interface IPopoverFrameProps {
  readonly allowAnimation?: boolean;
  readonly animatedPopoverDialogProps: Omit<
    IAnimatedPopoverDialogProps,
    'autoFocusRef' | 'id' | 'isOpen' | 'onAnimationEnd' | 'ref'
  >;
  readonly arrowColor?: BaseColor;
  readonly arrowSize?: ArrowSize;
  readonly autoFocusRef: RefObject<HTMLElement>;
  readonly __disabledFocusLock: boolean;
  readonly isOpen: boolean;
  readonly popoverId: string;
  readonly targetRef: RefObject<HTMLElement>;
  readonly tippyOptions: TippyProps;
}

export const PopoverFrame = React.forwardRef<HTMLDivElement, PropsWithChildren<IPopoverFrameProps>>(
  (
    {
      allowAnimation = true,
      animatedPopoverDialogProps,
      arrowColor,
      arrowSize,
      autoFocusRef,
      children,
      __disabledFocusLock,
      isOpen,
      popoverId,
      tippyOptions,
      targetRef,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [shouldRenderDialog, setShouldRenderDialog] = useState(false);
    const [animationFinished, setAnimationFinished] = useState<boolean>(false);
    const handleAnimationEnd = useCallback(() => {
      if (!isOpen) {
        setShouldRenderDialog(false);
      }
      setAnimationFinished(true);
    }, [isOpen]);

    useEffect(() => {
      if (isOpen) {
        setShouldRenderDialog(true);
      }
    }, [isOpen]);

    const scrollTippyProps = useUpdateTippyWithScroll(isOpen) ?? {};

    return (
      <Tippy
        {...mergeProps(scrollTippyProps, tippyOptions)}
        reference={targetRef}
        render={(attrs) =>
          shouldRenderDialog && (
            <AnimatedPopoverDialog
              allowAnimation={allowAnimation}
              arrowColor={arrowColor}
              arrowSize={arrowSize}
              autoFocusRef={autoFocusRef}
              id={popoverId}
              isOpen={isOpen}
              onAnimationEnd={handleAnimationEnd}
              ref={forwardedRef}
              {...restProps}
              {...animatedPopoverDialogProps}
              {...attrs}
            >
              <ReactFocusLock disabled={!animationFinished || __disabledFocusLock}>
                {children}
              </ReactFocusLock>
            </AnimatedPopoverDialog>
          )
        }
        visible
      />
    );
  },
);

PopoverFrame.displayName = 'PopoverFrame';
