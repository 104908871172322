import {
  BorderRadius,
  gridUnit,
  px,
  shadowFocusStyles,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

interface IStyledStatusBarTriggerWrapper {
  readonly isFocusVisible?: boolean;
}

export const StyledStatusBarTriggerWrapper = styled.button<IStyledStatusBarTriggerWrapper>`
  display: flex;
  align-items: stretch;
  align-self: stretch;
  padding: 0;
  border: 0;
  background: black;
  min-width: ${px(9 * gridUnit)};
  border-radius: ${px(BorderRadius.M)};

  ${({ isFocusVisible }) =>
    isFocusVisible &&
    css`
    ${shadowFocusStyles};
    z-index: 1;
  `};
`;
