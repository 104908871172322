import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { InputState } from '@kontent-ai/component-library/Input';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { WrappingSkeleton } from '@kontent-ai/component-library/Skeleton';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { Spacing, Typography, gridUnit } from '@kontent-ai/component-library/tokens';
import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import {
  TimeInterval,
  timeIntervals,
} from '../../../../unifiedMissionControl/constants/timeIntervals.ts';
import { MissionControlRepository } from '../../../../unifiedMissionControl/repositories/MissionControlRepository.type.ts';
import { ITasksDoneOnTime } from '../../../../unifiedMissionControl/repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { DataState } from '../../../constants/DataState.ts';
import {
  getCorrectFormOfBeVerbWithCountOnTime,
  getDoneOnTimePercentage,
  getWidgetComparisonStatus,
} from '../../../contentStatus/utils/widgetUtils.tsx';
import { getValueTrend } from '../../../utils/getValueTrend.ts';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetErrorState } from '../../shared/components/WidgetErrorState.tsx';
import { WidgetWorkflowStep } from '../../shared/components/WidgetWorkflowStep.tsx';

const getExplanationLabel = (tasksDoneOnTime: ITasksDoneOnTime | null) => {
  if (!tasksDoneOnTime || tasksDoneOnTime.current.allCount <= 0) {
    return 'Shows only tasks due in the past and within the selected period.';
  }

  const tasks = pluralizeWithCount('task', tasksDoneOnTime.current.allCount);
  const be = getCorrectFormOfBeVerbWithCountOnTime(tasksDoneOnTime.current.onTimeCount);
  return `Calculated from the ${tasks} due within the selected period, out of which ${be} completed on time.`;
};

type TasksDoneOnTimeWidgetProps = Readonly<{
  tasksDoneOnTimeFetcher: MissionControlRepository['getTasksDoneOnTime'];
}>;

export const TasksDoneOnTimeWidget: React.FC<TasksDoneOnTimeWidgetProps> = ({
  tasksDoneOnTimeFetcher,
}) => {
  const [selectedInterval, setSelectedInterval] = useState(TimeInterval.CurrentMonth);
  const [state, setState] = useState<DataState>(DataState.Loading);
  const [tasksDoneOnTime, setTasksDoneOnTime] = useState<ITasksDoneOnTime | null>(null);

  useEffect(() => {
    setState(DataState.Loading);

    const fetcher = makeCancellablePromise(() => tasksDoneOnTimeFetcher(selectedInterval))
      .then((result) => {
        setTasksDoneOnTime(result);
        setState((result.current.allCount ?? 0) === 0 ? DataState.Empty : DataState.Loaded);
      })
      .catch(swallowCancelledPromiseError)
      .catch((error) => {
        logError(`${__filename}: Failed to fetch.`, error);
        setTasksDoneOnTime(null);
        setState(DataState.Error);
      });

    return fetcher.cancel;
  }, [tasksDoneOnTimeFetcher, selectedInterval]);

  const tasksDoneOnTimeCurrent = getDoneOnTimePercentage(tasksDoneOnTime?.current);
  const tasksDoneOnTimeDiff =
    tasksDoneOnTimeCurrent - getDoneOnTimePercentage(tasksDoneOnTime?.previous);

  const hasFailed = state === DataState.Error;
  const isLoading = state === DataState.Loading;
  const hasNoData = state === DataState.Empty;

  return (
    <Widget>
      <Widget.Title
        text="Tasks done on time"
        explanatoryLabel={getExplanationLabel(tasksDoneOnTime)}
      />

      <Widget.Subtitle>
        {hasFailed ? (
          <WidgetErrorState isInline />
        ) : hasNoData ? (
          <Box typography={Typography.HeadlineLarge}>--</Box>
        ) : (
          <>
            <WrappingSkeleton isLoading={isLoading} type="text">
              <Box typography={Typography.HeadlineLarge} minWidth={10 * gridUnit}>
                {tasksDoneOnTimeCurrent.toFixed(2)}%
              </Box>
            </WrappingSkeleton>
            {isLoading && <SrOnly>Loading data for tasks widget</SrOnly>}
          </>
        )}
      </Widget.Subtitle>

      {!hasFailed && (
        <>
          <Widget.AuxElement>
            <SingleSelect
              ariaLabel="Timeframe"
              inputState={isLoading ? InputState.ReadOnly : InputState.Default}
              items={timeIntervals}
              selectedItemId={selectedInterval}
              onSelectionChange={(intervalId: TimeInterval) => {
                setSelectedInterval(intervalId);
              }}
            />
          </Widget.AuxElement>

          <Widget.Body>
            {hasNoData ? (
              <Box typography={Typography.Subheadline}>No insights yet</Box>
            ) : (
              <WrappingSkeleton isLoading={isLoading} type="text">
                <Inline align="center" spacingX={Spacing.S} css={Typography.Subheadline}>
                  <WidgetWorkflowStep
                    state={getValueTrend(tasksDoneOnTimeDiff)}
                    percentage={tasksDoneOnTimeDiff}
                  />
                  {getWidgetComparisonStatus(tasksDoneOnTimeDiff)}
                </Inline>
              </WrappingSkeleton>
            )}
          </Widget.Body>
        </>
      )}
    </Widget>
  );
};

TasksDoneOnTimeWidget.displayName = 'TasksDoneOnTimeWidget';
