import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject, useState } from 'react';
import { DropDownFrame } from '../../../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import { DropDownOption } from '../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { DropDownOptionsGroup } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../../../data/models/languages/Language.ts';

interface ICopyFromLanguageButtonProps {
  readonly disabledTooltipText?: string;
  readonly languages: ReadonlyArray<ILanguage>;
  readonly onCopyFrom: (variantId: Uuid) => void;
}

export const CopyFromLanguageButton: React.FC<ICopyFromLanguageButtonProps> = ({
  disabledTooltipText,
  languages,
  onCopyFrom,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (): void => setIsMenuOpen(!isMenuOpen);

  const closeMenu = (): void => setIsMenuOpen(false);

  const onOptionClick = (variantId: Uuid): void => {
    closeMenu();
    onCopyFrom(variantId);
  };

  const handleClickOutside = (): void => {
    if (isMenuOpen) {
      closeMenu();
    }
  };

  const optionGroupTitle = 'Copy from language';

  const getTooltipText = () => {
    if (disabledTooltipText) {
      return disabledTooltipText;
    }

    return isMenuOpen ? '' : optionGroupTitle;
  };

  const renderSelector = (ref: React.RefObject<HTMLElement>) => (
    <Box paddingLeft={Spacing.S}>
      <IconButton
        activated={isMenuOpen}
        buttonStyle="tertiary"
        buttonState={disabledTooltipText ? 'disabled' : 'default'}
        iconName="DocCopy"
        onClick={toggleMenu}
        ref={ref as RefObject<HTMLButtonElement>}
        size="medium"
        ariaLabel="Copy from another language"
        tooltipPlacement="top-end"
        tooltipText={getTooltipText()}
        {...getDataUiActionAttribute(DataUiAction.CopyFromLanguage)}
      />
    </Box>
  );

  if (languages.length === 0) {
    return null;
  }

  return (
    <DropDownFrame
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
      isOptionListVisible={isMenuOpen}
      onClickOutside={handleClickOutside}
      renderSelector={renderSelector}
      renderContent={() => (
        <DropDownOptionsGroup title={optionGroupTitle}>
          {languages.map((language) => (
            <DropDownOption
              dataUiAttributes={getDataUiObjectNameAttribute(language.name)}
              key={language.id}
              onClick={() => onOptionClick(language.id)}
            >
              <DropDownOptionName text={language.name} />
            </DropDownOption>
          ))}
        </DropDownOptionsGroup>
      )}
    />
  );
};

CopyFromLanguageButton.displayName = 'CopyFromLanguageButton';
