import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import {
  isScheduleToPublishWorkflowStepSelected,
  isScheduledToPublishWorkflowStep,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import { CancelSchedulingButton } from './WorkflowStepActionButtons/CancelSchedulingButton.tsx';
import { UnpublishButton } from './WorkflowStepActionButtons/UnpublishButton.tsx';

export type AssignmentSectionSecondaryActionProps = {
  readonly onCancelSchedulingConfirmationDialog: () => void;
};

export const AssignmentSectionSecondaryAction = ({
  onCancelSchedulingConfirmationDialog,
}: AssignmentSectionSecondaryActionProps) => {
  const variantCapabilities = useSelector(
    (state) => state.contentApp.editorUi.activeCapabilities.variantCapabilities,
  );
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );

  if (!editedContentItemVariant) {
    return null;
  }

  const hasPublishedVersion = isVariantPublished(editedContentItemVariant);
  const isScheduledToPublish = isScheduleToPublishWorkflowStepSelected(
    editedContentItemVariant.assignment,
  );
  const canUnpublish = variantCapabilities.includes(ActiveCapabilityType.Unpublish);

  if (hasPublishedVersion && !isScheduledToPublish && canUnpublish) {
    return <UnpublishButton />;
  }

  const canCancelSchedule = variantCapabilities.includes(ActiveCapabilityType.CancelSchedule);
  if (isScheduledToPublishWorkflowStep(editedContentItemVariant.assignment.workflowStatus)) {
    return canCancelSchedule ? (
      <CancelSchedulingButton onCancel={onCancelSchedulingConfirmationDialog} />
    ) : null;
  }

  return null;
};
