import { template } from '../utils/stringUtils.ts';

export const Archive = 'Archive';
export const Archiving = 'Archiving';
export const CancelScheduling = 'Remove publishing schedule';
export const CancelSchedulingOfAll = 'Remove publishing schedule of all';
export const MoveToCollection = 'Change collection';
export const Moving = 'Moving';
export const ChangeUnpublishingOptions = 'Change unpublishing options';
export const ChangeWorkflowStep = 'Change workflow step';
export const ChangeWorkflowStepOrPublish = 'Change workflow step';
export const ChangingWorkflowStep = 'Changing workflow step';
export const ChangingWorkflow = 'Changing workflow';
export const CreateNew = 'Create new';
export const CreateNewVersion = 'Create a new version';
export const Continue = 'Continue';
export const Delete = 'Delete';
export const DeleteContentItem = 'Delete content item';
export const Deleting = 'Deleting';
export const DiscardCurrentVersion = 'Discard current version';
export const Discarding = 'Discarding';
export const Publish = 'Publish';
export const PublishAndScheduleUnpublish = 'Publish and schedule unpublish';
export const Publishing = 'Publishing';
export const RemoveUnpublishingDate = 'Remove unpublishing schedule';
export const Removing = 'Removing';
export const ReschedulePublishing = 'Reschedule publishing';
export const RescheduleUnpublishing = 'Reschedule unpublishing';
export const Restore = 'Restore';
export const RestoreFromArchivedStep = template`Restore to ${0}`;
export const Restoring = 'Restoring';
export const SaveChanges = 'Save changes';
export const SavingChanges = 'Saving changes';
export const Scheduling = 'Scheduling';
export const SchedulePublish = 'Schedule publish';
export const SchedulePublishAndUnpublish = 'schedule Publish and unpublish';
export const ScheduleUnpublish = 'Schedule unpublish';
export const Undo = 'Undo';
export const Unpublish = 'Unpublish and archive';
export const UnpublishAll = 'Unpublish and archive all';
export const Unpublishing = 'Unpublishing and archiving';
export const Unscheduling = 'Unscheduling';
