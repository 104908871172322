import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import { useButton } from '@react-aria/button';
import React, { useRef } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = Readonly<{
  onClick: () => void;
  tooltipText: string;
}>;

export const ItemSettings: React.FC<Props> = ({ onClick, tooltipText }) => {
  const ref = useRef();
  const { refObject, refToForward } = useAttachRef(ref.current);
  const { buttonProps } = useButton(
    {
      onPress: () => {
        onClick();
      },
    },
    refObject,
  );

  return (
    <QuinaryButton
      tooltipText={tooltipText}
      tooltipPlacement="right"
      buttonStyle="default"
      css={`margin-left: ${px(Spacing.S)}`}
      ref={refToForward}
      {...getDataUiActionAttribute(DataUiAction.GoToSettings)}
      {...buttonProps}
    >
      <QuinaryButton.Icon icon={Icons.Cogwheel} />
    </QuinaryButton>
  );
};

ItemSettings.displayName = 'ItemSettings';
