import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { IconSize, px } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { usePopover } from '../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import { addFlipping } from '../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { FallbacksPopoverContent } from '../../containers/FallbacksForLinkedContent/FallbacksPopoverContent.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export const FallbacksPopover: React.FC<{ readonly itemId: Uuid }> = ({ itemId }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openPopover = (): void => setIsPopoverOpen(true);
  const closePopover = (): void => setIsPopoverOpen(false);
  const togglePopover = (): void => (isPopoverOpen ? closePopover() : openPopover());

  const { Popover, popoverProps, targetProps } = usePopover({
    isOpen: isPopoverOpen,
    onClose: closePopover,
    adjustTippyOptions: (tippyOptions) => addFlipping(tippyOptions),
  });

  return (
    <Box>
      <QuinaryButton
        tooltipText={
          isPopoverOpen ? '' : 'View language fallbacks that apply for this untranslated item'
        }
        onClick={() => {
          togglePopover();
        }}
        activated={isPopoverOpen}
        {...getDataUiActionAttribute(DataUiAction.OpenFallbacksPopover)}
        {...targetProps}
      >
        <Box flexShrink={0} minWidth={px(IconSize.S)}>
          <QuinaryButton.Icon icon={Icons.ICircle} />
        </Box>
      </QuinaryButton>
      <Popover {...popoverProps}>
        <FallbacksPopoverContent itemId={itemId} onClose={closePopover} />
      </Popover>
    </Box>
  );
};

FallbacksPopover.displayName = 'FallbacksPopover';
