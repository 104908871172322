import { forwardRef } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { DropDownOptionList } from './DropDownOptionList.tsx';
import {
  CommonDropDownPositionerProps,
  DropDownPositioner,
  ExtraDropDownPositionerProps,
} from './DropDownPositioner.tsx';

export type CommonDropDownProps = CommonDropDownPositionerProps & {
  readonly hasTrailingElements?: boolean;
  readonly longer?: boolean;
  readonly optionListClassName?: string;
  readonly optionListDataUiAttributes: ObjectWithDataAttribute;
  readonly role?: string;
};

type DropDownFrameProps = CommonDropDownProps & ExtraDropDownPositionerProps;

export const DropDownFrame = forwardRef<HTMLElement, DropDownFrameProps>(
  (
    {
      hasTrailingElements,
      longer,
      optionListClassName,
      optionListDataUiAttributes,
      renderContent,
      role,
      ...restProps
    },
    ref,
  ) => (
    <DropDownPositioner
      {...restProps}
      renderContent={() => (
        <DropDownOptionList
          ref={ref}
          className={optionListClassName}
          hasTrailingElements={hasTrailingElements}
          longer={longer}
          optionListDataUiAttributes={optionListDataUiAttributes}
          role={role}
        >
          {renderContent()}
        </DropDownOptionList>
      )}
    />
  ),
);
