import { InnovationLabSettingsRoute } from '../../../applications/environmentSettings/innovationLab/constants/routePaths.ts';
import {
  ApiKeyListingRoute,
  AssetTypesRoute,
  AssetsRoute,
  AuditLogRoute,
  CalendarRoute,
  CollectionsRoute,
  ContentItemsRoute,
  ContentModelsRoute,
  ContentPaceRoute,
  ContentStatusRoute,
  ContentTypeSnippetsRoute,
  ContentTypesRoute,
  EnvironmentRoute,
  EnvironmentSettingsRoute,
  EnvironmentsRoute,
  GeneralEnvironmentSettingsRoute,
  LegacyWebhooksRoute,
  LocalizationRoute,
  MissionControlCalendarRoute,
  MissionControlDashboardRoute,
  MissionControlProjectOverviewRoute,
  MissionControlQuickstartRoute,
  MissionControlRootRoute,
  MissionControlYourWorkRoute,
  PreviewURLsRoute,
  ProjectOverviewRoute,
  ProjectRoute,
  ProjectRouteParams,
  ProjectSettingsEnvironmentsRoute,
  ProjectSettingsGeneralRoute,
  ProjectSettingsRoute,
  ProjectsRoute,
  QuickstartRoute,
  RelationsRoute,
  RolesRoute,
  SitemapRoute,
  SpacesRoute,
  SubscriptionRoute,
  TaxonomyGroupsRoute,
  UserProfileRoute,
  UsersRoute,
  WebSpotlightEntryRoute,
  WebhooksRoute,
  WorkflowsRoute,
  YourContentRoute,
} from '../../constants/routePaths.ts';
import { VariantIdMacro } from '../../constants/variantIdValues.ts';
import {
  buildPath,
  convertMatchPathParamsToBuildPathParams,
  matchPath,
} from './routeTransitionUtils.ts';

const routesToHideProjectMenu = [SubscriptionRoute, UserProfileRoute];

const routesToHideEnvironmentMenu = [ProjectSettingsRoute];

const routesViableAfterProjectSwitch: string[] = [
  ApiKeyListingRoute,
  AssetTypesRoute,
  AssetsRoute,
  AuditLogRoute,
  CalendarRoute,
  CollectionsRoute,
  ContentItemsRoute,
  ContentModelsRoute,
  ContentPaceRoute,
  ContentStatusRoute,
  ContentTypeSnippetsRoute,
  ContentTypesRoute,
  EnvironmentSettingsRoute,
  EnvironmentsRoute,
  GeneralEnvironmentSettingsRoute,
  InnovationLabSettingsRoute,
  LegacyWebhooksRoute,
  LocalizationRoute,
  MissionControlCalendarRoute,
  MissionControlDashboardRoute,
  MissionControlProjectOverviewRoute,
  MissionControlQuickstartRoute,
  MissionControlRootRoute,
  MissionControlYourWorkRoute,
  PreviewURLsRoute,
  ProjectOverviewRoute,
  ProjectSettingsEnvironmentsRoute,
  ProjectSettingsGeneralRoute,
  QuickstartRoute,
  RelationsRoute,
  RolesRoute,
  SitemapRoute,
  SpacesRoute,
  TaxonomyGroupsRoute,
  UsersRoute,
  WebSpotlightEntryRoute,
  WebhooksRoute,
  WorkflowsRoute,
  YourContentRoute,
].sort((a, b) => b.length - a.length);

const projectLevelRoutes = [ProjectSettingsRoute];

export function shouldHideProjectMenu(pathname: string): boolean {
  return routesToHideProjectMenu.some((route) => matchPath(pathname, route));
}

export function shouldHideEnvironmentMenu(pathname: string): boolean {
  return routesToHideEnvironmentMenu.some((route) => matchPath(pathname, route));
}

const getEnvironmentRouteToUse = (
  baseRouteToUse: string,
  isEnvironmentInactive: boolean,
  isAuthorizedToAccessSettings: boolean,
) => {
  if (isEnvironmentInactive) {
    return isAuthorizedToAccessSettings ? EnvironmentSettingsRoute : ProjectsRoute;
  }
  return baseRouteToUse;
};

const getProjectRouteToUse = (
  baseRouteToUse: string,
  isProjectInactive: boolean,
  isAuthorizedToAccessSettings: boolean,
) => {
  if (isProjectInactive) {
    return isAuthorizedToAccessSettings ? ProjectSettingsRoute : ProjectsRoute;
  }
  return baseRouteToUse;
};

export function getEnvironmentSwitchUrlFactory(
  pathname: string,
): (
  environmentId: Uuid,
  isEnvironmentInactive?: boolean,
  isAuthorizedToAccessSettings?: boolean,
) => string {
  const baseRouteToUse =
    routesViableAfterProjectSwitch.find((route) => matchPath(pathname, route)) || EnvironmentRoute;
  return (
    projectId: Uuid,
    isProjectInactive: boolean = false,
    isAuthorizedToAccessSettings: boolean = true,
  ) => {
    const routeToUse = getEnvironmentRouteToUse(
      baseRouteToUse,
      isProjectInactive,
      isAuthorizedToAccessSettings,
    );
    const buildParams = convertMatchPathParamsToBuildPathParams(matchPath(pathname, routeToUse));

    return buildPath(routeToUse, {
      ...buildParams,
      projectId,
      variantId: VariantIdMacro,
    });
  };
}

export function getProjectSwitchUrlFactory(
  pathname: string,
): (
  projectId: Uuid,
  isProjectInactive?: boolean,
  isAuthorizedToAccessSettings?: boolean,
) => string {
  const baseRouteToUse =
    routesViableAfterProjectSwitch.find((route) => matchPath(pathname, route)) || ProjectRoute;
  return (
    projectContainerId: Uuid,
    isProjectInactive: boolean = false,
    isAuthorizedToAccessSettings: boolean = true,
  ) => {
    const routeToUse = getProjectRouteToUse(
      baseRouteToUse,
      isProjectInactive,
      isAuthorizedToAccessSettings,
    );
    const matchParams = matchPath(pathname, routeToUse);

    return buildPath<ProjectRouteParams>(routeToUse, {
      ...matchParams,
      projectContainerId,
    });
  };
}

export function isAtProjectContainerLevel(pathname: string): boolean {
  return projectLevelRoutes.some((route) => !!matchPath(pathname, route));
}
