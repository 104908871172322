import { CancelScheduling } from '../../../../../../../_shared/constants/itemActions.ts';
import { DataUiWorkflowAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStepActionButton } from '../../../components/details/WorkflowStepActionButton.tsx';

export type CancelSchedulingButtonProps = {
  readonly onCancel: () => void;
};

export const CancelSchedulingButton = ({ onCancel }: CancelSchedulingButtonProps) => {
  return (
    <WorkflowStepActionButton
      buttonStyle="secondary"
      dataUiAction={DataUiWorkflowAction.CancelSchedule}
      destructive
      label={CancelScheduling}
      onClick={onCancel}
    />
  );
};
