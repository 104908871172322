import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { getDataAttribute } from '../../utils/dataAttributes/DataAttributes.ts';
import { DataUiAttributes, DataUiElement } from '../../utils/dataAttributes/DataUiAttributes.ts';
import {
  ScrollTableColumnSize,
  translateColumnSizeToClassSuffix,
} from './types/scrollTableTypes.ts';

export interface IScrollTableCellProps {
  readonly children?: React.ReactNode;
  readonly customClassName?: string;
  readonly isClickable?: boolean;
  readonly tooltip?: string;
  readonly disabledTooltip?: string;
  readonly tooltipPlacement?: Placement;
  readonly isGrowing?: boolean;
  readonly hasHoverEffect?: boolean;
  readonly onClick?: MouseEventHandler<HTMLSpanElement>;
  readonly onMouseDown?: MouseEventHandler<HTMLSpanElement>;
  readonly size: ScrollTableColumnSize;
  readonly title?: string;
  readonly dataUiElement?: DataUiElement;
}

export const ScrollTableCell: React.FC<IScrollTableCellProps> = ({
  children,
  customClassName,
  isClickable,
  tooltip,
  disabledTooltip,
  tooltipPlacement,
  isGrowing,
  hasHoverEffect,
  onClick,
  onMouseDown,
  size,
  title,
  dataUiElement,
}) => {
  const classSizeSuffix = translateColumnSizeToClassSuffix(size);

  return (
    <Tooltip tooltipText={disabledTooltip || tooltip} placement={tooltipPlacement ?? 'top'}>
      <div
        title={title}
        data-hj-suppress=""
        className={classNames('scroll-table__cell', {
          [`scroll-table__column--growing-${classSizeSuffix}`]: isGrowing,
          [`scroll-table__column--${classSizeSuffix}`]: !isGrowing,
        })}
        {...getDataAttribute(DataUiAttributes.Element, dataUiElement)}
        role="cell"
      >
        <span
          className={classNames('scroll-table__cell-item', customClassName, {
            'scroll-table__cell-item--is-clickable': isClickable,
            'scroll-table__cell-item--with-hover-effect': hasHoverEffect,
            'scroll-table__cell-item--is-disabled': !!disabledTooltip,
          })}
          onMouseDown={isClickable ? onMouseDown : undefined}
          onClick={isClickable ? onClick : undefined}
        >
          {children}
        </span>
      </div>
    </Tooltip>
  );
};

ScrollTableCell.displayName = 'ScrollTableCell';
