import { Icon, Icons } from '@kontent-ai/component-library/Icons';
import { Collection } from '@kontent-ai/utils';
import { forwardRef } from 'react';
import {
  DataUiCollection,
  DataUiRteAction,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { BaseBlockType, BlockType, getBaseType } from '../../../utils/blocks/blockType.ts';
import { EditorFeatureLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  TopLevelBlockCategoryFeature,
  isTextFeatureAllowed,
} from '../../apiLimitations/api/editorLimitationUtils.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { Resettable } from '../../toolbars/components/block/BlockToolbar.tsx';
import { RTEToolbarButtonWithMenu } from '../../toolbars/components/buttons/RTEToolbarButtonWithMenu.tsx';
import {
  CommandToolbarMenuItem,
  EditorCommandMenu,
} from '../../toolbars/components/menu/EditorCommandMenu.tsx';
import {
  ToolbarButtonInputProps,
  getToolbarButtonProps,
} from '../../toolbars/utils/toolbarUtils.ts';
import { getTextBlockTypeButtonTooltip } from './getTextBlockTypeButtonTooltip.ts';
import { headingMenuItems, listMenuItems } from './textBlockTypeMenuItems.ts';

export type BlockTypeBlockToolbarButtonProps = {
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly hidesDisallowedFeatures: boolean;
  readonly isToolbarVertical: boolean;
  readonly limitations: EditorFeatureLimitations;
  readonly onCommand: (command: RichTextInputCommand) => void;
};

export const BlockTypeBlockToolbarButton = forwardRef<Resettable, BlockTypeBlockToolbarButtonProps>(
  (
    {
      isToolbarVertical,
      hidesDisallowedFeatures,
      fullBlockTypesAtSelection,
      limitations,
      onCommand,
    },
    ref,
  ) => {
    const textAllowed = isTextFeatureAllowed(
      TopLevelBlockCategoryFeature.Text,
      fullBlockTypesAtSelection,
      limitations,
    );
    const currentBlockType = Collection.getFirst(Collection.getValues(fullBlockTypesAtSelection));

    if ((hidesDisallowedFeatures && !textAllowed) || !currentBlockType) {
      return null;
    }

    const buttonProps: ToolbarButtonInputProps = {
      fullBlockTypesAtSelection,
      currentVisualStyle: null,
      hidesDisallowedFeatures,
      limitations,
      metadataAtSelection: null,
      onCommand,
      selectionContainsText: null,
      tooltipPlacement: 'top-start',
    };

    const selectedMenuItem = menuItems.find(
      (item) => getToolbarButtonProps(item.command, buttonProps)?.isActive,
    );
    const selectedItemProps = selectedMenuItem
      ? getToolbarButtonProps(selectedMenuItem.command, buttonProps)
      : null;

    return (
      <RTEToolbarButtonWithMenu
        disabled={!textAllowed}
        fallbackToolbarPlacements={isToolbarVertical ? ['right'] : ['bottom-start']}
        icon={getBlockTypeIcon(getBaseType(currentBlockType))}
        isViolated={selectedItemProps?.isViolated}
        menuUiCollection={DataUiCollection.RteBlockTypeMenuOptions}
        ref={ref}
        toolbarPlacement={isToolbarVertical ? 'right' : 'top-start'}
        tooltipText={getTextBlockTypeButtonTooltip(textAllowed, selectedItemProps)}
        tooltipPlacement={isToolbarVertical ? 'right' : 'top'}
        uiAction={DataUiRteAction.ChangeBlockType}
      >
        <EditorCommandMenu
          fullBlockTypesAtSelection={fullBlockTypesAtSelection}
          hidesDisallowedFeatures={hidesDisallowedFeatures}
          limitations={limitations}
          items={menuItems}
          onCommand={onCommand}
        />
      </RTEToolbarButtonWithMenu>
    );
  },
);

const menuItems: ReadonlyArray<CommandToolbarMenuItem> = [...headingMenuItems, ...listMenuItems];

const getBlockTypeIcon = (blockType: BaseBlockType): Icon => {
  switch (blockType) {
    case BlockType.HeadingOne:
      return Icons.H1;
    case BlockType.HeadingTwo:
      return Icons.H2;
    case BlockType.HeadingThree:
      return Icons.H3;
    case BlockType.HeadingFour:
      return Icons.H4;
    case BlockType.HeadingFive:
      return Icons.H5;
    case BlockType.HeadingSix:
      return Icons.H6;
    case BlockType.UnorderedListItem:
      return Icons.ListBullets;
    case BlockType.OrderedListItem:
      return Icons.ListNumbers;
    default:
      return Icons.Pilcrow;
  }
};
