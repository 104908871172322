import { Box } from '@kontent-ai/component-library/Box';
import { quinaryButtonHeight } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { StatusBarIconButton } from '@kontent-ai/component-library/StatusBar';
import {
  NonAccessibleVerticalMenu,
  VerticalMenuItem,
  useNonAccessibleVerticalMenu,
} from '@kontent-ai/component-library/VerticalMenu';
import {
  IconSize,
  Spacing,
  colorIconDefaultInverse,
  spacingPopupDistance,
} from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { DropDownMenu } from '../../DropDownMenu/DropDownMenu.tsx';
import { breadcrumbsHeight } from '../decisionTokens.ts';
import { IBreadcrumbsItem, StyledListItem } from './BreadcrumbsItem.tsx';

export const breadcrumbsMenuTriggerCopy = 'Show collapsed items';

interface IBreadcrumbsVerticalMenu {
  readonly items: ReadonlyArray<IBreadcrumbsItem>;
}

interface IBreadcrumbsVerticalMenuItem
  extends IBreadcrumbsItem,
    VerticalMenuItem<IBreadcrumbsVerticalMenuItem> {}

export const BreadcrumbsVerticalMenu: React.FC<IBreadcrumbsVerticalMenu> = ({ items }) => {
  const menuItems: ReadonlyArray<IBreadcrumbsVerticalMenuItem> = items.map((item) => ({
    ...item,
    id: item.to,
  }));

  const { verticalMenuState } =
    useNonAccessibleVerticalMenu<IBreadcrumbsVerticalMenuItem>(menuItems);

  const quinaryButtonOffset = (breadcrumbsHeight - quinaryButtonHeight) / 2;

  return (
    <StyledListItem>
      <Box paddingX={Spacing.S}>
        <DropDownMenu
          renderTrigger={(triggerProps, isOptionListVisible) => (
            <StatusBarIconButton
              iconName="Ellipsis"
              isActive={isOptionListVisible}
              label={breadcrumbsMenuTriggerCopy}
              {...triggerProps}
            />
          )}
          renderDropDown={(hideMenu) => (
            <NonAccessibleVerticalMenu
              state={verticalMenuState}
              renderItem={({ item }) =>
                item.value && (
                  <MenuItemWithMouseHover
                    linkPath={item.value.to}
                    menuItemState="default"
                    text={item.textValue}
                    onPress={hideMenu}
                  />
                )
              }
            />
          )}
          tippyOptions={{
            placement: 'bottom-start',
            offset: [0, quinaryButtonOffset + spacingPopupDistance],
          }}
        />
      </Box>
      <Icons.ChevronRight size={IconSize.XS} color={colorIconDefaultInverse} />
    </StyledListItem>
  );
};

BreadcrumbsVerticalMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
