import { Card, cardBorderRadius } from '@kontent-ai/component-library/Card';
import { PaperLevel } from '@kontent-ai/component-library/Paper';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BorderRadius,
  Spacing,
  colorFocus,
  px,
  spacingPopupDistance,
} from '@kontent-ai/component-library/tokens';
import { Direction } from '@kontent-ai/types';
import React, { useEffect, useRef } from 'react';
import { PopoverFrame } from '../../../../../../component-library/components/Dialogs/Popover/components/PopoverFrame.tsx';
import { arrowSize } from '../../../../../../component-library/components/Dialogs/Popover/tokens.ts';
import {
  IAdjustTippyOptions,
  usePopover,
} from '../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import {
  addNonInteractive,
  createAddArrow,
  createAddOffset,
  createAddPreventOverflow,
} from '../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { usePreventOverflowFromScrollContainer } from '../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { DraggedFile } from '../../../../../_shared/utils/fileDragDropUtils.ts';
import { getImageType } from '../../../../../_shared/utils/fileTypeDetection.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { targetLineWidth, usePreviewAdjustments } from './usePreviewAdjustments.ts';

const maxItemsToDisplay = 5;

function alphabeticalComparerWithEmptyAsLast(item1Key: string, item2Key: string): number {
  if (!item1Key) {
    return 1;
  }
  if (!item2Key) {
    return -1;
  }
  if (item1Key < item2Key) {
    return -1;
  }
  if (item1Key > item2Key) {
    return 1;
  }
  return 0;
}

const getAggregatedFileTypeInfos = (fileTypes: ReadonlyArray<string>): ReadonlyArray<string> => {
  const aggregatedTypes = [
    ...fileTypes.reduce(
      (countPerType, fileType) => countPerType.set(fileType, (countPerType.get(fileType) ?? 0) + 1),
      new Map<string, number>(),
    ),
  ];
  if (aggregatedTypes.length > maxItemsToDisplay) {
    return [`${fileTypes.length} files`];
  }
  return aggregatedTypes
    .sort(([item1Key], [item2Key]) => alphabeticalComparerWithEmptyAsLast(item1Key, item2Key))
    .map(([fileType, count]) =>
      fileType
        ? `${count} image${count > 1 ? 's' : ''} (${fileType})`
        : `${count} file${count > 1 ? 's' : ''}`,
    );
};

const getFileTypeInfos = (fileTypes: ReadonlyArray<string>): ReadonlyArray<string> =>
  fileTypes.length > 0
    ? fileTypes.map((fileType) => (fileType ? `Image (${fileType})` : 'File'))
    : ['File(s)'];

interface IDraggedFilesPreviewProps {
  readonly contentRef: React.RefObject<HTMLElement>;
  readonly direction: Direction;
  readonly files: ReadonlyArray<DraggedFile>;
  readonly onMount?: () => void;
  readonly onUnmount?: () => void;
  readonly targetBlockElement: HTMLElement;
}

export const DraggedFilesPreview: React.FC<IDraggedFilesPreviewProps> = ({
  contentRef,
  direction,
  files,
  onMount,
  onUnmount,
  targetBlockElement,
}) => {
  useEffect(() => {
    onMount?.();
    return onUnmount;
  }, [onMount, onUnmount]);

  const targetRef = useRef<HTMLElement>(targetBlockElement);
  targetRef.current = targetBlockElement;
  const popoverRef = useRef<HTMLDivElement>(null);

  const {
    offset,
    containerWidth,
    adjustTippyOptions: addPreviewAdjustmentsTippyOptions,
    popoverHorizontalDisplacement,
    targetHeight,
  } = usePreviewAdjustments(contentRef, targetBlockElement, popoverRef, direction);

  const { preventOverflowModifier } = usePreventOverflowFromScrollContainer(arrowSize / 2);

  const adjustTippyOptions: IAdjustTippyOptions = compose(
    createAddPreventOverflow(preventOverflowModifier.options),
    createAddArrow(() =>
      popoverRef.current
        ? (popoverRef.current.getBoundingClientRect().height - arrowSize) / 2
        : cardBorderRadius,
    ),
    createAddOffset(offset),
    addPreviewAdjustmentsTippyOptions,
    addNonInteractive,
  );

  const { popoverProps } = usePopover({
    adjustTippyOptions,
    allowAnimation: false,
    popoverRef,
    isOpen: true,
    placement: 'left',
    // This is an interaction preview and not a real dialog, we don't want anything to interrupt the interaction
    shouldCloseOnBlur: false,
    __disabledFocusLock: true,
  });

  const fileTypes = files.map((file) => getImageType(file.type) ?? '');
  const fileTypeInfos =
    fileTypes.length > maxItemsToDisplay
      ? getAggregatedFileTypeInfos(fileTypes)
      : getFileTypeInfos(fileTypes);

  return (
    <PopoverFrame
      {...popoverProps}
      targetRef={contentRef}
      css={`
        // Target drop highlight attached to the center of the popover (arrow)
        &:before {
          position: absolute;
          z-index: -1;
          top: calc(50% - ${px((targetHeight || targetLineWidth) / 2)});
          left: calc(100% + (${px(
            arrowSize / 2 + spacingPopupDistance - popoverHorizontalDisplacement,
          )}));
          height: ${px(targetHeight ? targetHeight : targetLineWidth)};
          width: ${px(containerWidth)};
          background-color: ${targetHeight ? 'transparent' : colorFocus};
          border: ${targetHeight ? `solid ${px(targetLineWidth)} ${colorFocus}` : 'none'};
          border-radius: ${px(targetHeight ? BorderRadius.S : BorderRadius.Pill)};
          content: '';
        }
      `}
    >
      <Card level={PaperLevel.Popout} cardLabel="Drop files" component="section">
        <Card.Body>
          <Stack spacing={Spacing.XL}>
            {fileTypeInfos.map((fileTypeInfo, index) => (
              <div key={index}>{fileTypeInfo}</div>
            ))}
          </Stack>
        </Card.Body>
      </Card>
    </PopoverFrame>
  );
};

DraggedFilesPreview.displayName = 'DraggedFilesPreview';
