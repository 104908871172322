import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { ChangeEvent, useRef } from 'react';
import { FileWithThumbnail } from '../../applications/contentInventory/assets/models/FileWithThumbnail.type.ts';
import { Button } from '../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../uiComponents/Button/buttonStyle.ts';
import { LinkLike } from '../uiComponents/LinkLike/LinkLike.tsx';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { getDroppedFiles } from '../utils/fileDragDropUtils.ts';

interface IBrowseButtonProps {
  readonly accept?: string;
  readonly className?: string;
  readonly dataUiAction?: DataUiAction;
  readonly buttonStyle?: ButtonStyle;
  readonly linkName: string;
  readonly multiple?: boolean;
  readonly onUpload: ((files: ReadonlyArray<FileWithThumbnail>) => void) | null;
  readonly supportClick?: boolean;
  readonly tooltipText?: string;
  readonly screenReaderText?: string;
}

export const BrowseButton = (props: IBrowseButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const upload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target?.files ?? [];
    const filesWithThumbnail = getDroppedFiles(Array.prototype.slice.call(files, 0, files.length));

    props.onUpload?.(filesWithThumbnail);
  };

  const onClick = () => {
    if ((props.supportClick ?? true) && fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  return (
    <Tooltip placement="top" tooltipText={props.tooltipText}>
      <span className="notranslate">
        <input
          {...getDataUiInputAttribute(DataUiInput.UploadFiles)}
          style={{ display: 'none' }}
          type="file"
          multiple={props.multiple ?? true}
          ref={fileInputRef}
          onChange={upload}
          accept={props.accept}
        />
        {props.buttonStyle ? (
          <Button
            style={props.buttonStyle}
            onClick={onClick}
            className={props.className}
            {...(props.dataUiAction && getDataUiActionAttribute(props.dataUiAction))}
            disabled={!props.supportClick}
          >
            {props.linkName}
          </Button>
        ) : (
          <LinkLike
            onClick={onClick}
            className={props.className}
            screenReaderText={props.screenReaderText}
            {...(props.dataUiAction && getDataUiActionAttribute(props.dataUiAction))}
          >
            {props.linkName}
          </LinkLike>
        )}
      </span>
    </Tooltip>
  );
};
