import { NonAccessibleVerticalMenu as NonAccessibleVerticalMenuComponent } from './VerticalMenu.tsx';
import { useNonAccessibleVerticalMenu as useNonAccessibleVerticalMenuHook } from './useNonAccessibleVerticalMenu.ts';

/** @deprecated Use useVerticalMenu instead.*/
export const useNonAccessibleVerticalMenu = useNonAccessibleVerticalMenuHook;
/** @deprecated Use useVerticalMenu instead.*/
export const NonAccessibleVerticalMenu = NonAccessibleVerticalMenuComponent;

export { type VerticalMenuFrameProps, VerticalMenuFrame } from './VerticalMenuFrame.tsx';
export {
  type VerticalMenuSection,
  type VerticalMenuItem,
  type IItemProps,
} from './types.ts';

export { VirtualizedVerticalMenuFrame } from './VirtualizedVerticalMenuFrame.tsx';

export { useVerticalMenu } from './useVerticalMenu.ts';
export { type VerticalMenuState } from './useNonAccessibleVerticalMenu.ts';

export {
  mapItemsToCollectionComponents,
  isItem,
  isItemNode,
  isSection,
  isSectionNode,
} from './utils/utils.tsx';
export {
  scrollItemIntoView,
  scrollVirtualizedItemIntoView,
} from './utils/scrollItemIntoView.ts';

export { CollapsibleVerticalMenuItemIcon } from './components/CollapsibleVerticalMenuItemIcon.tsx';
export { verticalMenuItems } from './stories/storyItems.ts';
export { type StoryMenuItem } from './stories/StoryMenuItem.type.ts';

export {
  GridListMenu,
  useGridListMenuState,
} from './GridListMenu.tsx';
