import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { ICommentThread, IInlineCommentThread } from '../../../models/comments/CommentThreads.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { getItemElementsInContentGroup } from '../../../stores/utils/contentItemElementsUtils.ts';
import {
  ICommentThreadWithLocation,
  getDiscussionsThreads,
  isThreadInline,
} from '../../../utils/commentUtils.ts';
import { getItemElementCommentManager } from '../../../utils/getItemElementCommentManager.ts';
import {
  isUnresolvedInlineCommentThread,
  isUnresolvedSavedInlineCommentThread,
} from '../utils/inlineCommentUtils.ts';

const EmptyComments: ReadonlyArray<ICommentThreadWithLocation> = [];

const aggregateInlineComments = memoize.allForever(
  (...commentsPerElement: ReadonlyArray<ReadonlyArray<ICommentThreadWithLocation>>) =>
    commentsPerElement.flat(),
);

export const getInlineCommentThreads = memoize.weak(
  (threads: ReadonlyArray<ICommentThread>): ReadonlyArray<IInlineCommentThread> =>
    threads.filter(isThreadInline),
);

const getOrderedInlineCommentsForElement = memoize.weak(
  (
    element: ICompiledContentItemElementData,
    commentThreads: ReadonlyArray<IInlineCommentThread>,
    loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    if (!commentThreads.length) {
      return EmptyComments;
    }

    const commentManager = getItemElementCommentManager(element.type);
    if (!commentManager) {
      return EmptyComments;
    }

    const commentThreadReferences = commentManager.getCommentThreadReferences(
      element,
      commentThreads,
      loadedContentItemTypes,
      null,
    );
    if (!commentThreadReferences) {
      return EmptyComments;
    }

    return commentThreadReferences;
  },
);

const getElementsForContentGroup = (state: IStore, contentGroupId: string) => {
  const { editedContentItemVariantElements, editedContentItem, loadedContentItemTypes } =
    state.contentApp;

  if (!editedContentItem) {
    throw InvariantException('inlineCommentUtils.ts: "editedContentItem" is not loaded');
  }

  const editedContentItemType = loadedContentItemTypes.get(
    editedContentItem.editedContentItemTypeId,
  );
  if (!editedContentItemType) {
    throw InvariantException('inlineCommentUtils.ts: "editedContentItemType" is not loaded');
  }

  return getItemElementsInContentGroup(
    editedContentItemVariantElements,
    editedContentItemType,
    contentGroupId,
  );
};

const getOrderedInlineCommentsMemoized = memoize.weak(
  (
    commentThreads: ReadonlyArray<IInlineCommentThread>,
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
    includePredicate?: (thread: IInlineCommentThread) => boolean,
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    const elementComments = elements
      .map((element: ICompiledContentItemElementData) =>
        getOrderedInlineCommentsForElement(element, commentThreads, loadedContentItemTypes),
      )
      .filter((comments) => !!comments.length);

    const orderedComments = includePredicate
      ? aggregateInlineComments(...elementComments).filter((c) => includePredicate(c.commentThread))
      : aggregateInlineComments(...elementComments);

    return orderedComments;
  },
);

export const getOrderedInlineComments = (
  state: IStore,
  contentGroupId?: string | null,
  includePredicate?: (thread: IInlineCommentThread) => boolean,
): ReadonlyArray<ICommentThreadWithLocation> => {
  const {
    editedContentItemVariantComments: { commentThreads },
    editedContentItemVariantElements,
    loadedContentItemTypes,
  } = state.contentApp;

  const elements = contentGroupId
    ? getElementsForContentGroup(state, contentGroupId)
    : editedContentItemVariantElements;

  return getOrderedInlineCommentsMemoized(
    getInlineCommentThreads(commentThreads),
    elements,
    loadedContentItemTypes,
    includePredicate,
  );
};

const getCommentsOnRemovedContentMemoized = memoize.weak(
  (
    commentThreads: ReadonlyArray<ICommentThread>,
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
  ): ReadonlyArray<IInlineCommentThread> => {
    const orderedInlineCommentThreads = getOrderedInlineCommentsMemoized(
      getInlineCommentThreads(commentThreads),
      elements,
      loadedContentItemTypes,
    );

    const unresolvedInlineCommentThreads = commentThreads.filter(
      isUnresolvedSavedInlineCommentThread,
    );

    if (orderedInlineCommentThreads.length === 0) {
      return unresolvedInlineCommentThreads;
    }

    return unresolvedInlineCommentThreads.filter(
      (thread) =>
        !orderedInlineCommentThreads.some(
          (threadWithLocation) => threadWithLocation.commentThread.id === thread.id,
        ),
    );
  },
);

export const getCommentsOnRemovedContent = (state: IStore): ReadonlyArray<IInlineCommentThread> => {
  const {
    editedContentItemVariantComments: { commentThreads },
    editedContentItemVariantElements,
    loadedContentItemTypes,
  } = state.contentApp;

  return getCommentsOnRemovedContentMemoized(
    commentThreads,
    editedContentItemVariantElements,
    loadedContentItemTypes,
  );
};

export const getAreAnyCommentThreadsInDiscussionSidebar = (state: IStore): boolean => {
  const discussionCommentThreads = getDiscussionsThreads(
    state.contentApp.editedContentItemVariantComments.commentThreads,
  );
  if (discussionCommentThreads.length) {
    return true;
  }

  const commentsOnRemovedContent = getCommentsOnRemovedContent(state);
  return !!commentsOnRemovedContent.length;
};

export const getElementCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<ICommentThread>,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> => {
    const inlineCommentThreads = getInlineCommentThreads(commentThreads);
    return inlineCommentThreads.filter(
      (thread) =>
        thread.contentComponentId === contentComponentId && thread.elementId === elementId,
    );
  },
);

export const getUnresolvedElementCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<IInlineCommentThread>,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getElementCommentThreads(commentThreads, elementId, contentComponentId).filter(
      isUnresolvedInlineCommentThread,
    ),
);

export const getLinkedItemCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<ICommentThread>,
    linkedContentItemId: Uuid,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getElementCommentThreads(commentThreads, elementId, contentComponentId).filter(
      (thread) => thread.externalSegmentId === linkedContentItemId,
    ),
);

export const getUnresolvedLinkedItemCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<IInlineCommentThread>,
    linkedContentItemId: Uuid,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getLinkedItemCommentThreads(
      commentThreads,
      linkedContentItemId,
      elementId,
      contentComponentId,
    ).filter((thread) => isUnresolvedInlineCommentThread(thread)),
);

export const getAssetCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<ICommentThread>,
    assetId: Uuid,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getElementCommentThreads(commentThreads, elementId, contentComponentId).filter(
      (thread) => thread.externalSegmentId === assetId,
    ),
);

export const getUnresolvedAssetCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<IInlineCommentThread>,
    assetId: Uuid,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getAssetCommentThreads(commentThreads, assetId, elementId, contentComponentId).filter(
      (thread) => isUnresolvedInlineCommentThread(thread),
    ),
);

export const getRichTextAssetCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<ICommentThread>,
    externalSegmentId: Uuid | null,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getElementCommentThreads(commentThreads, elementId, contentComponentId).filter(
      (thread) => thread.externalSegmentId === externalSegmentId,
    ),
);

export const getUnresolvedRichTextAssetCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<IInlineCommentThread>,
    externalSegmentId: Uuid | null,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getRichTextAssetCommentThreads(
      commentThreads,
      externalSegmentId,
      elementId,
      contentComponentId,
    ).filter((thread) => isUnresolvedInlineCommentThread(thread)),
);

export const getRichTextLinkedItemCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<ICommentThread>,
    externalSegmentId: Uuid,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getElementCommentThreads(commentThreads, elementId, contentComponentId).filter(
      (thread) => thread.externalSegmentId === externalSegmentId,
    ),
);

export const getUnresolvedRichTextLinkedItemCommentThreads = memoize.weak(
  (
    commentThreads: ReadonlyArray<IInlineCommentThread>,
    externalSegmentId: Uuid,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyArray<IInlineCommentThread> =>
    getRichTextLinkedItemCommentThreads(
      commentThreads,
      externalSegmentId,
      elementId,
      contentComponentId,
    ).filter((thread) => isUnresolvedInlineCommentThread(thread)),
);

export const selectUnresolvedInlineCommentThreads = (
  state: IStore,
): ReadonlyArray<ICommentThreadWithLocation> =>
  getOrderedInlineComments(state, null, isUnresolvedInlineCommentThread);
