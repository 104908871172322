import { mergeProps } from '@react-aria/utils';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Stack } from '../../../layout/Stack/Stack.tsx';
import {
  colorToggleIcon,
  colorToggleIconSelected,
  colorToggleText,
  colorToggleTextSelected,
} from '../../../tokens/decision/colors.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { LetterSpacing, Typography } from '../../../tokens/quarks/typography.ts';
import { RouterLink } from '../../Anchor/Anchor.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { StyledNavigationItem } from './StyledNavigationItem.tsx';

const descenderOffset = '.2em';

const NavigationBarLabel = styled.div<{ readonly $isActive?: boolean }>`
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: stretch;
  ${Typography.LabelSmall};
  letter-spacing: ${LetterSpacing.M};

  color: ${({ $isActive }) => ($isActive ? colorToggleTextSelected : colorToggleText)};

  /* to prevent letter descenders from being cut off by 'overflow: hidden;' because of the smaller line-height */
  /* for example 'g' in 'Project settings’ */
  padding-bottom: ${descenderOffset};
  margin-bottom: -${descenderOffset};
`;

type Props = Readonly<{
  icon: IconName;
  isActive?: boolean;
  label: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  to: string;
  tooltipText?: string;
}>;

type InjectedProps = {
  children: ReactNode;
  $isActive: boolean;
  $isFocusVisible: boolean;
} & ReturnType<typeof useOurFocusRing>['focusProps'];

const BaseNavigationItemComponent = ({
  tooltipText,
  icon,
  label,
  isActive,
  renderElement,
}: Readonly<{
  tooltipText?: string;
  icon: IconName;
  isActive: boolean;
  label: string;
  renderElement: (props: InjectedProps) => ReactElement;
}>) => {
  const Icon = Icons[icon];
  const { isFocusVisible, focusProps } = useOurFocusRing();

  const children = (
    <Stack spacing={Spacing.XS} align="center">
      <Icon size={IconSize.L} color={isActive ? colorToggleIconSelected : colorToggleIcon} />
      <NavigationBarLabel $isActive={isActive}>{label}</NavigationBarLabel>
    </Stack>
  );

  return (
    <Tooltip tooltipText={tooltipText} placement="right">
      {renderElement({
        $isActive: isActive,
        $isFocusVisible: isFocusVisible,
        children,
        ...focusProps,
      })}
    </Tooltip>
  );
};

const StyledNavigationLink = styled(StyledNavigationItem).attrs({ component: RouterLink })``;

export const NavigationBarLink = React.forwardRef<HTMLElement, Omit<Props, 'onClick'>>(
  ({ isActive, icon, label, to, tooltipText, ...otherProps }, forwardedRef) => (
    <BaseNavigationItemComponent
      isActive={!!isActive}
      icon={icon}
      label={label}
      renderElement={(injectedProps) => (
        <StyledNavigationLink
          aria-current={isActive ? 'page' : undefined}
          ref={forwardedRef}
          to={to}
          {...mergeProps(injectedProps, otherProps)}
        />
      )}
    />
  ),
);

const StyledNavigationButton = styled(StyledNavigationItem).attrs({ component: 'button' })`
  background-color: ${BaseColor.Transparent};
  border: none;
`;

export const NavigationBarButton = React.forwardRef<HTMLButtonElement, Omit<Props, 'to'>>(
  ({ isActive, icon, label, tooltipText, onClick, ...otherProps }, forwardedRef) => (
    <BaseNavigationItemComponent
      isActive={!!isActive}
      icon={icon}
      label={label}
      renderElement={(injectedProps) => (
        <StyledNavigationButton
          onClick={isActive ? undefined : onClick}
          ref={forwardedRef}
          {...mergeProps(injectedProps, otherProps)}
        />
      )}
    />
  ),
);
