import { useLayoutEffect } from 'react';
import { PlanType } from '../../data/constants/PlanType.ts';
import { ICurrentUserInfo } from '../../data/models/user/CurrentUserInfo.ts';
import { AmplitudeUser, boot, shutdown } from '../utils/amplitudeUtils.ts';
import { isUserId } from '../utils/validation/typeValidators.ts';

type Props = {
  readonly businessRole?: string;
  readonly businessType?: string;
  readonly companyName?: string;
  readonly highestPlan?: PlanType;
  readonly otherBusinessRole?: string | null;
  readonly userInfo: ICurrentUserInfo;
};

export const Amplitude = ({
  businessRole,
  businessType,
  companyName,
  highestPlan,
  otherBusinessRole,
  userInfo,
}: Props) => {
  useLayoutEffect(() => {
    const user: AmplitudeUser = {
      app_id: self._envConfig.intercomAppId,
      ...(isUserId(userInfo.userId)
        ? {
            user_id: userInfo.userId,
            email: userInfo.email,
            created_at: Math.floor(Date.parse(userInfo.createdAt) / 1000),
            sign_up_date: userInfo.createdAt,
          }
        : undefined),
      ...(businessType ? { business_type: businessType } : undefined),
      ...(businessRole ? { business_role: businessRole } : undefined),
      ...(companyName ? { provided_company_name: companyName } : undefined),
      ...(otherBusinessRole ? { business_role_other: otherBusinessRole } : undefined),
      ...(highestPlan ? { highest_plan: highestPlan } : undefined),
    };

    boot(user);

    return () => {
      shutdown();
    };
  }, [businessRole, businessType, companyName, highestPlan, otherBusinessRole, userInfo]);

  return null;
};
