import { RefObject } from 'react';
import { ActionItem, ActionMenu } from '../../../../_shared/components/ActionMenu.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IMyProjectMoreActionsStateProps {
  readonly actions: ReadonlyArray<ActionItem>;
}

export const MyProjectMoreActions = (props: IMyProjectMoreActionsStateProps): JSX.Element => (
  <ActionMenu
    actions={props.actions}
    renderToggleButton={(ref: RefObject<HTMLDivElement>, onClick, isOpen) => (
      <SquareButton
        ref={ref}
        onClick={onClick}
        tooltipText={isOpen ? undefined : 'More actions'}
        tooltipPlacement="top-start"
        style={ButtonStyle.Tertiary}
        iconName={IconName.Cogwheel}
        ariaLabel="More actions"
        {...getDataUiActionAttribute(DataUiAction.MoreActions)}
      />
    )}
  />
);

MyProjectMoreActions.displayName = 'MyProjectMoreActions';
