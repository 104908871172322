import { Icons } from '@kontent-ai/component-library/Icons';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  RTEToolbarIconButton,
  SpecificButtonProps,
} from '../../toolbars/components/buttons/RTEToolbarButton.tsx';

export const AddSuggestionButton = (props: SpecificButtonProps) => (
  <RTEToolbarIconButton
    {...props}
    icon={Icons.BubblePencil}
    shortcut={ControlAltShortcutTemplate('S')}
    tooltipPlacement={props.tooltipPlacement}
    tooltipText={props.tooltipText ?? 'Add suggestion'}
    {...getDataUiActionAttribute(DataUiRteAction.AddSuggestion)}
  />
);
