import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { IFriendlyWarning } from '../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { DataUiElement } from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { PublishMethodSection } from '../../containers/Elements/PublishMethodSection.tsx';
import { PublishingErrorSection } from '../../containers/Elements/PublishingErrorSection.tsx';
import { PublishingQuickTipSection } from '../../containers/Elements/PublishingQuickTipSection.tsx';
import { UnpublishScheduleEditor } from '../../containers/UnpublishScheduleEditor.tsx';
import { PublishingFriendlyWarningSection } from '../Elements/PublishingFriendlyWarningSection/PublishingFriendlyWarningSection.tsx';
import { ModalSection } from '../ModalSection.tsx';

export interface IPublishingSectionProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
}

export const PublishingSection: React.FC<IPublishingSectionProps> = (props) => (
  <>
    <PublishingFriendlyWarningSection
      activeUsersNames={props.activeUsersNames}
      friendlyWarningReasons={props.friendlyWarningReasons}
    />
    <PublishingErrorSection />
    <PublishMethodSection />
    <ModalSection
      key="unpublishScheduleSection"
      dataUiElementName={DataUiElement.UnpublishScheduleSection}
    >
      <Stack align="start" spacing={Spacing.L}>
        <Box typography={Typography.LabelLarge}>Unpublishing</Box>
        <UnpublishScheduleEditor />
      </Stack>
    </ModalSection>
    <PublishingQuickTipSection />
  </>
);

PublishingSection.displayName = 'PublishingSection';
