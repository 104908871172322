import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icon } from '@kontent-ai/component-library/Icons';
import { Placement } from '@kontent-ai/component-library/types';
import React, { MouseEventHandler, forwardRef, ReactNode } from 'react';
import { getDataAttributeProps } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

const LeftMouseButton = 0;

const onToolSelectedWithLeftButtonVerification =
  (action: MouseEventHandler<HTMLButtonElement>) =>
  (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (e.button === LeftMouseButton) {
      action(e);
    }
  };

export type SpecificButtonProps = {
  readonly disabled?: boolean;
  readonly isFocusable?: boolean;
  readonly hasHiddenShortcut?: boolean;
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText?: string;
};

export type ToggleButtonProps = SpecificButtonProps & {
  readonly isActive?: boolean;
  readonly isViolated?: boolean;
};

type RTEToolbarButtonProps = ToggleButtonProps & {
  readonly children: ReactNode;
  readonly shortcut?: string;
};

export const RTEToolbarButton = forwardRef<HTMLButtonElement, RTEToolbarButtonProps>(
  (
    {
      children,
      disabled,
      hasHiddenShortcut,
      isActive,
      isFocusable,
      isViolated,
      onClick,
      shortcut,
      tooltipPlacement,
      tooltipText,
      ...rest
    },
    ref,
  ) => (
    <QuinaryButton
      activated={isActive}
      buttonStyle={isViolated ? 'error-selected' : 'default'}
      disabled={disabled}
      disableTabulator={!isFocusable}
      onClick={onToolSelectedWithLeftButtonVerification(onClick)}
      ref={ref}
      tooltipPlacement={tooltipPlacement}
      tooltipShortcuts={disabled || hasHiddenShortcut ? undefined : shortcut}
      tooltipText={tooltipText ?? ''}
      data-active={isActive}
      {...getDataAttributeProps(rest)}
    >
      {children}
    </QuinaryButton>
  ),
);

RTEToolbarButton.displayName = 'RTEToolbarButton';

export type RTEToolbarIconButtonProps = Omit<RTEToolbarButtonProps, 'children'> & {
  readonly icon: Icon;
};

export const RTEToolbarIconButton = forwardRef<HTMLButtonElement, RTEToolbarIconButtonProps>(
  ({ icon, tooltipText, ...rest }, ref) => (
    <RTEToolbarButton ref={ref} tooltipText={tooltipText} {...rest}>
      <QuinaryButton.Icon icon={icon} />
    </RTEToolbarButton>
  ),
);

RTEToolbarIconButton.displayName = 'RTEToolbarIconButton';
