import { useEventListener } from '@kontent-ai/hooks';
import PropTypes from 'prop-types';
import React, { ReactElement, useRef } from 'react';
// eslint-disable-next-line import/no-restricted-paths
import { compose } from '../../../../client/app/_shared/utils/func/compose.ts';
import { PopoverFrame } from '../../../../client/component-library/components/Dialogs/Popover/components/PopoverFrame.tsx';
import {
  IAdjustTippyOptions,
  usePopover,
} from '../../../../client/component-library/components/Dialogs/Popover/usePopover.tsx';
import {
  addFlipping,
  createAddPreventOverflow,
} from '../../../../client/component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Placement, placements } from '../../types/placement.ts';
import type { DisabledFocusLockProp } from '../../types/utils.d.ts';
import { CLPropTypes } from '../../validators/propTypes.ts';
import { ClippedOverlay } from '../Scrims/ClippedOverlay.tsx';
import { InversePopover, StepCounterProps } from './components/InversePopover.tsx';
import { onboardingBubbleBackgroundColor } from './tokens.ts';

type UseOnboardingPopoverProps = DisabledFocusLockProp &
  Readonly<{
    adjustTippyOptions?: IAdjustTippyOptions;
    isVisible: boolean;
    onClose?: () => void;
    placement?: Placement;
    popoverRef?: React.Ref<HTMLElement>;
    shouldCloseOnInteractOutside?: (element: HTMLElement) => boolean;
    targetRef?: React.Ref<HTMLElement>;
  }>;

export const useOnboardingPopover = ({
  adjustTippyOptions,
  __disabledFocusLock,
  isVisible,
  onClose,
  placement = 'left',
  popoverRef,
  shouldCloseOnInteractOutside,
  targetRef,
}: UseOnboardingPopoverProps) => {
  const { popoverProps, targetProps, dialogTitleProps, targetRefObject } = usePopover({
    adjustTippyOptions,
    arrowColor: onboardingBubbleBackgroundColor,
    __disabledFocusLock,
    isOpen: isVisible,
    onClose,
    placement,
    popoverRef,
    shouldCloseOnBlur: false,
    shouldCloseOnInteractOutside,
    targetRef,
  });

  const { role, ...restTargetProps } = targetProps;

  return {
    dialogTitleProps,
    popoverProps,
    targetProps: restTargetProps,
    targetRefObject,
  };
};

export type OnboardingPopoverProps = DisabledFocusLockProp &
  Readonly<{
    headlineText: string;
    /** @param highlightedElementRef Provide a reference to the element that should remain uncovered by the fog (in case you set includeFog to
     *  true). If not provided, the target (rendered by renderTarget) element will be highlighted. */
    highlightedElementRef?: React.RefObject<HTMLElement>;
    includeFog?: boolean;
    isVisible: boolean;
    onDismiss: () => void;
    onNext?: () => void;
    placement?: Placement;
    renderTarget: (
      targetProps: ReturnType<typeof useOnboardingPopover>['targetProps'],
    ) => ReactElement;
    showPrimaryButton?: boolean;
    /** @param targetRef Provide a reference to the element rendered by **renderTarget** only if you already have a ref to the target element
     *  that you work with yourself. */
    targetRef?: React.Ref<HTMLElement>;
  }>;

type AllOnboardingPopoverProps = React.PropsWithChildren<
  AllOrNoneProps<StepCounterProps> & OnboardingPopoverProps
>;

const propTypes: PropTypeMap<AllOnboardingPopoverProps> = {
  children: PropTypes.node,
  currentStep: PropTypes.number as any,
  __disabledFocusLock: PropTypes.bool,
  headlineText: PropTypes.string.isRequired,
  includeFog: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  placement: PropTypes.oneOf(placements),
  renderTarget: PropTypes.func.isRequired,
  highlightedElementRef: CLPropTypes.refObject,
  showPrimaryButton: PropTypes.bool,
  targetRef: CLPropTypes.ref,
  totalSteps: PropTypes.number as any,
};

const adjustTippyOptions = (addExplicitUpdate: IAdjustTippyOptions) =>
  compose(addFlipping, createAddPreventOverflow({ padding: Spacing.XL }), addExplicitUpdate);

export const OnboardingPopover = React.forwardRef<HTMLElement, AllOnboardingPopoverProps>(
  (
    {
      children,
      currentStep,
      __disabledFocusLock,
      headlineText,
      includeFog = false,
      isVisible,
      onDismiss,
      onNext,
      placement,
      renderTarget,
      highlightedElementRef,
      showPrimaryButton,
      targetRef,
      totalSteps,
    },
    forwardedRef,
  ) => {
    const overlayRef = useRef<HTMLDivElement>(null);

    // When the target moves as a side effect of some action that cannot be detected by the Tippy
    // E.g. adding siblings to the same flex container, we need to force update the popover position
    const updatePopover = useRef<(() => void) | undefined>();

    const addExplicitUpdate: IAdjustTippyOptions = (tippyOptions) => ({
      ...tippyOptions,
      onMount: (instance) => {
        updatePopover.current = () => instance.popperInstance?.update();
      },
    });

    const { popoverProps, targetProps, dialogTitleProps, targetRefObject } = useOnboardingPopover({
      adjustTippyOptions: adjustTippyOptions(addExplicitUpdate),
      __disabledFocusLock,
      isVisible,
      onClose: onDismiss,
      placement,
      popoverRef: forwardedRef,
      shouldCloseOnInteractOutside: (element) =>
        !(
          targetRefObject.current?.contains(element) ||
          overlayRef.current?.contains(element) ||
          highlightedElementRef?.current?.contains(element)
        ),
      targetRef,
    });

    useEventListener(
      'keydown',
      (event) => {
        if (event.key === 'ArrowRight') {
          onNext?.();
        }
      },
      onNext ? window : null,
    );

    if (!isVisible) {
      return renderTarget(targetProps);
    }

    const stepsProps =
      currentStep && totalSteps
        ? {
            currentStep,
            totalSteps,
          }
        : {};

    const primaryButtonProps = showPrimaryButton
      ? {
          showPrimaryButton,
          onPrimaryButtonClick: currentStep !== totalSteps && onNext ? onNext : onDismiss,
        }
      : ({
          showPrimaryButton: false,
        } as const);

    return (
      <>
        {renderTarget(targetProps)}
        <PopoverFrame {...popoverProps}>
          <InversePopover
            dialogTitleProps={dialogTitleProps}
            headlineText={headlineText}
            onCloseActionClick={onDismiss}
            {...primaryButtonProps}
            {...stepsProps}
          >
            {children}
          </InversePopover>
        </PopoverFrame>
        <ClippedOverlay
          ref={overlayRef}
          targetRef={highlightedElementRef ?? targetRefObject}
          visible={includeFog}
          onTargetBoundingClientRectUpdate={() => updatePopover.current?.()}
        />
      </>
    );
  },
);

OnboardingPopover.displayName = 'OnboardingPopover';
OnboardingPopover.propTypes = propTypes;
