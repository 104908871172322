import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Menu } from '@kontent-ai/component-library/Menu';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { Spacing, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { FC, RefObject } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { updateAssetListingOrdering } from '../../actions/thunkAssetLibraryActions.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { getColumnName, orderingOptions } from '../../utils/assetListingOrderingUtils.ts';

type AssetListingOrderingProps = {
  readonly onOrderingChange: (orderingData: AssetsOrderBy) => void;
  readonly orderBy: AssetsOrderBy;
};

const getAriaLabel = (columnName: string, option: AssetsOrderBy): string => {
  const { columnCode, direction } = option;

  if (columnCode === 'name') {
    return `Sort by name, ${direction.toLowerCase()}`;
  }

  return `Sort by ${columnName}`;
};

export const AssetListingOrdering: FC<AssetListingOrderingProps> = ({
  onOrderingChange,
  orderBy,
}) => {
  const dispatch = useDispatch();

  const hasPotentiallyUnsortedAssets = useSelector((s) =>
    s.assetLibraryApp.potentiallyUnsortedBy.has(orderBy.columnCode),
  );

  return (
    <Row alignY="center" spacing={Spacing.XS}>
      <Column>
        <Label color={colorTextDefault} size={LabelSize.L}>
          Sort by:
        </Label>
      </Column>

      <Column>
        <Menu>
          <Menu.Trigger>
            {(ref, triggerProps) => (
              <QuinaryButton
                tooltipText=""
                ref={ref as RefObject<HTMLButtonElement>}
                {...triggerProps}
              >
                <SrOnly>Sort by:</SrOnly>
                {hasPotentiallyUnsortedAssets ? 'Unsorted' : getColumnName(orderBy)}
              </QuinaryButton>
            )}
          </Menu.Trigger>

          <Menu.List>
            {orderingOptions.map((option, i) => {
              const columnName = getColumnName(option);

              return (
                <Menu.Item
                  aria-label={getAriaLabel(columnName, option)}
                  key={i}
                  menuItemState="default"
                  label={columnName}
                  id={i.toString()}
                  onAction={() => {
                    dispatch(updateAssetListingOrdering(option));
                    onOrderingChange(option);
                  }}
                />
              );
            })}
          </Menu.List>
        </Menu>
      </Column>
    </Row>
  );
};

AssetListingOrdering.displayName = 'AssetListingOrdering';
