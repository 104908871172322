import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect, MultiSelectProps } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { FeatureState } from '../../../../../../_shared/components/FeatureStateTag.tsx';
import { DataUiCollection } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isDefined } from '../../../../../../_shared/utils/filter/isDefined.ts';
import { ListingFilterCategory } from '../../../../shared/components/filter/ListingFilterCategory.tsx';

type ListingFilterCategorySelectorProps<TOption extends ISelectItem<TOption>> = {
  readonly ariaLabel?: string;
  readonly collection: DataUiCollection;
  readonly disabled?: boolean;
  readonly expandedKeys?: readonly Uuid[] | ReadonlySet<Uuid>;
  readonly featureState?: FeatureState;
  readonly onChange: MultiSelectProps<TOption>['onSelectionChange'];
  readonly onExpandedChange?: (keys: ReadonlySet<Uuid>) => void;
  readonly onSearchTextChange?: (newText: string) => void;
  readonly options: MultiSelectProps<TOption>['items'];
  readonly placeholder: string;
  readonly renderOption?: MultiSelectProps<TOption>['renderMenuOption'];
  readonly renderSelectedOption?: MultiSelectProps<TOption>['renderSelectedOption'];
  readonly selectedOptionIds?: MultiSelectProps<TOption>['selectedItemIds'];
  readonly title: string;
  readonly titleTooltipText?: string;
};

export const ListingFilterCategorySelector = <TOptionSelector extends ISelectItem<TOptionSelector>>(
  props: ListingFilterCategorySelectorProps<TOptionSelector>,
) => {
  const expandedProps =
    isDefined(props.expandedKeys) && isDefined(props.onExpandedChange)
      ? {
          expandedKeys: new Set(props.expandedKeys),
          onExpandedChange: props.onExpandedChange,
        }
      : {};

  return props.options.length ? (
    <ListingFilterCategory
      collectionName={props.collection}
      featureState={props.featureState}
      title={props.title}
    >
      <MultiSelect
        ariaLabel={props.ariaLabel}
        inputState={props.disabled ? InputState.Disabled : InputState.Default}
        items={props.options}
        onInputChange={props.onSearchTextChange}
        onSelectionChange={props.onChange}
        placeholder={props.placeholder}
        renderMenuOption={props.renderOption}
        renderSelectedOption={props.renderSelectedOption}
        selectedItemIds={props.selectedOptionIds}
        tooltipText={props.titleTooltipText}
        {...expandedProps}
      />
    </ListingFilterCategory>
  ) : null;
};
