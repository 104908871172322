import { Redirect, Switch } from 'react-router';
import {
  CalendarRoute,
  ContentAssignedToYouRoute,
  ContentRecentlyEditedByYouRoute,
  MissionControlCalendarRoute,
  MissionControlProjectOverviewRoute,
  MissionControlQuickstartRoute,
  MissionControlRootRoute,
  MissionControlYourWorkRoute,
  ProjectOverviewRoute,
  QuickstartRoute,
  YourContentRoute,
  YourTasksRoute,
} from '../../../_shared/constants/routePaths.ts';

export const RedirectHomeRoutesToMissionControl = () => (
  <Switch>
    <Redirect from={ProjectOverviewRoute} to={MissionControlProjectOverviewRoute} />
    <Redirect from={CalendarRoute} to={MissionControlCalendarRoute} />
    <Redirect from={YourContentRoute} to={MissionControlYourWorkRoute} />
    <Redirect from={ContentAssignedToYouRoute} to={MissionControlYourWorkRoute} />
    <Redirect from={ContentRecentlyEditedByYouRoute} to={MissionControlYourWorkRoute} />
    <Redirect from={YourTasksRoute} to={MissionControlYourWorkRoute} />
    <Redirect from={QuickstartRoute} to={MissionControlQuickstartRoute} />
    <Redirect to={MissionControlRootRoute} />
  </Switch>
);
