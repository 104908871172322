import { List } from 'immutable';
import React, { useEffect } from 'react';
import { rememberScrollId } from '../../../../_shared/actions/sharedActions.ts';
import { CreateAutoScrollId } from '../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { discussionSidebarOpened } from '../../../itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { focusCommentThread } from '../../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import {
  getAreAnyCommentThreadsInDiscussionSidebar,
  getOrderedInlineComments,
} from '../../../itemEditor/features/ContentItemEditing/selectors/inlineCommentSelectors.ts';
import { CreateContentGroupTabsId } from '../../../itemEditor/features/ContentItemEditing/utils/contentGroupTabsId.ts';
import { DummyFirstRelevantCommentThreadId } from '../../../itemEditor/models/comments/CommentThreads.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { isThreadResolved } from '../../../itemEditor/utils/commentUtils.ts';
import { IContentGroup } from '../../content/models/contentTypeElements/types/ContentGroup.ts';

type Props = {
  readonly redirectPath: string;
  readonly commentThreadId: Uuid;
};

export const EnsureFocusedCommentThread: React.FC<React.PropsWithChildren<Props>> = ({
  commentThreadId,
  redirectPath,
}) => {
  const dispatch = useDispatch();

  const loadedContentItemTypes = useSelector((state) => state.contentApp.loadedContentItemTypes);
  const contentTypeGroups = useSelector<List<IContentGroup> | null>((state) => {
    const editedContentItem = state.contentApp.editedContentItem;
    if (!editedContentItem) {
      return null;
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    return editedContentItemType?.contentGroups ?? Immutable.List();
  });

  const selectedContentGroupId = useSelector((state) => {
    const editedContentItem = state.contentApp.editedContentItem;
    if (!editedContentItem || !contentTypeGroups) {
      return null;
    }

    const contentItemId = editedContentItem.id;

    const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(contentItemId);
    return getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, contentTypeGroups, state);
  });

  const firstInlineUnresolvedCommentThread = useSelector((state) => {
    const contentGroupInlineCommentThreads = getOrderedInlineComments(
      state,
      selectedContentGroupId,
    );
    return contentGroupInlineCommentThreads.find(
      (commentThread) => !isThreadResolved(commentThread.commentThread),
    );
  });

  const areAnyCommentThreadsInDiscussionSidebar = useSelector(
    getAreAnyCommentThreadsInDiscussionSidebar,
  );

  const shouldShowFirstRelevantCommentThread =
    commentThreadId === DummyFirstRelevantCommentThreadId;

  useEffect(() => {
    if (shouldShowFirstRelevantCommentThread) {
      if (firstInlineUnresolvedCommentThread) {
        const firstInlineUnresolvedCommentThreadId: Uuid =
          firstInlineUnresolvedCommentThread.commentThread.id;
        const scrollId = CreateAutoScrollId.forCommentThread(firstInlineUnresolvedCommentThreadId);

        dispatch(
          rememberScrollId({
            path: redirectPath,
            scrollId,
          }),
        );
        dispatch(focusCommentThread(firstInlineUnresolvedCommentThreadId));
      } else if (areAnyCommentThreadsInDiscussionSidebar) {
        dispatch(discussionSidebarOpened());
      }
    } else {
      const scrollId = CreateAutoScrollId.forCommentThread(commentThreadId);
      dispatch(
        rememberScrollId({
          path: redirectPath,
          scrollId,
        }),
      );
      dispatch(focusCommentThread(commentThreadId));
    }
  }, [
    areAnyCommentThreadsInDiscussionSidebar,
    commentThreadId,
    firstInlineUnresolvedCommentThread,
    redirectPath,
    shouldShowFirstRelevantCommentThread,
  ]);

  return null;
};
