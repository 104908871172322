import { Action } from '../../../../@types/Action.type.ts';
import {
  ChangeWorkflowStep_EnableUnpublishingSchedule_Changed,
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ScheduledToUnpublish_Changed,
} from '../constants/changeWorkflowStepActionTypes.ts';

const defaultState = false;

export function enableScheduledUnpublishing(state = defaultState, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return !!action.payload.changeWorkflowStepModalData.scheduledToUnpublishAt;

    case ChangeWorkflowStep_EnableUnpublishingSchedule_Changed:
      return action.payload.enableScheduledUnpublishing;

    case ChangeWorkflowStep_ScheduledToUnpublish_Changed:
      return action.payload.scheduledToUnpublishAt ? true : state;

    default:
      return state;
  }
}
