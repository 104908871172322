import React, { useEffect, useState, useCallback } from 'react';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { createAssignedContentItem } from '../../../../../data/models/listingContentItems/IAssignedListingContentItem.ts';
import { IListingContentItemServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  ListingRequestOptionsModel,
  ListingServerResponseModel,
} from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { getOrderBy } from '../../../../contentInventory/content/models/filter/contentItemFilterUtils.ts';
import { entityListWidgetDisplayCount } from '../../shared/constants/widgetConstants.ts';
import {
  useTrackWidgetDetailItemClick,
  useTrackWidgetOverviewItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../shared/hooks/tracking.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { ItemsAssignedToYouWidget } from '../components/ItemsAssignedToYouWidget.tsx';
import { AssignedContentItemsOrderByColumnCode } from '../constants/assignedContentItemsOrderByColumnCode.ts';

export const ItemsAssignedToYouWidgetContainer: React.FC = () => {
  const [numberOfItemsToFetch, setNumberOfItemsToFetch] = useState(entityListWidgetDisplayCount);

  const [orderBy, setOrderBy] = useState<OrderBy<AssignedContentItemsOrderByColumnCode>>({
    columnCode: AssignedContentItemsOrderByColumnCode.LastModified,
    direction: OrderByDirection.Descending,
  });

  const fetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal?: AbortSignal) =>
      itemsAssignedToYouFetcher(orderBy, requestOptions, abortSignal),
    [orderBy],
  );

  const {
    fetchInit,
    fetchMore,
    state: itemsState,
    data: itemsAssignedToYou,
  } = useWidgetListingDataFetcher(fetcher, createAssignedContentItem);

  useEffect(() => fetchInit(numberOfItemsToFetch).cancel, [fetchInit, numberOfItemsToFetch]);

  const changeOrderBy = (
    newOrderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
    requiredNumberOfItems: number,
  ) => {
    setOrderBy(newOrderBy);
    setNumberOfItemsToFetch(requiredNumberOfItems);
  };

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.ItemsAssignedToYou,
  );
  const trackWidgetShowMoreButtonClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.ItemsAssignedToYou,
  );
  const trackWidgetDetailItemClick = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.ItemsAssignedToYou,
  );

  return (
    <ItemsAssignedToYouWidget
      fetchMore={fetchMore}
      items={itemsAssignedToYou}
      itemsState={itemsState}
      onChangeOrderBy={changeOrderBy}
      onDetailItemClick={trackWidgetDetailItemClick}
      onItemClick={trackWidgetOverviewItemClick}
      onShowMoreClick={trackWidgetShowMoreButtonClick}
      orderBy={orderBy}
    />
  );
};

const itemsAssignedToYouFetcher = (
  orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
  requestOptionsModel: ListingRequestOptionsModel,
  abortSignal?: AbortSignal,
): Promise<ListingServerResponseModel<IListingContentItemServerModel>> =>
  repositoryCollection.contentItemRepository.getItemsAssignedToCurrentUser(
    getOrderBy(orderBy),
    requestOptionsModel,
    abortSignal,
  );
