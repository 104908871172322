import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement, placements } from '@kontent-ai/component-library/types';
import classNames from 'classnames';
import PropTypes, { Validator } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { singleOrDoubleClick } from '../../utils/clickUtils.ts';
import {
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import {
  ScrollTableColumnSize,
  translateColumnSizeToClassSuffix,
} from './types/scrollTableTypes.ts';

interface IScrollTableCellWrappedByLinkProps {
  readonly allowNavigationWithOnClick?: boolean;
  readonly dataUiAttribute?: ObjectWithDataAttribute;
  readonly children: React.ReactNode;
  readonly hasBalloon?: boolean;
  readonly isGrowing?: boolean;
  readonly linkPath: string;
  readonly onItemClick?: (shiftPressed: boolean) => void;
  readonly onItemDoubleClick?: () => void;
  readonly openInNewWindow?: boolean;
  readonly size: ScrollTableColumnSize;
  readonly title?: string;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText?: string;
}

const propTypes: PropTypeMap<IScrollTableCellWrappedByLinkProps> = {
  allowNavigationWithOnClick: PropTypes.bool,
  dataUiAttribute: PropTypes.object,
  children: PropTypes.node.isRequired,
  hasBalloon: PropTypes.bool,
  isGrowing: PropTypes.bool,
  linkPath: PropTypes.string.isRequired,
  onItemClick: PropTypes.func,
  onItemDoubleClick: PropTypes.func,
  openInNewWindow: PropTypes.bool,
  size: PropTypes.number.isRequired as Validator<ScrollTableColumnSize>,
  title: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(placements),
  tooltipText: PropTypes.string,
};

export const ScrollTableCellWrappedByLink: React.FC<IScrollTableCellWrappedByLinkProps> = ({
  allowNavigationWithOnClick,
  children,
  dataUiAttribute,
  hasBalloon,
  isGrowing,
  linkPath,
  onItemClick,
  onItemDoubleClick,
  openInNewWindow,
  size,
  title,
  tooltipPlacement,
  tooltipText,
  ...otherProps
}) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    if (event.ctrlKey) {
      // perform browser-default behaviour
      return;
    }

    if (!allowNavigationWithOnClick) {
      event.preventDefault();
    }

    event.stopPropagation();

    // Capture shiftKey - singleOrDoubleClick uses timeouts and it removes some event properties
    const shiftKey = event.shiftKey;
    const click = singleOrDoubleClick<HTMLAnchorElement>(
      () => onItemClick?.(shiftKey),
      onItemDoubleClick,
      true,
    );

    click(event);
  };

  const handleDisabledClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    if (!allowNavigationWithOnClick) {
      event.preventDefault();
    }
  };

  const link = (
    <LinkWrapper
      isDisabled={!onItemClick && !allowNavigationWithOnClick}
      linkPath={linkPath}
      onClick={onItemClick ? handleClick : handleDisabledClick}
      openInNewWindow={openInNewWindow}
      {...otherProps}
    >
      {children}
    </LinkWrapper>
  );

  const classSizeSuffix = translateColumnSizeToClassSuffix(size);

  return (
    <div
      title={title}
      data-hj-suppress=""
      className={classNames('scroll-table__cell', {
        'scroll-table__cell-item--with-balloon': hasBalloon,
        [`scroll-table__column--growing-${classSizeSuffix}`]: isGrowing,
        [`scroll-table__column--${classSizeSuffix}`]: !isGrowing,
      })}
      role="cell"
      {...dataUiAttribute}
    >
      {tooltipText ? (
        <Tooltip placement={tooltipPlacement ?? 'top'} tooltipText={tooltipText}>
          {link}
        </Tooltip>
      ) : (
        link
      )}
    </div>
  );
};

ScrollTableCellWrappedByLink.displayName = 'ScrollTableCellWrappedByLink';
ScrollTableCellWrappedByLink.propTypes = propTypes;

interface ILinkWrapper {
  readonly children: React.ReactNode;
  readonly isDisabled: boolean;
  readonly linkPath: string;
  readonly onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  readonly openInNewWindow?: boolean;
}

const LinkWrapper = React.forwardRef<HTMLAnchorElement, ILinkWrapper>(
  ({ children, isDisabled, linkPath, onClick, openInNewWindow, ...otherProps }, forwardedRef) => {
    return (
      <Link
        className={classNames('scroll-table__cell-item', {
          'scroll-table__cell-item--is-link': !isDisabled,
          'scroll-table__cell-item--is-disabled-link': isDisabled,
        })}
        onClick={onClick}
        target={openInNewWindow ? '_blank' : undefined}
        rel={openInNewWindow ? 'noopener noreferrer' : undefined}
        to={linkPath}
        ref={forwardedRef}
        // Setting tabIndex to -1 removes it from the tabbing order, as the whole row is focusable
        tabIndex={-1}
        {...getDataUiElementAttribute(DataUiElement.CellItemLink)}
        {...otherProps}
      >
        {children}
      </Link>
    );
  },
);

LinkWrapper.displayName = 'LinkWrapper';
