import {
  px,
  spacingElementEdgeHorizontal,
  spacingMainLayoutRight,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import {
  FullScreenModalDialog,
  IFullScreenModalDialogProps,
} from '../../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { HotkeysHandler } from '../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import { ImageLimitsConfig } from '../../../../../../_shared/utils/assets/assetValidationUtils.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { preventDefault } from '../../../../../../_shared/utils/func/functionalTools.ts';
import { AssetLibrary } from '../../../containers/AssetListing/AssetLibrary.tsx';
import { AssetsOrderBy } from '../../../types/orderBy.type.ts';

export interface IAssetSelectorDialogOwnProps
  extends Pick<IFullScreenModalDialogProps, 'onClose' | 'onReturnFocus'> {
  readonly primaryButtonText: string;
  readonly showImagesOnly: boolean;
  readonly titleBarText: string;
}

export interface IAssetSelectorDialogProps extends IAssetSelectorDialogOwnProps {
  readonly clearAssetSelection: () => void;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly imageLimits?: ImageLimitsConfig;
  readonly isPlacingEnabled: boolean;
  readonly onInit: (orderBy: AssetsOrderBy) => void;
  readonly onAssetClick: (assetId: Uuid) => void;
  readonly onAssetDoubleClick: (assetId: Uuid) => void;
  readonly onPlace: () => void;
  readonly selectAssets: (assets: ReadonlyArray<Uuid>) => void;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly toggleAssetSelection: (assetId: Uuid, shiftPressed: boolean) => void;
}

const StyledHotkeysHandler = styled(HotkeysHandler)`
  height: 100%;
  margin-left: ${px(-1 * spacingElementEdgeHorizontal)};
  margin-right: ${px(-1 * spacingMainLayoutRight)};
`;

export const AssetSelectorDialog: React.FC<IAssetSelectorDialogProps> = ({
  clearAssetSelection,
  deselectAssets,
  imageLimits,
  isPlacingEnabled,
  onAssetClick,
  onAssetDoubleClick,
  onClose,
  onInit,
  onPlace,
  onReturnFocus,
  primaryButtonText,
  selectAssets,
  selectedAssets,
  showImagesOnly,
  titleBarText,
  toggleAssetSelection,
}) => {
  const onSubmit = () => {
    if (isPlacingEnabled) {
      onPlace();
    }
  };

  return (
    <FullScreenModalDialog
      bodyPadding="none"
      cancelAction={{
        onClick: onClose,
        tooltipText: 'Cancel',
        tooltipShortcuts: ShortcutSymbols.Escape,
        ...getDataUiActionAttribute(DataUiAction.Cancel),
      }}
      headline={titleBarText}
      isDismissable
      isOpen
      onClose={onClose}
      onReturnFocus={onReturnFocus}
      primaryAction={{
        disabled: !isPlacingEnabled,
        onClick: onSubmit,
        text: primaryButtonText,
        tooltipText: isPlacingEnabled ? primaryButtonText : undefined,
        tooltipShortcuts: isPlacingEnabled ? ShortcutSymbols.Enter : undefined,
        ...getDataUiActionAttribute(DataUiAction.Insert),
      }}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <StyledHotkeysHandler
        className="canvas__workspace canvas__workspace--in-modal"
        handlers={{
          onEnter: compose(onSubmit, preventDefault),
        }}
      >
        <AssetLibrary
          allowSelect
          clearAssetSelection={clearAssetSelection}
          deselectAssets={deselectAssets}
          imageLimits={imageLimits}
          onAssetClick={onAssetClick}
          onAssetDoubleClick={onAssetDoubleClick}
          onInit={onInit}
          selectAssets={selectAssets}
          selectedAssets={selectedAssets}
          shouldAutoSelectUploadedAssets
          showImagesOnly={showImagesOnly}
          toggleAssetSelection={toggleAssetSelection}
        />
      </StyledHotkeysHandler>
    </FullScreenModalDialog>
  );
};

AssetSelectorDialog.displayName = 'AssetSelectorDialog';
