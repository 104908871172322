import { IconButton } from '@kontent-ai/component-library/Button';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { copyToClipboardDelay } from '../../constants/uiConstants.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type ReadCodenameProps = {
  readonly composedCodename: string | null;
  readonly disabledEditingMessage?: string | null;
  readonly footnoteMessage?: string | null;
  readonly hideTitle?: boolean;
  readonly isEditable?: boolean;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onEdit: () => void;
};

const propTypes: PropTypeMap<ReadCodenameProps> = {
  composedCodename: PropTypes.string,
  disabledEditingMessage: PropTypes.string,
  footnoteMessage: PropTypes.string,
  hideTitle: PropTypes.bool,
  isEditable: PropTypes.bool,
  onCopied: PropTypes.func,
  onCopyCompleted: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
};

export const ReadCodename: React.FC<ReadCodenameProps> = ({
  composedCodename,
  disabledEditingMessage,
  footnoteMessage,
  hideTitle,
  isEditable,
  onCopied,
  onCopyCompleted,
  onEdit,
}) => {
  const hiddenContainerRef = useRef<HTMLDivElement>(null);
  const delayedAction = useRef<ICancellablePromise | null>(null);

  const deferredOnCopyCompleted = (): void => {
    delayedAction.current = delay(copyToClipboardDelay)
      .then(() => {
        onCopyCompleted?.();
      })
      .catch(swallowCancelledPromiseError);
  };

  const onSuccess = (): void => {
    onCopied?.();
    deferredOnCopyCompleted();
  };

  useEffect(() => {
    return () => {
      delayedAction.current?.cancel();
    };
  }, []);

  return (
    <div>
      <SrOnly ref={hiddenContainerRef} />
      <Clipboard
        auxiliaryElement={
          isEditable && (
            <IconButton
              buttonState={disabledEditingMessage ? 'disabled' : 'default'}
              buttonStyle="secondary"
              iconName="Edit"
              onClick={onEdit}
              ariaLabel="Edit code name."
              size="medium"
              tooltipText={disabledEditingMessage ?? 'Edit'}
              tooltipPlacement="top-end"
              {...getDataUiActionAttribute(DataUiAction.Edit)}
            />
          )
        }
        caption={footnoteMessage ?? undefined}
        containerRef={hiddenContainerRef}
        label={hideTitle ? undefined : 'Codename'}
        onCopyCompleted={onCopyCompleted}
        onError={deferredOnCopyCompleted}
        onSuccess={onSuccess}
        value={composedCodename || ''}
        {...getDataUiInputAttribute(DataUiInput.Codename)}
      />
    </div>
  );
};

ReadCodename.displayName = 'ReadCodename';
ReadCodename.propTypes = propTypes;
