import { Card, cardBorderRadius } from '@kontent-ai/component-library/Card';
import { Paper, PaperLevel, paperBorderRadius } from '@kontent-ai/component-library/Paper';
import { Spacing, gridUnit, px, spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { usePrevious } from '@kontent-ai/hooks';
import PropTypes from 'prop-types';
import React, { RefCallback, useCallback, useContext, useEffect, useRef } from 'react';
import { Instance as TippyInstance } from 'tippy.js';
import { ArrowSize } from '../../../../../../../component-library/components/Dialogs/Popover/ArrowSizeEnum.ts';
import { IPopoverDialogProps } from '../../../../../../../component-library/components/Dialogs/Popover/components/PopoverDialog.tsx';
import { toolbarArrowSize } from '../../../../../../../component-library/components/Dialogs/Popover/tokens.ts';
import {
  IAdjustTippyOptions,
  usePopover,
} from '../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import {
  GetPopperOffset,
  createGetPopperOffset,
  getPopperOffset,
} from '../../../../../../../component-library/components/Dialogs/Popover/utils/placementUtils.ts';
import {
  createAddArrow,
  createAddFlipping,
  createAddInlinePositioning,
  createAddOffset,
  createAddPreventOverflow,
} from '../../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { usePreventOverflowFromScrollContainer } from '../../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { WebSpotlightContext } from '../../../../../webSpotlight/context/WebSpotlightContext.tsx';
import { getStopEventsConsumedByDraftJs } from '../../../../editorCore/utils/stopEventsConsumedByDraftJs.ts';

function useAddUpdateOnFloatingEditorPositionChange(): IAdjustTippyOptions {
  const instanceRef = useRef<TippyInstance | null>(null);

  const registerInstance: IAdjustTippyOptions = useCallback(
    (tippyOptions) => ({
      ...tippyOptions,
      onMount: (instance) => {
        instanceRef.current = instance;
      },
      onDestroy: () => {
        instanceRef.current = null;
      },
    }),
    [],
  );

  useEffect(() => {
    instanceRef.current?.popperInstance?.forceUpdate();
  }, []);

  const { floatingEditorPosition } = useContext(WebSpotlightContext);
  const previousFloatingEditorPosition = usePrevious(floatingEditorPosition);
  useEffect(() => {
    if (previousFloatingEditorPosition !== floatingEditorPosition) {
      instanceRef.current?.popperInstance?.forceUpdate();
    }
  }, [previousFloatingEditorPosition, floatingEditorPosition]);

  return registerInstance;
}

interface IInlineContentWithDialogProps {
  readonly isInfo?: boolean;
  readonly renderContent: (ref: RefCallback<HTMLElement>) => React.ReactElement;
  readonly renderDialog: () => React.ReactNode;
  readonly onClose?: IPopoverDialogProps['onClose'];
}

const propTypes: PropTypeMap<IInlineContentWithDialogProps> = {
  isInfo: PropTypes.bool,
  renderContent: PropTypes.func.isRequired,
  renderDialog: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

const getInfoPopperOffset: GetPopperOffset = createGetPopperOffset(
  toolbarArrowSize,
  Spacing.S,
  paperBorderRadius,
);

export const InlineContentWithDialog: React.FC<IInlineContentWithDialogProps> = ({
  isInfo,
  renderContent,
  renderDialog,
  onClose,
}) => {
  const addUpdateOnFloatingEditorPositionChange = useAddUpdateOnFloatingEditorPositionChange();

  const targetRef = useRef<HTMLElement>(null);
  const arrowSize = isInfo ? ArrowSize.S : ArrowSize.M;

  const popoverProtectiveArea = spacingPopupDistance + arrowSize / 2;
  const { preventOverflowModifier, boundaryProps } =
    usePreventOverflowFromScrollContainer(popoverProtectiveArea);

  const placement = 'top';
  const adjustTippyOptions: IAdjustTippyOptions = compose(
    createAddInlinePositioning(targetRef),
    createAddFlipping(boundaryProps),
    createAddPreventOverflow(preventOverflowModifier.options),
    createAddOffset(isInfo ? getInfoPopperOffset : getPopperOffset),
    createAddArrow(isInfo ? Spacing.S : cardBorderRadius),
    addUpdateOnFloatingEditorPositionChange,
  );

  const dialog = renderDialog();

  const { Popover, targetProps, popoverProps } = usePopover({
    adjustTippyOptions,
    __disabledFocusLock: true,
    isOpen: !!dialog,
    placement,
    onClose,
    shouldCloseOnInteractOutside: (element) => !targetRef.current?.contains(element),
    shouldCloseOnBlur: false,
    targetRef,
  });

  const props = {
    ...popoverProps,
    animatedPopoverDialogProps: {
      ...popoverProps.animatedPopoverDialogProps,
      ...getStopEventsConsumedByDraftJs(false),
    },
  };

  return (
    <>
      {renderContent(targetProps.ref)}
      {dialog && (
        <Popover {...props} allowAnimation={false} arrowSize={arrowSize}>
          {isInfo ? (
            <Paper
              component="section"
              level={PaperLevel.Popout}
              css={`max-width: calc(100vw - ${px(2 * popoverProtectiveArea)})`}
            >
              {dialog}
            </Paper>
          ) : (
            <Card
              cardLabel="Link detail"
              component="section"
              level={PaperLevel.Popout}
              css={`
                min-width: ${px(46 * gridUnit)};
                max-width: calc(100vw - ${px(2 * popoverProtectiveArea)});
              `}
            >
              <Card.Body>{dialog}</Card.Body>
            </Card>
          )}
        </Popover>
      )}
    </>
  );
};

InlineContentWithDialog.displayName = 'InlineContentWithDialog';
InlineContentWithDialog.propTypes = propTypes;
