import React from 'react';
import {
  UnpublishingValidationResult,
  validateUnpublishingConfiguration,
} from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/unpublishingValidationUtils.ts';
import { useDispatch } from '../../../../hooks/useDispatch.ts';
import { useSelector } from '../../../../hooks/useSelector.ts';
import { DataUiWorkflowAction } from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  changeScheduleToUnpublishMethod,
  scheduledToUnpublishChanged,
} from '../../actions/changeWorkflowStepModalActions.ts';
import { ScheduleMethodSection } from '../../components/Elements/ScheduleMethodSection.tsx';
import { ScheduleMethod } from '../../constants/scheduleMethods.ts';
import {
  DateIsInPastError,
  UnpublishDateBeforePublishDateError,
} from '../../constants/uiConstants.ts';
import { IChangeWorkflowStepModalData } from '../../reducers/IChangeWorkflowStepModalData.ts';

export const UnpublishMethodSection: React.FC = () => {
  const dispatch = useDispatch();

  const error = useSelector((s) => getError(s.contentApp.changeWorkflowStepModalData));
  const scheduleMethod = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduleMethod,
  );
  const scheduledToUnpublishAt = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledToUnpublishAt,
  );
  const scheduledUnpublishDisplayTimeZone = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledUnpublishDisplayTimeZone,
  );
  const scheduledToPublishAt = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledToPublishAt,
  );
  const allowUnpublishNow = !!scheduledToPublishAt;
  const options = [
    {
      id: ScheduleMethod.Now,
      label: 'Unpublish now',
      disabled: allowUnpublishNow,
      tooltipText: allowUnpublishNow
        ? 'If you need to unpublish the item, cancel scheduled publishing first.'
        : undefined,
      dataUiAttribute: DataUiWorkflowAction.SelectNow,
    },
    {
      id: ScheduleMethod.Schedule,
      label: 'Schedule unpublish',
      dataUiAttribute: DataUiWorkflowAction.SelectSchedule,
    },
  ];

  return (
    <ScheduleMethodSection
      onScheduleMethodChange={(method: ScheduleMethod) =>
        dispatch(changeScheduleToUnpublishMethod(method))
      }
      onScheduleChange={(scheduledTo: string | null, timeZoneId: string) =>
        dispatch(scheduledToUnpublishChanged(scheduledTo, timeZoneId))
      }
      scheduledAt={scheduledToUnpublishAt}
      scheduleDisplayTimeZone={scheduledUnpublishDisplayTimeZone}
      selectedScheduleMethod={scheduleMethod}
      scheduledToMinValue={scheduledToPublishAt}
      invalidDateError={error}
      options={options}
      sectionTitle="Unpublishing"
    />
  );
};

UnpublishMethodSection.displayName = 'UnpublishMethodSection';

const getError = (changeWorkflowStepModalData: IChangeWorkflowStepModalData) => {
  const unpublishingValidationResult = validateUnpublishingConfiguration(
    changeWorkflowStepModalData,
  );

  switch (unpublishingValidationResult) {
    case UnpublishingValidationResult.UnpublishDateIsInPast: {
      return DateIsInPastError;
    }
    case UnpublishingValidationResult.UnpublishDateIsSameOrBeforePublishDate: {
      return UnpublishDateBeforePublishDateError;
    }
    default: {
      return undefined;
    }
  }
};
