export const ChangeWorkflowStep_ShowAllContributors_Selected =
  'ChangeWorkflowStep_ShowAllContributors_Selected';
export const ChangeWorkflowStep_ShowAvailableContributors_Selected =
  'ChangeWorkflowStep_ShowAvailableContributors_Selected';
export const ChangeWorkflowStep_SelectedContributors_Changed =
  'ChangeWorkflowStep_SelectedContributors_Changed';
export const ChangeWorkflowStep_DueDate_Changed = 'ChangeWorkflowStep_DueDate_Changed';
export const ChangeWorkflowStep_Note_Changed = 'ChangeWorkflowStep_Note_Changed';
export const ChangeWorkflowStep_ScheduledToPublish_Changed =
  'ChangeWorkflowStep_ScheduledToPublish_Changed';
export const ChangeWorkflowStep_EnableUnpublishingSchedule_Changed =
  'ChangeWorkflowStep_EnableUnpublishingSchedule_Changed';
export const ChangeWorkflowStep_ScheduledToUnpublish_Changed =
  'ChangeWorkflowStep_ScheduledToUnpublish_Changed';
export const ChangeWorkflowStep_ScheduleToPublishMethod_Changed =
  'ChangeWorkflowStep_ScheduleToPublishMethod_Changed';
export const ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed =
  'ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed';

export const ChangeWorkflowStep_NotificationBar_Hidden =
  'ChangeWorkflowStep_NotificationBar_Hidden';
export const ChangeWorkflowStep_WorkflowStep_Selected = 'ChangeWorkflowStep_WorkflowStep_Selected';
export const ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set =
  'ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set';
