import { CompiledTypeElementType } from '../../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { AssetFileTypeOption } from '../../../applications/contentInventory/content/models/assetFileTypeOptions.ts';
import { ValidationRegex } from '../../../applications/contentModels/shared/models/elements/types/TypeElementData.ts';
import {
  TableBlockCategoryFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  TopLevelBlockCategoryFeature,
} from '../../../applications/richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import {
  IAssetReferenceServerModel,
  IModularContentItemReferenceServerModel,
} from '../elementServerModels.type.ts';
import {
  ContentTypeBaseServerModel,
  ContentTypeBaseServerModelWithElements,
  ContentTypeElementBaseServerModel,
  ContentTypeElementServerModel,
} from './sharedContentTypeModels.type.ts';

export type ContentTypesServerModel = {
  readonly data: ContentTypeServerModel[];
  readonly limit: number;
  readonly skip: number;
  readonly total: number;
};

export interface ICompiledContentTypesServerModel {
  readonly data: ReadonlyArray<CompiledContentTypeServerModel>;
}

export interface ICompiledContentTypesByIdsQueryServerModel {
  readonly typeIds: UuidArray;
}

export type ContentTypeServerModel = ContentTypeBaseServerModelWithElements & {
  readonly contentGroups: ContentTypeContentGroupServerModel[];
};

export type CompiledContentTypeServerModel = ContentTypeBaseServerModel & {
  readonly contentElements: CompiledContentTypeElementServerModel[];
  readonly contentGroups?: CompiledContentTypeContentGroupServerModel[];
};

export type CreateNewContentTypeServerModel = {
  readonly contentElements: ContentTypeElementServerModel[];
  readonly contentGroups: ContentTypeContentGroupServerModel[];
  readonly name: string;
};

export type CompiledContentTypeElementServerModel = ContentTypeElementBaseServerModel & {
  readonly type: CompiledTypeElementType;
};

export type ContentTypeContentGroupServerModel = {
  readonly id: Uuid;
  readonly name: string;
  readonly codeName: string | null;
};

export type CompiledContentTypeContentGroupServerModel = ContentTypeContentGroupServerModel;

export type DefaultValueServerModelExtension<TValue> = {
  readonly global: {
    readonly value: TValue;
  };
} | null;

export type AssetTypeElementServerModelExtension = {
  readonly assetFileType: AssetFileTypeOption;
  readonly default: DefaultValueServerModelExtension<ReadonlyArray<IAssetReferenceServerModel>>;
  readonly fileSize: number | null;
  readonly isRequired: boolean;
  readonly maxHeight: number | null;
  readonly maxItems: number | null;
  readonly maxWidth: number | null;
  readonly minHeight: number | null;
  readonly minItems: number | null;
  readonly minWidth: number | null;
};

export type GuidelinesTypeElementServerModelExtension = {
  readonly guidelinesHtml?: string;
  readonly guidelinesJson?: string;
};

export type MultipleChoiceOptionServerModel = {
  readonly codeName: string | null;
  readonly id: Uuid;
  readonly name: string;
};

export enum MultipleChoiceModeServerModel {
  Multiple = 'multiple',
  Single = 'single',
}

export type MultipleChoiceTypeElementServerModelExtension = {
  readonly default: DefaultValueServerModelExtension<ReadonlyArray<Uuid>>;
  readonly mode: MultipleChoiceModeServerModel;
  readonly options: MultipleChoiceOptionServerModel[];
};

export enum LinkedItemsRelationTypeServerModel {
  LinkedItems = 'linked-items',
  Subpages = 'subpages',
}

export type LinkedItemsTypeElementServerModelExtension = {
  readonly allowedTypes: UuidArray;
  readonly default: DefaultValueServerModelExtension<
    ReadonlyArray<IModularContentItemReferenceServerModel>
  >;
  readonly maxItems: number | null;
  readonly minItems: number | null;
  readonly relationType: LinkedItemsRelationTypeServerModel;
};

export type TextTypeElementServerModelExtension = {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
  readonly default: DefaultValueServerModelExtension<string>;
  readonly maxChars: number | null;
  readonly maxWords: number | null;
  readonly validationRegex: ValidationRegex | null;
};

export type RichTextElementServerModelExtension = {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
  readonly allowedBlocks: ReadonlyArray<TopLevelBlockCategoryFeature>;
  readonly allowedItemLinkTypes: UuidArray;
  readonly allowedTextBlocks: ReadonlyArray<TextBlockTypeFeature>;
  readonly allowedTextFormatting: ReadonlyArray<TextFormattingFeature>;
  readonly allowedTableBlocks: ReadonlyArray<TableBlockCategoryFeature>;
  readonly allowedTableTextBlocks: ReadonlyArray<TextBlockTypeFeature>;
  readonly allowedTableTextFormatting: ReadonlyArray<TextFormattingFeature>;
  readonly allowedTypes: UuidArray;
  readonly fileSize: number | null;
  readonly maxHeight: number | null;
  readonly maxWidth: number | null;
  readonly minHeight: number | null;
  readonly minWidth: number | null;
  readonly richStringImageType: AssetFileTypeOption;
  readonly maxChars: number | null;
  readonly maxWords: number | null;
};

export type TaxonomyTypeElementServerModelExtension = {
  readonly default: DefaultValueServerModelExtension<UuidArray>;
  readonly maxItems: number | null;
  readonly minItems: number | null;
  readonly taxonomyGroupId: Uuid | null;
};

export type ContentTypeSnippetTypeElementServerModelExtension = {
  readonly snippetId: Uuid | null;
};

export enum UrlSlugModeServerModel {
  Auto = 'autogenerated',
  Custom = 'custom',
}

export type UrlSlugTypeElementServerModelExtension = {
  readonly dependentStringTypeElementId: Uuid | null;
  readonly maxChars: number | null;
  readonly mode: UrlSlugModeServerModel | null;
  readonly validationRegex: ValidationRegex | null;
};

export type NumberTypeElementServerModelExtension = {
  readonly default: DefaultValueServerModelExtension<number>;
};

export type CustomTypeElementExtension = {
  readonly allowedElements: UuidArray | null;
  readonly config: string | null;
  readonly sourceUrl: string | null;
};

export type DateTimeElementServerModelExtension = {
  readonly default: DefaultValueServerModelExtension<DateTimeStamp>;
};

export type ContentTypeUsageServerModel = {
  readonly contentComponentsCount: number;
  readonly itemsCount: number;
  readonly typeId: Uuid;
};

export type FailedTypeServerModel = {
  readonly code: string;
  readonly description: string;
  readonly id: Uuid;
};

export type TypeListOperationResultServerModel = {
  readonly failedTypes: FailedTypeServerModel[];
  readonly modifiedTypeIds: UuidArray;
};

// ----

export type AssetTypeElementServerModel = AssetTypeElementServerModelExtension &
  ContentTypeElementServerModel;

export type DateTimeTypeElementServerModel = DateTimeElementServerModelExtension &
  ContentTypeElementServerModel;

export type GuidelinesTypeElementServerModel = GuidelinesTypeElementServerModelExtension &
  ContentTypeElementServerModel;

export type MultipleChoiceTypeElementServerModel = MultipleChoiceTypeElementServerModelExtension &
  ContentTypeElementServerModel;

export type NumberTypeElementServerModel = NumberTypeElementServerModelExtension &
  ContentTypeElementServerModel;

export type CustomTypeElementServerModel = CustomTypeElementExtension &
  ContentTypeElementServerModel;

export type LinkedItemsTypeElementServerModel = LinkedItemsTypeElementServerModelExtension &
  ContentTypeElementServerModel;

export type TextTypeElementServerModel = TextTypeElementServerModelExtension &
  ContentTypeElementServerModel;

export type RichTextTypeElementServerModel = RichTextElementServerModelExtension &
  ContentTypeElementServerModel;

export type TaxonomyTypeElementServerModel = TaxonomyTypeElementServerModelExtension &
  ContentTypeElementServerModel;

export type ContentTypeSnippetTypeElementServerModel =
  ContentTypeSnippetTypeElementServerModelExtension & ContentTypeElementServerModel;

export type UrlSlugTypeElementServerModel = UrlSlugTypeElementServerModelExtension &
  ContentTypeElementServerModel;

// ------

export type AssetCompiledTypeElementServerModel = AssetTypeElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type DateTimeCompiledTypeElementServerModel = DateTimeElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type GuidelinesCompiledTypeElementServerModel = GuidelinesTypeElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type MultipleChoiceCompiledTypeElementServerModel =
  MultipleChoiceTypeElementServerModelExtension & CompiledContentTypeElementServerModel;

export type NumberCompiledTypeElementServerModel = NumberTypeElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type CustomCompiledTypeElementServerModel = CustomTypeElementExtension &
  CompiledContentTypeElementServerModel;

export type LinkedItemsCompiledTypeElementServerModel = LinkedItemsTypeElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type RichTextCompiledTypeElementServerModel = RichTextElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type TextCompiledTypeElementServerModel = TextTypeElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type TaxonomyCompiledTypeElementServerModel = TaxonomyTypeElementServerModelExtension &
  CompiledContentTypeElementServerModel;

export type UrlSlugCompiledTypeElementServerModel = UrlSlugTypeElementServerModelExtension &
  CompiledContentTypeElementServerModel;
