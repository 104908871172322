import { Box } from '@kontent-ai/component-library/Box';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ItemsAssignedToYouWidgetContainer } from '../../widgets/itemsAssignedToYou/containers/ItemsAssignedToYouWidgetContainer.tsx';
import { RecentlyEditedByYouWidgetContainer } from '../../widgets/recentlyEditedByYou/containers/RecentlyEditedByYouWidgetContainer.tsx';
import { TasksAssignedByYouWidgetLiveContainer } from '../../widgets/tasksAssignedByYouWidget/containers/TasksAssignedByYouWidgetLiveContainer.tsx';
import { TasksAssignedToYouWidgetContainer } from '../../widgets/tasksAssignedToYou/containers/TasksAssignedToYouWidgetContainer.tsx';

export const YourWork: React.FC = () => {
  return (
    <>
      <HtmlPageTitle appName={AppNames.YourWork} />
      <Canvas>
        <Section>
          <SectionHeading>Items</SectionHeading>
          <Grid>
            <Box>
              <RecentlyEditedByYouWidgetContainer />
            </Box>
            <Box>
              <ItemsAssignedToYouWidgetContainer />
            </Box>
          </Grid>
        </Section>
        <Section>
          <SectionHeading>Tasks</SectionHeading>
          <Grid>
            <Box>
              <TasksAssignedToYouWidgetContainer />
            </Box>
            <Box>
              <TasksAssignedByYouWidgetLiveContainer />
            </Box>
          </Grid>
        </Section>
      </Canvas>
    </>
  );
};

const Canvas: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.MissionControl)}>
    <div className="canvas__workspace">
      <div className="canvas__content">
        <div className="canvas__content-pane">{children}</div>
      </div>
    </div>
  </div>
);

const Section: React.FC<PropsWithChildren> = ({ children }) => (
  <Box component="section" marginBottom={Spacing.XXL}>
    {children}
  </Box>
);

const SectionHeading: React.FC<{ readonly children: string }> = ({ children }) => (
  <Box
    color={colorTextDefault}
    component="h2"
    marginBottom={Spacing.XL}
    typography={Typography.HeadlineLarge}
  >
    {children}
  </Box>
);

const Grid = styled(Box).attrs({
  display: 'grid',
  gap: Spacing.L,
})`
  grid-template-columns: repeat(auto-fit, minmax(470px, 1fr));
`;
