import { InvariantException } from '@kontent-ai/errors';
import { useAttachRef } from '@kontent-ai/hooks';
import { FocusScope } from '@react-aria/focus';
import { useGridList, useGridListItem } from '@react-aria/gridlist';
import { AriaMenuOptions } from '@react-aria/menu';
import { ListState, useListState } from '@react-stately/list';
import { Key, Node } from '@react-types/shared';
import React, { forwardRef, ComponentProps, ReactNode, useRef } from 'react';
import { Divider } from '../../../../client/component-library/components/Dividers/Divider.tsx';
import { DividerDirection } from '../../../../client/component-library/components/Dividers/components/StyledDivider.tsx';
import { RefForwardingComponent } from '../../@types/RefForwardingComponent.type.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { MenuItemWithMouseHover } from '../MenuItem/MenuItemWithMouseHover.tsx';
import { VerticalMenuFrame, VerticalMenuSizingProps } from './VerticalMenuFrame.tsx';
import { Item } from './components/Item.tsx';
import { Section } from './components/Section.tsx';
import {
  BaseMenuItem,
  IItemProps,
  ListMenuItem,
  VerticalMenuItem,
  VerticalMenuSection,
} from './types.ts';
import { isItemNode, isSectionNode, mapItemsToCollectionComponents } from './utils/utils.tsx';

interface GridListMenuProps<TItem extends VerticalMenuItem<TItem>>
  extends VerticalMenuSizingProps,
    Partial<Pick<ComponentProps<typeof FocusScope>, 'contain' | 'restoreFocus'>>,
    Pick<AriaMenuOptions<TItem>, 'autoFocus' | 'onAction' | 'aria-label'> {
  readonly items: ReadonlyArray<VerticalMenuSection<TItem> | TItem> | undefined;
  readonly pinnedItemId?: Key;
  readonly renderItem: (itemProps: IItemProps<TItem>) => React.ReactNode;
  readonly state: ListState<VerticalMenuSection<TItem> | TItem>;
}

interface GridListMenuItemProps<TItem> {
  readonly item: Node<TItem>;
  readonly state: ReturnType<typeof useListState>;
  readonly trailingElements?: React.ReactNode;
  readonly onPress?: () => void;
}

interface NonAccessibleVerticalMenuForwardingRef
  extends RefForwardingComponent<GridListMenuProps<BaseMenuItem>, HTMLDivElement> {
  <TItem extends VerticalMenuItem<TItem>>(props: GridListMenuProps<TItem>): ReactNode;
}

export const useGridListMenuState = <TItem extends VerticalMenuItem<TItem>>(
  items: ReadonlyArray<VerticalMenuSection<TItem> | TItem> | undefined,
) => ({
  items,
  state: useListState<TItem | VerticalMenuSection<TItem>>({
    items,
    children: mapItemsToCollectionComponents,
  }),
});

export const GridListMenuComponent: NonAccessibleVerticalMenuForwardingRef = forwardRef(
  ({ items, autoFocus, state, renderItem, pinnedItemId, ...otherProps }, forwardedRef) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);
    const { gridProps } = useGridList(
      { items, 'aria-label': otherProps['aria-label'] },
      state,
      refObject,
    );

    const commonItemProps = {
      pinnedItemId,
      renderItem,
      state,
    };

    const pinnedItem = pinnedItemId ? state.collection.getItem(pinnedItemId) : undefined;

    return (
      <FocusScope contain restoreFocus autoFocus>
        <VerticalMenuFrame
          ref={refToForward}
          renderPinnedItem={() => {
            return (
              pinnedItem &&
              isItemNode(pinnedItem) && (
                <Box paddingBottom={Spacing.S}>
                  <Divider
                    offsetAfter={Spacing.S}
                    offsetBefore={Spacing.None}
                    color={BaseColor.Gray20}
                    direction={DividerDirection.Horizontal}
                  />
                  {renderItem({
                    item: pinnedItem,
                    level: 0,
                  })}
                </Box>
              )
            );
          }}
          {...gridProps}
          {...otherProps}
        >
          {[...state.collection].map((sectionItem, i) => {
            if (isSectionNode(sectionItem)) {
              const childItems = sectionItem.hasChildNodes
                ? Array.from(sectionItem.childNodes)
                : [];

              return [
                <Section
                  key={sectionItem.key}
                  isFirst={i === 0}
                  item={sectionItem}
                  {...commonItemProps}
                />,
                ...childItems.map(
                  (item) =>
                    isItemNode(item) && <Item key={item.key} {...commonItemProps} item={item} />,
                ),
              ];
            }
            if (isItemNode(sectionItem)) {
              return <Item key={sectionItem.key} item={sectionItem} {...commonItemProps} />;
            }

            throw InvariantException(`Unknown Node type '${sectionItem.type}'.`);
          })}
        </VerticalMenuFrame>
      </FocusScope>
    );
  },
);

const GridListMenuItemComponent = <TItem extends ListMenuItem<TItem>>({
  item,
  state,
  trailingElements,
  onPress,
}: GridListMenuItemProps<TItem>) => {
  const ref = useRef(null);
  const { rowProps, gridCellProps } = useGridListItem(
    {
      node: item,
    },
    state,
    ref,
  );

  return (
    <div {...rowProps}>
      <MenuItemWithMouseHover
        {...gridCellProps}
        menuItemState={item?.value?.isSelected ? 'selected' : 'default'}
        text={item.textValue}
        ref={ref}
        trailingElements={trailingElements}
        onPress={onPress}
        {...item?.value?.dataAttributes}
      />
    </div>
  );
};

export const GridListMenu = Object.assign(GridListMenuComponent, {
  Item: GridListMenuItemComponent,
});
