import Immutable from 'immutable';
import React from 'react';
import { Column } from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../_shared/constants/itemColumnCode.ts';
import { WorkflowStatusTagForVariant } from '../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { IUserInfo } from '../../../../_shared/models/UserInfo.ts';
import { formatUserName } from '../../../../_shared/utils/usersUtils.ts';
import { PublishingState } from '../../../../data/constants/PublishingState.ts';
import { IAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { TableCellDueDate } from '../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { NameWithLanguage } from '../NameWithLanguage.tsx';

export type YourTaskDataProps = {
  readonly contentItemName: string;
  readonly contentItemScheduledToPublishAt: DateTimeStamp | null;
  readonly contentItemScheduledToUnpublishAt: DateTimeStamp | null;
  readonly contentItemVariantName?: string;
  readonly contentType: string;
  readonly description: string;
  readonly taskDueDate: string | null;
  readonly id: Uuid;
  readonly pathToItem: string;
  readonly publishingState: PublishingState;
  readonly taskAuthor: IUserInfo;
  readonly workflowStatus: IAssignmentWorkflowStep;
};

type YourTaskProps = YourTaskDataProps & {
  readonly onNavigateToTask: () => void;
};

export const YourTask: React.FC<YourTaskProps> = (props) => {
  const {
    id,
    contentItemName,
    contentItemScheduledToPublishAt,
    contentItemScheduledToUnpublishAt,
    contentItemVariantName,
    contentType,
    description,
    taskDueDate,
    pathToItem,
    publishingState,
    taskAuthor,
    workflowStatus,
    onNavigateToTask,
  } = props;

  const taskAuthorName = formatUserName(taskAuthor);

  return (
    <DataTableRow dataUiObjectName={id} id={id} key={id}>
      <LinkDataTableCell
        isMultiline
        linkPath={pathToItem}
        onClick={onNavigateToTask}
        title={description}
      >
        <div className="your-task__description">{description}</div>
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={pathToItem} onClick={onNavigateToTask}>
        <TableCellDueDate dueDate={taskDueDate} />
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={pathToItem} onClick={onNavigateToTask} title={taskAuthorName}>
        {taskAuthorName}
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={pathToItem} onClick={onNavigateToTask}>
        <NameWithLanguage language={contentItemVariantName} name={contentItemName} />
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={pathToItem} onClick={onNavigateToTask}>
        {contentType}
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={pathToItem} onClick={onNavigateToTask}>
        <WorkflowStatusTagForVariant
          publishingState={publishingState}
          workflowStatus={workflowStatus}
          scheduledToPublishAt={contentItemScheduledToPublishAt}
          scheduledToUnpublishAt={contentItemScheduledToUnpublishAt}
        />
      </LinkDataTableCell>
    </DataTableRow>
  );
};

YourTask.displayName = 'YourTask';

export const tableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Task',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: 'Due date',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--5',
  },
  {
    columnName: 'Assigned by',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Content item name',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
]);
