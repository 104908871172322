import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { InputState } from '@kontent-ai/component-library/Input';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { WrappingSkeleton } from '@kontent-ai/component-library/Skeleton';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { Spacing, Typography, gridUnit } from '@kontent-ai/component-library/tokens';
import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { DataState } from '../../../constants/DataState.ts';
import { TimeInterval, timeIntervals } from '../../../constants/timeIntervals.ts';
import {
  getCorrectFormOfBeVerbWithCountOnTime,
  getDoneOnTimePercentage,
  getWidgetComparisonStatus,
} from '../../../contentStatus/utils/widgetUtils.tsx';
import { MissionControlRepository } from '../../../repositories/MissionControlRepository.type.ts';
import { ITasksDoneOnTime } from '../../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { getValueTrend } from '../../../utils/getValueTrend.ts';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetErrorState } from '../../shared/components/WidgetErrorState.tsx';
import { WidgetWorkflowStep } from '../../shared/components/WidgetWorkflowStep.tsx';

const getExplanationLabel = (tasksDoneOnTime: ITasksDoneOnTime | null) => {
  if (!tasksDoneOnTime || tasksDoneOnTime.current.allCount <= 0) {
    return 'Shows only items due in the past and within the selected period.';
  }

  const items = pluralizeWithCount('item', tasksDoneOnTime.current.allCount);
  const be = getCorrectFormOfBeVerbWithCountOnTime(tasksDoneOnTime.current.onTimeCount);
  return `Calculated from the ${items} due, out of which ${be} published on time within the selected period.`;
};

type ItemsPublishedOnTimeWidgetProps = Readonly<{
  itemsPublishedOnTimeFetcher: MissionControlRepository['getItemsPublishedOnTime'];
}>;

export const ItemsPublishedOnTimeWidget: React.FC<ItemsPublishedOnTimeWidgetProps> = ({
  itemsPublishedOnTimeFetcher,
}) => {
  const [selectedIntervalId, setSelectedIntervalId] = useState(TimeInterval.CurrentMonth);
  const [state, setState] = useState<DataState>(DataState.Loading);
  const [tasksDoneOnTime, setTasksDoneOnTime] = useState<ITasksDoneOnTime | null>(null);

  useEffect(() => {
    setState(DataState.Loading);

    const fetcher = makeCancellablePromise(() => itemsPublishedOnTimeFetcher(selectedIntervalId))
      .then((response) => {
        setTasksDoneOnTime(response);
        setState((response.current.allCount ?? 0) === 0 ? DataState.Empty : DataState.Loaded);
      })
      .catch(swallowCancelledPromiseError)
      .catch((e) => {
        logError(`${__filename}: Failed to fetch.`, e);
        setState(DataState.Error);
      });

    return fetcher.cancel;
  }, [itemsPublishedOnTimeFetcher, selectedIntervalId]);

  const publishedCurrentPercent = getDoneOnTimePercentage(tasksDoneOnTime?.current);
  const publishedDiffPercent =
    publishedCurrentPercent - getDoneOnTimePercentage(tasksDoneOnTime?.previous);

  const hasFailed = state === DataState.Error;
  const isLoading = state === DataState.Loading;
  const hasNoData = state === DataState.Empty;

  return (
    <Widget>
      <Widget.Title
        text="Items published on time"
        explanatoryLabel={getExplanationLabel(tasksDoneOnTime)}
      />

      <Widget.Subtitle>
        {hasFailed ? (
          <WidgetErrorState isInline />
        ) : hasNoData ? (
          <Box typography={Typography.HeadlineLarge}>
            --
            <SrOnly>There are no data</SrOnly>
          </Box>
        ) : (
          <>
            {isLoading && <SrOnly>Loading data for tasks widget</SrOnly>}
            <WrappingSkeleton isLoading={isLoading} type="text">
              <Box typography={Typography.HeadlineLarge} minWidth={10 * gridUnit}>
                {publishedCurrentPercent.toFixed(2)}%
              </Box>
            </WrappingSkeleton>
          </>
        )}
      </Widget.Subtitle>

      {!hasFailed && (
        <>
          <Widget.AuxElement>
            <SingleSelect
              ariaLabel="Timeframe"
              inputState={isLoading ? InputState.Disabled : InputState.Default}
              items={timeIntervals}
              selectedItemId={selectedIntervalId}
              onSelectionChange={(itemId: TimeInterval) => {
                setSelectedIntervalId(itemId);
              }}
            />
          </Widget.AuxElement>

          <Widget.Body>
            {hasNoData ? (
              <Box typography={Typography.Subheadline}>No insights yet</Box>
            ) : (
              <WrappingSkeleton isLoading={isLoading} type="text">
                <Inline align="center" spacingX={Spacing.S} css={Typography.Subheadline}>
                  <WidgetWorkflowStep
                    state={getValueTrend(publishedDiffPercent)}
                    percentage={publishedDiffPercent}
                  />
                  {getWidgetComparisonStatus(publishedDiffPercent)}
                </Inline>
              </WrappingSkeleton>
            )}
          </Widget.Body>
        </>
      )}
    </Widget>
  );
};

ItemsPublishedOnTimeWidget.displayName = 'ItemsPublishedOnTimeWidget';
