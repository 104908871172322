import { Box } from '@kontent-ai/component-library/Box';
import { Icon } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Color, Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Widget } from './Widget.tsx';

type WidgetBaseEmptyStateProps = Readonly<{
  icon: Icon;
  iconColor: Color;
  message: string;
  title: string;
}>;

export const WidgetBaseEmptyState: React.FC<WidgetBaseEmptyStateProps> = ({
  icon: IconComponent,
  iconColor,
  message,
  title,
}) => {
  return (
    <Widget.Body>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        minHeight={200}
      >
        <Stack align="center" spacing={Spacing.L}>
          <IconComponent height={48} width={48} color={iconColor} />
          <Stack align="center" spacing={Spacing.XXS}>
            <Box textAlign="center" typography={Typography.HeadlineLarge}>
              {title}
            </Box>
            <Box typography={Typography.BodyMedium}>{message}</Box>
          </Stack>
        </Stack>
      </Box>
    </Widget.Body>
  );
};
