import { Key, Node } from '@react-types/shared';
import { ReactElement } from 'react';
import { ListChildComponentProps } from 'react-window';
import { VerticalMenuProps } from '../VerticalMenu.tsx';
import { verticalMenuScrollablePartPadding } from '../decisionTokens.ts';
import { IItemProps as IRenderItemProps, VerticalMenuItem, VerticalMenuSection } from '../types.ts';
import { VerticalMenuState } from '../useNonAccessibleVerticalMenu.ts';
import { isItemNode, isSectionNode } from '../utils/utils.tsx';
import { Section } from './Section.tsx';

export interface IItemProps<TItem extends VerticalMenuItem<TItem>>
  extends Pick<VerticalMenuProps<TItem>, 'pinnedItemId' | 'renderItem' | 'state'>,
    Omit<ListChildComponentProps, 'data'> {
  readonly item: Node<VerticalMenuSection<TItem> | TItem> | undefined;
  readonly pinnedItemId?: Key;
  readonly renderItem: (itemProps: IRenderItemProps<TItem>) => React.ReactNode;
  readonly state: VerticalMenuState<TItem>;
}

export const VirtualizedRow = <TItem extends VerticalMenuItem<TItem>>(
  props: IItemProps<TItem>,
): ReactElement | null => {
  const { item, pinnedItemId, renderItem, state, style, index, ...otherProps } = props;

  if (!item || item.key === pinnedItemId) {
    return null;
  }

  const top = (typeof style.top === 'string' ? Number.parseFloat(style.top) : style.top) ?? 0;

  const styleWithPadding = {
    ...style,
    top: top + verticalMenuScrollablePartPadding,
  };

  if (isSectionNode(item)) {
    return (
      <div key={item.key} style={styleWithPadding} {...otherProps}>
        <Section
          isFirst={index === 0}
          {...{
            item,
            pinnedItemId,
            renderItem,
            state,
          }}
        />
      </div>
    );
  }

  if (isItemNode(item)) {
    return (
      <div key={item.key} style={styleWithPadding} {...otherProps}>
        {renderItem({
          item,
          level: item.level,
        })}
      </div>
    );
  }

  return null;
};

VirtualizedRow.displayName = 'VirtualizedRow';
