import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { Placement } from '@popperjs/core';
import { useOverlay } from '@react-aria/overlays';
import { useRef } from 'react';
import {
  DropDownMenuPositioner,
  DropDownMenuPositionerProps,
  DropdownTippyOptions,
} from '../../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = Pick<
  DropDownMenuPositionerProps,
  'renderDropDown' | 'renderTrigger' | 'isDropDownVisible'
> & {
  readonly fallbackToolbarPlacements?: ReadonlyArray<Placement>;
  readonly onClose: () => void;
  readonly toolbarPlacement?: 'top-start' | 'bottom-start' | 'right';
  readonly menuUiCollection: DataUiCollection;
};

export const ToolbarButtonMenuPositioner = ({
  fallbackToolbarPlacements,
  isDropDownVisible,
  onClose,
  renderDropDown,
  renderTrigger,
  toolbarPlacement = 'bottom-start',
  menuUiCollection,
}: Props) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { overlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: isDropDownVisible,
      onClose,
      shouldCloseOnInteractOutside: (element) =>
        !(triggerRef.current?.contains(element) || menuRef.current?.contains(element)),
    },
    menuRef,
  );

  const tippyOptions: DropdownTippyOptions = {
    offset: [0, spacingPopupDistance],
    placement: toolbarPlacement,
    popperOptions: {
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: fallbackToolbarPlacements,
          },
        },
      ],
    },
  };

  return (
    <DropDownMenuPositioner
      isDropDownVisible={isDropDownVisible}
      renderDropDown={(triggerWidth, triggerRefObject, dropDownProps) => (
        <div ref={menuRef} {...overlayProps} {...getDataUiCollectionAttribute(menuUiCollection)}>
          {renderDropDown(triggerWidth, triggerRefObject, dropDownProps)}
        </div>
      )}
      renderTrigger={renderTrigger}
      tippyOptions={tippyOptions}
      triggerRef={triggerRef}
    />
  );
};
