import {
  DateIsInPastError,
  IncompleteElementsError,
  PublishDateAfterUnpublishDateError,
  UnpublishDateBeforePublishDateError,
} from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { IChangeWorkflowStepModalData } from '../../../../../../_shared/features/ChangeWorkflowStepModal/reducers/IChangeWorkflowStepModalData.ts';
import { isBulkPublishAllowed } from '../../../../../../_shared/utils/validation/isBulkPublishAllowed.ts';
import { IItemListingBulkActionsCheck } from '../../../../../contentInventory/content/models/ItemListingBulkActionStatus.ts';
import {
  DateTimeInvalidValue,
  SelectedItemsCannotBePublished,
} from '../../../../constants/errorMessages.ts';
import {
  PublishingValidationResult,
  validatePublishingConfiguration,
} from './publishingValidationUtils.ts';
import {
  UnpublishingValidationResult,
  validateUnpublishingConfiguration,
  validateUnpublishingDate,
} from './unpublishingValidationUtils.ts';

export const getUpdateStepSubmitButtonTooltipErrorMessage = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): string | undefined => {
  if (!changeWorkflowStepModalData.dueDate.isValid) {
    return DateTimeInvalidValue;
  }

  return getContributorsValidationErrorMessage(changeWorkflowStepModalData.workflowStep.name);
};

export const getContributorsValidationErrorMessage = (workflowStepName: string): string =>
  `Assign at least one contributor who can work with the\n${workflowStepName} step.`;

const getGeneralPublishingErrors = (hasElementsWithWarnings: boolean) => {
  const errors: string[] = [];

  if (hasElementsWithWarnings) {
    errors.push(IncompleteElementsError);
  }

  return errors;
};

const createTooltipMessage = (errors: ReadonlyArray<string>): string => {
  const messages = errors.length > 1 ? errors.map((error) => `•  ${error}`) : errors;
  return messages.join('\n');
};

export const getPublishSubmitButtonTooltipMessage = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
  hasElementsWithWarnings: boolean,
  bulkActionCheckResult: IItemListingBulkActionsCheck | null,
): string => {
  const errors = getGeneralPublishingErrors(hasElementsWithWarnings);

  if (!isBulkPublishAllowed(bulkActionCheckResult)) {
    errors.push(SelectedItemsCannotBePublished);
  }

  errors.push(...getScheduledUnpublishingErrorsIfEnabled(changeWorkflowStepModalData));

  return createTooltipMessage(errors);
};

export const getScheduledPublishSubmitButtonTooltipMessage = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
  hasElementsWithWarnings: boolean,
  bulkActionCheckResult: IItemListingBulkActionsCheck | null,
): string => {
  const errors = getGeneralPublishingErrors(hasElementsWithWarnings);
  const publishingValidationResult = validatePublishingConfiguration(changeWorkflowStepModalData);

  switch (publishingValidationResult) {
    case PublishingValidationResult.PublishDateIsEmptyOrInvalid: {
      errors.push(DateTimeInvalidValue);
      break;
    }
    case PublishingValidationResult.PublishDateIsInPast: {
      errors.push(DateIsInPastError);
      break;
    }
    case PublishingValidationResult.PublishDateIsSameOrAfterUnpublishDate: {
      errors.push(PublishDateAfterUnpublishDateError);
      break;
    }
    default: {
      break;
    }
  }

  errors.push(...getScheduledUnpublishingErrorsIfEnabled(changeWorkflowStepModalData));

  if (!isBulkPublishAllowed(bulkActionCheckResult)) {
    errors.push(SelectedItemsCannotBePublished);
  }

  return createTooltipMessage(errors);
};

export const getScheduledUnpublishSubmitButtonTooltipMessage = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): string => {
  const errors: string[] = [];
  const unpublishingValidationResult = validateUnpublishingConfiguration(
    changeWorkflowStepModalData,
  );

  switch (unpublishingValidationResult) {
    case UnpublishingValidationResult.UnpublishDateIsEmptyOrInvalid: {
      errors.push(DateTimeInvalidValue);
      break;
    }
    case UnpublishingValidationResult.UnpublishDateIsInPast: {
      errors.push(DateIsInPastError);
      break;
    }
    case UnpublishingValidationResult.UnpublishDateIsSameOrBeforePublishDate: {
      errors.push(UnpublishDateBeforePublishDateError);
      break;
    }
    default: {
      break;
    }
  }

  return createTooltipMessage(errors);
};

const getScheduledUnpublishingErrorsIfEnabled = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): string[] => {
  if (!changeWorkflowStepModalData.enableScheduledUnpublishing) {
    return [];
  }

  const errors: string[] = [];
  const unpublishingValidationResult = validateUnpublishingDate(
    changeWorkflowStepModalData.scheduledToUnpublishAt,
  );
  switch (unpublishingValidationResult) {
    case UnpublishingValidationResult.UnpublishDateIsEmptyOrInvalid:
      errors.push(DateTimeInvalidValue);
      break;
    case UnpublishingValidationResult.UnpublishDateIsInPast:
      errors.push(DateIsInPastError);
      break;
    default:
      break;
  }
  return errors;
};
