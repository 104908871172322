import { MenuItemState, MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { ShortcutSet } from '@kontent-ai/component-library/ShortcutSet';
import { VerticalMenuItem, useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ToolbarButtonProps } from '../../utils/toolbarUtils.ts';

export type EditorToolbarMenuItem = VerticalMenuItem<EditorToolbarMenuItem> &
  ToolbarButtonProps & {
    readonly iconName?: IconName;
    readonly shortcuts?: string;
    readonly uiAction: DataUiRteAction;
  };

type Props = {
  readonly items: ReadonlyArray<EditorToolbarMenuItem>;
};

export const EditorToolbarMenu = ({ items }: Props) => {
  const { verticalMenuState, VerticalMenu, verticalMenuProps } =
    useVerticalMenu<EditorToolbarMenuItem>(items);

  return (
    <VerticalMenu
      {...verticalMenuProps}
      aria-label="AI action menu"
      contain={false}
      renderItem={({ item: { value } }) => {
        if (!value) return null;

        const { shortcuts, iconName, isViolated, label, onClick, tooltipText, uiAction } = value;
        const menuItemState = getMenuItemState(value);

        return (
          <MenuItemWithMouseHover
            tooltipText={isViolated || menuItemState === 'disabled' ? tooltipText : undefined}
            tooltipPlacement="right"
            menuItemState={menuItemState}
            text={label}
            leadingElement={iconName && <Icon iconName={iconName} />}
            trailingElements={
              shortcuts && (
                <ShortcutSet
                  shortcuts={shortcuts}
                  shortcutStyle={isViolated ? 'inverse' : 'default'}
                />
              )
            }
            onPress={onClick}
            {...getDataUiActionAttribute(uiAction)}
          />
        );
      }}
      state={verticalMenuState}
    />
  );
};

const getMenuItemState = (menuItem: EditorToolbarMenuItem): MenuItemState => {
  if (menuItem.isViolated) {
    return menuItem.isActive ? 'error-selected' : 'error';
  }

  if (menuItem.disabled) {
    return 'disabled';
  }

  if (menuItem.isActive) {
    return 'selected';
  }

  return 'default';
};
