import React, { useCallback } from 'react';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ListingRequestOptionsModel } from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import {
  useTrackWidgetDetailItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../../../unifiedMissionControl/widgets/shared/hooks/tracking.ts';
import { TaskWidgetDetailTableLiveContainer } from '../../shared/containers/TaskWidgetDetailTableLiveContainer.tsx';
import { MostOverdueTasksWidget } from '../components/MostOverdueTasksWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const MostOverdueTasksWidgetLiveContainer: React.FC = () => {
  const tasksFetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal: AbortSignal) =>
      missionControlRepository.getOverdueTasks(requestOptions, abortSignal),
    [],
  );

  const trackWidgetDetailItemClick = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.OverdueTasks,
  );
  const trackWidgetShowMoreButtonClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.OverdueTasks,
  );

  return (
    <MostOverdueTasksWidget
      onDetailItemClick={trackWidgetDetailItemClick}
      onShowMoreClick={trackWidgetShowMoreButtonClick}
      tasksFetcher={tasksFetcher}
      WidgetDetail={TaskWidgetDetailTableLiveContainer}
    />
  );
};

MostOverdueTasksWidgetLiveContainer.displayName = 'MostOverdueTasksWidgetLiveResolver';
