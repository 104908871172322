import { assert } from '@kontent-ai/utils';
import React from 'react';
import { useHistory } from 'react-router';
import { forgetSmartLinkCommand } from '../../../../../../_shared/actions/sharedActions.ts';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  ContentItemPreviewRoute,
  ContentItemPreviewWithEditorRoute,
  ContentItemPreviewWithEditorRouteParams,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { SquareButton } from '../../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';

export const CloseAction: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPath = history.location.pathname;

  const match = matchPath<ContentItemPreviewWithEditorRouteParams<string>>(
    currentPath,
    ContentItemPreviewWithEditorRoute,
  );
  assert(match, () => 'Current route does not fit to ContentItemPreviewWithEditorRoute');

  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    const targetPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemPreviewRoute, {
      app: match.app,
      projectId: match.projectId,
      variantId: match.variantId,
      spaceId: match.spaceId,
      contentItemIds: parseContentItemIds(match.contentItemIds),
    });
    history.push(targetPath);
    dispatch(forgetSmartLinkCommand());
  };

  return (
    <SquareButton
      iconName={IconName.Times}
      onClick={onClose}
      style={ButtonStyle.Quinary}
      size={SquareButtonSize.Quinary}
      tooltipText="Close"
    />
  );
};

CloseAction.displayName = 'CloseAction';
