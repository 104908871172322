import { Icon, Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  BaseColor,
  Spacing,
  colorAlertText,
  colorSuccessText,
  colorTextDefault,
} from '@kontent-ai/component-library/tokens';
import { UnreachableCaseException } from '@kontent-ai/errors';
import {
  ICancellablePromise,
  delay,
  notNull,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React, { MouseEventHandler, RefObject, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { copyToClipboardDelay } from '../../../../../../_shared/constants/uiConstants.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { CommentEventTypes } from '../../../../../../_shared/models/events/ContentItemEventData.type.ts';
import { getEditedContentItemVariantId } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { SquareButton } from '../../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { createDropDown } from '../../../../../../_shared/uiComponents/DropDown/DropDown.tsx';
import { DropDownOption } from '../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import { getDataAttribute } from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiCollection,
  DataUiCommentsAction,
  ObjectWithDataAttribute,
  getDataUiCollectionAttribute,
  getDataUiCommentActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getCurrentProjectId } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { CommentRoute } from '../../../../../smartLink/constants/routePaths.ts';
import { ElementAttributes } from '../../../../constants/elementAttributes.ts';
import { commentThreadItemEditingStarted } from '../../actions/contentItemEditingActions.ts';
import {
  reopenCommentThread,
  resolveCommentThread,
} from '../../actions/thunkContentItemEditingActions.ts';

export interface ICommentContextMenuOwnProps {
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly allowUnresolve: boolean;
  readonly commentId: Uuid;
  readonly threadId: Uuid;
}

type Option = {
  readonly dataAttributeObject: ObjectWithDataAttribute;
  readonly icon: Icon;
  readonly id: string;
  readonly onClick: (hide: MouseEventHandler<HTMLElement>) => MouseEventHandler<HTMLElement>;
  readonly optionText: string;
  readonly status?: CopyLinkStatus;
};

const DropDown = createDropDown<Option>();

const tippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  placement: 'left-start',
};

const propTypes: PropTypeMap<ICommentContextMenuOwnProps> = {
  allowEdit: PropTypes.bool.isRequired,
  allowResolve: PropTypes.bool.isRequired,
  allowUnresolve: PropTypes.bool.isRequired,
  threadId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
};

type CopyLinkStatus = 'initial' | 'success' | 'error';

const getCopyOptionText = (status: CopyLinkStatus): string => {
  switch (status) {
    case 'initial':
      return 'Copy link';
    case 'error':
      return 'Copying failed';
    case 'success':
      return 'Copied';
    default:
      throw UnreachableCaseException(status, `This copy status '${status}' is not allowed.`);
  }
};
const getCopyOptionIcon = (status: CopyLinkStatus): Icon => {
  switch (status) {
    case 'initial':
      return Icons.Chain;
    case 'error':
      return Icons.TimesCircle;
    case 'success':
      return Icons.CbCheckPreview;
    default:
      throw UnreachableCaseException(status, `This copy status '${status}' is not allowed.`);
  }
};

const getColorFromStatus = (status?: CopyLinkStatus | null): BaseColor => {
  switch (status) {
    case 'initial':
    case undefined:
    case null:
      return colorTextDefault;
    case 'error':
      return colorAlertText;
    case 'success':
      return colorSuccessText;
    default:
      throw UnreachableCaseException(status, `This copy status '${status}' is not allowed.`);
  }
};

const getCurrentUrlWihNewPath = (newPath: string): string => {
  const currentURL = window.location.href;
  // Replace the entire path in the current URL
  return currentURL.replace(window.location.pathname, newPath);
};

export const CommentContextMenu: React.FC<ICommentContextMenuOwnProps> = ({
  allowUnresolve,
  allowResolve,
  allowEdit,
  commentId,
  threadId,
}) => {
  const dispatch = useDispatch();
  const currentProjectId = useSelector(getCurrentProjectId);
  const editedItemVariantId = useSelector(getEditedContentItemVariantId);
  const [copyLinkStatus, setCopyLinkStatus] = useState<CopyLinkStatus>('initial');
  const delayedCloseRef = useRef<ICancellablePromise | null>(null);

  const cancelDelayedCloseAndResetCopyStatus = () => {
    delayedCloseRef.current?.cancel();
    delayedCloseRef.current = null;
    setCopyLinkStatus('initial');
  };
  const onMarkAsResolved = () => dispatch(resolveCommentThread(threadId, true));
  const onMarkAsUnresolved = () => dispatch(reopenCommentThread(threadId));
  const onStartCommentEdit = () =>
    dispatch(
      commentThreadItemEditingStarted({
        threadId,
        commentId,
      }),
    );
  const getOnMarkResolved =
    (hideMenu: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> =>
    (event) => {
      event.stopPropagation();
      hideMenu(event);
      onMarkAsResolved();
    };

  const getOnMarkUnresolved =
    (hideMenu: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> =>
    (event) => {
      event.stopPropagation();
      hideMenu(event);
      onMarkAsUnresolved();
    };

  const getOnEdit =
    (hideMenu: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> =>
    (event) => {
      event.stopPropagation();
      hideMenu(event);
      onStartCommentEdit();
    };

  const getCopyLink =
    (hideMenu: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> =>
    (event) => {
      event.stopPropagation();
      if (copyLinkStatus !== 'initial') {
        return;
      }

      if (!editedItemVariantId?.itemId && !editedItemVariantId?.itemId) {
        setCopyLinkStatus('error');
        delayedCloseRef.current = delay(copyToClipboardDelay)
          .catch(swallowCancelledPromiseError)
          .then(() => {
            hideMenu(event);
          });

        return;
      }

      const pathToCopy = generatePath(CommentRoute, {
        commentThreadId: threadId,
        itemGuid: editedItemVariantId?.itemId,
        variantGuid: editedItemVariantId?.variantId,
        projectId: currentProjectId,
      });

      const urlToCopy = getCurrentUrlWihNewPath(pathToCopy);

      navigator.clipboard
        .writeText(urlToCopy)
        .then(() => setCopyLinkStatus('success'))
        .then(() =>
          dispatch(
            trackUserEventWithData(TrackedEvent.Comments, { action: CommentEventTypes.CopyLink }),
          ),
        )
        .then(() => {
          delayedCloseRef.current = delay(copyToClipboardDelay)
            .catch(swallowCancelledPromiseError)
            .then(() => {
              hideMenu(event);
            });
        });
    };

  const getOnButtonClick =
    (openMenu: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> =>
    (event) => {
      event.stopPropagation();
      openMenu(event);
    };

  const options: ReadonlyArray<Option> = [
    {
      dataAttributeObject: getDataUiCommentActionAttribute(DataUiCommentsAction.GetLink),
      icon: getCopyOptionIcon(copyLinkStatus),
      id: 'link',
      onClick: getCopyLink,
      optionText: getCopyOptionText(copyLinkStatus),
      status: copyLinkStatus,
    },
    allowResolve
      ? {
          dataAttributeObject: getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsResolved),
          icon: Icons.CheckCircle,
          id: 'resolve',
          onClick: getOnMarkResolved,
          optionText: 'Resolve',
        }
      : null,
    allowUnresolve
      ? {
          dataAttributeObject: getDataUiCommentActionAttribute(
            DataUiCommentsAction.MarkAsUnresolved,
          ),
          icon: Icons.RotateDoubleRight,
          id: 'unresolve',
          onClick: getOnMarkUnresolved,
          optionText: 'Reopen',
        }
      : null,
    allowEdit
      ? {
          dataAttributeObject: getDataUiCommentActionAttribute(DataUiCommentsAction.Edit),
          icon: Icons.Edit,
          id: 'edit',
          onClick: getOnEdit,
          optionText: 'Edit',
        }
      : null,
  ].filter(notNull);

  return (
    <DropDown
      optionListDataUiAttributes={{
        ...getDataUiCollectionAttribute(DataUiCollection.CommentMenuItems),
        // We need to prevent comment blur upon clicking on its context menu (outside the comment)
        // to make sure the active comment in floating editor doesn't hide upon this action
        ...getDataAttribute(ElementAttributes.BlurCommentThreadOnClick, 'false'),
      }}
      options={options}
      renderSelectedOption={(ref: RefObject<HTMLDivElement>, onClick, isOpen) => (
        <SquareButton
          customButtonClassName="comment__action u-rotate-90"
          iconName={IconName.Ellipsis}
          isActive={isOpen}
          onClick={getOnButtonClick(onClick)}
          ref={ref}
          size={SquareButtonSize.Quinary}
          style={ButtonStyle.Quinary}
          tooltipPlacement="top-end"
          tooltipText="More actions"
          {...getDataUiCommentActionAttribute(DataUiCommentsAction.OpenCommentMenu)}
        />
      )}
      renderOption={(hideMenu, option) => (
        <DropDownOption
          key={option.id}
          onClick={option.onClick(hideMenu)}
          dataUiAttributes={option.dataAttributeObject}
        >
          <Inline
            spacing={Spacing.S}
            align="center"
            css={{
              color: getColorFromStatus(option.status),
            }}
          >
            <option.icon />
            <DropDownOptionName text={option.optionText} />
          </Inline>
        </DropDownOption>
      )}
      onClose={cancelDelayedCloseAndResetCopyStatus}
      tippyOptions={tippyOptions}
    />
  );
};

CommentContextMenu.displayName = 'CommentContextMenu';
CommentContextMenu.propTypes = propTypes;
