import { Icons } from '@kontent-ai/component-library/Icons';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  RTEToolbarIconButton,
  SpecificButtonProps,
} from '../../toolbars/components/buttons/RTEToolbarButton.tsx';

export const ClearFormattingButton = (props: SpecificButtonProps) => (
  <RTEToolbarIconButton
    {...props}
    icon={Icons.TWithCross}
    tooltipPlacement={props.tooltipPlacement}
    tooltipText={props.tooltipText ?? 'Clear formatting'}
    {...getDataUiActionAttribute(DataUiRteAction.ClearFormatting)}
  />
);
