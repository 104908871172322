import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import React, { useRef } from 'react';
import {
  UnpublishingValidationResult,
  validateUnpublishingDate,
} from '../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/unpublishingValidationUtils.ts';
import { DatetimePickerRefType } from '../../../components/DatetimePicker/DatetimePicker.tsx';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { useSelector } from '../../../hooks/useSelector.ts';
import { DataUiElement } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  enableScheduledUnpublishingChanged,
  scheduledToUnpublishChanged,
} from '../actions/changeWorkflowStepModalActions.ts';
import { ScheduleEditor } from '../components/Elements/ScheduleEditor.tsx';
import { DateIsInPastError } from '../constants/uiConstants.ts';
import { IChangeWorkflowStepModalData } from '../reducers/IChangeWorkflowStepModalData.ts';

const getValidationError = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): string | undefined => {
  if (!changeWorkflowStepModalData.enableScheduledUnpublishing) {
    return undefined;
  }

  const validationError = validateUnpublishingDate(
    changeWorkflowStepModalData.scheduledToUnpublishAt,
  );

  switch (validationError) {
    case UnpublishingValidationResult.UnpublishDateIsInPast:
      return DateIsInPastError;

    default:
      return undefined;
  }
};

export const UnpublishScheduleEditor = () => {
  const dispatch = useDispatch();
  const scheduledToPublishAt = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledToPublishAt,
  );
  const scheduledToUnpublishAt = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledToUnpublishAt,
  );
  const scheduledToUnpublishTimezone = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledUnpublishDisplayTimeZone,
  );
  const error = useSelector((s) => getValidationError(s.contentApp.changeWorkflowStepModalData));
  const isEnabled = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.enableScheduledUnpublishing,
  );

  const isInitialEditRef = useRef(!scheduledToUnpublishAt);

  const onCheckBoxChecked = (
    value: boolean,
    datetimePickerRef: React.RefObject<DatetimePickerRefType>,
  ) => {
    dispatch(enableScheduledUnpublishingChanged(value));
    if (value) {
      datetimePickerRef.current?.focusInputAtTheEnd();
    } else {
      dispatch(scheduledToUnpublishChanged(null, scheduledToUnpublishTimezone));
    }
  };

  return (
    <ScheduleEditor
      error={error}
      minValue={scheduledToPublishAt ?? undefined}
      onScheduleChange={(newDate, newTimezoneId) => {
        dispatch(scheduledToUnpublishChanged(newDate, newTimezoneId));
      }}
      scheduledAt={scheduledToUnpublishAt}
      scheduledTimeZone={scheduledToUnpublishTimezone}
      dataUiElementName={DataUiElement.UnpublishScheduleInformation}
      renderCheckBox={(datetimePickerRef) =>
        isInitialEditRef.current ? (
          <Checkbox
            checkboxState="default"
            checked={isEnabled}
            onToggle={(value) => onCheckBoxChecked(value, datetimePickerRef)}
          >
            Schedule unpublish
          </Checkbox>
        ) : null
      }
    />
  );
};
