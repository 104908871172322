import { Collection } from '@kontent-ai/utils';
import { DraftBlockRenderConfig } from 'draft-js';
import Immutable from 'immutable';
import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import { RTECommandSource } from '../../../../_shared/models/events/RTECommandEventData.type.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetBaseBlockRenderMap, OnUpdate } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BaseBlockRenderMap,
  mergeBlockRenderMaps,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { BaseBlockType, TextBlockTypes } from '../../utils/blocks/blockType.ts';
import {
  HeadingBlockTypeSequence,
  isListItem,
  isTextBlock,
} from '../../utils/blocks/blockTypeUtils.ts';
import {
  getBaseBlockTypes,
  getFullBlockTypesAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { ApiLimitationsPlugin } from '../apiLimitations/ApiLimitationsPlugin.tsx';
import { TextBlockTypeFeature } from '../apiLimitations/api/editorLimitationUtils.ts';
import {
  CanHandleNewCharsNatively,
  CustomInputHandlingPlugin,
  PostProcessInsertedChars,
} from '../customInputHandling/CustomInputHandlingPlugin.tsx';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import {
  canCommandExecute,
  getNextAllowedBlockTypeForSelection,
} from '../keyboardShortcuts/api/editorCommandUtils.ts';
import { BlockToolbarPlugin, RenderBlockToolbarContent } from '../toolbars/BlockToolbarPlugin.tsx';
import { InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { Resettable } from '../toolbars/components/block/BlockToolbar.tsx';
import { EditorToolbarDivider } from '../toolbars/components/buttons/EditorToolbarDivider.tsx';
import { shouldResetBlockToolbar } from '../toolbars/utils/toolbarUtils.ts';
import { UndoRedoPlugin } from '../undoRedo/UndoRedoPlugin.tsx';
import { AllowPlaceholder, PlaceholderPlugin } from '../visuals/PlaceholderPlugin.tsx';
import { EditorTextBlockTypesApi } from './api/EditorTextBlockTypeApi.type.ts';
import {
  MarkdownBlockConversionResult,
  MarkdownBlockTypeCommandMap,
  getAutomaticMarkdownBlockConversionResult,
  isMarkdownShorthandBlockType,
} from './api/editorBlockMarkdownUtils.ts';
import { editorTextBlockTypeApi } from './api/editorTextBlockTypeApi.ts';
import {
  BlockTypeBlockToolbarButton as BlockTypeBlockToolbarButtonComponent,
  BlockTypeBlockToolbarButtonProps,
} from './components/BlockTypeBlockToolbarButton.tsx';
import {
  BlockTypeInlineToolbarButtons as BlockTypeInlineToolbarButtonsComponent,
  BlockTypeInlineToolbarButtonsProps,
} from './components/BlockTypeInlineToolbarButtons.tsx';
import { RichTextListRefresher } from './components/RichTextListRefresher.tsx';

export type TextBlockTypesPlugin = EditorPlugin<
  None,
  None,
  EditorTextBlockTypesApi,
  [
    ApiLimitationsPlugin,
    BlockToolbarPlugin,
    InlineToolbarPlugin,
    KeyboardShortcutsPlugin<RichTextInputCommand>,
    CustomInputHandlingPlugin,
    UndoRedoPlugin,
    PlaceholderPlugin,
  ]
>;

const BlockTypeInlineToolbarButtons: React.FC<
  Pick<PluginState<TextBlockTypesPlugin>, 'editorState' | 'handleCommand'> &
    Pick<BlockTypeInlineToolbarButtonsProps, 'limitations' | 'hidesDisallowedFeatures'>
> = ({ editorState, handleCommand, hidesDisallowedFeatures, limitations }) => {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);

  const onCommand = useCallback(
    (command: RichTextInputCommand): void => {
      handleCommand(command, RTECommandSource.InlineToolbar);
    },
    [handleCommand],
  );

  return (
    <BlockTypeInlineToolbarButtonsComponent
      fullBlockTypesAtSelection={fullBlockTypesAtSelection}
      hidesDisallowedFeatures={hidesDisallowedFeatures}
      limitations={limitations}
      onCommand={onCommand}
    />
  );
};

BlockTypeInlineToolbarButtons.displayName = 'BlockTypeButtons';

const BlockTypeBlockToolbarButton = forwardRef<
  Resettable,
  Pick<PluginState<TextBlockTypesPlugin>, 'editorState' | 'handleCommand'> &
    Pick<
      BlockTypeBlockToolbarButtonProps,
      'limitations' | 'hidesDisallowedFeatures' | 'isToolbarVertical'
    >
>(
  (
    { editorState, handleCommand, hidesDisallowedFeatures, isToolbarVertical, limitations },
    ref,
  ) => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);

    const onCommand = useCallback(
      (command: RichTextInputCommand) => handleCommand(command, RTECommandSource.BlockToolbar),
      [handleCommand],
    );

    return (
      <BlockTypeBlockToolbarButtonComponent
        fullBlockTypesAtSelection={fullBlockTypesAtSelection}
        hidesDisallowedFeatures={hidesDisallowedFeatures}
        isToolbarVertical={isToolbarVertical}
        limitations={limitations}
        onCommand={onCommand}
        ref={ref}
      />
    );
  },
);

BlockTypeBlockToolbarButton.displayName = 'BlockTypeBlockToolbarButton';

const headingsRenderMap: BaseBlockRenderMap = Immutable.Map<BaseBlockType, DraftBlockRenderConfig>({
  [BaseBlockType.HeadingOne]: {
    element: 'h1',
  },
  [BaseBlockType.HeadingTwo]: {
    element: 'h2',
  },
  [BaseBlockType.HeadingThree]: {
    element: 'h3',
  },
  [BaseBlockType.HeadingFour]: {
    element: 'h4',
  },
  [BaseBlockType.HeadingFive]: {
    element: 'h5',
  },
  [BaseBlockType.HeadingSix]: {
    element: 'h6',
  },
  [BaseBlockType.OrderedListItem]: {
    element: 'div',
  },
  [BaseBlockType.UnorderedListItem]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), headingsRenderMap);

const canHandleNewCharsNatively: Decorator<CanHandleNewCharsNatively> =
  (baseCanHandleNewCharsNatively) => (params) => {
    if (!baseCanHandleNewCharsNatively(params)) {
      return false;
    }

    const { editorState, chars } = params;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = content.getBlockForKey(selection.getStartKey());

    if (isTextBlock(block)) {
      const text = block.getText();
      const offset = selection.getStartOffset();
      const futureText = text.substring(0, offset) + chars;

      return !getAutomaticMarkdownBlockConversionResult(futureText);
    }
    return true;
  };

export const useTextBlockTypes: PluginCreator<TextBlockTypesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('TextBlockTypesPlugin', {
        ComposedEditor: (props) => {
          const { hidesDisallowedFeatures, trackRTECommandUsed } = props;

          const setUndoOnBackspaceAfterChanges = useRef(false);
          const undoOnBackspace = useRef(false);

          const onUpdate: Decorator<OnUpdate> = useCallback(
            (baseOnUpdate) => (params) => {
              baseOnUpdate(params);
              undoOnBackspace.current = setUndoOnBackspaceAfterChanges.current;
              setUndoOnBackspaceAfterChanges.current = false;
            },
            [],
          );

          const paragraphButtonRef = useRef<Resettable>(null);

          const reset: Decorator<OnUpdate> = useCallback(
            (baseOnUpdate) => (params) => {
              if (shouldResetBlockToolbar(params.changeReason)) {
                paragraphButtonRef.current?.reset();
              }
              baseOnUpdate(params);
            },
            [],
          );

          const renderInlineToolbarButtons: Decorator<Render<TextBlockTypesPlugin>> = useCallback(
            (baseRender) => (state) => {
              const { editorState } = state;
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();

              const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
              const limitations = state.getApi().getLimitations();
              const baseBlockTypesAtSelection = getBaseBlockTypes(fullBlockTypesAtSelection);
              const textBlocksAtSelection = !!Collection.intersect(
                TextBlockTypes,
                baseBlockTypesAtSelection,
              ).length;
              const isOnlyParagraphAllowed =
                limitations.allowedTextBlocks.size === 1 &&
                limitations.allowedTextBlocks.has(TextBlockTypeFeature.Paragraph);
              const hideBlockTypeToolbar =
                !state.canUpdateContent() ||
                !textBlocksAtSelection ||
                (hidesDisallowedFeatures && isOnlyParagraphAllowed);

              const buttonsBefore = baseRender(state);

              if (hideBlockTypeToolbar) {
                return buttonsBefore;
              }

              return (
                <>
                  {buttonsBefore}
                  {buttonsBefore && <EditorToolbarDivider />}
                  <BlockTypeInlineToolbarButtons
                    editorState={state.editorState}
                    hidesDisallowedFeatures={!!hidesDisallowedFeatures}
                    limitations={state.getApi().getLimitations()}
                    handleCommand={state.handleCommand}
                  />
                </>
              );
            },
            [hidesDisallowedFeatures],
          );

          const renderBlockToolbarContent: Decorator<
            RenderBlockToolbarContent<TextBlockTypesPlugin>
          > = useCallback(
            (baseRender) => (state, isToolbarVertical) => (
              <>
                {baseRender(state, isToolbarVertical)}
                <BlockTypeBlockToolbarButton
                  editorState={state.editorState}
                  handleCommand={state.handleCommand}
                  hidesDisallowedFeatures={!!hidesDisallowedFeatures}
                  isToolbarVertical={isToolbarVertical}
                  limitations={state.getApi().getLimitations()}
                  ref={paragraphButtonRef}
                />
              </>
            ),
            [hidesDisallowedFeatures],
          );

          const render: Decorator<Render<TextBlockTypesPlugin>> = useCallback(
            (baseRender) => (state) => (
              <>
                {baseRender(state)}
                <RichTextListRefresher
                  content={state.editorState.getCurrentContent()}
                  editorId={state.getEditorId()}
                  editorRef={state.getRteInputRef()}
                />
              </>
            ),
            [],
          );

          const apply: Apply<TextBlockTypesPlugin> = useCallback(
            (state) => {
              state.onUpdate.decorate(onUpdate);
              state.render.decorate(render);
              state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);
              state.renderBlockToolbarContent.decorate(renderBlockToolbarContent);
              state.onUpdate.decorate(reset);
              state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);

              const cycleHeading = () => {
                if (!state.canUpdateContent()) {
                  return;
                }

                return state.executeChange((editorState) => {
                  const content = editorState.getCurrentContent();
                  const selection = editorState.getSelection();

                  const nextBlockType = getNextAllowedBlockTypeForSelection(
                    HeadingBlockTypeSequence,
                    content,
                    selection,
                    state.getApi().getLimitations(),
                  );
                  if (nextBlockType) {
                    return state.getApi().changeBlocksType(editorState, nextBlockType);
                  }
                  return editorState;
                });
              };

              const changeBlockDepth = (blockOffsetToAdd: -1 | 1): void => {
                state.executeChange((editorState) => {
                  const selection = editorState.getSelection();
                  return state.getApi().adjustBlocksDepth(editorState, selection, blockOffsetToAdd);
                });
              };

              const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
                (baseExecuteCommand) => (command, isShiftPressed) => {
                  switch (command) {
                    case RichTextInputCommand.HeadingOne:
                    case RichTextInputCommand.HeadingTwo:
                    case RichTextInputCommand.HeadingThree:
                    case RichTextInputCommand.HeadingFour:
                    case RichTextInputCommand.HeadingFive:
                    case RichTextInputCommand.HeadingSix:
                    case RichTextInputCommand.OrderedList:
                    case RichTextInputCommand.UnorderedList:
                    case RichTextInputCommand.Unstyled: {
                      state.executeChange((editorState) =>
                        state.getApi().executeBlockTypeCommand(editorState, command),
                      );
                      return true;
                    }

                    case RichTextInputCommand.CycleHeading: {
                      cycleHeading();
                      return true;
                    }

                    case RichTextInputCommand.BlockDepthIncrease: {
                      changeBlockDepth(1);
                      return true;
                    }

                    case RichTextInputCommand.BlockDepthDecrease: {
                      changeBlockDepth(-1);
                      return true;
                    }

                    case RichTextInputCommand.Backspace: {
                      if (
                        undoOnBackspace.current &&
                        state.getEditorState().getSelection().isCollapsed()
                      ) {
                        state.undo();
                        return true;
                      }
                      break;
                    }

                    default:
                      break;
                  }

                  return baseExecuteCommand(command, isShiftPressed);
                };

              state.executeCommand.decorate(executeCommand);

              const onMarkdownConversion = (
                markdownConversionResult: MarkdownBlockConversionResult,
              ) => {
                if (!trackRTECommandUsed) {
                  return;
                }

                const editorState = state.getEditorState();
                const { blockType } = markdownConversionResult;

                if (!isMarkdownShorthandBlockType(blockType)) {
                  return;
                }
                const command = MarkdownBlockTypeCommandMap[blockType];
                const editorIsLocked = state.isEditorLocked();
                const commandStatus = state.getApi().getCommandStatus(editorState, command);
                const canUpdateContent = state.canUpdateContent();
                const canExecuteCommand = !editorIsLocked && canCommandExecute(commandStatus);
                const source = RTECommandSource.ShorthandConversion;

                trackRTECommandUsed({
                  canExecuteCommand,
                  canUpdateContent,
                  command,
                  commandStatus,
                  source,
                });
              };

              const postProcessInsertedChars: Decorator<PostProcessInsertedChars> =
                (basePostProcessInsertedChars) => (params) => {
                  const newEditorState = basePostProcessInsertedChars(params);
                  const withMarkdownConverted = state
                    .getApi()
                    .applyAutomaticMarkdownBlockConversion(
                      newEditorState,
                      (markdownConversionResult) => {
                        onMarkdownConversion(markdownConversionResult);
                        setUndoOnBackspaceAfterChanges.current = true;
                      },
                    );

                  return withMarkdownConverted;
                };

              state.canHandleNewCharsNatively.decorate(canHandleNewCharsNatively);
              state.postProcessInsertedChars.decorate(postProcessInsertedChars);

              const allowPlaceholder: Decorator<AllowPlaceholder> =
                (baseAllowPlaceholder) => () => {
                  const blockMap = state.getEditorState().getCurrentContent().getBlockMap();
                  const firstBlock = blockMap.first();
                  if (!!firstBlock && isListItem(firstBlock)) {
                    // Do not display placeholder for the empty list block, as it would overlap with its marker/number
                    return false;
                  }

                  return baseAllowPlaceholder();
                };

              state.allowPlaceholder.decorate(allowPlaceholder);

              return {};
            },
            [
              onUpdate,
              render,
              renderBlockToolbarContent,
              renderInlineToolbarButtons,
              reset,
              trackRTECommandUsed,
            ],
          );

          const { getApiMethods } = useEditorApi<TextBlockTypesPlugin>(editorTextBlockTypeApi);

          return useEditorWithPlugin(baseEditor, props, { apply, getApiMethods });
        },
      }),
    [baseEditor],
  );

type DisplayTextBlockTypesPlugin = EditorPlugin<None, None, None, [PlaceholderPlugin]>;

export const useDisplayTextBlockTypes: PluginCreator<DisplayTextBlockTypesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplayTextBlockTypesPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<DisplayTextBlockTypesPlugin> = useCallback((state) => {
            state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);

            const allowPlaceholder: Decorator<AllowPlaceholder> = (baseAllowPlaceholder) => () => {
              const blockMap = state.getEditorState().getCurrentContent().getBlockMap();
              const firstBlock = blockMap.first();
              if (!!firstBlock && isListItem(firstBlock)) {
                // Do not display placeholder for the empty list block, as it would overlap with its marker/number
                return false;
              }

              return baseAllowPlaceholder();
            };

            state.allowPlaceholder.decorate(allowPlaceholder);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
