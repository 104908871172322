import React from 'react';
import { AssignmentSectionPrimaryAction } from './AssignmentSectionPrimaryAction.tsx';
import { AssignmentSectionSecondaryAction } from './AssignmentSectionSecondaryAction.tsx';

export const AssignmentSectionActions: React.FC<
  Readonly<{
    onCancelSchedulingConfirmationDialog: () => void;
  }>
> = ({ onCancelSchedulingConfirmationDialog }) => (
  <>
    <AssignmentSectionPrimaryAction />
    <AssignmentSectionSecondaryAction
      onCancelSchedulingConfirmationDialog={onCancelSchedulingConfirmationDialog}
    />
  </>
);
