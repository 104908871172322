import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IModalDialogTitleBarCallbackProps {
  readonly onClose: () => void;
}

export interface IModalDialogTitleBarProps extends IModalDialogTitleBarCallbackProps {
  readonly noDivider?: boolean;
  readonly title: string;
}

const propTypes: PropTypesShape<IModalDialogTitleBarProps> = {
  noDivider: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export const ModalDialogTitleBar: React.FC<IModalDialogTitleBarProps> = ({
  noDivider,
  onClose,
  title,
}) => (
  <div
    className={classNames('modal-dialog__header', {
      'modal-dialog__header--with-divider': !noDivider,
    })}
  >
    <div className="modal-dialog__header-title" data-hj-suppress="">
      {title}
    </div>
    <SquareButton
      className="modal-dialog__header-close"
      iconName={IconName.ModalClose}
      onClick={onClose}
      size={SquareButtonSize.Quinary}
      style={ButtonStyle.Quinary}
      tooltipPlacement="left"
      tooltipShortcuts="Esc"
      tooltipText="Close"
      {...getDataUiActionAttribute(DataUiAction.Cancel)}
    />
  </div>
);

ModalDialogTitleBar.displayName = 'ModalDialogTitleBar';
ModalDialogTitleBar.propTypes = propTypes;
