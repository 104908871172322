import { IconButton } from '@kontent-ai/component-library/Button';
import React, { MouseEventHandler, RefObject } from 'react';
import { ActionItem, ActionMenu } from '../../../../_shared/components/ActionMenu.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  EnvironmentsLimitReachedTooltipMessage,
  MissingEnvironmentPermissionsMessage,
} from '../constants/uiConstants.ts';
import { EnvironmentRenameAdvicePopover } from './EnvironmentRenameAdvicePopover.tsx';

export interface IEnvironmentMoreActionsStateProps {
  readonly canAccessToSettings: boolean;
  readonly disabled: boolean;
  readonly environmentId: Uuid;
  readonly hasManageEnvironmentCapability: boolean;
  readonly hasManageProductionEnvironmentCapability: boolean;
  readonly isMasterEnvironment: boolean;
  readonly onCreateEnvironment: (environmentId: Uuid) => void;
  readonly onDeleteEnvironment: (environmentId: Uuid) => void;
  readonly onDismissRenameAdvice: () => void;
  readonly onGoToEnvironment: () => void;
  readonly onGoToEnvironmentSettings: () => void;
  readonly onSwapEnvironment: (environmentId: Uuid) => void;
  readonly planLimitReached: boolean;
  readonly showRenameAdvice: boolean;
}

export const EnvironmentMoreActions: React.FC<IEnvironmentMoreActionsStateProps> = ({
  canAccessToSettings,
  disabled,
  environmentId,
  hasManageEnvironmentCapability,
  hasManageProductionEnvironmentCapability,
  isMasterEnvironment,
  onCreateEnvironment,
  onDeleteEnvironment,
  onDismissRenameAdvice,
  onGoToEnvironment,
  onGoToEnvironmentSettings,
  onSwapEnvironment,
  planLimitReached,
  showRenameAdvice,
}) => {
  const getActions = () => {
    const goToEnvironmentAction: ActionItem = {
      text: 'Go to environment',
      onClick: () => onGoToEnvironment(),
      isDestructive: false,
      hasCapabilityToExecuteAction: true,
      dataUiAction: DataUiAction.GoToEnvironment,
    };

    const goToEnvironmentSettingsAction: ActionItem = {
      text: 'Environment settings',
      onClick: () => onGoToEnvironmentSettings(),
      disabledState: {
        disabledMessage: canAccessToSettings ? undefined : MissingEnvironmentPermissionsMessage,
        isDisabled: !canAccessToSettings,
      },
      isDestructive: false,
      hasCapabilityToExecuteAction: true,
      dataUiAction: DataUiAction.GoToSettings,
    };

    const cloneAction: ActionItem = {
      text: 'Clone',
      onClick: () => onCreateEnvironment(environmentId),
      disabledState: {
        disabledMessage: hasManageEnvironmentCapability
          ? planLimitReached
            ? EnvironmentsLimitReachedTooltipMessage
            : undefined
          : MissingEnvironmentPermissionsMessage,
        isDisabled: planLimitReached || !hasManageEnvironmentCapability,
      },
      isDestructive: false,
      hasCapabilityToExecuteAction: true,
      dataUiAction: DataUiAction.Clone,
    };

    const deleteAction: ActionItem = {
      text: 'Delete',
      onClick: () => onDeleteEnvironment(environmentId),
      disabledState: hasManageEnvironmentCapability
        ? isMasterEnvironment
          ? {
              disabledMessage: 'Production environment cannot be deleted.',
              isDisabled: isMasterEnvironment,
            }
          : undefined
        : {
            disabledMessage: MissingEnvironmentPermissionsMessage,
            isDisabled: !hasManageEnvironmentCapability,
          },
      isDestructive: true,
      hasCapabilityToExecuteAction: true,
      dataUiAction: DataUiAction.Delete,
    };

    const swapAction: ActionItem = {
      text: 'Mark as production',
      onClick: () => onSwapEnvironment(environmentId),
      disabledState: {
        disabledMessage: hasManageProductionEnvironmentCapability
          ? undefined
          : 'You don’t have permission to manage production environment.',
        isDisabled: isMasterEnvironment || !hasManageProductionEnvironmentCapability,
      },
      isDestructive: false,
      hasCapabilityToExecuteAction: true,
      dataUiAction: DataUiAction.SwapEnvironments,
    };

    const productionActions = [
      goToEnvironmentAction,
      goToEnvironmentSettingsAction,
      cloneAction,
      deleteAction,
    ];
    const nonProductionActions = [
      goToEnvironmentAction,
      goToEnvironmentSettingsAction,
      cloneAction,
      deleteAction,
      swapAction,
    ];

    return isMasterEnvironment ? productionActions : nonProductionActions;
  };

  const _renderToggleButton = (
    ref: RefObject<HTMLButtonElement>,
    onClick: MouseEventHandler<HTMLElement>,
    isOpen: boolean,
    isDisabled: boolean,
  ): React.ReactNode => {
    return (
      <EnvironmentRenameAdvicePopover
        onDismiss={onDismissRenameAdvice}
        isVisible={showRenameAdvice}
      >
        <IconButton
          ref={ref}
          onClick={onClick}
          iconName="Ellipsis"
          size="medium"
          buttonStyle="tertiary"
          tooltipText={
            isDisabled ? MissingEnvironmentPermissionsMessage : isOpen ? '' : 'More actions'
          }
          tooltipPlacement="top-start"
          ariaLabel="More actions"
          buttonState={isDisabled ? 'disabled' : 'default'}
          {...getDataUiActionAttribute(DataUiAction.MoreActions)}
        />
      </EnvironmentRenameAdvicePopover>
    );
  };

  return (
    <ActionMenu
      actions={getActions()}
      renderToggleButton={(
        ref: RefObject<HTMLButtonElement>,
        onClick: MouseEventHandler<HTMLElement>,
        isOpen: boolean,
      ) => _renderToggleButton(ref, onClick, isOpen, disabled)}
    />
  );
};

EnvironmentMoreActions.displayName = 'EnvironmentMoreActions';
