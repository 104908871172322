import { Textarea } from '@kontent-ai/component-library/Input';
import React, { ChangeEvent, useState } from 'react';
import { ModalDialog } from '../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ReviewContentDialogProps = {
  readonly initialGuidelines: string;
  readonly isDialogOpen: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (guidelines: string) => void;
};

export const ReviewContentDialog: React.FC<ReviewContentDialogProps> = ({
  initialGuidelines,
  isDialogOpen,
  onClose,
  onSubmit,
}) => {
  const [guidelines, setGuidelines] = useState(initialGuidelines);

  const onAiGuidelinesChanged = (e: ChangeEvent<HTMLTextAreaElement>): void =>
    setGuidelines(e.currentTarget.value);

  return (
    <ModalDialog
      headline="Review content"
      isOpen={isDialogOpen}
      isDismissable
      primaryAction={{
        text: 'Review content',
        disabled: !guidelines,
        tooltipText: guidelines ? '' : 'Fill the AI guidelines first.',
        onClick: () => onSubmit(guidelines),
      }}
      onClose={onClose}
    >
      <Textarea
        autoFocus
        value={guidelines}
        placeholder="Write guidelines for AI with rules to review your content …"
        onChange={onAiGuidelinesChanged}
        minRows={3}
        maxRows={10}
        label="AI guidelines"
        caption="Your guidelines can contain different rules for your content, such as tone of voice, brand guidelines, language guidelines, grammar rules."
        {...getDataUiInputAttribute(DataUiInput.Text)}
      />
    </ModalDialog>
  );
};

ReviewContentDialog.displayName = 'ReviewContentDialog';
