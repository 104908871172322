export const WidgetEmptyStateDefaultTitle = 'Great!';

export const NoOverdueTasksMessage = 'No overdue tasks.';

export const AllContentUpToDateMessage = 'All your content is up-to-date.';

export const NoUnchangedItemsMessage = 'No unchanged items.';

export const NoRecentlyEditedItemsByYouTitle = 'No recently edited items';
export const NoRecentlyEditedItemsByYouMessage =
  'Need to start? Jump into an item or create a new one.';

export const NoItemAssignedToYouTitle = 'No items assigned to you yet';
export const NoItemAssignedToYouMessage = 'Items you’re a contributor to will show up here.';

export const NoTasksAssignedByYouTitle = 'All tasks are done!';
export const NoTasksAssignedByYouMessage = 'Delegate tasks to your team and they’ll appear here.';

export const NoTaskAssignedToYouTitle = 'All tasks are done!';
export const NoTaskAssignedToYouMessage = 'Check back later for any new tasks assigned to you.';
