import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { EditorState } from 'draft-js';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Callback, RegisterCallback } from '../../../../_shared/types/RegisterCallback.type.ts';
import { EditorSizeHandler } from '../../components/utility/EditorSizeHandler.tsx';
import { HideDuringSelection } from '../../components/utility/HideDuringSelection.tsx';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import {
  doesSelectionContainText,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { InlineToolbar as InlineToolbarComponent } from './components/inline/InlineToolbar.tsx';
import { useSelfPositioningComponentCallback } from './hooks/useSelfPositioningComponentCallback.tsx';

export type CanDisplayInlineToolbar = (editorState: EditorState) => boolean;

type InlineToolbarPluginState = {
  readonly canDisplayInlineToolbar: DecorableFunction<CanDisplayInlineToolbar>;
  readonly renderInlineToolbarButtons: DecorableFunction<Render<InlineToolbarPlugin>>;
};

type InlineToolbarPluginProps = {
  readonly hidesDisallowedFeatures?: boolean;
};

export type InlineToolbarPlugin = EditorPlugin<
  InlineToolbarPluginState,
  InlineToolbarPluginProps,
  None,
  [StylesPlugin]
>;

type InlineToolbarProps = {
  readonly editorRef: React.RefObject<HTMLDivElement>;
  readonly editorState: EditorState;
  readonly registerUpdateToolbarPosition: RegisterCallback<Callback>;
};

const InlineToolbar: React.FC<React.PropsWithChildren<InlineToolbarProps>> = ({
  editorState,
  editorRef,
  children,
  registerUpdateToolbarPosition,
}) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const metadataAtSelection = getMetadataAtSelection(content, selection);
  const selectionContainsText = doesSelectionContainText(selection, metadataAtSelection);
  const showInlineToolbar =
    !!children && selection.getHasFocus() && !selection.isCollapsed() && selectionContainsText;
  if (!showInlineToolbar) {
    return null;
  }

  return (
    <InlineToolbarComponent
      editorRef={editorRef}
      registerUpdateToolbarPosition={registerUpdateToolbarPosition}
      renderContent={() => (
        <Box padding={Spacing.S}>
          <Inline spacing={Spacing.S}>{children}</Inline>
        </Box>
      )}
    />
  );
};

InlineToolbar.displayName = 'InlineToolbar';

const ToolbarButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${px(Spacing.S)};
`;

ToolbarButtonContainer.displayName = 'ToolbarButtonContainer';

const renderInlineToolbarButtons: Render<InlineToolbarPlugin> = () => null;

const canDisplayInlineToolbar: CanDisplayInlineToolbar = () => true;

export const useInlineToolbar: PluginCreator<InlineToolbarPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('InlineToolbarPlugin', {
        ComposedEditor: (props) => {
          const {
            registerUpdateSelfPositioningComponent,
            updateSelfPositioningComponent,
            onUpdateDecorator,
          } = useSelfPositioningComponentCallback();

          const renderOverlays: Decorator<Render<InlineToolbarPlugin>> = useCallback(
            (baseRenderOverlays) => (state) => (
              <>
                {baseRenderOverlays(state)}
                <HideDuringSelection observeRef={state.getWrapperRef()}>
                  {state.canDisplayInlineToolbar(state.editorState) && (
                    <InlineToolbar
                      editorRef={state.getRteInputRef()}
                      editorState={state.editorState}
                      registerUpdateToolbarPosition={registerUpdateSelfPositioningComponent}
                    >
                      {state.renderInlineToolbarButtons(state)}
                    </InlineToolbar>
                  )}
                </HideDuringSelection>
              </>
            ),
            [registerUpdateSelfPositioningComponent],
          );

          const render: Decorator<Render<InlineToolbarPlugin>> = useCallback(
            (baseRender) => (state) => (
              <>
                {baseRender(state)}
                <EditorSizeHandler
                  editorRef={state.getWrapperRef()}
                  onSizeChanged={updateSelfPositioningComponent}
                />
              </>
            ),
            [updateSelfPositioningComponent],
          );

          const apply: Apply<InlineToolbarPlugin> = useCallback(
            (state) => {
              state.onUpdate.decorate(onUpdateDecorator);
              state.render.decorate(render);
              state.renderOverlays.decorate(renderOverlays);

              return {
                canDisplayInlineToolbar: decorable(canDisplayInlineToolbar),
                renderInlineToolbarButtons: decorable(renderInlineToolbarButtons),
              };
            },
            [onUpdateDecorator, render, renderOverlays],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
