import React from 'react';
import { ConfirmationDialog } from '../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';

type Props = {
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

export const CancelScheduleUnpublishConfirmationDialog: React.FC<Props> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <ConfirmationDialog
      confirmButtonText="Remove schedule"
      headerContent="Remove the unpublishing schedule"
      onClose={onCancel}
      onConfirm={onConfirm}
    >
      <p className="confirmation-dialog__section">
        The scheduled unpublish date will be removed and{' '}
        <strong>the item won’t be unpublished and archived.</strong>
      </p>
      <p className="confirmation-dialog__section">
        Are you sure you want to remove the scheduled unpublish date?
      </p>
    </ConfirmationDialog>
  );
};

CancelScheduleUnpublishConfirmationDialog.displayName = 'CancelScheduleUnpublishConfirmationDialog';
