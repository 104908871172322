import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  GridListMenu,
  VerticalMenuItem,
  useGridListMenuState,
} from '@kontent-ai/component-library/VerticalMenu';
import {
  Typography,
  colorTextHint,
  colorTextHintInverse,
} from '@kontent-ai/component-library/tokens';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { SelectGroupRenderMode } from '../../../../../component-library/components/StatusBar/Selects/types/SelectGroupRenderMode.ts';
import { ProductionEnvironmentDefaultName } from '../../../../applications/projectSettings/environments/constants/uiConstants.ts';
import { AppNames } from '../../../constants/applicationNames.ts';
import { StatefulDropDown } from '../../../uiComponents/DropDown/StatefulDropDown.tsx';
import {
  DataUiAction,
  DataUiCollection,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { StatusBarDropDownSelected } from '../../StatusBar/StatusBarDropDownSelected.tsx';
import { ItemSettings } from '../ItemSettings.tsx';
import { optionMaxWidth, projectMenuTippyOptions } from '../constants.ts';
import { MenuItem } from '../types.type.ts';

type ProductionEnvironmentLabelProps = Readonly<{
  type: 'default' | 'inverse';
}>;
const ProductionEnvironmentLabel = ({ type }: ProductionEnvironmentLabelProps) => (
  <Box
    typography={Typography.LabelLarge}
    color={type === 'inverse' ? colorTextHintInverse : colorTextHint}
    overflow="hidden"
    textOverflow="ellipsis"
  >
    ({ProductionEnvironmentDefaultName})
  </Box>
);

export type EnvironmentMenuItem = MenuItem &
  VerticalMenuItem<EnvironmentMenuItem> & {
    readonly dataAttributes: ObjectWithDataAttribute;
    readonly isSelected: boolean;
    readonly settingsEnabled: boolean;
    readonly settingsLink: string;
  };

type EnvironmentSelectProps = {
  readonly environmentId: Uuid;
  readonly environmentItems: ReadonlyArray<EnvironmentMenuItem>;
  readonly productionId: Uuid;
  readonly renderMode: SelectGroupRenderMode;
  readonly selectedEnvironmentName: string;
};

export const EnvironmentSelect: React.FC<EnvironmentSelectProps> = ({
  environmentId,
  environmentItems,
  productionId,
  selectedEnvironmentName,
  renderMode,
}) => {
  const history = useHistory();
  const { state } = useGridListMenuState([
    {
      id: 'environments',
      items: environmentItems,
      label: 'Environments',
      type: 'section',
    },
  ]);

  const [transitionFinished, setTransitionFinished] = useState(true);
  const handleTransitionEnd = () => {
    setTransitionFinished(true);
  };

  return (
    <StatefulDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.Environments)}
      onClose={() => setTransitionFinished(false)}
      renderContent={(closeEnvironmentList) =>
        transitionFinished && (
          <GridListMenu
            pinnedItemId="environments-pinned"
            aria-label="Environment select"
            state={state}
            items={environmentItems}
            maxWidth={optionMaxWidth}
            renderItem={({ item }) => (
              <GridListMenu.Item
                item={item}
                state={state}
                onPress={() => {
                  closeEnvironmentList();
                  if (item.value) {
                    history.push(item.value.link);
                  }
                }}
                trailingElements={
                  <Inline align="center" noWrap>
                    {item.value?.id === productionId && (
                      <ProductionEnvironmentLabel type="default" />
                    )}
                    {item.value?.settingsEnabled && (
                      <ItemSettings
                        onClick={() => {
                          closeEnvironmentList();
                          if (item.value) {
                            history.push(item.value.settingsLink);
                          }
                        }}
                        tooltipText={AppNames.EnvironmentSettings}
                      />
                    )}
                  </Inline>
                }
              />
            )}
          />
        )
      }
      renderSelectedOption={(ref, toggleEnvironmentList, isOpen) => (
        <StatusBarDropDownSelected
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.OpenEnvironmentMenu)}
          isExpanded={isOpen}
          onClick={toggleEnvironmentList}
          ref={ref}
          renderMode={renderMode}
          onChevronTransitionEnd={handleTransitionEnd}
          tag={environmentId === productionId && <ProductionEnvironmentLabel type="inverse" />}
        >
          {selectedEnvironmentName}
        </StatusBarDropDownSelected>
      )}
      tippyOptions={projectMenuTippyOptions}
    />
  );
};

EnvironmentSelect.displayName = 'EnvironmentSelect';
