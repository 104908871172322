import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  isAiBetaEnabled as isAiBetaEnabledSelector,
  isAiReviewPrototypeEnabled,
} from '../../../../_shared/selectors/aiSelectors.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { useLockEditor } from '../behavior/LockEditorPlugin.tsx';
import { useAiMenu } from './AiMenuPlugin.tsx';
import { useChangeTone } from './actions/ChangeTone/ChangeTonePlugin.tsx';
import { useMatchWritingStyleOfItem } from './actions/ChangeTone/MatchWritingStyleOfItem/MatchWritingStyleOfItemPlugin.tsx';
import { useCreateTitle } from './actions/CreateTitle/CreateTitlePlugin.tsx';
import { useImproveContent } from './actions/ImproveContent/ImproveContentPlugin.tsx';
import { useMakeShorter } from './actions/MakeShorter/MakeShorterPlugin.tsx';
import { useReviewContent } from './actions/ReviewContent/ReviewContentPlugin.tsx';
import { useSummarize } from './actions/Summarize/SummarizePlugin.tsx';

const aiPlugins = [
  useAiMenu,
  useLockEditor,
  useMakeShorter,
  useImproveContent,
  useChangeTone,
  useMatchWritingStyleOfItem,
  useCreateTitle,
  useSummarize,
];
const noPlugins: ReadonlyArray<PluginCreator> = [];

export const useAiPlugins = (): ReadonlyArray<PluginCreator> => {
  const isAiBetaEnabled = useSelector(isAiBetaEnabledSelector);
  const isReviewEnabled = useSelector(isAiReviewPrototypeEnabled);

  return isAiBetaEnabled
    ? [...aiPlugins, ...(isReviewEnabled ? [useReviewContent] : [])]
    : noPlugins;
};
