import { notNullNorUndefined } from '@kontent-ai/utils';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { BlockType } from '../../../../utils/blocks/blockType.ts';
import { EditorFeatureLimitations } from '../../../apiLimitations/api/EditorFeatureLimitations.ts';
import { RichTextInputCommand } from '../../../keyboardShortcuts/api/EditorCommand.ts';
import { ToolbarButtonInputProps, getToolbarButtonProps } from '../../utils/toolbarUtils.ts';
import { EditorToolbarMenu, EditorToolbarMenuItem } from './EditorToolbarMenu.tsx';

export type CommandToolbarMenuItem = Pick<
  EditorToolbarMenuItem,
  'label' | 'shortcuts' | 'uiAction'
> &
  Partial<Pick<EditorToolbarMenuItem, 'disabled' | 'tooltipText'>> & {
    readonly command: RichTextInputCommand;
    readonly iconName?: IconName;
  };

type Props = {
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly hidesDisallowedFeatures: boolean;
  readonly limitations: EditorFeatureLimitations;
  readonly onCommand: (command: RichTextInputCommand) => void;
  readonly items: ReadonlyArray<CommandToolbarMenuItem>;
};

export const EditorCommandMenu = ({
  fullBlockTypesAtSelection,
  hidesDisallowedFeatures,
  items,
  limitations,
  onCommand,
}: Props) => {
  const inputProps: ToolbarButtonInputProps = {
    fullBlockTypesAtSelection,
    currentVisualStyle: null,
    hidesDisallowedFeatures,
    limitations,
    metadataAtSelection: null,
    onCommand,
    selectionContainsText: null,
    tooltipPlacement: 'top-start',
  };

  const menuItems = items
    .map((item) => getToolbarMenuItem(item, inputProps))
    .filter(notNullNorUndefined);

  return <EditorToolbarMenu items={menuItems} />;
};

export const getToolbarMenuItem = (
  { command, disabled, tooltipText, iconName, label, shortcuts, uiAction }: CommandToolbarMenuItem,
  inputProps: ToolbarButtonInputProps,
): EditorToolbarMenuItem | null => {
  const buttonProps = getToolbarButtonProps(command, inputProps);

  return (
    buttonProps && {
      ...buttonProps,
      disabled: disabled || buttonProps.disabled,
      tooltipText:
        tooltipText ||
        (buttonProps.disabled || buttonProps.isViolated ? buttonProps.tooltipText : undefined),
      iconName,
      id: command,
      label,
      shortcuts,
      uiAction,
    }
  );
};
