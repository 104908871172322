import {
  trackUserEvent,
  trackUserEventWithData,
} from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { CreateNewVersion } from '../../../../../../../_shared/constants/itemActions.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DataUiWorkflowAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createNewVersion } from '../../../actions/thunkContentItemEditingActions.ts';
import { WorkflowStepActionButton } from '../../../components/details/WorkflowStepActionButton.tsx';

export const CreateNewVersionButton = () => {
  const dispatch = useDispatch();
  const createNew = async () => {
    await dispatch(
      createNewVersion(() => dispatch(trackUserEvent(TrackedEvent.ContentEntryNewVersion))),
    );
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.CreateANewVersion,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.CreateNewVersion}
      label={CreateNewVersion}
      onClick={createNew}
    />
  );
};
