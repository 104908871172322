import { mergeProps } from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, { ComponentProps, RefAttributes, useRef } from 'react';
import { VariableSizeList as VirtualizedList } from 'react-window';
// eslint-disable-next-line import/no-restricted-paths
import { DropDownMenuControlled } from '../../../../../client/component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { DefaultTag } from '../../../../../client/component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../client/component-library/components/Tag/Tag.tsx';
import { RemovalState } from '../../../../../client/component-library/components/Tag/removalStateEnum.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import { Inline } from '../../../layout/Inline/Inline.tsx';
import { spacingBetweenTags } from '../../../tokens/decision/spacing.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { px } from '../../../tokens/utils/utils.ts';
import {
  DataUiCLElement,
  ObjectWithDataAttribute,
  getDataUiCLElementAttribute,
  getDataUiComponentAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { IInputProps } from '../../Input/Input.tsx';
import { BaseInputComponent } from '../../Input/components/BaseInputComponent.tsx';
import { InputState } from '../../Input/inputStateEnum.ts';
import { simpleMenuItemHeight } from '../../MenuItem/decisionTokens.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { isItem } from '../../VerticalMenu/utils/utils.tsx';
import { unrequired } from '../../_utils/unrequired.ts';
import { AutoGrowingTextField } from '../components/AutoGrowingTextField.tsx';
import { ChevronTrigger } from '../components/ChevronTrigger.tsx';
import { CollapsibleOption } from '../components/CollapsibleOption.tsx';
import { ListBox } from '../components/ListBox.tsx';
import { SelectMenu } from '../components/SelectMenu.tsx';
import { useSelectMenu } from '../hooks/useSelectMenu.tsx';
import { IBaseSelectItem, ISelectItem, ItemId, VirtualizedOrNot } from '../types.ts';
import { findItem } from '../utils/findItem.ts';
import { multiSelectInputSpacing, multiSelectTagOffset } from './tokens.ts';
import { useMultiSelect } from './useMultiSelect.ts';
import {
  ExpandableOrNot,
  MultiSelectState,
  PublicMultiSelectState,
  UseMultiSelectStateOptions,
  useMultiSelectState,
} from './useMultiSelectState.ts';

type MultiSelectInputProps = Partial<
  Pick<
    IInputProps,
    | 'ariaLabel'
    | 'autoFocus'
    | 'caption'
    | 'delayAutoFocus'
    | 'label'
    | 'placeholder'
    | 'tooltipPlacement'
    | 'tooltipText'
  >
>;

export type RenderOptionCallback<TItem extends ISelectItem<TItem>> = (
  permanentItemId: ItemId,
  permanentItem: TItem,
  defaultTagProps: Pick<ComponentProps<typeof DefaultTag>, 'label' | 'removalState'>,
  state: PublicMultiSelectState<TItem>,
) => React.ReactNode;

export type RenderGeneralTag = (
  count: number,
  defaultTagProps: Omit<ComponentProps<typeof DefaultTag>, 'children'>,
) => React.ReactNode;

type GeneralTagOrNot = Readonly<
  | {
      /** Minimum number of selected options from which they are combined into a single 'GeneralTag'. */
      generalTagThreshold: number;
      /** Use our GeneralTag component to render a single tag when 'generalTagThreshold' of selected options is reached. */
      renderGeneralTag: RenderGeneralTag;
    }
  | {
      generalTagThreshold?: never;
      renderGeneralTag?: never;
    }
>;

export type MultiSelectProps<TItem extends ISelectItem<TItem>> = GeneralTagOrNot &
  MultiSelectInputProps &
  // Omit flattens the distributive type therefore `expandedKeys` and `onExpandedChange` are excluded
  Omit<
    UseMultiSelectStateOptions<TItem>,
    'inputRef' | 'onSelectionChange' | 'expandedKeys' | 'onExpandedChange'
  > &
  VirtualizedOrNot<TItem> &
  ExpandableOrNot<TItem> &
  Readonly<{
    delayAutoFocus?: number;
    inputDataAttributes?: ObjectWithDataAttribute;
    onSelectionChange?: (itemIds: ReadonlySet<ItemId>, closeMenu: () => void) => void;
    permanentOptions?: ReadonlyArray<TItem>;
    placeholderType?: 'text-field' | 'tag';
    renderPrefix?: (
      selectedItemIds: ReadonlyArray<ItemId>,
      selectedItems: ReadonlyArray<TItem>,
    ) => React.ReactNode;
    renderPermanentOption?: RenderOptionCallback<TItem>;
    renderSelectedOption?: RenderOptionCallback<TItem>;
    verticalMenuDataAttributes?: ObjectWithDataAttribute;
  }>;

const propTypes: PropTypeMap<MultiSelectProps<IBaseSelectItem>> = {
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  caption: PropTypes.string,
  customFilterPredicate: PropTypes.func,
  defaultSelectedItemIds: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Set<ItemId>)]),
  delayAutoFocus: PropTypes.number,
  disabledItemIds: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Set<ItemId>)]),
  expandedKeys: PropTypes.instanceOf(Set<ItemId>) as any,
  generalTagThreshold: PropTypes.number as any,
  inputDataAttributes: PropTypes.object,
  inputState: PropTypes.oneOf(Object.values(InputState)),
  isVirtualized: PropTypes.bool as any,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  onExpandedChange: PropTypes.func as any,
  onInputChange: PropTypes.func,
  onMenuClose: PropTypes.func,
  onSelectionChange: PropTypes.func,
  optionHeight: PropTypes.func as any,
  permanentOptions: PropTypes.array,
  placeholder: PropTypes.string,
  placeholderType: PropTypes.oneOf(['text-field', 'tag']),
  renderGeneralTag: PropTypes.func as any,
  renderMenuOption: PropTypes.func,
  renderPermanentOption: PropTypes.func,
  renderPrefix: PropTypes.func,
  renderSelectedOption: PropTypes.func,
  selectedItemIds: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Set<ItemId>)]),
  tooltipPlacement: unrequired(Tooltip.propTypes?.placement),
  tooltipText: unrequired(Tooltip.propTypes?.tooltipText),
  verticalMenuDataAttributes: PropTypes.object,
};

const renderCollapsibleOption: MultiSelectProps<IBaseSelectItem>['renderMenuOption'] = (props) => (
  <CollapsibleOption {...props} />
);

type GetRenderMenuOptionCallback = (
  pickedProps: Pick<MultiSelectProps<IBaseSelectItem>, 'renderMenuOption' | 'expandedKeys'>,
) => MultiSelectProps<IBaseSelectItem>['renderMenuOption'];
const getRenderMenuOption: GetRenderMenuOptionCallback = ({ renderMenuOption, expandedKeys }) => {
  if (renderMenuOption) {
    return renderMenuOption;
  }

  if (expandedKeys) {
    return renderCollapsibleOption;
  }

  return undefined;
};

export const MultiSelectComponent = React.forwardRef(
  <TItem extends ISelectItem<TItem>>(
    props: MultiSelectProps<TItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const {
      ariaLabel,
      autoFocus,
      caption,
      customFilterPredicate,
      defaultSelectedItemIds,
      delayAutoFocus,
      disabledItemIds,
      expandedKeys,
      generalTagThreshold,
      inputDataAttributes,
      inputState,
      isVirtualized = false,
      items,
      label,
      onExpandedChange,
      onInputChange,
      onMenuClose,
      onSelectionChange,
      optionHeight = simpleMenuItemHeight,
      permanentOptions,
      placeholder,
      placeholderType = 'text-field',
      renderGeneralTag,
      renderMenuOption,
      renderPermanentOption,
      renderPrefix,
      renderSelectedOption,
      selectedItemIds,
      tooltipPlacement,
      tooltipText,
      verticalMenuDataAttributes,
      ...otherProps
    } = props;
    const isReadOnly = inputState === InputState.ReadOnly;
    const isDisabled = inputState === InputState.Disabled;

    const inputRef = useRef<HTMLTextAreaElement>(null);
    const inputFieldRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const menuRef = React.useRef<HTMLDivElement>(null);
    const virtualizedListRef = React.useRef<VirtualizedList<HTMLDivElement>>(null);

    const state: MultiSelectState<TItem> = useMultiSelectState({
      ...props,
      customFilterPredicate,
      inputRef,
      onSelectionChange,
    });

    const { selectionManager } = state;

    const { inputProps, listBoxProps, triggerProps } = useMultiSelect(
      {
        ...props,
        isVirtualized,
        inputRef,
        inputState,
        inputFieldRef,
        triggerRef,
        menuRef,
        virtualizedListRef,
      },
      state,
    );

    const { isOpen } = state;

    const chevronTrigger = <ChevronTrigger ref={triggerRef} {...triggerProps} />;

    const selectedKeys = [...(selectionManager.selectedKeys ?? [])].map((key) => key.toString());
    const selectedItems = selectedKeys.flatMap<TItem>((key) => {
      const item = findItem(items, ({ id }) => id === key);
      if (!item) {
        return [];
      }
      return isItem(item) ? [item] : [];
    });

    const shouldRenderPlaceholder = !selectedItems.length && !permanentOptions?.length;
    const areChangesAllowed =
      inputState !== InputState.Disabled && inputState !== InputState.ReadOnly;

    const selectMenuProps = useSelectMenu(
      {
        isReadOnly,
        isVirtualized,
        menuRef,
        optionHeight,
        renderMenuOption: getRenderMenuOption(props),
        selectionMode: 'multi',
        verticalMenuDataAttributes,
        virtualizedListRef,
      },
      state,
    );

    const renderOption = (
      item: TItem,
      defaultTagProps: {
        readonly label: string;
      },
      customRenderer: MultiSelectProps<TItem>['renderSelectedOption'],
    ) => {
      if (customRenderer) {
        return (
          <React.Fragment key={item.id}>
            {customRenderer(item.id, item, defaultTagProps, state)}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={item.id}>
          <DefaultTag {...defaultTagProps} />
        </React.Fragment>
      );
    };

    const renderSelectedOptions = () => {
      const shouldRenderGeneralTag = !!(
        renderGeneralTag &&
        generalTagThreshold > 0 &&
        selectedItems.length >= generalTagThreshold
      );

      if (shouldRenderGeneralTag) {
        const generalTagProps = {
          onLabelClick: () => state.openMenu(),
          onRemoveClick: () => selectionManager.clearSelection(),
          disabled: !areChangesAllowed,
          removalState: areChangesAllowed ? RemovalState.Allowed : RemovalState.NoRemoval,
          ...getDataUiCLElementAttribute(DataUiCLElement.MultiSelectOption),
        };

        return renderGeneralTag(selectedItems.length, generalTagProps);
      }

      return selectedItems.map((item) => {
        const defaultTagProps = {
          label: item.label,
          onLabelClick: () => selectionManager.setFocusedKey(item.id),
          onRemoveClick: () => selectionManager.select(item.id),
          disabled: !areChangesAllowed,
          removalState: areChangesAllowed ? RemovalState.Allowed : RemovalState.NoRemoval,
          ...getDataUiCLElementAttribute(DataUiCLElement.MultiSelectOption),
        };

        return renderOption(item, defaultTagProps, renderSelectedOption);
      });
    };

    const renderTags = () => {
      if (selectedItems.length || permanentOptions?.length) {
        return (
          <>
            {permanentOptions?.map((item) => {
              const defaultTagProps = {
                label: item.label,
                removalState: RemovalState.NoRemoval,
                ...getDataUiCLElementAttribute(DataUiCLElement.MultiSelectPermanentOption),
              };

              return renderOption(item, defaultTagProps, renderPermanentOption);
            })}
            {renderSelectedOptions()}
          </>
        );
      }

      if (placeholder && placeholderType === 'tag') {
        return (
          <Tag
            background={BaseColor.Gray30}
            label={placeholder}
            removalState={RemovalState.NoRemoval}
            {...getDataUiCLElementAttribute(DataUiCLElement.MultiSelectPlaceholder)}
          />
        );
      }

      return null;
    };

    return (
      <div ref={forwardedRef} {...getDataUiComponentAttribute(MultiSelect)} {...otherProps}>
        <DropDownMenuControlled
          triggerRef={inputFieldRef}
          renderTrigger={(dropDownTriggerProps) => {
            const {
              ref: dropDownTriggerRef,
              'aria-expanded': _,
              ...restTriggerProps
            } = filterOutCallbackProps(dropDownTriggerProps);
            return (
              <BaseInputComponent
                renderControlComponent={(
                  controlComponentRef,
                  { placeholder: controlComponentPlaceholder, ...injectedProps },
                ) => (
                  <Box paddingY={multiSelectInputSpacing} marginX={px(multiSelectTagOffset * -1)}>
                    <Inline
                      align="center"
                      spacing={spacingBetweenTags}
                      onClick={inputProps.onClick}
                      {...getDataUiCLElementAttribute(DataUiCLElement.MultiSelectOptionArea)}
                    >
                      {renderTags()}
                      <AutoGrowingTextField
                        placeholder={
                          shouldRenderPlaceholder && placeholderType === 'text-field'
                            ? controlComponentPlaceholder
                            : undefined
                        }
                        ref={controlComponentRef as React.Ref<HTMLTextAreaElement>}
                        {...getDataUiCLElementAttribute(DataUiCLElement.MultiSelectSearchText)}
                        {...injectedProps}
                      />
                    </Inline>
                  </Box>
                )}
                inputFieldRef={dropDownTriggerRef}
                prefix={renderPrefix?.(selectedKeys, selectedItems)}
                suffixes={[chevronTrigger]}
                {...mergeProps(inputProps, restTriggerProps, {
                  ariaLabel,
                  autoFocus,
                  caption,
                  inputState,
                  label,
                  placeholder,
                  tooltipPlacement,
                  tooltipText,
                })}
                {...inputDataAttributes}
              />
            );
          }}
          renderDropDown={(triggerWidth, _, menuProps) => (
            <ListBox {...mergeProps(listBoxProps, menuProps)}>
              <SelectMenu triggerWidth={triggerWidth} {...selectMenuProps} />
            </ListBox>
          )}
          isDropDownVisible={isOpen && !isDisabled}
          onDropDownVisibilityChange={(newIsOpen) => {
            if (!newIsOpen) {
              state.revertState();
            }
          }}
          type="listbox"
        />
      </div>
    );
  },
);

MultiSelectComponent.displayName = 'MultiSelect';
MultiSelectComponent.propTypes = propTypes;

export const MultiSelect = MultiSelectComponent as (<TItem extends ISelectItem<TItem>>(
  p: MultiSelectProps<TItem> & RefAttributes<HTMLDivElement>,
) => ReturnType<React.FC<MultiSelectProps<TItem>>>) &
  Pick<React.FC, 'displayName'>;

type TriggerProps = Parameters<ComponentProps<typeof DropDownMenuControlled>['renderTrigger']>[0];
type PickedProps = Pick<
  TriggerProps,
  'ref' | 'aria-controls' | 'aria-expanded' | 'aria-haspopup' | 'id'
>;

// We run into problems when the callbacks were spread on the textarea components, so we filter them out
const filterOutCallbackProps = (props: TriggerProps): PickedProps =>
  Object.fromEntries(Object.entries(props).filter(([key]) => !key.startsWith('on')));
