import { Collection } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.type.ts';
import { Task, createTaskFromServerModel } from '../../../../_shared/models/Task.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { RequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import {
  loadContentTypes,
  loadLanguages,
  loadUsers,
} from '../../../../data/actions/thunkDataActions.ts';
import { ITaskRepository } from '../../../../repositories/interfaces/ITaskRepository.type.ts';
import { ITaskServerModel } from '../../../../repositories/serverModels/ITaskServerModel.type.ts';
import {
  YourContent_InitYourTasks_Finished,
  YourContent_InitYourTasks_Started,
  YourTasks_LoadRelatedItems_Finished,
  YourTasks_LoadRelatedItems_Started,
} from '../../constants/yourContentActionTypes.ts';
import { YourTasksLimit } from '../../constants/yourTasks.ts';
import { getYourTaskVariantIdentifier } from '../../selectors/getYourTasks.ts';
import { ItemIdsByVariantIds } from '../../types/ItemIdsByVariantIds.type.ts';

type LoadRelatedItems = (itemIds: ItemIdsByVariantIds, abortSignal?: AbortSignal) => ThunkPromise;

interface IDeps {
  readonly loadRelatedItems: LoadRelatedItems;
  readonly taskRepository: Pick<ITaskRepository, 'getYourTasks'>;
  readonly requestTokenFactory: RequestTokenFactory;
}

export const createTokenizedInitYourTasksStarted = (cancellationToken: Uuid) => () =>
  ({
    type: YourContent_InitYourTasks_Started,
    payload: {
      cancellationToken,
    },
  }) as const;

const finished = (yourTasks: Task[], hasMore: boolean) =>
  ({
    type: YourContent_InitYourTasks_Finished,
    payload: {
      hasMore,
      yourTasks,
    },
  }) as const;

const loadRelatedItemsStarted = () =>
  ({
    type: YourTasks_LoadRelatedItems_Started,
  }) as const;

const loadRelatedItemsFinished = () =>
  ({
    type: YourTasks_LoadRelatedItems_Finished,
  }) as const;

export type InitYourTasksActionsType = ReturnType<
  | ReturnType<typeof createTokenizedInitYourTasksStarted>
  | typeof finished
  | typeof loadRelatedItemsStarted
  | typeof loadRelatedItemsFinished
>;

const isLoadingAlreadyInProgress = (getState: GetState): boolean =>
  !!getState().yourContentApp.yourTasks.cancellationToken;

export const createInitYourTasks =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    if (isLoadingAlreadyInProgress(getState)) {
      return; // Need to prevent multiple initializations at once to avoid unintended behavior, due to the way the combination of cancellation and caching in repository work.
    }

    const { isCurrentTokenValid, tokenizedActionCreator: initYourTasksStarted } =
      deps.requestTokenFactory(getState);

    dispatch(initYourTasksStarted());

    await Promise.all([
      dispatch(loadUsers()),
      dispatch(loadLanguages()),
      dispatch(loadContentTypes()),
    ]);

    // The filter has a side effect that loads related content items, but it is not called when tasks are loaded from cache.
    const taskFilter = createTasksFilter(dispatch, getState, deps.loadRelatedItems, abortSignal);
    const { data: tasks, wasDataCountLimitExceeded: hasMore } =
      await deps.taskRepository.getYourTasks(
        YourTasksLimit,
        taskFilter,
        isCurrentTokenValid,
        abortSignal,
      );

    if (isCurrentTokenValid()) {
      dispatch(finished(tasks.map(createTaskFromServerModel), hasMore));

      const missingRelatedVariants = getMissingRelatedVariants(getState());
      if (missingRelatedVariants.size > 0) {
        // Ensure all tasks' related content items are loaded when tasks are received from cache.
        await dispatch(deps.loadRelatedItems(missingRelatedVariants, abortSignal));
      }
      dispatch(loadRelatedItemsFinished());
    }
  };

const createTasksFilter = (
  dispatch: Dispatch,
  getState: GetState,
  loadRelatedItems: LoadRelatedItems,
  abortSignal?: AbortSignal,
) => {
  let startDispatched = false;
  return async (
    tasks: ReadonlyArray<ITaskServerModel>,
  ): Promise<ReadonlyArray<ITaskServerModel>> => {
    if (!startDispatched) {
      dispatch(loadRelatedItemsStarted());
      startDispatched = true;
    }

    const relatedItemIds = groupItemIdsByVariantId(tasks.map((i) => i.itemVariantId));
    await dispatch(loadRelatedItems(relatedItemIds, abortSignal));

    const relatedItems = getState().data.yourTasksContentItems.byId;
    return tasks.filter((task) =>
      relatedItems.has(
        getYourTaskVariantIdentifier(task.itemVariantId.itemId, task.itemVariantId.variantId),
      ),
    );
  };
};

const getMissingRelatedVariants = (state: IStore): ItemIdsByVariantIds => {
  const {
    data: { tasks, yourTasksContentItems },
  } = state;

  const allRelatedVariantIds = Collection.getValues(tasks.byId).map((task) => task.contentItemId);
  const loadedRelatedVariantIds = Collection.getValues(yourTasksContentItems.byId).map(
    (contentItem) => contentItem.variant.id,
  );
  return removeIdsFromVariantGroups(
    groupItemIdsByVariantId(allRelatedVariantIds),
    loadedRelatedVariantIds,
  );
};

const groupItemIdsByVariantId = (ids: ReadonlyArray<ContentItemId>): ItemIdsByVariantIds => {
  const itemIdsByVariantIds: ItemIdsByVariantIds = new Map<string, Set<Uuid>>();
  ids.forEach((id) => {
    const { itemId, variantId } = id;
    if (itemIdsByVariantIds.has(variantId)) {
      itemIdsByVariantIds.get(variantId)?.add(itemId);
    } else {
      itemIdsByVariantIds.set(variantId, new Set([itemId]));
    }
  });
  return itemIdsByVariantIds;
};

const removeIdsFromVariantGroups = (
  itemIdsByVariantIds: ItemIdsByVariantIds,
  idsToRemove: ReadonlyArray<ContentItemId>,
): ItemIdsByVariantIds => {
  const itemIdsByVariantIdsCopy = new Map(itemIdsByVariantIds);

  idsToRemove.forEach((idToRemove) => {
    const itemIds = itemIdsByVariantIdsCopy.get(idToRemove.variantId);
    if (!itemIds) {
      return;
    }

    itemIds.delete(idToRemove.itemId);
    if (itemIds.size === 0) {
      itemIdsByVariantIdsCopy.delete(idToRemove.variantId);
    }
  });

  return itemIdsByVariantIdsCopy;
};
