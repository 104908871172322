import { Button } from '@kontent-ai/component-library/Button';
import { Icon } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps } from 'react';
import {
  DataUiWorkflowAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export const WorkflowStepActionButton: React.FC<
  Readonly<{
    buttonStyle: 'primary' | 'secondary' | 'tertiary';
    destructive?: true;
    dataUiAction: DataUiWorkflowAction;
    icon?: Icon;
    label: string;
    onClick: ComponentProps<typeof Button>['onClick'];
  }>
> = ({ dataUiAction, icon, label, ...props }) => (
  <Button buttonDisplay="block" {...props} {...getDataUiActionAttribute(dataUiAction)}>
    {icon && <Button.Icon icon={icon} />}
    <Button.Label
      css={`
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
    >
      {label}
    </Button.Label>
  </Button>
);

WorkflowStepActionButton.displayName = 'WorkflowStepActionButton';
