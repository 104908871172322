import { Box } from '@kontent-ai/component-library/Box';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  BorderRadius,
  Spacing,
  Typography,
  colorTextDefault,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';

const onboardingMinWidthBreakpoint = 1101; // see quickstart.less

const StyledRow = styled(Row)`
  justify-content: center;
  @media (min-width: ${px(onboardingMinWidthBreakpoint)}) {
    justify-content: normal;
  }
`;

const Text = styled(Box)`
  @media (min-width: ${px(onboardingMinWidthBreakpoint)}) {
    max-width: 405px;
  }
`;

const Video = styled.iframe`
  border-radius: ${px(BorderRadius.M)};
  border: none;
  height: 252px;
  width: 475px;
`;

export const GettingStartedVideoSection: React.FC = () => {
  return (
    <Paper padding={Spacing.XL} borderRadius={BorderRadius.L} marginBottom={Spacing.XL}>
      <StyledRow>
        <Column>
          <Text
            color={colorTextDefault}
            justifyContent="center"
            marginBottom={Spacing.XL}
            typography={Typography.UIParagraph}
          >
            <Box marginBottom={Spacing.L} typography={Typography.HeadlineLarge}>
              Getting started with your Kontent.ai&nbsp;Trial
            </Box>
            Welcome to your trial environment! Before you dig in, please make sure to view this
            quick (~6 minute) tutorial so you know where to start.
          </Text>
        </Column>
        <Column width="fit-content">
          <Video
            src="https://www.youtube.com/embed/IJzgfPdIpT4"
            title="YouTube video player"
            allow="encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Column>
      </StyledRow>
    </Paper>
  );
};
