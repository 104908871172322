import React, { useContext } from 'react';
import { Feature } from '../../../../@types/FeatureEnum.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { isFeatureEnabled } from '../../../_shared/utils/featureToggles.ts';

const IsUnifiedMissionControlEnabledContext = React.createContext<boolean>(false);

export const IsUnifiedMissionControlEnabledContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isEnabledForEnvironment = isFeatureEnabled(Feature.UnifiedMissionControl);
  const isEnabledForCurrentProject = useSelector(
    (s) => s.sharedApp.projectFeatureFlags.UnifiedMissionControl,
  );
  const isEnabled = isEnabledForEnvironment || isEnabledForCurrentProject;

  return (
    <IsUnifiedMissionControlEnabledContext.Provider value={isEnabled}>
      {children}
    </IsUnifiedMissionControlEnabledContext.Provider>
  );
};

export const useIsUnifiedMissionControlEnabled = (): boolean =>
  useContext(IsUnifiedMissionControlEnabledContext);
