import PropTypes, { ValidationMap } from 'prop-types';
import { RichTextCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { getLimitationsDomainModel } from '../../../../contentModels/shared/utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import {
  allTableBlockCategoryFeatures,
  allTextBlockTypeFeatures,
  allTextFormattingFeatures,
  allTopLevelBlockCategoryFeatures,
} from '../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { ElementType } from '../ContentItemElementType.ts';
import { defaultRichTextFileTypeOption } from '../assetFileTypeOptions.ts';
import { FileSizeUnit } from '../fileSizeOptions.ts';
import { UnitOption } from '../textLengthOptions.ts';
import {
  AllowedContentTypesLimitParametersValidationMap,
  AllowedItemLinkTypesLimitParametersValidationMap,
  BaseTypeElementValidationMap,
  EmptyBaseTypeElement,
  IBaseTypeElement,
  ITypeElementWithAllowedContentTypesLimit,
  ITypeElementWithAllowedItemLinkTypesLimit,
  ITypeElementWithAssetLimit,
  ITypeElementWithLimitationCategories,
  ITypeElementWithTextLimit,
  TypeElementWithAssetLimitValidationMap,
  TypeElementWithLimitationCategoriesValidationMap,
  TypeElementWithTextLimitValidationMap,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

interface IRichTextTypeLimitation
  extends ITypeElementWithTextLimit,
    ITypeElementWithAssetLimit,
    ITypeElementWithAllowedContentTypesLimit,
    ITypeElementWithAllowedItemLinkTypesLimit,
    ITypeElementWithLimitationCategories {}

export interface IRichTextTypeElement extends IBaseTypeElement, IRichTextTypeLimitation {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
  readonly type: ElementType.RichText;
}

export const EmptyRichTextTypeElement: IRichTextTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  _fileSizeUnitOption: FileSizeUnit.Byte,
  _lengthUnitOption: UnitOption.Words,
  aiGuidelinesIds: [],
  allowedBlocks: allTopLevelBlockCategoryFeatures,
  allowedItemLinkTypes: [],
  allowedTextBlocks: allTextBlockTypeFeatures,
  allowedTextFormatting: allTextFormattingFeatures,
  allowedTableBlocks: allTableBlockCategoryFeatures,
  allowedTableTextBlocks: allTextBlockTypeFeatures,
  allowedTableTextFormatting: allTextFormattingFeatures,
  allowedTypes: [],
  fileSize: null,
  fileType: defaultRichTextFileTypeOption,
  maxChars: null,
  maxHeight: null,
  maxWidth: null,
  maxWords: null,
  minHeight: null,
  minWidth: null,
  type: ElementType.RichText,
};

export function getRichTextTypeElementFromServerModel(
  serverModel: RichTextCompiledTypeElementServerModel,
): IRichTextTypeElement {
  const allowedBlocks = getLimitationsDomainModel(
    serverModel.allowedBlocks,
    allTopLevelBlockCategoryFeatures,
  );
  const allowedTextBlocks = getLimitationsDomainModel(
    serverModel.allowedTextBlocks,
    allTextBlockTypeFeatures,
  );
  const allowedTextFormatting = getLimitationsDomainModel(
    serverModel.allowedTextFormatting,
    allTextFormattingFeatures,
  );
  const allowedTableBlocks = getLimitationsDomainModel(
    serverModel.allowedTableBlocks,
    allTableBlockCategoryFeatures,
  );
  const allowedTableTextBlocks = getLimitationsDomainModel(
    serverModel.allowedTableTextBlocks,
    allTextBlockTypeFeatures,
  );
  const allowedTableTextFormatting = getLimitationsDomainModel(
    serverModel.allowedTableTextFormatting,
    allTextFormattingFeatures,
  );

  return {
    ...getBaseTypeElementFromServerModel(serverModel),
    _configurability: EmptyRichTextTypeElement._configurability,
    _fileSizeUnitOption: FileSizeUnit.Byte,
    _lengthUnitOption: serverModel.maxChars ? UnitOption.Characters : UnitOption.Words,
    aiGuidelinesIds: serverModel.aiGuidelinesIds,
    allowedBlocks,
    allowedItemLinkTypes: serverModel.allowedItemLinkTypes,
    allowedTextBlocks,
    allowedTextFormatting,
    allowedTableBlocks,
    allowedTableTextBlocks,
    allowedTableTextFormatting,
    allowedTypes: serverModel.allowedTypes,
    fileSize: serverModel.fileSize || null,
    fileType: serverModel.richStringImageType || defaultRichTextFileTypeOption,
    maxChars: serverModel.maxChars !== null ? serverModel.maxChars : null,
    maxHeight: serverModel.maxHeight,
    maxWidth: serverModel.maxWidth,
    maxWords: serverModel.maxWords !== null ? serverModel.maxWords : null,
    minHeight: serverModel.minHeight,
    minWidth: serverModel.minWidth,
    type: ElementType.RichText,
  };
}

export const RichTextTypeElementPropTypes = PropTypes.exact<ValidationMap<IRichTextTypeElement>>({
  ...BaseTypeElementValidationMap([ElementType.RichText]),
  ...AllowedContentTypesLimitParametersValidationMap,
  ...AllowedItemLinkTypesLimitParametersValidationMap,
  ...TypeElementWithTextLimitValidationMap,
  ...TypeElementWithAssetLimitValidationMap,
  ...TypeElementWithLimitationCategoriesValidationMap,
});
