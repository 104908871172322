import { Collection } from '@kontent-ai/utils';
import {
  PublishingValidationResult,
  validatePublishingConfiguration,
} from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/publishingValidationUtils.ts';
import {
  UnpublishingValidationResult,
  validateUnpublishingConfiguration,
  validateUnpublishingDate,
} from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/unpublishingValidationUtils.ts';
import {
  getElementsWithWarnings,
  isPublishingBlockedByDefaultVariant,
} from '../../../../../applications/itemEditor/selectors/itemValidations.ts';
import { DateTime } from '../../../../models/DateTime.ts';
import { IStore } from '../../../../stores/IStore.type.ts';
import { getContributorsAvailableAsAssigneesInChangeWorkflowStepModal } from '../../../../utils/permissions/getAvailableContributors.ts';
import { isBulkPublishAllowed } from '../../../../utils/validation/isBulkPublishAllowed.ts';
import { IChangeWorkflowStepModalData } from '../../reducers/IChangeWorkflowStepModalData.ts';
import { ChangeWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';
import {
  isWorkflowStepModalOpenedInBulk,
  isWorkflowStepModalOpenedInItemEditing,
} from '../../utils/changeWorkflowStepModalUtils.ts';

export const isWorkflowStepModalSubmitDisabled = (state: IStore): boolean => {
  const { changeWorkflowStepModalData } = state.contentApp;

  if (
    isWorkflowStepModalOpenedInItemEditing(changeWorkflowStepModalData) &&
    isEditedItemBeingSaved(state)
  ) {
    return true;
  }

  switch (changeWorkflowStepModalData.workflowStepAction) {
    case ChangeWorkflowStepModalAction.Publish:
    case ChangeWorkflowStepModalAction.ScheduleToPublish:
      return isPublishingModalSubmitDisabled(state);

    case ChangeWorkflowStepModalAction.UnpublishAndArchive:
    case ChangeWorkflowStepModalAction.ScheduleToUnpublishAndArchive:
      return isUnpublishingModalSubmitDisabled(state);

    case ChangeWorkflowStepModalAction.RestoreFromArchivedStep:
    case ChangeWorkflowStepModalAction.UpdateStep:
      return isChangeAssignmentModalSubmitDisabled(state);

    default:
      return false;
  }
};

const isEditedItemBeingSaved = (state: IStore): boolean => {
  const editedContentItemStatus = state.contentApp.editedContentItemStatus;
  return !Collection.isEmpty(editedContentItemStatus.saving);
};

const isPublishingModalSubmitDisabled = (state: IStore): boolean => {
  const { changeWorkflowStepModalData } = state.contentApp;

  if (
    isWorkflowStepModalOpenedInBulk(changeWorkflowStepModalData) &&
    !isBulkPublishAllowed(state.contentApp.listingUi.bulkActionCheckResult)
  ) {
    return true;
  }

  return (
    validatePublishingConfiguration(changeWorkflowStepModalData) !==
      PublishingValidationResult.Ok ||
    isUnpublishingConfigurationEnabledAndInvalid(changeWorkflowStepModalData) ||
    anyPublishingWarnings(state) ||
    isPublishingBlockedByDefaultVariant(state)
  );
};

const isUnpublishingModalSubmitDisabled = (state: IStore): boolean =>
  validateUnpublishingConfiguration(state.contentApp.changeWorkflowStepModalData) !==
  UnpublishingValidationResult.Ok;

const isChangeAssignmentModalSubmitDisabled = (state: IStore): boolean => {
  const dateTime = state.contentApp.changeWorkflowStepModalData.dueDate;

  return !isDueDateValidOrEmpty(dateTime) || noAssignedContributors(state);
};

const isDueDateValidOrEmpty = (dueDate: DateTime): boolean => dueDate.isValid;

const noAssignedContributors = (state: IStore): boolean =>
  !getContributorsAvailableAsAssigneesInChangeWorkflowStepModal(state).availableAssigned.length;

const anyPublishingWarnings = (state: IStore): boolean => !!getElementsWithWarnings(state).length;

const isUnpublishingConfigurationEnabledAndInvalid = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): boolean =>
  changeWorkflowStepModalData.enableScheduledUnpublishing &&
  validateUnpublishingDate(changeWorkflowStepModalData.scheduledToUnpublishAt) !==
    UnpublishingValidationResult.Ok;
