import { QuinaryButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import styled from 'styled-components';

const KopilotIcon = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  content: "";
  background-image: url('/images/kopilot.svg');
  background-repeat: no-repeat;
  background-size: cover;
`;

type Props = {
  readonly isToolbarVertical: boolean;
  readonly onClick: () => void;
};

export const KopilotButton: React.FC<Props> = ({ isToolbarVertical, onClick }) => (
  <QuinaryButton
    onClick={onClick}
    tooltipPlacement={isToolbarVertical ? 'right' : 'top'}
    tooltipText="AI request"
    tooltipShortcuts="//"
  >
    <KopilotIcon />
  </QuinaryButton>
);

KopilotButton.displayName = 'KopilotButton';
