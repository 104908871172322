import { TextTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  ITextTypeElementData,
  getTextTypeElementDataFromServerModel,
} from '../../models/elements/TextTypeElementData.ts';
import { hasTextDefaultValue } from '../typeElementDefaultValueUtils.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';
import { getIntegerFromString } from './utils/typeElementDataConvertersUtils.ts';

function convertTypeElementRecordToServerModel(
  typeElement: ITextTypeElementData,
): TextTypeElementServerModel {
  const maxChars = typeElement.maxChars && getIntegerFromString(typeElement.maxChars);
  const maxWords = typeElement.maxWords && getIntegerFromString(typeElement.maxWords);

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    aiGuidelinesIds: typeElement.aiGuidelinesIds,
    default: hasTextDefaultValue(typeElement)
      ? { global: { value: typeElement.defaultValue } }
      : null,
    maxChars: maxChars && maxChars > 0 ? maxChars : null,
    maxWords: maxWords && maxWords > 0 ? maxWords : null,
    type: ElementType.Text,
    validationRegex: typeElement.validationRegex,
  };
}

export function createTextTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: TextTypeElementServerModel) =>
      getTextTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: ITextTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
