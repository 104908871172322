import React, { useEffect } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../_shared/components/NotificationBar.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import {
  closeUserDetail,
  hideNotificationBarInUserDetail,
  userDetailInitialized,
} from '../../actions/subscriptionUserDetailActions.ts';
import { UserDetailModalBody } from './UserDetailModalBody.tsx';

export interface IUserDetailProps {
  readonly user: IUserInfo;
  readonly onNavigateBack: () => void;
}

export const UserDetail: React.FC<IUserDetailProps> = ({ user, onNavigateBack }) => {
  const dispatch = useDispatch();

  const savingError = useSelector((s) => s.subscriptionApp.users.userDetailUi.savingError);

  useEffect(() => {
    dispatch(userDetailInitialized());

    return compose(dispatch, closeUserDetail) as () => void;
  }, []);

  return (
    <ModalDialog
      headline="User detail"
      isDismissable
      isOpen
      minWidth="960px"
      onClose={onNavigateBack}
      withDividers
      shouldCloseOnInteractOutside={() => false}
      renderNotificationBar={() => {
        return (
          <NotificationBar
            type={NotificationBarType.Error}
            message={savingError}
            onClosePanel={compose(dispatch, hideNotificationBarInUserDetail)}
          />
        );
      }}
    >
      <UserDetailModalBody user={user} onNavigateBack={onNavigateBack} />
    </ModalDialog>
  );
};

UserDetail.displayName = 'UserDetail';
