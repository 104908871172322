import { InvariantException } from '@kontent-ai/errors';
import { IAssignment } from '../../../../applications/itemEditor/models/contentItem/Assignment.ts';
import { EditedContentItemVariant } from '../../../../applications/itemEditor/models/contentItem/edited/EditedContentItemVariant.ts';
import {
  IWorkflowStep,
  createAssignmentWorkflowStep,
} from '../../../../data/models/workflow/WorkflowStep.ts';
import { createDateTime } from '../../../models/DateTime.ts';
import { getEditedContentItemVariant } from '../../../selectors/getEditedContentItemVariant.ts';
import { IStore } from '../../../stores/IStore.type.ts';
import { isArchivedWorkflowStep } from '../../../utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../utils/contentItemVariantUtils.ts';
import { DefaultDisplayTimeZoneId } from '../../../utils/dateTime/timeZoneUtils.ts';
import { ScheduleMethod } from '../constants/scheduleMethods.ts';
import {
  IChangeWorkflowStepModalData,
  createChangeWorkflowStepModalData,
} from '../reducers/IChangeWorkflowStepModalData.ts';
import { WorkflowStepWithWorkflow } from '../types/WorkflowStepWithWorkflow.type.ts';
import {
  ChangeWorkflowStepModalAction,
  OpenWorkflowStepModalAction,
} from '../types/workflowStepModalActionTypes.ts';
import {
  getWorkflowActionForUpdateStep,
  isScheduling,
} from '../utils/changeWorkflowStepModalActionUtils.ts';
import {
  getPreselectedStepForPublish,
  getPreselectedStepForRestoreFromArchivedStepInEditing,
  getPreselectedStepForUnpublish,
  getPreselectedStepForUpdateStep,
} from './getPreselectedWorkflowStep.ts';
import { isPublishingAvailable } from './isPublishingAvailable.ts';

export const createInitialChangeWorkflowStepModalDataForItemEditing = (
  state: IStore,
  workflowAction: OpenWorkflowStepModalAction,
): IChangeWorkflowStepModalData | null => {
  switch (workflowAction) {
    case OpenWorkflowStepModalAction.Publish:
      return createInitialModalDataForPublish(state);
    case OpenWorkflowStepModalAction.RestoreFromArchivedStep:
      return createInitialModalDataForRestore(state);
    case OpenWorkflowStepModalAction.UnpublishAndArchive:
      return createInitialModalDataForUnpublish(state);
    case OpenWorkflowStepModalAction.UpdateStep:
      return createInitialModalDataForUpdateStep(state);
    default:
      throw InvariantException(
        'createInitialChangeWorkflowStepModalDataForItemEditing.ts: Unknown modal workflow action.',
      );
  }
};

const createInitialModalDataForPublish = (state: IStore): IChangeWorkflowStepModalData | null => {
  const editedVariant = getEditedContentItemVariant(state);

  if (!editedVariant) {
    return null;
  }

  const { assignment } = editedVariant;
  const { workflowId } = assignment.workflowStatus;

  const workflowStepAction =
    isPublishingAvailable(state) && !assignment.scheduledToPublishAt
      ? ChangeWorkflowStepModalAction.Publish
      : ChangeWorkflowStepModalAction.ScheduleToPublish;

  return createChangeWorkflowStepModalDataForItemEditing(
    assignment,
    getPreselectedStepForPublish(state, workflowId),
    workflowStepAction,
  );
};

const createInitialModalDataForUnpublish = (state: IStore): IChangeWorkflowStepModalData | null => {
  const editedVariant = getEditedContentItemVariant(state);

  if (!editedVariant) {
    return null;
  }

  const { assignment } = editedVariant;
  const { workflowId } = assignment.workflowStatus;

  return createChangeWorkflowStepModalDataForItemEditing(
    assignment,
    getPreselectedStepForUnpublish(state, workflowId),
    getWorkflowActionForUnpublish(assignment),
  );
};

const createInitialModalDataForRestore = (state: IStore): IChangeWorkflowStepModalData | null => {
  const editedVariant = getEditedContentItemVariant(state);

  if (!editedVariant) {
    return null;
  }

  const { assignment } = editedVariant;
  const { workflowId } = assignment.workflowStatus;

  return createChangeWorkflowStepModalDataForItemEditing(
    assignment,
    getPreselectedStepForRestoreFromArchivedStepInEditing(state, workflowId),
    ChangeWorkflowStepModalAction.RestoreFromArchivedStep,
  );
};

const createInitialModalDataForUpdateStep = (
  state: IStore,
): IChangeWorkflowStepModalData | null => {
  const editedVariant = getEditedContentItemVariant(state);

  if (!editedVariant) {
    return null;
  }

  const { assignment } = editedVariant;
  const { workflowId } = assignment.workflowStatus;
  const selectedWorkflowStep = getPreselectedStepForUpdateStep(state, workflowId);
  const selectedScheduleMethod = isPublishingAvailable(state)
    ? ScheduleMethod.Now
    : ScheduleMethod.Schedule;
  const workflowAction = getInitialWorkflowActionForUpdateStep(
    selectedWorkflowStep?.step,
    editedVariant,
    selectedScheduleMethod,
  );

  return createChangeWorkflowStepModalDataForItemEditing(
    assignment,
    selectedWorkflowStep,
    workflowAction,
  );
};

const getInitialWorkflowActionForUpdateStep = (
  selectedWorkflowStep: IWorkflowStep | undefined,
  variant: EditedContentItemVariant,
  scheduleMethod?: ScheduleMethod,
): ChangeWorkflowStepModalAction => {
  if (isArchivedWorkflowStep(selectedWorkflowStep) && isVariantPublished(variant)) {
    return getWorkflowActionForUnpublish(variant.assignment);
  }

  return getWorkflowActionForUpdateStep(selectedWorkflowStep, scheduleMethod);
};

const getWorkflowActionForUnpublish = (assignment: IAssignment): ChangeWorkflowStepModalAction => {
  return assignment.scheduledToUnpublishAt
    ? ChangeWorkflowStepModalAction.ScheduleToUnpublishAndArchive
    : ChangeWorkflowStepModalAction.UnpublishAndArchive;
};

const createChangeWorkflowStepModalDataForItemEditing = (
  assignment: IAssignment,
  selectedWorkflowStep: WorkflowStepWithWorkflow | undefined,
  workflowStepAction: ChangeWorkflowStepModalAction,
): IChangeWorkflowStepModalData =>
  createChangeWorkflowStepModalData({
    contributors: assignment.assignees,
    dueDate: {
      ...createDateTime(),
      value: assignment.due || '',
    },
    isInBulk: false,
    note: assignment.note,
    scheduledToPublishAt: assignment.scheduledToPublishAt,
    scheduledPublishDisplayTimeZone:
      assignment.scheduledPublishDisplayTimeZone ?? DefaultDisplayTimeZoneId,
    scheduledToUnpublishAt: assignment.scheduledToUnpublishAt,
    scheduledUnpublishDisplayTimeZone:
      assignment.scheduledUnpublishDisplayTimeZone ?? DefaultDisplayTimeZoneId,
    scheduleMethod: isScheduling(workflowStepAction) ? ScheduleMethod.Schedule : ScheduleMethod.Now,
    isPublishingRescheduled: !!assignment.scheduledToPublishAt,
    workflowStepAction,
    ...(selectedWorkflowStep
      ? {
          workflowStep: createAssignmentWorkflowStep(
            selectedWorkflowStep.step,
            selectedWorkflowStep.workflow.id,
          ),
        }
      : {}),
  });
