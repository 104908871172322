import classNames from 'classnames';
import React, { forwardRef } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type ClickableScrollTableRowProps = AllOrNoneProps<{
  readonly rowFocusLinkPath: string;
  readonly rowFocusLinkAriaLabel: string;
}>;

export type IScrollTableRowProps = ClickableScrollTableRowProps & {
  readonly children: React.ReactNode | React.ReactNodeArray;
  readonly elementName?: DataUiElement;
  readonly isDisabled?: boolean;
  readonly isHighlighted?: boolean;
  readonly isLoading?: boolean;
  readonly isPlaceholder?: boolean;
  readonly isSelected?: boolean;
  readonly isSkeleton?: boolean;
  readonly objectName: string;
};

export const ScrollTableRow = forwardRef<HTMLDivElement, IScrollTableRowProps>(
  (
    {
      children,
      elementName,
      isDisabled,
      isHighlighted,
      isLoading,
      isPlaceholder,
      isSelected,
      isSkeleton,
      objectName,
      rowFocusLinkPath,
      rowFocusLinkAriaLabel,
    },
    ref,
  ) => (
    <div ref={ref}>
      {rowFocusLinkPath && (
        <a href={rowFocusLinkPath} className="sr-only scroll-table__row-select">
          {rowFocusLinkAriaLabel}
        </a>
      )}

      <div
        {...getDataUiObjectNameAttribute(objectName)}
        {...(elementName && getDataUiElementAttribute(elementName))}
        className={classNames('scroll-table__row', {
          'scroll-table__row--is-highlighted': isHighlighted,
          'scroll-table__row--is-selected': isSelected,
          'scroll-table__row--is-disabled': isDisabled,
          'scroll-table__row--is-placeholder': isPlaceholder,
          'scroll-table__row--is-skeleton': isSkeleton,
        })}
        role="row"
      >
        {children}
      </div>
      {isLoading && (
        <div className="scroll-table__row-loading-indicator-pane">
          <div
            className="scroll-table__row-loading-indicator"
            {...getDataUiElementAttribute(DataUiElement.Loader)}
          />
        </div>
      )}
    </div>
  ),
);

ScrollTableRow.displayName = 'ScrollTableRow';
