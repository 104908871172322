import React, { useCallback, useMemo } from 'react';
import { DataUiRteAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PhoneLinkProperties } from '../../../../itemEditor/models/contentItemElements/richText/PhoneLinkProperties.type.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginProps,
  PluginState,
} from '../../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { getLinkStatus } from '../../apiLimitations/api/editorLimitationUtils.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { GetLinkEntityComponent, GetLinkOptions, LinksPlugin } from '../LinksPlugin.tsx';
import { isNewLinkOfType, isPhoneLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { NewLinkType } from '../api/NewLinkType.ts';
import { isLinkAllowedAtSelection } from '../api/editorLinkUtils.ts';
import { NewPhoneLink } from './components/NewPhoneLink.tsx';
import { PhoneLink } from './components/PhoneLink.tsx';

export type PhoneLinksPlugin = EditorPlugin<None, None, None, [LinksPlugin]>;

type UpdatePhoneLink = (
  entityKey: string,
  values: PhoneLinkProperties,
  allowUndo?: boolean,
) => void;

type CustomLinkProps = Pick<
  PluginState<PhoneLinksPlugin>,
  'editLink' | 'cancelNewLink' | 'linkEditingCancelled' | 'unlink' | 'getApi'
> &
  Pick<PluginProps<PhoneLinksPlugin>, 'disabled'> & {
    readonly updatePhoneLink: UpdatePhoneLink;
  };

type NewPhoneLinkEntityProps = EntityDecoratorProps<CustomLinkProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const NewPhoneLinkEntity: React.FC<NewPhoneLinkEntityProps> = ({
  cancelNewLink,
  children,
  contentState,
  decoratedText,
  entityKey,
  updatePhoneLink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isNewLinkOfType(entity, NewLinkType.PhoneLink)) {
    return children;
  }

  const isPlaceholder = !!entity.getData().isPlaceholder;

  return (
    <NewPhoneLink
      linkText={decoratedText}
      isPlaceholder={isPlaceholder}
      key={entityKey}
      onCancel={() => cancelNewLink(entityKey, isPlaceholder)}
      onInsert={(values) => updatePhoneLink(entityKey, values, false)}
    >
      {children}
    </NewPhoneLink>
  );
};

NewPhoneLinkEntity.displayName = 'NewPhoneLinkEntity';

type PhoneLinkEntityProps = EntityDecoratorProps<CustomLinkProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const PhoneLinkEntity: React.FC<PhoneLinkEntityProps> = ({
  blockKey,
  children,
  contentState,
  decoratedText,
  disabled,
  editLink,
  entityKey,
  getApi,
  linkEditingCancelled,
  unlink,
  updatePhoneLink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isPhoneLink(entity)) {
    return children;
  }

  const { phoneNumber } = entity.getData();
  const status = getLinkStatus({ blockKey, contentState }, getApi().getLimitations());

  return (
    <PhoneLink
      disabled={disabled}
      key={entityKey}
      onCancel={() => linkEditingCancelled(entityKey)}
      onEdit={() => editLink(entityKey)}
      onUnlink={() => unlink(entityKey)}
      onUpdate={(values) => updatePhoneLink(entityKey, values)}
      phoneNumber={phoneNumber}
      status={status}
      text={decoratedText}
    >
      {children}
    </PhoneLink>
  );
};

PhoneLinkEntity.displayName = 'PhoneLinkEntity';

export const usePhoneLinks: PluginCreator<PhoneLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('PhoneLinksPlugin', {
        ComposedEditor: (props) => {
          const { disabled } = props;

          const apply: Apply<PhoneLinksPlugin> = useCallback(
            (state) => {
              const updatePhoneLink: UpdatePhoneLink = (entityKey, values, allowUndo = true) => {
                if (!state.canUpdateContent(EditorChangeReason.Internal)) {
                  return;
                }

                state.executeChange((editorState) => {
                  const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
                  if (selection) {
                    const newEditorState = state
                      .getApi()
                      .createPhoneLink(editorState, selection, values, allowUndo);
                    state.linkEditingFinished();
                    return newEditorState;
                  }
                  return editorState;
                }, EditorChangeReason.Internal);
              };

              const getLinkEntityComponent: Decorator<GetLinkEntityComponent<CustomLinkProps>> =
                (baseGetLinkEntityComponent) => (linkType) => {
                  const customLinkProps: CustomLinkProps = {
                    cancelNewLink: state.cancelNewLink,
                    disabled,
                    editLink: state.editLink,
                    getApi: state.getApi,
                    linkEditingCancelled: state.linkEditingCancelled,
                    unlink: state.unlink,
                    updatePhoneLink,
                  };

                  switch (linkType) {
                    case LinkType.Phone:
                      return {
                        component: PhoneLinkEntity,
                        props: customLinkProps,
                      };

                    case NewLinkType.PhoneLink:
                      return {
                        component: NewPhoneLinkEntity,
                        props: customLinkProps,
                      };

                    default:
                      return baseGetLinkEntityComponent(linkType);
                  }
                };

              state.getLinkEntityComponent.decorate(getLinkEntityComponent);

              const newPhoneLink = (): void => {
                if (!state.canUpdateContent()) {
                  return;
                }

                state.executeChange((editorState) => {
                  const selection = editorState.getSelection();
                  const content = editorState.getCurrentContent();
                  if (isLinkAllowedAtSelection(content, selection)) {
                    const newEditorState = selection.isCollapsed()
                      ? state
                          .getApi()
                          .createLinkPlaceholder(editorState, selection, NewLinkType.PhoneLink)
                      : state.getApi().createNewPhoneLink(editorState, selection);
                    if (editorState !== newEditorState) {
                      const entityKey = newEditorState
                        .getCurrentContent()
                        .getLastCreatedEntityKey();
                      state.setEditedLinkEntityKey(entityKey);
                      return newEditorState;
                    }
                  }
                  return editorState;
                }, EditorChangeReason.Internal);
              };

              const getLinkOptions: Decorator<GetLinkOptions> = (baseGetLinkOptions) => () => [
                ...baseGetLinkOptions(),
                {
                  name: 'Phone',
                  onClick: newPhoneLink,
                  uiAction: DataUiRteAction.AddPhoneLink,
                },
              ];

              state.getLinkOptions.decorate(getLinkOptions);

              return {};
            },
            [disabled],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
