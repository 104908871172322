import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IAddNextStepButtonProps {
  readonly onClick?: () => void;
  readonly disabled?: boolean;
}

export const AddNextStepButton: React.FC<IAddNextStepButtonProps> = ({ onClick, disabled }) => (
  <div className="bar-item__outer-action">
    <SquareButton
      style={ButtonStyle.Tertiary}
      onClick={onClick}
      tooltipText="Add next step"
      tooltipPlacement="left"
      disabled={disabled}
      iconName={IconName.Plus}
      {...getDataUiActionAttribute(DataUiAction.CreateNew)}
    />
  </div>
);

AddNextStepButton.displayName = 'AddNextStepButton';

const propTypes: PropTypesShape<IAddNextStepButtonProps> = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
AddNextStepButton.propTypes = propTypes;
