import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  SliceDirection,
  SliceFrom,
  useSliceOverflowingItems,
} from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import type { RefForwardingComponent } from '@kontent-ai/component-library/types';
import { CLPropTypes } from '@kontent-ai/component-library/validators';
import { FocusScope } from '@react-aria/focus';
import PropTypes from 'prop-types';
import { ComponentProps, ReactElement, ReactNode, forwardRef, useMemo, useRef } from 'react';
import { StatusBarIconButton } from '../../../../app/_shared/components/StatusBar/components/StatusBarIconButton.tsx';
import {
  StyledSelectGroup,
  StyledSelectGroupPopup,
} from '../../../../app/_shared/components/StatusBar/components/StyledSelectGroup.tsx';
import { DropDownMenu } from '../../DropDownMenu/DropDownMenu.tsx';
import { DropdownTippyOptions } from '../../DropDownMenu/DropDownMenuPositioner.tsx';
import { SelectWrapper } from './components/SelectWrapper.tsx';
import { StyledSelectGroupFlexibleWrapper } from './components/StyledSelectGroupFlexibleWrapper.tsx';
import { SelectGroupRenderMode } from './types/SelectGroupRenderMode.ts';

const getPinnedSelect = <Select extends BaseSelect>(
  selects: ReadonlyArray<Select>,
  pinnedSelectId: string | undefined,
): Select | undefined => {
  if (pinnedSelectId) {
    const pinnedSelect = selects.find((select) => select.id === pinnedSelectId);

    if (pinnedSelect) {
      return pinnedSelect;
    }
  }

  return selects[0];
};

export type BaseSelect = Readonly<{
  id: string;
}>;

export type SelectGroupProps<Select extends BaseSelect> = {
  readonly children?: never;
  /** Used in the ellipsis button tooltip and as a hidden label for screen readers/accessibility. */
  readonly ellipsisButtonLabel?: string;
  /** Which select should always be shown (not hidden in the ellipsis menu). Defaults to the first select group. */
  readonly pinnedSelectId?: string;
  readonly renderSelect: (
    group: NoInfer<Select>,
    renderMode: SelectGroupRenderMode,
  ) => ReactElement;
  readonly selects: ReadonlyArray<Select>;
  /** Override automatic shortening of SelectGroup items. */
  readonly shorteningMode?: 'auto' | 'always' | 'never';
  readonly tippyOptions?: DropdownTippyOptions | null;
};

const propTypes: PropTypeMap<SelectGroupProps<any>> = {
  children: CLPropTypes.never,
  ellipsisButtonLabel: PropTypes.string,
  pinnedSelectId: PropTypes.string,
  renderSelect: PropTypes.func.isRequired,
  selects: PropTypes.array.isRequired,
  shorteningMode: PropTypes.oneOf(['auto', 'always', 'never']),
  tippyOptions: PropTypes.object,
};

interface SelectGroupForwardingRef
  extends RefForwardingComponent<SelectGroupProps<BaseSelect>, HTMLDivElement> {
  <TSelect extends BaseSelect>(props: SelectGroupProps<TSelect>): ReactNode;
}

export const SelectGroup: SelectGroupForwardingRef = forwardRef((props, forwardedRef) => {
  const {
    ellipsisButtonLabel = 'More menus',
    pinnedSelectId,
    renderSelect,
    selects,
    shorteningMode = 'auto',
    tippyOptions,
    ...otherProps
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const { attachVisibleItemRef, hiddenItems } = useSliceOverflowingItems(
    selects,
    containerRef,
    SliceFrom.End,
    SliceDirection.Horizontal,
  );

  const pinnedSelect = getPinnedSelect(selects, pinnedSelectId);
  const hideableSelects = useMemo(
    () => selects.filter((select) => select.id !== pinnedSelect?.id),
    [pinnedSelect?.id, selects],
  );

  // We need to either hide all but 1 or show all.
  const showEllipsisMenu: boolean =
    shorteningMode === 'always' || (shorteningMode === 'auto' && hiddenItems.length > 0);
  const allowShrink: boolean =
    shorteningMode === 'never' || showEllipsisMenu || hideableSelects.length <= 0;

  const visibleSelects = showEllipsisMenu && pinnedSelect ? [pinnedSelect] : selects;

  if (!selects.length) {
    return null;
  }

  return (
    <Inline
      noWrap
      spacingX={Spacing.S}
      {...({ minWidth: 0, flexShrink: 1 } satisfies Partial<ComponentProps<typeof Box>>)}
    >
      {showEllipsisMenu && (
        <DropDownMenu
          tippyOptions={tippyOptions}
          renderTrigger={(triggerProps, isOptionListVisible) => (
            <StatusBarIconButton
              iconName="Ellipsis"
              label={ellipsisButtonLabel}
              isActive={isOptionListVisible}
              {...triggerProps}
            />
          )}
          renderDropDown={() => (
            <FocusScope autoFocus restoreFocus contain>
              <StyledSelectGroupPopup>
                {hideableSelects.map((hiddenSelect) => (
                  <SelectWrapper key={hiddenSelect.id} allowShrink>
                    {renderSelect(hiddenSelect, 'InEllipsisMenu')}
                  </SelectWrapper>
                ))}
              </StyledSelectGroupPopup>
            </FocusScope>
          )}
        />
      )}
      <StyledSelectGroupFlexibleWrapper ref={containerRef}>
        <StyledSelectGroup {...otherProps} ref={forwardedRef}>
          {visibleSelects.map((visibleSelect, index) => (
            <SelectWrapper
              allowShrink={allowShrink}
              key={visibleSelect.id}
              ref={attachVisibleItemRef(index)}
            >
              {renderSelect(visibleSelect, 'InFlow')}
            </SelectWrapper>
          ))}
        </StyledSelectGroup>
      </StyledSelectGroupFlexibleWrapper>
    </Inline>
  );
});

SelectGroup.propTypes = propTypes;
