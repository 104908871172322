import React from 'react';
import { MenuItem } from '../../MenuItem/MenuItem.tsx';
import { NonAccessibleVerticalMenu } from '../../VerticalMenu/VerticalMenu.tsx';
import { VerticalMenuFrameProps } from '../../VerticalMenu/VerticalMenuFrame.tsx';
import { useNonAccessibleVerticalMenu } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { IBaseSelectItem } from '../types.ts';

const items: IBaseSelectItem[] = [
  {
    id: 'Empty',
    label: 'We couldn’t find any match.',
  },
];

export interface IEmptyVerticalMenuProps extends Pick<VerticalMenuFrameProps, 'width'> {}

export const EmptyVerticalMenu = React.forwardRef<HTMLDivElement, IEmptyVerticalMenuProps>(
  ({ width, ...restProps }, forwardedRef) => {
    const { verticalMenuState } = useNonAccessibleVerticalMenu<IBaseSelectItem>(items);

    return (
      <NonAccessibleVerticalMenu
        ref={forwardedRef}
        renderItem={({ item }) => (
          <MenuItem tooltipText={item.textValue} menuItemState="disabled" text={item.textValue} />
        )}
        state={verticalMenuState}
        width={width}
        {...restProps}
      />
    );
  },
);

EmptyVerticalMenu.displayName = 'EmptyVerticalMenu';
