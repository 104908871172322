import { TextTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { UnitOption } from '../../../../contentInventory/content/models/textLengthOptions.ts';
import { IBaseTextTypeElementData } from '../../types/IBaseTextTypeElementData.type.ts';
import {
  EmptyBaseTypeElementData,
  ITypeElementDataWithValidationRegex,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface ITextTypeElementData
  extends IBaseTextTypeElementData,
    ITypeElementDataWithValidationRegex {
  readonly defaultValue: string;
}

export const EmptyTextTypeElementData: ITextTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Recommended,
  _lengthUnitOption: UnitOption.Words,
  aiGuidelinesIds: [],
  defaultValue: '',
  maxChars: null,
  maxWords: null,
  type: ElementType.Text,
  validationRegex: null,
};

export function getTextTypeElementDataFromServerModel(
  serverModel: TextTypeElementServerModel,
): ITextTypeElementData {
  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyTextTypeElementData._configurability,
    _lengthUnitOption: serverModel.maxChars ? UnitOption.Characters : UnitOption.Words,
    aiGuidelinesIds: serverModel.aiGuidelinesIds ?? [],
    defaultValue: serverModel.default?.global.value ?? '',
    maxChars: serverModel.maxChars
      ? serverModel.maxChars.toString()
      : EmptyTextTypeElementData.maxChars,
    maxWords: serverModel.maxWords
      ? serverModel.maxWords.toString()
      : EmptyTextTypeElementData.maxWords,
    type: EmptyTextTypeElementData.type,
    validationRegex: serverModel.validationRegex,
  };
}
