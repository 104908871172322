import React, { useCallback } from 'react';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ListingRequestOptionsModel } from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { TaskWidgetDetailTableLiveContainer } from '../../shared/containers/TaskWidgetDetailTableLiveContainer.tsx';
import {
  useTrackWidgetDetailItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../shared/hooks/tracking.ts';
import { TasksAssignedByYouWidget } from '../components/TasksAssignedByYouWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const TasksAssignedByYouWidgetLiveContainer: React.FC = () => {
  const tasksFetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal: AbortSignal) =>
      missionControlRepository.getTasksCreatedByCurrentUser(requestOptions, abortSignal),
    [],
  );

  const trackWidgetDetailItemClicked = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.TasksAssignedByYou,
  );
  const trackWidgetShowMoreButtonClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.TasksAssignedByYou,
  );

  return (
    <TasksAssignedByYouWidget
      onDetailItemClick={trackWidgetDetailItemClicked}
      onShowMoreClick={trackWidgetShowMoreButtonClick}
      tasksFetcher={tasksFetcher}
      WidgetDetail={TaskWidgetDetailTableLiveContainer}
    />
  );
};

TasksAssignedByYouWidgetLiveContainer.displayName = 'TasksAssignedByYouWidgetLiveContainer';
